import {useTranslation} from "react-i18next";

interface props {
    fnSaveHeight: () => void;
    btnActivate: boolean;
}

const HeightFooterView = ({
                              fnSaveHeight,
                              btnActivate,
                          }: props) => {
    const { t } = useTranslation('register')
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    className="btn btnBig btnBlack"
                    onClick={fnSaveHeight}
                    disabled={btnActivate}
                >
                    <span>{t('common.next')}</span>
                </button>
            </div>
        </footer>
    );
};

export default HeightFooterView;
