import { useTranslation } from 'react-i18next';

const RoomDisposedPopView = ({ setRoomDisposedPop }) => {
	const { t } = useTranslation('metaverse');

	return (
		<div className="roomDisposedWrap">
			<div
				className="popupInfo padding-t36"
				style={{
					whiteSpace: 'pre-wrap',
				}}
			>
				<p className="blackTxt text-c bold-500">{t('invalidLink')}</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={() => {
							setRoomDisposedPop(false);
						}}
					>
						<span>{t('confirm')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default RoomDisposedPopView;
