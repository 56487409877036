import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { PATH } from '@dating/constants/RoutingEndPoints';
import LeaveChatContainer from '@dating/ui/components/leaveChat/LeaveChatContainer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MyManagerMessageDetailHeaderView = ({
	setOpenLeaveChat,
	modifyMenuPop,
	navigationRoom,
	handleMessageSendClick,
}) => {
	const { t } = useTranslation('messenger');
	const { setShowDirectMessageList } = useDirectMessageRepo();
	const navigate = useNavigate();
	const handleBackClick = () => {
		setShowDirectMessageList(true);
		handleMessageSendClick();
		const isOnline = sessionStorage.getItem('isOnline') !== 'false';
		navigate(
			isOnline ? PATH.MESSENGER.MANAGER_ONLINE : PATH.MESSENGER.MANAGER_OFFLINE,
		);
		sessionStorage.removeItem('isOnline');
	};
	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left  d-flex">
					<button className="btnBack" onClick={handleBackClick} />
					<p className="bold margin-l8">
						<span className="cmBadge h-Badge">{t('coupleManager')}</span>
						{navigationRoom.otherUserInfo.nickName}
					</p>
				</div>
				<div className="h-right">
					<div className="menuDotWap top">
						<button className="menuDot" onClick={(e) => modifyMenuPop(e)} />
						<LeaveChatContainer setOpenLeaveChat={setOpenLeaveChat} />
					</div>
				</div>
			</div>
		</header>
	);
};

export default MyManagerMessageDetailHeaderView;
