import { useLocation } from 'react-router-dom';
import ManagerProfileCardView from '@/ui/components/dm/manager/managerProfile/views/ManagerProfileCardView';
import { Outlet } from 'react-router-dom';
import { useMyManagerService } from '@/ui/components/dm/myManager/service/useMyManagerService';
import MyManagerTabView from '@/ui/components/dm/myManager/views/MyManagerTabView';
import PurchaseUseButtonView from '../../../../../ui/components/dm/myManager/purchase/views/PurchaseUseButtonView';
import PurchaseButtonView from '../../../../../ui/components/dm/myManager/purchase/views/PurchaseButtonView';
import { useManagerRepo } from '@dating/repository/manager/useManagerRepo';
import { useManagerAdapter } from '@dating/adapter/manager/useManagerAdapter';
import { useEffect } from 'react';

const MyManagerLayout = () => {
	const {
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		isOnline,
		setIsOnline,
		srcPrefix,
		managerProfile,
	} = useMyManagerService();

	const { activeManagerProducts } = useManagerRepo();
	const { getActiveManagerProduct } = useManagerAdapter();

	const location = useLocation();

	const isOnlinePath = location.pathname.includes('/managerOnline');
	const isOfflinePath = location.pathname.includes('/managerOffline');

	useEffect(() => {
		getActiveManagerProduct();
	}, [location.pathname]);

	const isValidAndAvailable = (product: any) => {
		if (!product) return false;

		const hasValidIds = product.orderId !== null && product.productId !== null;
		const hasAvailableCount = product.availableCnt > 0;

		// useStatus가 'DONE'이고 availableCnt가 0인 경우도 false 반환
		if (product.useStatus === 'DONE' && product.availableCnt === 0) {
			return false;
		}

		return hasValidIds && hasAvailableCount;
	};
	// 각각의 상품 존재 여부 확인
	const hasOnlineProduct = isValidAndAvailable(activeManagerProducts?.online);
	const hasOfflineProduct = isValidAndAvailable(activeManagerProducts?.offline);

	// 현재 경로에 따라 적절한 버튼 결정
	const shouldShowPurchaseUseButton = isOnlinePath
		? hasOnlineProduct
		: isOfflinePath
		? hasOfflineProduct
		: false;

	const shouldHideButton = (product) => {
		return (
			product?.availableCnt === 0 &&
			(product?.useStatus === 'WAIT' || product?.useStatus === 'USING')
		);
	};

	useEffect(() => {
		if (isOnlinePath) {
			setShowOnlineMessageList(true);
		} else {
			setShowOnlineMessageList(false);
		}
	}, [isOnlinePath]);

	return (
		<div className="messengerWrap">
			<div className="inner">
				<div className="tabContent">
					<div className="tabCon tabCon02 active">
						<div className="msgWrap">
							<ManagerProfileCardView
								srcPrefix={srcPrefix}
								managerProfile={managerProfile}
							/>
							<MyManagerTabView
								setIsOnline={setIsOnline}
								setShowOnlineMessageList={setShowOnlineMessageList}
								setShowOfflineMessageList={setShowOfflineMessageList}
								isOnline={isOnline}
							/>
							<Outlet />

							{/* availableCnt가 0이고 WAIT 상태일 때는 아무 버튼도 보이지 않음 */}
							{shouldHideButton(
								isOnlinePath
									? activeManagerProducts?.online
									: activeManagerProducts?.offline,
							) ? null : shouldShowPurchaseUseButton ? (
								<PurchaseUseButtonView isOnline={isOnlinePath} />
							) : (
								<PurchaseButtonView isOnline={isOnline} />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyManagerLayout;
