import { useTranslation } from 'react-i18next';

const RoomPwdInputWrapView = ({ roomPwdResult, handleRoomPwdChange }) => {
	const { t } = useTranslation('metaverse');

	return (
		<div className="roomNameBox margin-t10">
			<div className="leftRightTxt">
				<p className="fz12 color-999">{t('password')}</p>
				<span className="fz12 color-999">{roomPwdResult}/6</span>
			</div>
			<div className="inputWrap margin-t4">
				<div className="input">
					<input
						name="password"
						type="text"
						inputMode="numeric"
						pattern="[0-9]*"
						onChange={handleRoomPwdChange('password')}
						maxLength={6}
						placeholder={t('passwordPlaceholder')}
					/>
				</div>
			</div>
		</div>
	);
};

export default RoomPwdInputWrapView;
