import { UserProfileCOVO } from '@/site/api';
import ProfileSmImgView from '@dating/ui/pages/header/views/ProfileSmImgView';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

interface Props {
	myProfile: UserProfileCOVO;
	srcPrefix: string;
	managerEmpty: boolean;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	setShowOnlineMessageList: (show: boolean) => void;
	setShowOfflineMessageList: (show: boolean) => void;
	setIsOnline: (isOnline: boolean) => void;
	isMessenger: boolean;
	setIsMessenger: (isMessenger: boolean) => void;
}

const MessageHeaderView = ({
	myProfile,
	srcPrefix,
	setMyProfileOpen,
	managerEmpty,
	setShowOnlineMessageList,
	setShowOfflineMessageList,
	setIsOnline,
	isMessenger,
	setIsMessenger,
}: Props) => {
	const { t } = useTranslation('messenger')
	const navigate = useNavigate();
	const getManagerPath = () =>
		managerEmpty ? PATH.MESSENGER.MANAGER_NONE : PATH.MESSENGER.MANAGER_ONLINE;

	const TABS = {
		MESSENGER: {
			title: t('messenger'),
			path: PATH.MESSENGER.ROOT,
			type: 'MESSENGER',
		},
		MANAGER: {
			title: t('connectManager'),
			path: PATH.MESSENGER.MANAGER_ONLINE,
			type: 'MANAGER',
		},
	} as const;
	return (
		<header className="cateHeader">
			<div className="headerWrap">
				<div className="h-left">
					<div className="headerTab">
						<button
							type="button"
							className={`tabButton tab01 ${isMessenger ? 'active' : ''}`}
							onClick={() => {
								setIsMessenger(true);
								navigate(TABS.MESSENGER.path);
								setShowOfflineMessageList(false);
								setShowOnlineMessageList(false);
							}}
						>
							<span>{TABS.MESSENGER.title}</span>
						</button>
						<button
							type="button"
							className={`tabButton tab02 ${!isMessenger ? 'active' : ''}`}
							onClick={() => {
								setIsMessenger(false);
								setIsOnline(true);
								setShowOfflineMessageList(false);
								setShowOnlineMessageList(true);
								navigate(getManagerPath());
							}}
						>
							<span>{TABS.MANAGER.title}</span>
						</button>
					</div>
				</div>
				<div className="h-center" />
				<div className="h-right">
					<ProfileSmImgView
						myProfile={myProfile}
						srcPrefix={srcPrefix}
						setMyProfileOpen={setMyProfileOpen}
					/>
				</div>
			</div>
		</header>
	);
};

export default MessageHeaderView;
