import OfflineManagerDetailContainer from '@/ui/components/dm/manager/offline/detail/OfflineManagerDetailContainer';
import OnlineManagerDetailContainer from '@/ui/components/dm/manager/online/detail/OnlineManagerDetailContainer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ManagerHushHomeContainer } from '@dating/ui/pages/home/managerHushHome/ManagerHushHomeContainer';
import { ManagerMyProfileContainer } from '@dating/ui/pages/myProfile/ManagerMyProfileContainer';
import { ManagerLikeContainer } from '@dating/ui/pages/like/managerLike/ManagerLikeContainer';
import { useMessageRouteHandler } from '@/hooks/useMessageRouteHandler';
import Spinner from "@dating/ui/components/spinner/Spinner";
import {lazy, Suspense} from 'react';

const OnlineManagerListContainer = lazy(() => import('@/ui/components/dm/manager/online/list/OnlineManagerListContainer'));
const OfflineManagerListContainer = lazy(() => import('@/ui/components/dm/manager/offline/list/OfflineManagerListContainer'));

const ManagerRouter = () => {
	const location = useLocation();
	useMessageRouteHandler();
	return (
		<Suspense fallback={<Spinner/>}>
			<Routes location={location}>
				{/* 홈 */}
				<Route path="home" element={<ManagerHushHomeContainer />} />

				{/* 내 프로필*/}
				<Route path="/myProfile" element={<ManagerMyProfileContainer />} />

				{/* 좋아요 */}
				<Route path="/like" element={<ManagerLikeContainer />} />

				{/* 온라인 매니저 전용 routes */}
				<Route path="messenger/online/*">
					<Route path="*" element={<OnlineManagerListContainer />} />
					<Route path=":roomId" element={<OnlineManagerDetailContainer />} />
				</Route>
				{/* 오프라인 매니저 전용 routes */}
				<Route path="messenger/offline/*">
					<Route path="*" element={<OfflineManagerListContainer />} />
					<Route path=":roomId" element={<OfflineManagerDetailContainer />} />
				</Route>
			</Routes>
		</Suspense>
	);
};

export default ManagerRouter;
