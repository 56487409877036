import SettingsContainer from '@dating/ui/pages/settings/SettingsContainer';
import AccountSettingContainer from '@dating/ui/pages/settings/accountSetting/AccountSettingContainer';
import AgreeContainer from '@dating/ui/pages/settings/agree/AgreeContainer';
import BlockAcquaintanceContainer from '@dating/ui/pages/settings/blockAcquaintance/BlockAcquaintanceContainer';
import BlockListContainer from '@dating/ui/pages/settings/blockList/BlockListContainer';
import IdealSettingEditContainer from '@dating/ui/pages/settings/idealSettingEdit/IdealSettingEditContainer';
import DeleteUserContainer from '@dating/ui/pages/settings/deleteUser/DeleteUserContainer';
import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from './RouteTransition';
import { Policy01Container } from '@dating/ui/pages/settings/agree/Policy01Container';
import { Policy02Container } from '@dating/ui/pages/settings/agree/Policy02Container';
import { Policy03Container } from '@dating/ui/pages/settings/agree/Policy03Container';
import { Policy04Container } from '@dating/ui/pages/settings/agree/Policy04Container';
import { InactiveAccountContainer } from '@dating/ui/pages/settings/inactiveAccount/InactiveAccountContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlerUtils } from '@/utils/errorHandlerUtils';

const SettingRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<RouteTransition location={location} navigationType={navigationType}>
				<Routes location={location}>
					<Route path="/" element={<SettingsContainer />} />
					<Route path="/accountSetting" element={<AccountSettingContainer />} />

					<Route path="/agree" element={<AgreeContainer />} />
					<Route
						path="/blockAcquaintance"
						element={<BlockAcquaintanceContainer />}
					/>
					<Route path="/blockList" element={<BlockListContainer />} />
					<Route path="/withdrawal" element={<DeleteUserContainer />} />
					<Route path="/inactiveAccount" element={<InactiveAccountContainer />} />
					<Route
						path="/idealSettingEdit"
						element={<IdealSettingEditContainer />}
					/>
					<Route path="/policy01" element={<Policy01Container/>} />
					<Route path="/policy02" element={<Policy02Container/>} />
					<Route path="/policy03" element={<Policy03Container/>} />
					<Route path="/policy04" element={<Policy04Container/>} />
				</Routes>
			</RouteTransition>
		</ErrorBoundary>
	);
};

export default SettingRouter;
