import MessageListView from './views/MessageListView';
import { useMessageListService } from './service/useMessageListService';
import { useEffect } from 'react';
const MessageListContainer = () => {
	const { showDirectMessageList, setIsMessenger, fadeIn } =
		useMessageListService();

	useEffect(() => {
		setIsMessenger(true);
	}, []);

	return (
		<>
			{showDirectMessageList && (
				<div id="conWrap" className="metaPopWrap bdNone">
					<div className="messengerWrap">
						<div className="inner">
							<div className="tabContent">
								<div
									className={`tabCon tabCon01 active spinnerContent ${
										fadeIn ? 'fade-in' : ''
									}`}
								>
									<MessageListView />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default MessageListContainer;
