import { useTranslation } from 'react-i18next';

interface Props {
    confirmPopMessage: string;
    fnCommonConfirmPopExecute: () => void;
    fnCommonConfirmPopOff: () => void;
}

export const CommonConfirmPopView = ({
                                         confirmPopMessage,
                                         fnCommonConfirmPopExecute,
                                         fnCommonConfirmPopOff,
                                     }: Props) => {
    const { t } = useTranslation('common');

    return (
        <div className="logoutWrap">
            <div className="popupInfo padding-t36">
                <p className="blackTxt text-c bold-500">
                    {confirmPopMessage}
                </p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            fnCommonConfirmPopOff();
                        }}
                    >
                        <span>{t('report.cancel')}
                            {/* 취 소 */}
                        </span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnCommonConfirmPopExecute();
                        }}
                    >
                        <span>{t('report.confirm')}
                            {/* 확 인 */}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};
