import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import {
	type BlockPostCIVO,
	CommentCIVO,
	DeleteCommentCIVO,
	PostIdCIVO,
	PostInfoCOVO,
	PostLikeCIVO,
} from '@/site/api';
import { useFeedListStateRepo } from '@dating/ui/pages/lounge/feedList/useFeedListStateRepo';
import {useEffect, useRef, useState} from 'react';
import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useQuery } from '@tanstack/react-query';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useLocation} from "react-router-dom";

export const useFeedListService = () => {
	const {
		postUser,
		setPostUser,
		checkedFilter,
		setCheckedFilter,
	} = useFeedListStateRepo();

	const {
		setFeedCommentOpen,
		clearFeedForm,
		keyword,
		openFeedHidePop,
		setOpenFeedHidePop,
		blockPostId,
		setBlockPostId,
		feedFilterOpen,
		setFeedFilterOpen,
		feedFilterList,
		feedEditPops,
		setFeedEditPops,
		scrollY,
		setScrollY,
		setIsTopBtnVisible,
		isTopBtnVisible,
		commentState,
		setCommentState,

		getMaxPage,
		
		// 전역적으로 쓰게 이쪽으로
		postList,
		setPostList,
		setPostListAdd,
		fadeIn,
		setFadeIn,
		isClickedFeedMenu,
		setIsClickedFeedMenu,
		hasMore,
		setHasMore,
		pageArray,
		setPageArray,
		setPageArrayReset,

		commentValue,
		setCommentValue,
		openComment,
		setOpenComment,
		focusedPostId,
		setFocusedPostId,

		commentResponse,
		setCommentResponse,
		observeCard,
		setObserveCard,
		isSearchPost,
		setIsSearchPost,
		haveToRefresh,
		setHaveToRefresh,
	} = useLoungeRepo();

	const { clearPreviewURLs } = useImgCropRepo();

	const {
		myProfile,
		myProfileOpen,
		setUserProfileOpen,
		setMyProfileOpen,
		srcPrefix,
		sliderSettings,
	} = useDatingRepo();

	const {
		getPosts,
		getPosts2,
		getSearchPosts,
		removePost,
		saveComment,
		statusSaveComment,
		dataSaveComment,
		getComment,
		getPostsByGender,
		deleteComment,
	} = useLoungeAdapter();

	const {
		savePostLike,
		statusSavePostLike,
		dataSavePostLike,
	} = useLikeAdapter();

	const {
		saveBlockPost,
		statusSaveBlockPost,
		dataSaveBlockPost,
	} = useSettingAdapter();

	const location = useLocation()

	useEffect(()=>{

	})
	const { status: statusGetComment, data: dataGetComment } = useQuery({
		queryKey: ['getComment',{menuType:myProfileOpen?'profile':'lounge', postId: focusedPostId.toString()}],
		queryFn: ({postId, menuType}) => {
			return getComment(focusedPostId.toString(),myProfileOpen?'profile':'lounge');
		},
	});

	const { status: statusGetSearchPosts, data: dataGetSearchPosts } = useQuery({
		queryKey: ['getSearchPosts',{searchWord: keyword.trim()}],
		queryFn: () => {
			return getSearchPosts({searchWord: keyword.trim()});
		},
		enabled: keyword.trim().length != 0,
	});
	////////////////////////////////////////////////

	/*
	* saveComment 하면, 창닫기 처리 => 댓글 그대로 추가해서 보여주는 처리로 변경하였음
	* */
	/*
	useEffect(() => {
		if (statusSaveComment === 'success' && dataSaveComment) {
			commentViewHandler(false);

			// 댓글입력 input 초기화
			setCommentValue('');
		}
	}, [statusSaveComment, dataSaveComment]);
	* */

	useEffect(() => {
		if(location.pathname != PATH.LOUNGE.SEARCH_LIST) return
		if (statusGetSearchPosts === 'pending') {
			setFadeIn(false);
		} else if (statusGetSearchPosts === 'success' && dataGetSearchPosts) {
			setIsSearchPost(true)
			setPostList(dataGetSearchPosts);

			// 케밥 control list set
			initFeedEditPops(dataGetSearchPosts);

			setFadeIn(true);
		}
	}, [statusGetSearchPosts, dataGetSearchPosts]);

	/*
	 * deleteContent
	 * 피드삭제
	 */
	const deleteContent = (id: string) => {
		const map: PostIdCIVO = {
			postId: id.toString(),
		};
		removePost(map);
	};

	/*
	 * commentViewHandler
	 * 댓글창 오픈 시 container로 상태값 전달, wrap에 no-scroll 클래스 추가
	 */
	const commentViewHandler = (state: boolean) => {
		// 댓글창 열기
		setOpenComment(state);

		// wrap에 no-scroll class 추가
		setCommentState(state);

		// 댓글창 활성화 시 footer menu z-index 조정
		setFeedCommentOpen(state);

		setCommentValue(''); // 댓글입력 input 초기화
	};

	/*
	 * commentListHandler
	 * 선택한 postId로 commentList handle
	 * menuType : profile-댓글 모두 삭제 가능, lounge-내 댓글만 삭제 가능
	 */
	function commentListHandler(
		postId: string,
		postUser: { userUid: string; nickname: string },
	) {
		// 클릭한 postId repo에 저장
		setFocusedPostId(postId);

		getComment(postId,'lounge').then((value) => {
			if (postId != '') {
				// 댓글리스트 repo에 저장
				setCommentResponse(value);

				// 작성자 닉네임 저장
				setPostUser(postUser);
			} else {
				setCommentResponse([]);
			}
		}).catch(
			reason => {
				
			},
		);
	}

	/*
	 * fnSaveComment
	 * 댓글 등록
	 */
	const fnSaveComment = (id: string, comment: string) => {
		if (comment != null) {
			let commentObject: CommentCIVO = {
				postId: id,
				comment: comment,
			};
			saveComment(commentObject);
		}
	};

	/*
	 * fnSavePostLike
	 * 게시글 좋아요
	 */
	const fnSavePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		savePostLike(map);
	};

	/*
	 * fnSaveBlockPost
	 * 게시글 숨기기
	 */
	const fnSaveBlockPost = () => {
		if (blockPostId != '') {
			const map: BlockPostCIVO = {
				postId: blockPostId,
			};
			saveBlockPost(map);
		}
		setCommentState(false);
	};


	useEffect(() => {
		if (statusSaveBlockPost === 'success' && dataSaveBlockPost) {
			setOpenFeedHidePop(false);
			setBlockPostId('');
		}
	}, [statusSaveBlockPost, dataSaveBlockPost]);

	/*
	 * fnGetPosts
	 * 게시글 조회
	 */
	const flagRef = useRef<boolean>(false);
	const fnGetPosts = () => {
		// 이미 조회 중 or 더 이상 데이터없음
		if(flagRef.current  || !useLoungeRepo.getState().hasMore) return
		flagRef.current = true
		getPosts2(getMaxPage() + 1).then((value) => {
			if(getMaxPage() == null || getMaxPage() == 0){
				setPageArray(1)
				setIsSearchPost(false)
				setPostList(value)
			} else if (!useLoungeRepo.getState().pageArray.includes(getMaxPage() + 1)) {
				setPageArray(getMaxPage() + 1)
				setPostListAdd(value);
			}
			setFeedFilterOpen(false);
			setFadeIn(true);
			if(!value || value.length == 0) {
				setHasMore(false)
			}
			flagRef.current = false
		}).catch((e)=>{
			flagRef.current = false
		});
	};
	/*
	 * fnGetPostsByGender
	 * 성별 필터 적용 게시글 조회
	 */
	const fnGetPostsByGender = (gender: string) => {
		getPostsByGender(gender).then((value) => {
			setIsSearchPost(false)
			setPostList(value);
			setFeedFilterOpen(false);
			setFadeIn(true);
		}).catch(
			reason => {
				
			},
		);
	};

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feeds: PostInfoCOVO[]) => {
		let newItem = {};
		feeds.map((feed, i) => {
			newItem[feed.postId] = false;
		});
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		initFeedEditPops(postList);
		setIsClickedFeedMenu({ postId: postId, state: true });
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);


	/*
	 * fnDeleteComment
	 * 댓글 삭제
	 */
	const fnDeleteComment = (param: { postId: string, commentId: number }) => {
		const map: DeleteCommentCIVO = {
			postId: param.postId,
			commentId: param.commentId,
		};
		deleteComment(map).then(value => {
			if (value) {
				getComment(param.postId,'lounge').then(response => {
					if (response && response.length > 0) {
						// 댓글리스트 repo에 저장
						setCommentResponse(response);
					} else {
						setCommentResponse([]);
					}

					// 작성자 닉네임 저장
					setPostUser(postUser);
				});
			}
		}).catch(
			reason => {
				
			},
		);
	};

	/*
	 * handleScrollY
	 * 스크롤 할 때 마다 scrollY값 set
	 */
	const handleScrollY = (yOffset: number) => {
		if (yOffset) {
			setScrollY(yOffset);
		}
	};

	/*
	 * scrollToTop
	 * 탑버튼 클릭 시 스크롤 위치 초기화
	 */
	const scrollToTop = (feedWrap: HTMLDivElement) => {
		const scrollStep = -feedWrap.scrollTop / 15;
		const scrollInterval = setInterval(() => {
			if (feedWrap.scrollTop !== 0) {
				feedWrap.scrollBy(0, scrollStep);
			} else {
				clearInterval(scrollInterval);
			}
		}, 15);
	};

	return {
		scrollToTop,
		handleScrollY,
		fadeIn,
		getPosts2,
		postList,
		setPostList,
		setPostListAdd,
		deleteContent,
		commentState,
		commentListHandler,
		commentViewHandler,
		openComment,
		fnSaveComment,
		focusedPostId,
		commentResponse,
		sliderSettings,
		clearFeedForm,
		clearPreviewURLs,
		commentValue,
		setCommentValue,
		postUser,
		setUserProfileOpen,
		myProfileOpen,
		setMyProfileOpen,
		fnSavePostLike,
		srcPrefix,
		myProfile,
		openFeedHidePop,
		setOpenFeedHidePop,
		fnSaveBlockPost,
		setBlockPostId,
		feedFilterOpen,
		setFeedFilterOpen,
		checkedFilter,
		setCheckedFilter,
		feedFilterList,
		fnGetPosts,
		fnGetPostsByGender,
		setFadeIn,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		fnDeleteComment,
		setIsTopBtnVisible,
		isTopBtnVisible,
		scrollY,
		hasMore,
		setHasMore,
		pageArray,
		setPageArray,
		setPageArrayReset,
		setFocusedPostId,
		observeCard,
		setObserveCard,
		isSearchPost,
		setIsSearchPost,
		haveToRefresh,
		setHaveToRefresh,
	};
};
