import {EditType} from "@dating/repository/profile/useEditProfileRepo";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    drinkingValue: string;
    setDrinkingValue: (drinkingValue: string) => void;
    fnModifyProfileDrink: () => void;
    closeEditPopup: (key: string) => void;
}

const EditDrinkingPopView = ({
                                 focusItem,
                                 editPop,
                                 setEditPop,
                                 drinkingValue,
                                 setDrinkingValue,
                                 fnModifyProfileDrink,
                                 closeEditPopup,
                             }: props) => {

    const { t } = useTranslation('register')

    const drinkings = [
        {
            label: t('idealDetail.noPreference'),
            code: 32,
        },
        {
            label: t('idealDetail.dontDrink'),
            code: 33,
        },
        {
            label: t('idealDetail.occasionallyDrink'),
            code: 34,
        },
        {
            label: t('idealDetail.frequentlyDrink'),
            code: 35,
        },
    ];


    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">{t('idealDetail.drink')}</h2>
                        <p className="titleSub01">{t('idealDetail.selectDrink')}</p>
                    </div>
                    <ul className="popCheckList">

                        {drinkings.map((item, i) => {
                            return (
                                <li key={item.code.toString()}>
                                    <span className="bold-500">{item.label}</span>
                                    <div>
                                        <input
                                            id={item.code.toString()}
                                            className="checkbox"
                                            name="drink-option"
                                            type="checkbox"
                                            checked={item.code.toString() === drinkingValue}
                                            onChange={() => setDrinkingValue(item.code.toString())}
                                        />
                                        <label htmlFor={item.code.toString()} className="checkbox-label"></label>
                                    </div>
                                </li>
                            );
                        })}

                    </ul>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileDrink();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditDrinkingPopView;