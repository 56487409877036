import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import HeightView from "./views/HeightView";
import HeightFooterView from "./views/HeightFooterView";
import {useHeightService} from "./service/useHeightService";
import React, {useEffect} from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";

import {useNavigate} from "react-router-dom";

const HeightContainer = () => {
    const {
        inputHeight,
        fnSaveHeight,
        handleInputChange,
        handleInputKeyDown,
        btnActivate,
        activeIndex,
        response,
        registerSteps,
    } = useHeightService()

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (response) {
            navigate(registerSteps[5].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView/>

            <div className="contentsWrap">
                <HeightView
                    step={step}
                    inputHeight={inputHeight}
                    handleInputChange={handleInputChange}
                    handleInputKeyDown={handleInputKeyDown}
                    activeIndex={activeIndex}
                />
            </div>

            <HeightFooterView
                fnSaveHeight={fnSaveHeight}
                btnActivate={btnActivate}
            />
        </div>
    );
};

export default HeightContainer;
