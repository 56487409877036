import {useTranslation} from "react-i18next";

interface props {
    showPopup: (isPopupVisible: boolean) => void;
    setPopupType: (popupType: string) => void;
    religion: {code: string, value: string};
    drinkingState: {code: string, value: string};
    smokingState: {code: string, value: string};
}

const IdealCateListView = ({
                               showPopup,
                               setPopupType,
                               religion,
                               drinkingState,
                               smokingState,
                           }: props) => {

    const { t } = useTranslation('register')

    return (
        <div className="cateList margin-t40">
            <div className="leftRightTxt">
                <span>{t('idealDetail.religion')}</span>
                <button
                    className={`${religion.value === "선택없음" ? "color-999" : "on"} "popOnBtn"`}
                    onClick={() => {
                        showPopup(true);
                        setPopupType("religion");
                    }}
                >
                    {religion.value == "선택없음" ? t('idealDetail.nothing') : religion.value}
                </button>
            </div>
            <div className="leftRightTxt">
                <span>{t('idealDetail.drink')}</span>
                <button
                    className={`${drinkingState.value === "선택없음" ? "color-999" : "on"} "popOnBtn"`}
                    onClick={() => {
                        showPopup(true);
                        setPopupType("drinking");
                    }}
                >
                    {drinkingState.value == "선택없음" ? t('idealDetail.nothing') : drinkingState.value}
                </button>
            </div>
            <div className="leftRightTxt">
                <span>{t('idealDetail.smoking')}</span>
                <button
                    className={`${smokingState.value === "선택없음" ? "color-999" : "on"} "popOnBtn"`}
                    onClick={() => {
                        showPopup(true);
                        setPopupType("smoking");
                    }}
                >
                    {smokingState.value == "선택없음" ? t('idealDetail.nothing') : smokingState.value}
                </button>
            </div>
        </div>
    );
};

export default IdealCateListView;
