import {useTranslation} from "react-i18next";

interface props {
    selectedGender: string;
    setSelectedGender: (selectedGender: string) => void;
}

const GenderSelectView = ({
                              selectedGender,
                              setSelectedGender,
                          }: props) => {
    const { t } = useTranslation('register')
    return (
        <div className="content">
            <div className="txtRadio d-flex gap-16">
                <div>
                    <input
                        type="radio"
                        id="men"
                        name="sex"
                        value="M"
                        onChange={() => setSelectedGender("M")}
                        checked={selectedGender === "M" ? true : false}
                    />
                    <label htmlFor="men">{t('genderStep.man')}</label>
                </div>
                <div>
                    <input
                        type="radio"
                        id="woman"
                        name="sex"
                        value="F"
                        onChange={() => setSelectedGender("F")}
                        checked={selectedGender === "F" ? true : false}
                    />
                    <label htmlFor="woman">{t('genderStep.woman')}</label>
                </div>
            </div>
        </div>
    );
};

export default GenderSelectView;
