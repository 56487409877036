import {EditType} from "@dating/repository/profile/useEditProfileRepo";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    smokingValue: string;
    setSmokingValue: (smokingValue: string) => void;
    fnModifyProfileSmoking: () => void;
    closeEditPopup: (key: string) => void;
}

const EditSmokingPopView = ({
                                focusItem,
                                editPop,
                                setEditPop,
                                smokingValue,
                                setSmokingValue,
                                fnModifyProfileSmoking,
                                closeEditPopup,
                            }: props) => {

    const { t } = useTranslation('register')

    const smokings = [
        {
            label: t('idealDetail.noPreference'),
            code: 36,
        },
        {
            label: t('idealDetail.dontDo'),
            code: 37,
        },
        {
            label: t('idealDetail.occasionallyDo'),
            code: 38,
        },
        {
            label: t('idealDetail.frequentlyDo'),
            code: 39,
        },
    ];

    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">{t('idealDetail.smoking')}</h2>
                        <p className="titleSub01">{t('idealDetail.selectSmoking')}</p>
                    </div>
                    <ul className="popCheckList">
                        {smokings.map((item) => {
                            return (
                                <li key={item.code.toString()}>
                                    <span className="bold-500">{item.label}</span>
                                    <div>
                                        <input
                                            id={item.code.toString()}
                                            className="checkbox"
                                            name="smoking-option"
                                            type="checkbox"
                                            value={item.code.toString()}
                                            checked={item.code.toString() === smokingValue}
                                            onChange={() => setSmokingValue(item.code.toString())}
                                        />
                                        <label htmlFor={item.code.toString()} className="checkbox-label"></label>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileSmoking();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditSmokingPopView;