import { detectDevice } from '@/utils/deviceDetection';
import { useAndroidJoyStickService } from './AndroidJoystick/service/useAndroidJoyStickService';
import IOSJoystickContainer from './iOSJoystick/IOSJoystickContainer';
import AndroidJoystickContainer from './AndroidJoystick/AndroidJoystickContainer';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

const JoyStickContainer = () => {
	const { position } = useAndroidJoyStickService();
	const { isIOS } = detectDevice();
	const { osType } = useAuthRepo();

	const shouldUseIOSJoystick =
		((window as any).flutter_inappwebview && osType === 'ios') ||
		(!!(window as any).flutter_inappwebview === false && isIOS);

	return (
		<div
			className="joystickWrapper"
			style={{
				position: 'absolute',
				left: `${position.x}px`,
				zIndex: '60',
				bottom: `${position.y}px`,
			}}
		>
			{shouldUseIOSJoystick ? (
				<IOSJoystickContainer size={120} stickSize={50} />
			) : (
				<AndroidJoystickContainer />
			)}
		</div>
	);
};

export default JoyStickContainer;
