/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PointCOVO } from '../models/PointCOVO';
import type { PointUsageHistoryCOVO } from '../models/PointUsageHistoryCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PointService {
    /**
     * 포인트 조회
     * @returns PointCOVO 포인트 조회 결과
     * @throws ApiError
     */
    public static getPoint(): CancelablePromise<PointCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/point/status',
        });
    }
    /**
     * 포인트 사용/적립/취소 내역 조회
     * @returns PointUsageHistoryCOVO 포인트 사용/적립/취소 내역 조회 결과
     * @throws ApiError
     */
    public static getPointUsageHistory(): CancelablePromise<PointUsageHistoryCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/point/usage-history',
        });
    }
}
