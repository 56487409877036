import {ReactNode, useRef, useState} from "react";
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useLocation} from "react-router-dom";

interface props {
    children: ReactNode;
    setList: () => void;
    setPageArrayReset : () => void;
    hasMore: boolean;
    setHasMore: (hasMore: boolean) => void;
    scrollWrapRef: React.RefObject<HTMLDivElement>;
    setObserveCard: (id:string) => void;
}

export const PullToRefresh = ({children, setList, setPageArrayReset, hasMore, setHasMore, scrollWrapRef, setObserveCard}:props) => {
    const maxDistance = 80
    // 스피너 영역
    const spinnerRef = useRef<HTMLDivElement>(null);
    // refresh 중?(데이터 불러오는 중?)
    const [isRefreshing, setIsRefreshing] = useState(false);
    // 터치 시작위치
    const [startY, setStartY] = useState(0);
    // 당겨졌는가
    const [pulled, setPulled] = useState(false);

    const resetToInitial = () => {
        if (spinnerRef.current) {
            spinnerRef.current.style.transition = 'height 0.1s ease'; // 0.1초 동안 부드럽게 닫히기
            spinnerRef.current.style.height = '0'; // 높이를 점차 줄이기
            spinnerRef.current.addEventListener('transitionend', () => {
                spinnerRef.current.style.willChange = 'unset';
            }, { once: true }); // 이벤트 리스너가 한 번만 실행되도록 설정
        }
        setPulled(false);
        setIsRefreshing(false);
    };

    const onStart = (y: number) => {
        setStartY(y);
        setPulled(true);
        if (spinnerRef.current) {
            spinnerRef.current.style.willChange = 'height';
        }
    };

    const onMove = (y: number) => {
        if (pulled && spinnerRef.current) {
            const moveY = y;
            const pulledDistance = Math.min(Math.pow(moveY - startY, 0.875), maxDistance);

            if (pulledDistance > 0) {
                spinnerRef.current.style.height = `${pulledDistance}px`;
                preventBodyScroll();

                if (pulledDistance >= maxDistance) {
                    setIsRefreshing(true);
                } else {
                    setIsRefreshing(false);
                }
            } else {
                if (pulled && spinnerRef.current) {
                    ableBodyScroll();
                    resetToInitial();
                }
            }
        }
    };

    const onEnd = async () => {
        if (pulled) {
            ableBodyScroll();
            if (isRefreshing) {
                try {
                    setPageArrayReset()
                    setObserveCard('')
                    await setList()
                    await new Promise((resolve) => {
                        setTimeout(resolve, 400);
                    });
                    resetToInitial();
                } catch (error) {
                    console.error('Error while refreshing:', error);
                }
            } else {
                resetToInitial();
            }
        }
    };

    const ableBodyScroll = () => {
        document.body.style.overflow = 'auto';
    };

    const preventBodyScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const onTouchStart = (e: TouchEvent) => {
        if (scrollWrapRef?.current?.scrollTop === 0) {
            onStart(e.touches[0].clientY);
        }
    }
    const onTouchMove = (e: TouchEvent) => {
        if (pulled) {
            onMove(e.touches[0].clientY);
        }
    }
    const onTouchEnd = (e: TouchEvent) => {
        if (pulled) {
            onEnd();
        }
    }

    const location = useLocation();

    return(
        <>
            {
                location.pathname == PATH.LOUNGE.LIST &&
                <div ref={scrollWrapRef} id={'scrollWrap'} className={'scroll_container'}>
                    <div ref={spinnerRef}>
                        {
                            isRefreshing &&
                            <div className={'image-container'}>
                                <img className={'centered-gif'} src={'/assets/img/common/icon/load_spinner.gif'}/>
                            </div>
                        }
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onTouchStart={(e) => onTouchStart(e)}
                        onTouchMove={(e) => onTouchMove(e)}
                        onTouchEnd={(e) => onTouchEnd(e)}
                    >
                        {children}
                    </div>
                </div>
            }
            {
                location.pathname != PATH.LOUNGE.LIST &&
                    <div ref={scrollWrapRef} id={'scrollWrap'}>
                        {children}
                    </div>
            }
        </>
    )
}
