import { Key } from 'react';

const WomenListView = ({ headCount, players, srcPrefix }) => {
	const maxWomenCount = headCount === 4 ? 2 : headCount === 3 ? 1 : 3;
	const womenPlayers = players.filter(
		(player: { gender: string; role: string }) =>
			player.gender === 'F' && player.role === 'NORMAL',
	);

	return (
		<div className="womenList">
			{womenPlayers.map(
				(profile: {
					uuid: Key | null | undefined;
					profileUrl: any;
					name: string;
				}) => (
					<div key={profile.uuid}>
						<img src={srcPrefix + profile.profileUrl} alt={profile.name} />
					</div>
				),
			)}

			{[...Array(maxWomenCount - womenPlayers.length)].map((_, index) => (
				<div key={`empty-${index}`}></div>
			))}
		</div>
	);
};

export default WomenListView;
