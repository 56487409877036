import { useNavigate } from 'react-router-dom';
import { useAttendCheckService } from '@dating/ui/pages/content/event/attendCheck/useAttendCheckService';
import {useTranslation} from "react-i18next";

export const AttendCheckContainer = () => {
	const {
		fnCheckDailyAttendance,
		attendChecks,
	} = useAttendCheckService();

	const navigate = useNavigate();

	const { t } = useTranslation('setting')

	return (
		<div className="wrap rightShow profileWrap footerNone active">
			<header>
				<div className="headerWrap">
					<div className="h-left">
					</div>
					<div className="h-center">
						<p className="title02">{t('attendCheck')}</p>
					</div>
					<div className="h-right">
						<button
							type="button"
							className="btnClose"
							onClick={() => {
								navigate(-1);
							}}
						>
							<span className="hide">닫기</span>
						</button>
					</div>
				</div>
			</header>

			<div className="popConWrap padding-b0">
				<div className="attendWrap">
					<div className="attendTitBox">
						<p className="attendSubTit">{t('attendCheckQuote')}</p>
						<h3 className="attendTit" dangerouslySetInnerHTML={{ __html: t('attendTitle') }}>
						</h3>
					</div>

					<div className="attendList">
						{
							attendChecks.attendCnt > 0 ?
								new Array(10).fill(0).map((item, index) => {
									const dayNumStyle = {
										"backgroundColor": "#9F9F9F"
									};

									return (
										<button
											key={index}
											type="button"
											className={`attendCheckItem ${index+1 < attendChecks.attendCnt ? 'active' : index+1 === attendChecks.attendCnt ? attendChecks.status: ''}`}
											onClick={(e) => {
												if (e.currentTarget.classList.contains('ready') && index+1 === attendChecks.attendCnt) {
													fnCheckDailyAttendance();
												}
											}}
										>
											<div className="attendStar ic-attend">
												<span>
													{
														index === 9 ? 10 : 1
													}
												</span>
											</div>
											<span className="dayNum"
												  style={
													  (index+1 === attendChecks.attendCnt) || (index+1 < attendChecks.attendCnt) ? undefined : dayNumStyle
												  }
											>
											{

												(index + 1 === attendChecks.attendCnt) && attendChecks.status === 'ready' ? t('attend') : index+1 + t('day')
											}
										</span>
										</button>
									);
								}) : null
						}
					</div>
				</div>
				<div className="inner">
					<div className="exBox"
						 dangerouslySetInnerHTML={{ __html: t('attendGuide') }}
					>
					</div>
				</div>
			</div>
		</div>
	);
};