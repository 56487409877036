import React from 'react';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useMyManagerListService } from '@/ui/components/dm/myManager/online/list/service/useMyManagerListService';
import { useManagerRepo } from '@dating/repository/manager/useManagerRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useManagerAdapter } from '@dating/adapter/manager/useManagerAdapter';
import { useTranslation } from 'react-i18next';

interface PurchaseUseButtonProps {
	className?: string;
	isOnline: boolean;
}

const PurchaseUseButtonView: React.FC<PurchaseUseButtonProps> = ({
	className = 'mgFoot',
	isOnline,
}) => {
	const { t } = useTranslation('messenger');
	const { successFn } = useMyManagerListService();
	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();
	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	const { activeManagerProducts } = useManagerRepo();
	const { useManagerProduct } = useManagerAdapter();

	// 현재 타입(온라인/오프라인)에 따른 상품 정보 가져오기
	const currentProduct = isOnline
		? activeManagerProducts?.online
		: activeManagerProducts?.offline;

	const handlePurchaseClick = async () => {
		if (!currentProduct) return;

		// 먼저 확인 팝업을 보여주고, yes를 눌렀을 때 API 호출
		fnCommonConfirmPopOn(
			t('useTicketConfirm', {
				text: t(isOnline ? 'online' : 'offline'),
			}),
			async () => {
				try {
					const result = await useManagerProduct(currentProduct.orderId);
					successFn(currentProduct, isOnline, result);
				} catch (error) {
					fnCommonAlertPopOn(t('activeVoucherRemaining'));
					console.error('매니저 구매 처리 중 오류 발생:', error);
				}
			},
			null,
			null,
			null,
		);
	};

	return (
		<div className={className}>
			<button
				type="button"
				className="addViewBtn openBt"
				onClick={handlePurchaseClick}
			>
				+ {t('useTicket')}
			</button>
		</div>
	);
};

export default PurchaseUseButtonView;
