import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PasswordInputViewProps {
	onClose: () => void;
	onSubmit: (password: number) => void;
	error: string | null;
}

const PasswordInputView = ({
	onClose,
	onSubmit,
	error,
}: PasswordInputViewProps) => {
	const { t } = useTranslation('metaverse');
	const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

	useEffect(() => {
		inputRefs.current[0]?.focus();
	}, []);

	useEffect(() => {
		if (error) {
			setInputValues(['', '', '', '', '', '']);
			inputRefs.current[0]?.focus();
		}
	}, [error]);

	const handleInputChange = (index: number, value: string) => {
		if (!/^\d*$/.test(value)) return;

		const newInputValues = [...inputValues];
		newInputValues[index] = value.slice(-1);
		setInputValues(newInputValues);

		if (value && index < 5) {
			inputRefs.current[index + 1]?.focus();
		}

		// 마지막 숫자까지 입력되었을 때
		if (index === 5 && value) {
			// 숫자로만 이루어진 문자열이므로 Number로 타입 단언
			onSubmit(Number([...newInputValues.slice(0, 5), value].join('')));
		}
	};

	const handleInputKeyDown = (index: number, key: string) => {
		if (key === 'Backspace') {
			if (!inputValues[index] && index > 0) {
				inputRefs.current[index - 1]?.focus();
				const newInputValues = [...inputValues];
				newInputValues[index - 1] = '';
				setInputValues(newInputValues);
			} else if (inputValues[index]) {
				const newInputValues = [...inputValues];
				newInputValues[index] = '';
				setInputValues(newInputValues);
			}
		}
	};

	return (
		<div className="popWrap popWrap-transition-enter-done passwordPop">
			<button className="btnClose_w" type="button" onClick={onClose} />
			<div className="popupInfo">
				<div className="passwordInputModal">
					<div className="contentsWrap">
						<p className="fz16fw500 color-fff margin-b16">
							{t('passwordLabel')}
						</p>
						<div className="inputWrap">
							<div className="input certifiBox">
								{inputValues.map((value, index) => (
									<input
										key={index}
										type="number"
										pattern="\d*"
										value={value}
										className={`text-c padding-0 ${error ? 'error' : ''}`}
										onChange={(e) => handleInputChange(index, e.target.value)}
										onKeyDown={(e) => handleInputKeyDown(index, e.key)}
										ref={(ref) => {
											inputRefs.current[index] = ref;
										}}
										inputMode="numeric"
										maxLength={1}
										autoComplete="off"
										placeholder="N"
									/>
								))}
							</div>
							{error && (
								<p className="errorMessage fz12 color-red margin-t8">{error}</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordInputView;
