import { Outlet } from 'react-router-dom';
import ManagerTabView from '../../views/ManagerTabView';
import OnlineManagerListView from './views/OnlineManagerListView';
import {
	useOnlineManagerMessageService
} from '@/ui/components/dm/manager/online/service/useOnlineManagerMessageService';


const OnlineManagerListContainer = () => {
	const {
		isOnline,
		setIsOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setIsFirst,
		isFirst
	} = useOnlineManagerMessageService()

	return (
		<>
			<div className="wrap">
				<ManagerTabView
					setIsFirst ={setIsFirst}
					isFirst = {isFirst}
					setIsOnline={setIsOnline}
					setShowOnlineMessageList={setShowOnlineMessageList}
					setShowOfflineMessageList={setShowOfflineMessageList}
					isOnline={isOnline}
				/>
				<div
					id="conWrap"
					className="metaPopWrap bdNone"
					style={{ marginBottom: 60 }}
				>
					<div className="messengerWrap">
						<div className="inner">
							<div className="tabCon tabCon01">
								<OnlineManagerListView />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Outlet />
		</>
	);
};

export default OnlineManagerListContainer;
