import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const LifeStyleHeaderView = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('register')
    return (
        <header>
        <div className="headerWrap border-b h-fixed">
          <div className="h-left">
            <button className="btnBack" onClick={() => navigate(-1)}></button>
          </div>
          <div className="h-center">
            <span className="fz18" dangerouslySetInnerHTML={{ __html: t('interestLifeStyle.header') }}></span>
          </div>
          <div className="h-right"></div>
        </div>
      </header>
    )
};

export default LifeStyleHeaderView;