import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const HeaderRollingView = () => {

    const [activeIdx, setActiveIdx] = useState<number>(0)
    const { t } = useTranslation('lounge');

    const headerQuotes = [t('headerQuote.1'),t('headerQuote.2'),t('headerQuote.3'),t('headerQuote.4'),t('headerQuote.5'),t('headerQuote.6'),t('headerQuote.7')]
    const rollingQuotes = headerQuotes.map((str,idx)=>{
        return <p key={idx} className={activeIdx === idx ? 'on':''} >{str}</p>
    })

    const updateTxt = () => {
        const randomIdx = Math.floor(Math.random() * headerQuotes.length);
        setActiveIdx(randomIdx)
    }

    useEffect(()=>{
        updateTxt()
        const intervalId = setInterval(updateTxt, 4000);
        return () => clearInterval(intervalId)
    },[])

    return (
        <>
            <div className="rdTxt">
                {rollingQuotes}
            </div>
        </>
    );
};

export default HeaderRollingView;



