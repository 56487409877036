import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { useTranslation } from 'react-i18next';

const LikeMatchingContainer = () => {
	const { srcPrefix, matchedItem, setMatchingSuccess } =
		useLikeMatchingService();
	const navigate = useNavigate();
	const location = useLocation();
	const navigateState = location.state as any;
	const { fnValidateOrder, fnOpenMessageRoom } = usePaymentService();
	const { t } = useTranslation('messenger');

	return (
		<div className="successWrap">
			<div className="successBg text-c">
				<p
					className="succTxt"
					dangerouslySetInnerHTML={{ __html: t('matchSuccess') }}
				/>
				<div className="succPeople">
					<div className="peopleRight">
						<img
							src={srcPrefix + matchedItem.toProfileUrl}
							alt={matchedItem.toProfileUrl}
						/>
					</div>
					<div className="loader">
						<div className="dot"></div>
						<div className="dot"></div>
						<div className="dot"></div>
						<div className="dot"></div>
					</div>
					<div className="peopleLeft">
						<img
							src={srcPrefix + matchedItem.myProfileUrl}
							alt={matchedItem.myProfileUrl}
						/>
					</div>
				</div>

				<div className="succBtnWrap">
					<Link
						to={`${PATH.MESSENGER.ROOT}/${matchedItem.roomId}`}
						state={{ navigationState: navigateState }}
						onClick={(e) => {
							e.stopPropagation();
							sessionStorage.setItem('fromLikeMatching', 'true');
							// 방 입장 이벤트 발생
							dmEventEmitter.emit('react-enter-dm-room', {
								roomId: matchedItem.roomId,
							});
							fnValidateOrder(fnOpenMessageRoom, {
								roomId: matchedItem.roomId,
								billingStatus: navigateState.billingStatus,
								orderId: navigateState.orderId,
								productId: 2,
							});
						}}
					>
						<button type="button" className="successBtn01">
							<span>{t('openChat')}</span>
						</button>
					</Link>
					<button
						type="button"
						className="successBtn02 margin-t16"
						onClick={() => {
							navigate(-1);
							setMatchingSuccess(false);
						}}
					>
						<span>{t('doLater')}</span>
					</button>
				</div>
			</div>
			<div className="flower-rotare">
				<span className="ic-flower flower01"></span>
				<span className="ic-flower flower02"></span>
				<span className="ic-flower flower03"></span>
			</div>
		</div>
	);
};

export default LikeMatchingContainer;
