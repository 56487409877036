import OfflineServiceFeaturesView from './OfflineServiceFeaturesView';
import { useTranslation } from 'react-i18next';

const OfflineManagerContentView = () => {
	const { t } = useTranslation('store');

	return (
		<div className="mgMatchingWrap">
			<div className="mgMatBox">
				<div className="inner">
					<h3 className="title02 main-color">{t('introduction')}
						{/* 소개 */}
					</h3>
					<p className="txt01 bold fz18">{t('wantRealMeet')}
						{/* “진짜 만남을 원하시나요?” */}
					</p>
					<p className="txt01">
						{t('serviceDescription')}
						{/* 온라인 매칭에서 끝나는 것이 아니라, 진정한 만남을 통해 실제 사랑을 찾고 싶으신가요? 오프라인 매니저 서비스는 당신의 기대를 충족시키는 진정한 만남을 제공합니다. */}
						<br />
						<br />
						{t('differentFromApps')}
						{/* 허쉬는 기존의 소개팅 어플과 확실히 다릅니다. */}
						<br />
						<br />
						{t('detailedHelp')}
						{/* 단순히 매칭을 성사시키는 데에 그치지 않고, 이상형을 현실에서 직접 만나볼 수 있도록 세심하게 도와드립니다. 모든 매칭은 매니저 서비스를 구매한 회원들 간에만 이루어지므로, 신뢰할 수 있는 만남을 보장합니다. */}
					</p>
				</div>
			</div>
			<div className="mgMatBox">
				<div className="inner">
					<h3 className="title02 ic-mgMat01">{t('realDate')}
						{/* 진짜 소개팅같은 만남 */}
					</h3>
					<p className="txt01 bold">{t('carefulCoordination')}
						{/* 만남 전 세심한 조율 */}
					</p>
					<p className="txt01">
						{t('coordinationDescription')}
						{/* 매칭이 성사된 후, 매니저가 직접 일정과 장소를 조율하여 안전하고 편안한 만남이 이루어질 수 있도록 준비합니다. 매니저는 각 회원의 편의와 안전을 최우선으로 고려하여, 최적의 만남 장소를 제안합니다. */}
					</p>

					<h3 className="title02 ic-mgMat02">{t('customMatching')}
						{/* 맞춤형 매칭 */}
					</h3>
					<p className="txt01 bold">{t('personalizedIdealTypeAnalysis')}
						{/* 개인맞춤형 이상형 분석 */}
					</p>
					<p className="txt01">
						{t('systemDescription')}
						{/* 회원이 설정한 이상형 조건을 세심하게 분석하여, 가장 적합한 상대를 찾아드립니다. 매니저는 회원의 이상형뿐만 아니라, 회원의 성격, 취향, 라이프스타일까지 고려하여 최적의 매칭을 제공합니다. */}
					</p>

					<p className="txt01 bold">{t('accurateMatchingSystem')}
						{/* 정확한 매칭 시스템 */}
					</p>
					<p className="txt01">
						{t('systemDescription')}
						{/* 단순한 알고리즘 매칭이 아닌, 매니저의 경험과 직관이 더해진 정교한 매칭 시스템으로 내 이상형에 꼭 맞는 매칭을 제공합니다. */}
					</p>

					<h3 className="title03 ic-mgMat03">{t('unlimitedIntroductions')}
						{/* 무제한 소개 */}
					</h3>
					<p className="txt01 bold">{t('continuousMatching')}
						{/* 만족할 때까지 매칭 지속 */}
					</p>
					<p className="txt01">
						{t('continuousMatchingDescription')}
						{/* 매칭이 성사되지 않을 경우, 만족할 때까지 무제한으로 새로운 이성을 소개해드립니다. */}
					</p>

					<h3 className="title03 ic-mgMat04">{t('refundGuarantee')}
						{/* 환불 보장 */}
					</h3>
					<p className="txt01 bold">{t('fullRefund')}
						{/* 한 달 이내 100% 환불 */}
					</p>
					<p className="txt01">
						{t('refundDescription')}
						{/* 한 달 동안 만남이 이루어지지 않는다면, 묻지도 따지지도 않고 100% 환불해드립니다. */}
					</p>
				</div>
			</div>
		</div>
	);
};

export default OfflineManagerContentView;
