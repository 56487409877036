import {useTranslation} from "react-i18next";

interface props {
	pointConfirmMessage: { tit1: string; tit2: string; price: number; };
	pointConfirmFunc: {param: null, func: (param: any) => null},
	fnPointConfirmPopOff: () => void;
	fnValidateOrder: (callback: any, param: any) => void;
}

export const HushPickConfirmPopView = ({
										   pointConfirmMessage,
										   pointConfirmFunc,
										   fnPointConfirmPopOff,
										   fnValidateOrder,
									   }: props) => {
	const { t } = useTranslation('home');

	return (
		<div className="logoutWrap">
			<div className="popupInfo padding-t36">
				<p className="fz20 bold-500 text-c">
					{pointConfirmMessage.tit1}
				</p>
				<p className="fz16Fw500 color-999 text-c padding-t8">
					{pointConfirmMessage.tit2}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							fnPointConfirmPopOff();
						}}
					>
						<span>{t('pop.no')}</span>
					</button>
					<button
						type="button"
						className="btnBig btnBlack radiusNone"
						onClick={() => {
							fnValidateOrder(pointConfirmFunc.func, pointConfirmFunc.param);
							fnPointConfirmPopOff();
						}}
					>
						<span
							className="flexXYcenter"
							style={{
								fontSize: '16px',
							}}
						>
							{t('pop.getRecommendation')}
                            <span
								style={{
									fontSize: '10px',
								}}
							>
                                &nbsp;
							</span>
                            <span className="ic-berith">{pointConfirmMessage.price}</span>
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};
