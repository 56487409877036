import AddProfileFooterView from './views/AddProfileFooterView';
import AddProfileInputBoxView from './views/AddProfileInputBoxView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AddProfileImgView from '@dating/ui/pages/profile/addProfile/views/AddProfileImgView';
import { useProfileAddService } from "@dating/ui/pages/profile/addProfile/service/useProfileAddService";
import { useImgCropService } from "@dating/ui/pages/imgCrop/service/useImgCropService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {AuthAddBadgeTypePopView} from "@dating/ui/components/authAddBadge/views/AuthAddBadgeTypePopView";
import {useImgAddBoxService} from "@dating/ui/components/imgAddBox/useImgAddBoxService";
import {useTranslation} from "react-i18next";

const ProfileAddContainer = () => {
    const {
        title,
        previewURLs,
        btnActivate,
        fnSaveAddProfile,
        srcPrefix,
        removeImg,
        response,
        clearCropFiles,
        openTypePop,
        setOpenTypePop,
        setBadgeTypeClick,
        authBadgeForm,
        setAuthBadgeForm,
        badgeTypeKor,
        backStep,
        osType,
    } = useProfileAddService();

    const {
        updateFile,
    } = useImgCropService();

    const {
        arrayToBlob,
    } = useImgAddBoxService()

    useEffect(() => {
        if (response) {
        }
    }, [response]);

    const navigate = useNavigate();

    const { t } = useTranslation('profile')

    return (
        <>
            <AuthAddBadgeTypePopView openTypePop={openTypePop} setOpenTypePop={setOpenTypePop} setBadgeTypeClick={setBadgeTypeClick}/>
            <div className="wrap footerNone">
                <header>
                    <div className="headerWrap">
                        <div className="h-left"></div>
                        <div className="h-center">
                            <span className="fz18">{title}</span>
                        </div>
                        <div className="h-right">
                            <button
                                type="button"
                                className="btnClose"
                                onClick={() => {
                                    navigate(-1);
                                    clearCropFiles();
                                    backStep()
                                }}
                            />
                        </div>
                    </div>
                </header>
                <div className="contentsWrap">
                    <div className="titleWrap">
                        <p className="titleSub01" dangerouslySetInnerHTML={{ __html: t('badge.quote1') }}>
                        </p>
                    </div>
                    <AddProfileImgView
                        previewURLs={previewURLs}
                        updateFile={updateFile}
                        srcPrefix={srcPrefix}
                        removeImg={removeImg}
                        osType={osType}
                        arrayToBlob={arrayToBlob}
                    />
                    <AddProfileInputBoxView
                        setOpenTypePop={setOpenTypePop}
                        badgeTypeKor={badgeTypeKor}
                        authBadgeForm={authBadgeForm}
                        setAuthBadgeForm={setAuthBadgeForm}
                    />

                    <ul className="exTxtList">
                        <li dangerouslySetInnerHTML={{ __html: t('badge.quote2') }}>
                        </li>
                    </ul>
                </div>
                <AddProfileFooterView
                    btnActivate={btnActivate}
                    fnSaveAddProfile={fnSaveAddProfile}
                />
            </div>
        </>
    );
};

export default ProfileAddContainer;
