import { useFeedSearchService } from '@dating/ui/pages/lounge/feedSearch/service/useFeedSearchService';
import React from 'react';
import { useLoungeHeaderService } from '@dating/ui/pages/lounge/useLoungeHeaderService';

const FeedSearchGridContainer = () => {
	const {
		thumbnailClick,
		searchResult,
	} = useFeedSearchService();

	const {
		srcPrefix,
	} = useLoungeHeaderService();


	return (
		<>
			<div id="conWrap">
				<div className="feedWrap padding-t4">
					{
						searchResult.length > 0 ?
							(
								<div className="metaPopContent gallList">
									{
										searchResult.map((value,i) => {
											return (
												<a
													key={value.postId + i}
													onClick={(e) => {
														e.preventDefault()
														thumbnailClick(value.postId)
													}}
												>
													<img src={srcPrefix + value.filesUrl[0]}
														 alt={value.postId}/>
												</a>
											);
										})
									}
								</div>
							)
							: (
								<div className="dataNoneTxt">
									<p className="txt01">조회 결과가 없습니다.</p>
								</div>
							)
					}
				</div>
			</div>
		</>

	);
};

export default FeedSearchGridContainer;
