import {useSettingRepo} from "@dating/repository/setting/useSettingRepo";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useEffect, useState} from "react";
import {useBlockReportRepo} from "@dating/repository/dating/useBlockReportRepo";
import {BlockPhoneCIVO} from "@/site/api/models/BlockPhoneCIVO";
import {useQuery} from "@tanstack/react-query";
import {SettingService} from "@/site/api";
import {useTranslation} from "react-i18next";

export const useBlockAcquaintanceService = () => {
    const { t } = useTranslation('setting')
    const title = t('blockAcquaintances');

    const {
        setOpenBlockAcquaintancePop,
    } = useSettingRepo();

    const {
        blockedNumberList,
        setBlockedNumberList,
        setBlockCount,
        setBlockTf,
        setUnBlockCount,
        setUnBlockTf,
    } = useBlockReportRepo();

    const {
        saveBlockPhoneNumberUser,
        statusSaveBlockPhoneNumberUser,
        dataSaveBlockPhoneNumberUser,
        blockCount,
        blockTf,
        unBlockCount,
        unBlockTf,
        saveUnblockPhoneNumberUser,
        statusSaveUnblockPhoneNumberUser,
        dataSaveUnblockPhoneNumberUser,
    } = useSettingAdapter();


    const {status: statusGetBlockedNumberList, data: dataGetBlockedNumberList} = useQuery({
        queryKey: ['blockedNumberList'],
        queryFn: async () => {
            const res = await SettingService.getBlockContacts();
            return res.blockList;
        },
    });


    const[tempCount, setTempCount] = useState<number>(0);   // 차단처리한 카운트 임시 담음
    /*
     * fnSaveBlockAcquaintance
     * 내 연락처 전체 차단하기 버튼 클릭 - 차단 목록 저장
     */
    const fnSaveBlockAcquaintance = (list) => {
        return ()=>{
            const innerList = [];
            list.forEach((val,idx)=>{
                innerList.push({blockName:val.displayName,blockPhoneNumber:val.number})
            })
            const paramVO : BlockPhoneCIVO = {blockList:innerList}
            saveBlockPhoneNumberUser(paramVO);
            setTempCount(innerList.length)
        }
    };

    const fnSaveUnblockPhoneNumberUser = () => {
        return saveUnblockPhoneNumberUser()
    }

    useEffect(() => {
        if (statusSaveUnblockPhoneNumberUser === "success" && dataSaveUnblockPhoneNumberUser) {
            setUnBlockCount(dataSaveUnblockPhoneNumberUser.unblockedCount)
            setUnBlockTf(true)
        }else if (statusSaveUnblockPhoneNumberUser === "error") {
            alert('차단해제에 실패했습니다.')
        }
    }, [statusSaveUnblockPhoneNumberUser, dataSaveUnblockPhoneNumberUser]);


    useEffect(() => {
        if (statusSaveBlockPhoneNumberUser === "success" && dataSaveBlockPhoneNumberUser) {
            setBlockCount(tempCount)
            setTempCount(0)
            setBlockTf(true)
            setOpenBlockAcquaintancePop(true);
        }else if(statusSaveBlockPhoneNumberUser === "error"){
            setBlockCount(0)
            setBlockTf(false)
            alert('차단에 실패했습니다.')
        }
    }, [statusSaveBlockPhoneNumberUser, dataSaveBlockPhoneNumberUser]);

    
    useEffect(() => {
        if (statusGetBlockedNumberList === "success" && dataGetBlockedNumberList) {
            setBlockedNumberList(dataGetBlockedNumberList);
        }
    }, [statusGetBlockedNumberList, dataGetBlockedNumberList]);

    return {
        title,
        fnSaveBlockAcquaintance,
        statusSaveBlockPhoneNumberUser,
        dataSaveBlockPhoneNumberUser,
        blockCount,
        blockTf,
        unBlockCount,
        unBlockTf,
        fnSaveUnblockPhoneNumberUser,
        dataSaveUnblockPhoneNumberUser,
        blockedNumberList,
        setBlockedNumberList,
        setBlockCount,
        setBlockTf,
        setUnBlockCount,
        setUnBlockTf,
    };
};