import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import JobSelectFooterView from "./views/JobSelectFooterView";
import useJobSelectService from "@dating/ui/pages/register/job/service/useJobSelectService";
import React, {useEffect} from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import {useNavigate} from "react-router-dom";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import JobSelectListView from "@dating/ui/pages/register/job/views/JobSelectListView";
import {useTranslation} from "react-i18next";

const JobSelectContainer = () => {
    const {
        setSelectedJob,
        btnActivate,
        fnSaveJob,
        jobList,
        selectedJob,
        response,
        registerSteps,
    } = useJobSelectService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();

    const { t } = useTranslation('register')

    useEffect(() => {
        if (response) {
            navigate(registerSteps[6].url);
        }
    }, [response]);

    return (
        <div className="wrap">
            <RegisterHeaderView/>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView
                        step={step}
                    />
                    <h2 className="title01">{t('jobStep.inputJob')}</h2>
                    <p className="titleSub01">{t('jobStep.inputJob2')}</p>
                </div>
                <JobSelectListView
                    setSelectedJob={setSelectedJob}
                    jobList={jobList}
                    selectedJob={selectedJob}
                />
            </div>
            <JobSelectFooterView
                fnSaveJob={fnSaveJob}
                btnActivate={btnActivate}
            />
        </div>
    );
};

export default JobSelectContainer;
