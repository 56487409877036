import {EditType} from "@dating/repository/profile/useEditProfileRepo";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";


interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    activeHeightIndex: number;
    setActiveHeightIndex: (activeHeightIndex: number) => void;
    heightValue: string[];
    setHeightValue: (heightValue: string[]) => void;
    handleHeightChange: (index: number, value: string) => void;
    handleHeightKeyDown: (index: number, key: string) => void;
    fnModifyProfileHeight: () => void;
    closeEditPopup: (key: string) => void;
}

const EditHeightPopView = ({
                               focusItem,
                               editPop,
                               setEditPop,
                               activeHeightIndex,
                               heightValue,
                               setHeightValue,
                               handleHeightChange,
                               handleHeightKeyDown,
                               setActiveHeightIndex,
                               fnModifyProfileHeight,
                               closeEditPopup,
                           }: props) => {

    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(3).fill(null));
    const { t } = useTranslation('register')
    useEffect(() => {
        inputRefs.current[activeHeightIndex]?.focus();
    }, [activeHeightIndex]);

    useEffect(() => {
        if (editPop.height) {
            if (focusItem.value != "") {
                const array: string[] = focusItem.value.toString()?.split("");
                setHeightValue(array);
                setActiveHeightIndex(array.length - 1);
            }
        }
    }, [editPop.height]);

    useEffect(() => {
        inputRefs.current[activeHeightIndex]?.focus();
    }, [activeHeightIndex]);

    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">{t('modifyProfile.height')}</h2>
                        <p className="titleSub01">{t('modifyProfile.heightQuote')}</p>
                    </div>
                    <div className="content">
                        <div className="input certifiBox align-end">
                            {heightValue.map((value, index) => (
                                <input
                                    type="text"
                                    className="text-c padding-0"
                                    key={index}
                                    value={value}
                                    maxLength={1}
                                    onChange={(e) => {
                                        // const newValue = e.target.value;
                                        // handleHeightChange(index, newValue);
                                    }}
                                    onKeyDown={(e) => {
                                        const newValue = e.key;
                                        handleHeightChange(index, newValue);
                                    }}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                />
                            ))}
                            <span className="fz20 bold-500">CM</span>
                        </div>
                    </div>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileHeight();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditHeightPopView;