import {useTranslation} from "react-i18next";

interface props {
    fnProfileUpload: () => void;
    btnActivate: boolean;
}

const photoFooterView = ({
                             fnProfileUpload,
                             btnActivate,
                         }: props) => {

    const { t } = useTranslation('register')

    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnProfileUpload}
                    disabled={btnActivate}
                >
                    <span>{t('photoStep.complete')}</span>
                </button>
            </div>
        </footer>
    );
};

export default photoFooterView;