import { ErrorHandlerView } from '@dating/ui/components/error/ErrorHandlerView';

export const errorHandlerUtils = () => {
	// fallbackRender 에러 발생 시 보여줄 컴포넌트 리턴
	const fallbackRender = ({ error, resetErrorBoundary }) => {
		return (
			<ErrorHandlerView
				error={error}
				resetErrorBoundary={resetErrorBoundary}
			/>
		);
	};

	// onError 에러 발생 시 로깅
	const logErrorToService = (error, info) => {
		// 나중에 로그 파일에 저장할거얌
		console.error('Caught an error:', error, info);
	};

	return {
		fallbackRender,
		logErrorToService,
	};
};