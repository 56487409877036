import {EditType} from "@dating/repository/profile/useEditProfileRepo";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    religionValue: string;
    setReligionValue: (religionValue: string) => void;
    fnModifyProfileReligion: () => void;
    closeEditPopup: (key: string) => void;
}

const EditReligionPopView = ({
                                 focusItem,
                                 editPop,
                                 setEditPop,
                                 religionValue,
                                 setReligionValue,
                                 fnModifyProfileReligion,
                                 closeEditPopup,
                             }: props) => {

    const { t } = useTranslation('register')

    const religions = [
        {
            label: t('idealDetail.noPreference'),
            code: 26,
        },
        {
            label: t('idealDetail.noReligion'),
            code: 27,
        },
        {
            label: t('idealDetail.christianity'),
            code: 28,
        },
        {
            label: t('idealDetail.buddhism'),
            code: 29,
        },
        {
            label: t('idealDetail.catholicism'),
            code: 30,
        },
        {
            label: t('idealDetail.otherReligion'),
            code: 31,
        },
    ];

    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">{t('idealDetail.religion')}</h2>
                        <p className="titleSub01">{t('idealDetail.selectReligion')}</p>
                    </div>
                    <ul className="popCheckList">

                        {religions.map((item, i) => {
                            return (
                                <li key={item.code.toString()}>
                                    <span className="bold-500">{item.label}</span>
                                    <div>
                                        <input
                                            id={item.code.toString()}
                                            className="checkbox"
                                            name="religion-option"
                                            type="checkbox"
                                            checked={religionValue === item.code.toString()}
                                            onChange={() => setReligionValue(item.code.toString())}
                                        />
                                        <label htmlFor={item.code.toString()} className="checkbox-label"></label>
                                    </div>
                                </li>
                            );
                        })
                        }

                    </ul>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileReligion();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditReligionPopView;