import GameScene from '../GameScene';
import { CreateCollisionLayer } from '../layer/CreateCollisionLayer';
import { CreateWallObjectLayer } from '../setup-game/CreateWallObjectLayer';
import { KeyboardHandler } from '../setup-game/KeyboardHandler';
import { SetupCamera } from '../setup-game/SetupCamera';
import { SetupObjectLayer } from '../setup-game/SetupObjectLayer';
import { SetupPlayer } from '../setup-game/SetupPlayer';

export class GameSceneSetup {
	private scene: GameScene;
	private roomTheme: any;

	constructor(scene: GameScene, roomTheme: any) {
		this.scene = scene;
		this.roomTheme = roomTheme;
	}

	// 타일맵 생성
	setupTileMap(): Phaser.Tilemaps.Tilemap {
		return this.scene.make.tilemap({ key: 'tilemap' });
	}

	// 플레이어를 설정
	setupPlayer() {
		new SetupPlayer(this.roomTheme).execute(this.scene);
	}

	// 충돌 레이어를 생성
	setupCollisionLayer() {
		new CreateCollisionLayer().execute(this.scene);
	}

	// 오브젝트 레이어 설정
	setupObjectLayer() {
		new SetupObjectLayer().execute(this.scene);
	}

	// 벽 오브젝트 레이어를 생성
	setupWall() {
		new CreateWallObjectLayer(this.scene).add('Wall', 'wall', 'wall', false);
	}

	// 카메라 설정
	setupCamera() {
		new SetupCamera(this.scene, this.roomTheme).execute();
	}

	// 키보드 이벤트 핸들러 설정
	setupKeyboard() {
		new KeyboardHandler(this.scene.input).execute();
	}
}
