import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

export const PurchasePopView = () => {
	const { t } = useTranslation('messenger')
	const navigate = useNavigate();

	return (
		<div className="popWrap layerPopWrap d-flex" id="payPop">
			<div className="popupInfo padding-t16 padding-b16">
				<div className="storeMgList">
					<div
						className="mgBox"
						onClick={() => {
							navigate(PATH.STORE.OFFLINE);
						}}
					>
						<p className="ic-offline">
							<strong className="en">OFFLINE</strong>{t('matchManager')}
						</p>
					</div>
					<div
						className="mgBox hotBox"
						onClick={() => {
							navigate(PATH.STORE.SCREEN);
						}}
					>
						<p className="ic-screen">
							<strong className="en">SCREEN</strong>{t('matchManager')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
