import {BadgeFormType, BadgeFormTypeAll} from "@dating/model/ProfileModel";
import {useTranslation} from "react-i18next";

interface props {
    setOpenTypePop:(open:boolean) => void;
    badgeTypeKor: string;
    authBadgeForm:BadgeFormType;
    setAuthBadgeForm:(param:BadgeFormTypeAll) => void;
}

const AddProfileInputBoxView = ({
                                    setOpenTypePop,
                                    badgeTypeKor,
                                    authBadgeForm,
                                    setAuthBadgeForm,
                                }: props) => {
    const { t } = useTranslation('profile')

    return (
        <>
            <button className="select margin-t16 openBtn" data-target="#selectPop"
                    onClick={()=>{setOpenTypePop(true)}}
            >{badgeTypeKor ? badgeTypeKor : t('badge.pickOne')}</button>
            <div className="input margin-t8">
                <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    placeholder={t('badge.placeholder')}
                    defaultValue={authBadgeForm?.contents}
                    onChange={(e) => {
                        if (e.target.value.length > 0) setAuthBadgeForm({contents:e.target.value});
                    }}
                />
            </div>
        </>
    );
};

export default AddProfileInputBoxView;
