import {useTranslation} from "react-i18next";

interface props {
    fnSaveUserPrefer: () => void;
}

const IdealSettingFooterView = ({
                                    fnSaveUserPrefer
                                }: props) => {

    const { t } = useTranslation('register')
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnSaveUserPrefer();
                    }}
                >
                    <span>{t('common.next')}</span>
                </button>
            </div>
        </footer>
    );
};

export default IdealSettingFooterView;
