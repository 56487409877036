import RoomPwdInputWrapView from './RoomPwdInputWrapView';

const RoomPwdSettingBoxView = ({ roomPwdResult, handleRoomPwdChange }) => {
	return (
		<div className="settingList margin-t24">
			<RoomPwdInputWrapView
				roomPwdResult={roomPwdResult}
				handleRoomPwdChange={handleRoomPwdChange}
			/>
		</div>
	);
};

export default RoomPwdSettingBoxView;
