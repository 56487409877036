import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ProfileFeedView from '@dating/ui/pages/profile/views/ProfileFeedView';
import ProfileHeader from '@dating/ui/pages/userProfile/views/ProfileHeader';
import { useLikeSendService } from '@dating/ui/pages/like/likeSend/useLikeSendService';
import { useProfileEditService } from '@dating/ui/pages/profile/editProfile/service/useProfileEditService';
import MyProfileBigImg from '@dating/ui/pages/myProfile/views/MyProfileBigImg';
import { PATH } from '@dating/constants/RoutingEndPoints';
import AuthAddBadgeView from '@dating/ui/components/authAddBadge/views/AuthAddBadgeView';
import { useMyProfileService } from '@dating/ui/pages/myProfile/service/useMyProfileService';
import { useTranslation } from 'react-i18next';

const MyProfileContainer = () => {
	const {
		scrollHandler,
		myProfile,
		myPosts,
		initProfile,
		initFeedEditPops,
		isClickedFeedMenu,
		myBerith,
		badgeData,
		setSettingOpen,
		srcPrefix,
		myProfileOpen,
		formatDate,
	} = useMyProfileService();

	const {
		openMenuPop,
		setOpenMenuPop,
		setOpenBlock,
		menuDotClickHandler,
		setBlockUserUid,
		reportPopOpen,
	} = useBlockReportService();

	const { setSingleFeedId } = useLikeSendService();

	const { clearPreviewURLs, setMyProfileOpen } = useProfileEditService();

	const proInfo01 = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('profile');

	useEffect(() => {
		setMyProfileOpen(true);
	}, []);

	return (
		<>
			<div
				className="wrap profileWrap footerNone"
				style={{
					overflowY: 'auto',
					overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
				}}
				onScroll={(e) => {
					if (proInfo01.current) {
						const y = e.currentTarget.scrollTop;
						let rectTop = proInfo01?.current.getBoundingClientRect().top;
						scrollHandler(y, rectTop);
					}
				}}
				onClick={() => {
					initFeedEditPops(myPosts);
				}}
			>
				<ProfileHeader
					openMenuPop={openMenuPop}
					setOpenMenuPop={setOpenMenuPop}
					initProfile={initProfile}
					setSettingOpen={setSettingOpen}
					menuDotClickHandler={menuDotClickHandler}
					setBlockUserUid={setBlockUserUid}
					profile={myProfile}
					setOpenBlock={setOpenBlock}
					reportPopOpen={reportPopOpen}
					myProfileOpen={myProfileOpen}
				/>

				<div id="conWrap">
					<div className="myProfileTop">
						<div className="inner">
							<MyProfileBigImg myProfile={myProfile} srcPrefix={srcPrefix} />
							<div className="profileTxtBox text-c">
								<p className="txt01 fz16 bold">{myProfile.nickName}</p>
								<p className="txt02 fz14 color-999">
									{myProfile.age + t('age')}
									{/* 세 */},{' '}
									{myProfile.region1 && myProfile.region2
										? `${myProfile.region1} ${myProfile.region2}`
										: t('noLocation')}
									{/* 위치정보 없음 */}
								</p>
								<Link to={PATH.PROFILE.EDIT_PROFILE} className="profileModi">
									{t('editProfile')}
									{/* 프로필 수정 */}
								</Link>
							</div>
						</div>
					</div>

					{/*좋아요 버튼, 메세지 버튼, 닉네임, 나이, 지역*/}
					<div className="myProfileInfo" ref={proInfo01}>
						<div className="inner">
							<div className="berithBox">
								<Link to={PATH.STORE.HOME}>
									<div className="leftRightTxt">
                                        <span>{t('berithBalance')}
											{/* 보유 베리드 */}
                                        </span>
										<span className="ic-berith md bold">
                                            {myBerith ? myBerith : 0}
                                        </span>
									</div>
								</Link>
							</div>
							{/*인증*/}
							<AuthAddBadgeView
								clearPreviewURLs={clearPreviewURLs}
								myProfileOpen={myProfileOpen}
								badgeData={badgeData}
							/>

							{/*별점평가, 자기소개, 뱃지, 프로필요약, 추가인증정보, 내가 올린 라운지 게시글*/}
							<div
								className="myProfileBar"
								style={{
									overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
								}}
							>
								{/*<div className={'new'}>*/}
								{/* <Link to={PATH.SETTINGS.MISSION}>*/}
								{/*     새로운 미션이 도착했어요*/}
								{/* </Link>*/}
								{/*</div>*/}

								<div className={'new'}>
									<Link to={PATH.SETTINGS.EVENT}>{t('checkEvent')}
										{/* 이벤트를 확인하세요 */}
									</Link>
								</div>

								<div>
									<Link to={PATH.SETTINGS.IDEAL_EDIT}>{t('idealType')}
										{/* 이상형 설정 */}
									</Link>
								</div>

								<div>
									<Link to={PATH.SETTINGS.NOTICE}>{t('notice')}
										{/* 공지사항 */}
									</Link>
								</div>
							</div>

							{myPosts.length > 0 ? (
								<>
									<div className="myLoungeList">
										<p className="title03">{t('recentLoungePosts')}
											{/* 최근 작성한 라운지 */}
										</p>

										{/*내가 작성한 게시글 최대 2개 노출*/}
										{myPosts.length > 0
											? myPosts.map((post, i) => {
												if (i < 2) {
													return (
														<ProfileFeedView
															key={post.postId}
															post={post}
															srcPrefix={srcPrefix}
															setSingleFeedId={setSingleFeedId}
															formatDate={formatDate}
														/>
													);
												}
											})
											: null}
									</div>

									{/*게시글 더보기*/}
									<Link to={PATH.LOUNGE.MY} className="addView">
										{t('viewAllMyLoungePosts')}
										{/* 내가 작성한 라운지 전체보기 */}
									</Link>
								</>
							) : (
								<div className="dataNoneBox">
									<p className="txt01">
										{t('noLoungePosts')}
										{/* 작성된 라운지 게시글이 없어요. */}
										<br />
										{t('writeLoungePost')}
										{/* 라운지에서 게시글을 작성해 주세요. */}
									</p>
									<Link to={PATH.LOUNGE.LIST}>{t('goToLounge')}
										{/* 라운지 가기 */}
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyProfileContainer;
