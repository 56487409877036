import {useTranslation} from "react-i18next";

interface props {
    fnSaveBirthday : () => void
    btnActivate : boolean
}

const BirthdayFooterView = ({ fnSaveBirthday, btnActivate } : props) => {
  const { t } = useTranslation('register')
    return (
    <footer>
      <div className="f-btnWrap">
        <button
          className="btn btnBig btnBlack"
          onClick={fnSaveBirthday}
          disabled={btnActivate}
        >
          <span>{t('common.next')}</span>
        </button>
      </div>
    </footer>
  );
};

export default BirthdayFooterView;
