// src/api/axiosInterceptor.ts (이 파일은 스웨거 업데이트와 무관)
import axios, {
	AxiosHeaders,
	AxiosRequestConfig,
	CancelTokenSource,
} from 'axios';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { bridgeSetNativeDataToken } from '@/utils/bridgeCallHandler';
import Network from '@/services/Network';

const axiosInstance = axios.create({
	withCredentials: true,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
	async (config) => {
		const authState = useAuthRepo.getState().authToken;
		const accessToken = authState?.accessJmt;

		if (!config.headers) {
			config.headers = new AxiosHeaders();
		}

		if (accessToken) {
			config.headers.set('Authorization', `Bearer ${accessToken}`);
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// Response Interceptor
axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error: any) => {
		const originalRequest = error.config;
		const source: CancelTokenSource = axios.CancelToken.source();

		if (
			error.response?.status === 401 &&
			!originalRequest._retry &&
			error?.response?.data?.error?.error == 'AccessTokenExpiredException'
		) {
			originalRequest._retry = true;

			try {
				const sessionItem = sessionStorage.getItem('authInfo');
				const refreshToken = sessionItem
					? JSON.parse(sessionItem).state?.authToken?.refreshJmt
					: '';
				if (!refreshToken || refreshToken == '') goMain();
				const refreshConfig: AxiosRequestConfig = {
					url: '/api/auth/token/refresh',
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
						refreshToken: `Bearer ${refreshToken}`,
					},
					withCredentials: true,
					cancelToken: source.token,
				};

				const response = await axios.request(refreshConfig);
				if (response.data?.accessToken && response.data?.refreshToken) {
					// 새로운 토큰을 sessionStorage, zustand 저장
					const authInfo = JSON.parse(
						sessionStorage.getItem('authInfo') || '{}',
					);
					authInfo.state.authToken.accessJmt = response.data.accessToken;
					authInfo.state.authToken.refreshJmt = response.data.refreshToken;
					sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
					useAuthRepo.getState().setAuthToken({
						accessJmt: response.data.accessToken,
						refreshJmt: response.data.refreshToken,
					});
					// 새로운 토큰을 네이티브로 보냄
					bridgeSetNativeDataToken({
						accessJmt: response.data.accessToken,
						refreshJmt: response.data.refreshToken,
					});

					if (Network.instance) {
						try {
							await new Promise<void>((resolve, reject) => {
								try {
									Network.instance!.updateAccessToken(
										response.data.accessToken,
									);
									// 토큰 업데이트가 완료되기를 기다리기 위해 약간의 지연을 줌
									setTimeout(() => {
										console.log('Network token update completed');
										resolve();
									}, 100);
								} catch (err) {
									reject(err);
								}
							});
						} catch (error) {
							console.error('Failed to update network token:', error);
						}
					}

					// 원래 요청 재시도
					originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
					try {
						return axiosInstance(originalRequest);
					} catch (error: any) {
						goMain();
					}
				} else {
					goMain();
				}
			} catch (error: any) {
				if (
					error?.response?.status === 401 &&
					(error?.response?.data?.error?.error ==
						'AccessTokenExpiredException' ||
						error?.response?.data?.error?.error ==
							'RefreshTokenExpiredException')
				) {
					goMain();
				}
				return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	},
);

export const goMain = () => {
	sessionStorage.removeItem('authInfo');
	if (window.location.pathname !== PATH.REGISTER.REGISTER) {
		window.location.href = PATH.REGISTER.REGISTER;
	}
};

export default axiosInstance;
