import { bridgePickImage } from '@/utils/bridgeCallHandler';
import { useRef } from 'react';
import {useTranslation} from "react-i18next";

interface props {
	boxIndex: number;
	previewURLs: string[];
	osType: string;
	arrayToBlob: (value: []) => Blob;
	updateFile: (index: number, file: File | Blob) => void;
	removeImg: (boxIndex: number) => void;
};

export const ImgAddBoxView = ({
								  boxIndex,
								  previewURLs,
								  osType,
								  arrayToBlob,
								  updateFile,
								  removeImg,
							  }: props) => {

	const inputRef = useRef<HTMLInputElement>(null);

	const { t } = useTranslation('register')

	return (
		<>
			{previewURLs[boxIndex] ? (
				<div onClick={() => {
					inputRef.current?.click();
				}}>
					{/*이미지 삭제 버튼*/}
					<button
						type="button"
						className="deleteBtn"
						onClick={(e) => {
							removeImg(boxIndex);
							e.stopPropagation();
						}} />
					<label htmlFor={`img${boxIndex}`}
						   onClick={() => {
							   if (osType != '') {
								   bridgePickImage().then(value => {
									   if (value != null) {
										   const file = arrayToBlob(value);
										   updateFile(boxIndex, file);
									   }
								   });
							   }
						   }}
					/>
					<img
						src={previewURLs[boxIndex]}
						alt="preview-img"
						className={`img${boxIndex}`}
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						onClick={() => {
							if (osType != '') {
								bridgePickImage().then(value => {
									if (value != null) {
										const file = arrayToBlob(value);
										updateFile(boxIndex, file);
									}
								});
							}
						}}
					/>
					<input
						type={`${osType === '' ? 'file' : ''}`}
						id={`img${boxIndex}`}
						ref={inputRef}
						onChange={(e) => {
							e.preventDefault();
							const currentRef = inputRef?.current;
							if (currentRef && currentRef.files) {
								const file = currentRef.files[0];
								updateFile(boxIndex, file);
							}

							e.target.value = '';
						}}
						style={{ display: 'none' }}
					/>
				</div>
			) : (
				<>
					<div>
						<label htmlFor={`img${boxIndex}`}
							   onClick={() => {
								   if (osType != '') {
									   bridgePickImage().then(value => {
										   if (value != null) {
											   const file = arrayToBlob(value);
											   updateFile(boxIndex, file);
										   }
									   });
								   }
							   }}
						/>
						<input
							type={`${osType === '' ? 'file' : ''}`}
							id={`img${boxIndex}`}
							ref={inputRef}
							onChange={(e) => {
								e.preventDefault();
								const currentRef = inputRef?.current;
								if (currentRef && currentRef.files) {
									const file = currentRef.files[0];
									updateFile(boxIndex, file);
								}

								e.target.value = '';
							}}
							style={{ display: 'none' }}
						/>
						{boxIndex === 0 ? <span className="fz12 color-white">{t('termsStep.essential')}</span> : null}
					</div>
				</>
			)}
		</>
	);
};