import { MetaRoomCIVO, MetaRoomUserInCIVO } from '@/site/api';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { useCallback, useEffect, useState } from 'react';
import { useMetaRoomCommonService } from './useMetaRoomCommonService';
import { RoomType, useRoomEventService } from './useRoomEventService';
import { useRoomStateService } from './useRoomStateService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** 방 생성에 필요한 파라미터 타입 */
export interface CreateRoomParams {
	roomName: string;
	isSecret: boolean;
	password?: number;
	roomMaxUsers: number;
	roomThemeId: number;
}

/** 방 참여에 필요한 파라미터 타입 */
export interface JoinRoomParams {
	roomId: string;
	password?: number;
	roomTheme?: any;
	x?: number;
	y?: number;
	roomType?: RoomType;
}

/** 메타버스 방 관리를 위한 통합 서비스 */
export const useRoomManagementService = () => {
	const { myMetaUser } = useMetaUserRepo();
	const {
		joinedRoomData,
		setPreLeftRoomId,
		setDisposedRoomId,
		setShowPasswordInput,
		setPasswordError,
	} = useRoomRepo();
	const {
		saveCreateRoom,
		saveRoomUserIn,
		updateMetaRoomUser,
		statusUpdateMetaRoomUser,
		dataUpdateMetaRoomUser,
		statusSaveRoomUserIn,
		dataSaveRoomUserIn,
	} = useMetaRoomAdapter();
	const { setJoinedRoomId } = useRoomStateService();
	const { emitStartRoom, emitJoinRoom } = useRoomEventService();
	const { handleSaveRoomUserOut, checkRoomJoinStatus } =
		useMetaRoomCommonService();
	const navigate = useNavigate();
	const { t } = useTranslation('metaverse');
	const [currentJoinParams, setCurrentJoinParams] =
		useState<JoinRoomParams | null>(null);

	const handleRoomJoin = useCallback(
		(params: JoinRoomParams) => {
			emitStartRoom({
				roomType: params.roomType || 'CUSTOM',
				roomId: params.roomId,
				password: params.password,
				roomTheme: params.roomTheme,
				x: params.x || 1024,
				y: params.y || 1376,
			});
			emitJoinRoom();
			setJoinedRoomId(params.roomId);
			navigate(PATH.METAVERSE.ROOT);
			setPasswordError(null);
			setShowPasswordInput(false);
		},
		[
			emitStartRoom,
			emitJoinRoom,
			setJoinedRoomId,
			navigate,
			setPasswordError,
			setShowPasswordInput,
		],
	);

	const createRoom = useCallback(
		async (params: CreateRoomParams) => {
			if (!joinedRoomData?.id) return;

			try {
				if (joinedRoomData?.id) {
					const leftRoom = await handleSaveRoomUserOut();
					if (!leftRoom) {
						console.error('Failed to leave previous room');
						return;
					}
				}

				const param: MetaRoomCIVO = {
					roomId: joinedRoomData.id,
					roomName: params.roomName,
					isSecret: params.isSecret,
					password: params.password,
					roomMaxUsers: params.roomMaxUsers,
					roomThemeId: params.roomThemeId,
					userUid: myMetaUser.userUid,
				};

				await new Promise((resolve, reject) => {
					saveCreateRoom(param, {
						onSuccess: async (response) => {
							if (!response.result) {
								reject(
									new Error(response.resultCode || '방 생성에 실패했습니다'),
								);
								return;
							}

							const joinParam: MetaRoomUserInCIVO = {
								roomId: joinedRoomData.id,
								password: params.password,
								userUid: myMetaUser.userUid,
							};

							try {
								await saveRoomUserIn(joinParam);
								setJoinedRoomId(joinedRoomData.id);
								navigate(PATH.METAVERSE.ROOT);
								resolve(response);
							} catch (error) {
								reject(new Error('방 입장에 실패했습니다'));
							}
						},
						onError: (error) => {
							reject(new Error(error.message || '방 생성에 실패했습니다'));
						},
					});
				});
			} catch (error) {
				throw error;
			}
		},
		[
			joinedRoomData,
			myMetaUser.userUid,
			handleSaveRoomUserOut,
			saveCreateRoom,
			saveRoomUserIn,
			setJoinedRoomId,
			navigate,
		],
	);

	const joinRoom = useCallback(
		async (params: JoinRoomParams) => {
		  try {
			const joinedRoomResult = await checkRoomJoinStatus(params.roomId);
			setCurrentJoinParams(params);
	  
			try {
			  if (joinedRoomResult && 'isExit' in joinedRoomResult) {
				await new Promise((resolve, reject) => {
				  updateMetaRoomUser(
					{
					  roomId: params.roomId,
					  userUid: myMetaUser.userUid,
					  isExit: false,
					  password: params.password,
					},
					{
					  onSuccess: (data) => resolve(data),
					  onError: (error) => reject(error),
					}
				  );
				});
			  } else {
				await new Promise((resolve, reject) => {
				  saveRoomUserIn(
					{
					  roomId: params.roomId,
					  password: params.password,
					  userUid: myMetaUser.userUid,
					},
					{
					  onSuccess: (data) => resolve(data),
					  onError: (error) => reject(error),
					}
				  );
				});
			  }
			} catch (mutationError: any) {
			  setPasswordError(t('incorrectPassword'));
			  throw new Error(t('incorrectPassword'));
			}
		  } catch (error: any) {
			setPasswordError(t('incorrectPassword'));
			throw error;
		  }
		},
		[
		  myMetaUser.userUid,
		  checkRoomJoinStatus,
		  updateMetaRoomUser,
		  saveRoomUserIn,
		  setPasswordError,
		  t,
		]
	  );

	useEffect(() => {
		if (currentJoinParams) {
			if (
				(statusUpdateMetaRoomUser === 'success' &&
					dataUpdateMetaRoomUser?.roomId) ||
				(statusSaveRoomUserIn === 'success' &&
					dataSaveRoomUserIn?.result &&
					dataSaveRoomUserIn?.resultCode === 'pass')
			) {
				handleRoomJoin(currentJoinParams);
				setCurrentJoinParams(null);
			}
		}
	}, [
		statusUpdateMetaRoomUser,
		dataUpdateMetaRoomUser,
		statusSaveRoomUserIn,
		dataSaveRoomUserIn,
		currentJoinParams,
		handleRoomJoin,
	]);

	const disposeRoom = useCallback(
		async (roomId: string) => {
			try {
				setPreLeftRoomId('');
				setDisposedRoomId(roomId);
			} catch (error) {
				console.error('방 폐기 실패:', error);
			}
		},
		[setPreLeftRoomId, setDisposedRoomId],
	);

	return {
		createRoom,
		joinRoom,
		disposeRoom,
	};
};
