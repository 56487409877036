import { Link } from 'react-router-dom';
import { useAppFooterService } from './service/useAppFooterService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

const AppFooterWrapContainer = () => {
	const {
		homeOpen,
		loungeOpen,
		likeOpen,
		metaOpen,
		totalUnreadCount,
		showDirectMessageList,
		handleHomeClick,
		handleLoungeClick,
		handleLikeClick,
		handleMessageClick,
		handleMetaClick,
	} = useAppFooterService();

	const { t } = useTranslation('common');

	return (
		<div className="appFooterWrap">
			<button className={`${homeOpen ? 'active' : ''}`}>
				<a
					onClick={(e) => {
						e.preventDefault();
						handleHomeClick();
					}}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_406_218)">
							<path
								d="M9.99998 0.833252L0.833313 7.3027V19.1666H7.32776V11.5555H12.6722V19.1666H19.1666V7.3027L9.99998 0.833252Z"
								stroke="#333333"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_406_218">
								<rect width="20" height="20" fill="white" />
							</clipPath>
						</defs>
					</svg>
					<span>{t('footer.home')}</span>
				</a>
			</button>

			<button className={`${loungeOpen ? 'active' : ''}`}>
				<Link
					to={PATH.LOUNGE.LIST}
					onClick={() => {
						handleLoungeClick();
					}}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="0.75"
							y="0.75"
							width="18.5"
							height="18.5"
							rx="2.25"
							stroke="#333333"
							strokeWidth="1.5"
						/>
						<line
							y1="11.75"
							x2="20"
							y2="11.75"
							stroke="#333333"
							strokeWidth="1.5"
						/>
						<line
							x1="8.75"
							y1="15.5"
							x2="15.75"
							y2="15.5"
							stroke="#333333"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<circle cx="4.5" cy="15.5" r="1.5" fill="#333333" />
					</svg>
					<span>{t('footer.lounge')}</span>
				</Link>
			</button>

			<button className={`${likeOpen ? 'active' : ''}`}>
				<a
					onClick={(e) => {
						e.preventDefault();
						handleLikeClick();
					}}
				>
					<svg
						width="22"
						height="20"
						viewBox="0 0 22 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M21 6.769C21 11.5857 15 16.5 11.0014 19C7 16.5 0.977532 11.5828 1.00006 6.769C1.0279 0.82136 7.45077 -1.21541 10.9986 3.96219C14.5493 -1.21542 21 0.82137 21 6.769Z"
							stroke="#333333"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinejoin="round"
						/>
					</svg>
					<span>{t('footer.like')}</span>
				</a>
			</button>

			<button
				className={`
    ${showDirectMessageList ? 'active' : ''}
    ${totalUnreadCount > 0 && !showDirectMessageList ? 'msgOn' : ''}
  `}
			>
				<a
					onClick={(e) => {
						e.preventDefault();
						handleMessageClick();
					}}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 10V19H9.96318C14.9535 19 19 14.97 19 10C19 5.03 14.9569 1 9.96318 1C4.96951 1 1 5.03 1 10Z"
							fill="white"
							stroke="#333333"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M14.3511 11.6034C15.0904 11.6034 15.6898 11.0065 15.6898 10.2701C15.6898 9.53372 15.0904 8.93677 14.3511 8.93677C13.6117 8.93677 13.0123 9.53372 13.0123 10.2701C13.0123 11.0065 13.6117 11.6034 14.3511 11.6034Z"
							fill="#333333"
							className="circle"
						/>
						<path
							d="M9.99998 11.6034C10.7394 11.6034 11.3388 11.0065 11.3388 10.2701C11.3388 9.53372 10.7394 8.93677 9.99998 8.93677C9.26059 8.93677 8.66119 9.53372 8.66119 10.2701C8.66119 11.0065 9.26059 11.6034 9.99998 11.6034Z"
							fill="#333333"
							className="circle"
						/>
						<path
							d="M5.64891 11.6034C6.3883 11.6034 6.98769 11.0065 6.98769 10.2701C6.98769 9.53372 6.3883 8.93677 5.64891 8.93677C4.90951 8.93677 4.31012 9.53372 4.31012 10.2701C4.31012 11.0065 4.90951 11.6034 5.64891 11.6034Z"
							fill="#333333"
							className="circle"
						/>
					</svg>

					<span>{t('footer.messenger')}</span>
				</a>
			</button>

			<button className={`metaOn ${metaOpen ? 'active' : ''}`}>
				<Link
					to={PATH.METAVERSE.ROOT}
					onClick={() => {
						handleMetaClick();
					}}
				>
					<svg
						width="22"
						height="22"
						viewBox="0 0 22 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.8938 14.4239C18.521 17.9956 15.0543 20.5314 10.995 20.5314C5.73578 20.5314 1.46912 16.264 1.46912 11.0001C1.46912 9.79139 1.69134 8.63698 2.10615 7.5763C3.47899 4.00452 6.94566 1.46875 11 1.46875C15.0543 1.46875 18.521 4.00452 19.8987 7.5763"
							stroke="#333333"
							strokeWidth="1.5"
							strokeMiterlimit="10"
						/>
						<path
							d="M19.8988 7.57129C20.3086 8.6369 20.5309 9.79132 20.5309 10.9951C20.5309 12.1988 20.3086 13.3582 19.8988 14.4188"
							stroke="#333333"
							strokeWidth="1.5"
							strokeMiterlimit="10"
						/>
						<path
							d="M6.53583 7.57136C7.22225 4.00452 8.96052 1.46875 11 1.46875C13.0395 1.46875 14.7778 4.00452 15.4692 7.5763C15.6766 8.64191 15.7901 9.79633 15.7901 11.0001C15.7901 12.2038 15.6766 13.3632 15.4692 14.4239C14.7778 17.9956 13.0395 20.5314 11 20.5314C8.96052 20.5314 7.22225 17.9956 6.53583 14.4239"
							stroke="#333333"
							strokeMiterlimit="10"
						/>
						<path
							d="M6.53089 14.4237C6.32842 13.363 6.21484 12.2037 6.21484 11C6.21484 9.7962 6.32842 8.63685 6.53089 7.57617"
							stroke="#333333"
							strokeMiterlimit="10"
						/>
						<path
							d="M11 1.46875V7.57136V14.4239V20.5314"
							stroke="#333333"
							strokeMiterlimit="10"
						/>
						<path
							d="M19.8993 7.57129H19.8938"
							stroke="#333333"
							strokeWidth="1.89"
							strokeMiterlimit="10"
						/>
						<path
							d="M19.8285 7.57129H17.3105H12.2295H7.14853H7.14291H4.63051H2.10126"
							stroke="#333333"
							strokeMiterlimit="10"
						/>
						<path
							d="M19.9091 14.4238H19.9035H17.3798H14.8618H9.78083H4.69984H4.69422H2.18182"
							stroke="#333333"
							strokeMiterlimit="10"
						/>
					</svg>
					<span>{t('footer.metaverse')}</span>
				</Link>
			</button>
		</div>
	);
};

export default AppFooterWrapContainer;
