import {UserProfileCOVO} from '@/site/api';
import React from 'react';
import {useTranslation} from "react-i18next";

interface props {
    myModifyProfile: UserProfileCOVO;
    openPopupHandler: (detail: {}) => void;
}

const EditProfileDetailView = ({myModifyProfile, openPopupHandler}: props) => {
    const { t } = useTranslation('register')
    const details = [
        {
            id: 'user',
            title: t('idealDetail.nickname'),
            value: myModifyProfile?.nickName,
            key: 'nickName',
            isNull: myModifyProfile?.nickName ? false : true,
            editable: true,
        },
        {
            id: 'birthday',
            title: t('idealDetail.birth'),
            value: myModifyProfile?.birthday ? myModifyProfile?.birthday : 'null',
            key: 'birthday',
            isNull: true,
            editable: false,
        },
        {
            id: 'gender',
            title: t('idealDetail.gender'),
            value: myModifyProfile?.gender == "남성" ? t('genderStep.man') : t('genderStep.woman'),
            key: 'gender',
            isNull: true,
            editable: false,
        },
        {
            id: 'location',
            title: t('idealDetail.location'),
            value: myModifyProfile?.region1 && myModifyProfile.region2 ? `${myModifyProfile.region1} ${myModifyProfile.region2}`: t('idealDetail.noLocationQuote'),
            key: 'location',
            isNull: true,
            editable: false,
        },
        {
            id: 'job',
            title: t('idealDetail.job'),
            value: myModifyProfile?.job,
            key: 'job',
            isNull: myModifyProfile?.job ? false : true,
            editable: true,
        },
        {
            id: 'height',
            title: t('idealDetail.height'),
            value: myModifyProfile?.height,
            key: 'height',
            isNull: myModifyProfile?.height ? false : true,
            editable: true,
        },
        {
            id: 'drink',
            title: t('idealDetail.drink'),
            value: myModifyProfile?.drink ? myModifyProfile?.drink : t('idealDetail.selectDrink'),
            key: 'drinking',
            isNull: myModifyProfile?.drink ? false : true,
            editable: true,
        },
        {
            id: 'smoking',
            title: t('idealDetail.smoking'),
            value:
                myModifyProfile?.smoking !== "" ?
                    myModifyProfile?.smoking
                    : t('idealDetail.selectSmoking'),
            key: 'smoking',
            isNull: myModifyProfile?.smoking !== "" ? false : true,
            editable: true,
        },
        {
            id: 'religion',
            title: t('idealDetail.religion'),
            value: myModifyProfile?.religion ? myModifyProfile?.religion : t('idealDetail.selectReligion'),
            key: 'religion',
            isNull: myModifyProfile?.religion ? false : true,
            editable: true,
        },
        {
            id: 'pen',
            title: t('idealDetail.intro'),
            value: myModifyProfile?.introduce
                ? myModifyProfile?.introduce
                : t('idealDetail.inputIntro'),
            key: 'introduce',
            isNull: myModifyProfile?.introduce ? false : true,
            editable: true,
        },
        {
            id: 'like',
            title: t('idealDetail.like'),
            value:
                myModifyProfile?.interest?.concat(myModifyProfile?.extraInterest)?.length > 0
                    ? myModifyProfile.interest.concat(myModifyProfile?.extraInterest).join(', ')
                    : t('idealDetail.inputLike'),
            code: myModifyProfile?.interest?.length > 0 ? myModifyProfile?.interestCode : [],
            key: 'interest',
            isNull: myModifyProfile?.interest?.length > 0 ? false : true,
            editable: true,
        },
        {
            id: 'life',
            title: t('idealDetail.life'),
            value:
                myModifyProfile?.lifeStyle?.concat(myModifyProfile?.extraLifeStyle)?.length > 0
                    ? myModifyProfile.lifeStyle.concat(myModifyProfile?.extraLifeStyle).join(', ')
                    : t('idealDetail.inputLife'),
            code: myModifyProfile?.lifeStyle?.length > 0 ? myModifyProfile?.lifeStyleCode : [],
            key: 'lifeStyle',
            isNull: myModifyProfile?.lifeStyle?.length > 0 ? false : true,
            editable: true,
        },
    ];

    return (
        <>
            <div className="stepLastBox">
                <ul className="infoList">
                    {details.map((detail, i) => {
                        if (detail.id === 'pen') {
                            // 자기소개
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt  margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li>
                                        <p
                                            className={`bold-500 ${detail.isNull ? '' : 'color-000'}`}
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else if (detail.id === 'like') {
                            // 관심사
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt  margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li className={`${detail.isNull ? '' : 'on'}`}>
                                        <p
                                            className="bold-500"
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {typeof detail.value === 'object'
                                                ? detail.value
                                                : detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else if (detail.id === 'life') {
                            //라이프스타일
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt  margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li className={`${detail.isNull ? '' : 'on'}`}>
                                        <p
                                            className="bold-500"
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {typeof detail.value === 'object'
                                                ? detail.value
                                                : detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else {
                            return (
                                // 닉네임 ~ 종교
                                <li
                                    key={detail.id}
                                    className={`ic-${detail.id} leftRightTxt  ${
                                        detail.isNull ? '' : 'on'
                                    }`}
                                >
                                    <p>{detail.title}</p>
                                    <p
                                        onClick={() => {
                                            openPopupHandler(detail);
                                        }}
                                    >
                                        {detail.value}
                                    </p>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        </>
    );
};

export default EditProfileDetailView;
