import React from "react/index";
import EditProfileDetailView from './views/EditProfileDetailView';
import {useProfileEditService} from "@dating/ui/pages/profile/editProfile/service/useProfileEditService";
import {EditProfileHeaderView} from "@dating/ui/pages/profile/editProfile/views/EditProfileHeaderView";
import { EditProfilePopContainer } from '@dating/ui/components/editProfilePop/EditProfilePopContainer';
import MyProfileBigImg from "@dating/ui/pages/myProfile/views/MyProfileBigImg";
import {useTranslation} from "react-i18next";

const ProfileEditContainer = () => {
    const {
        title,
        clearPreviewURLs,
        myModifyProfile,
        openPopupHandler,
        srcPrefix,
        myProfile,
    } = useProfileEditService();
    const { t } = useTranslation('profile')

    return (
        <>
            {/*프로필 수정 팝업*/}
            <EditProfilePopContainer />

            <div className="wrap rightShow profileWrap footerNone active">
                <EditProfileHeaderView
                    right={true}
                    title={t('editProfile')}
                    clearPreviewURLs={clearPreviewURLs}
                />

                <div className="popConWrap padding-b0">
                    <div className="inner">
                        <div className="settingWrap">
                            <MyProfileBigImg
                                myProfile={myProfile}
                                srcPrefix={srcPrefix}
                            />
                            <EditProfileDetailView
                                myModifyProfile={myModifyProfile}
                                openPopupHandler={openPopupHandler}
                            />
                            {/*<EditProfileFooterView*/}
                            {/*    clearPreviewURLs={clearPreviewURLs}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileEditContainer;
