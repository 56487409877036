import { Link } from 'react-router-dom';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useMessageListService } from '../service/useMessageListService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import {useTranslation} from "react-i18next";

const MessageListView = () => {
	const { t } = useTranslation('messenger')
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const {
		userProfile,
		myProfile,
		srcPrefix,
		setUserProfileOpen,
		sortedMessages,
	} = useMessageListService();

	const renderMessageItem = (message) => {
		const {
			billingStatus,
			dmRoomType,
			roomId,
			otherUserInfo,
			messages,
			latestCreatedAt,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			unreadCount,
			display,
			user1Info,
		} = message;

		const createMessageListState = () => {
			const isUser1 = user1Info?.userUid === myProfile.userUid;
			const sortedMessagesData = [...messages].sort(
				(a, b) => parseInt(a.createdAt) - parseInt(b.createdAt),
			);

			return {
				billingStatus,
				myPaymentStatus: isUser1 ? billingStatus === 1 : billingStatus === 2,
				otherPaymentStatus: isUser1 ? billingStatus === 2 : billingStatus === 1,
				dmRoomType,
				roomId,
				visibleTo,
				isUserLeft,
				isOtherUserLeft,
				user1Info: user1Info || {
					userUid: '',
					nickName: '',
					profileUrl: '',
					location: '',
					age: '',
					blockType: '',
				},
				otherUserInfo: otherUserInfo || {
					userUid: '',
					nickName: '',
					profileUrl: '',
					location: '',
					age: '',
					blockType: '',
				},
				createdAt: latestCreatedAt,
				messages: sortedMessagesData,
			};
		};

		if (isUserLeft || visibleTo === -1) {
			return null;
		}

		const sortedMessages = [...messages].sort(
			(a, b) => parseInt(b.createdAt) - parseInt(a.createdAt),
		);
		const latestMessage = sortedMessages[0]?.content;
		if (visibleTo !== -1) {
			return (
				<div className="profileType" key={roomId}>
					<div className="proLeft width-100p">
						{isOtherUserLeft ||
						(otherUserInfo.blockType && otherUserInfo.blockType !== 'none') ? (
							<div className="proImg">
								<img
									src="/assets/img/common/profile_no.svg"
									alt="테스트이미지"
								/>
							</div>
						) : (
							<Link
								to={PATH.PROFILE.USER_PROFILE}
								onClick={() => {
									setUserProfileOpen(otherUserInfo.userUid);
								}}
								state={{
									isOtherProfile: userProfile.userUid !== myProfile.userUid,
								}}
							>
								<div className="proImg">
									<img
										src={srcPrefix + otherUserInfo.profileUrl}
										alt={otherUserInfo.nickName}
									/>
								</div>
							</Link>
						)}
						<Link
							className="proName margin-l8 flex-1 margin-l8 flex-1 margin-t5"
							style={{ width: 'calc(100% - 78px)', display: 'block' }}
							to={`${PATH.MESSENGER.ROOT}/${roomId}`}
							state={createMessageListState()}
							onClick={(e) => {
								e.stopPropagation();
								setOpenDirectMessageDetail(true, roomId);
								// 방 입장 이벤트 발생
								dmEventEmitter.emit('react-enter-dm-room', {
									roomId: roomId,
								});
								setUserProfileOpen(otherUserInfo.userUid);
							}}
						>
							<p className="d-flex justify-between align-center">
								<span className="bold">{otherUserInfo.nickName}</span>
								{!isOtherUserLeft && (
									<span className="fz10 color-999 bold-500">
										{messages.length > 0
											? new Date(parseInt(messages[0].createdAt))
													.toISOString()
													.split('T')[0]
											: latestCreatedAt
											? new Date(parseInt(latestCreatedAt))
													.toISOString()
													.split('T')[0]
											: ''}
									</span>
								)}
							</p>
							{!isOtherUserLeft && (
								<p className="fz12 color-999 padding-b4">
									{otherUserInfo.region1
										? otherUserInfo.region1
										: t('noRegion')}{' '}
									/ {otherUserInfo.age + t('howOld')}
								</p>
							)}
							<div
								className={`d-flex justify-between align-center ${
									isOtherUserLeft ? 'margin-t8' : ''
								}`}
							>
								<p
									className={`ellipsis ${
										isOtherUserLeft ? 'color-red fz14' : 'fz12'
									} ${display?.className || ''}`}
								>
									{isOtherUserLeft
										? t('disconnectMsg')
										: display?.prefix || latestMessage || ''}
								</p>
								{sortedMessages.length > 0 &&
									sortedMessages[0].sender.userUid === otherUserInfo.userUid &&
									unreadCount > 0 && (
										<span className="chatMsgOn margin-l5">{unreadCount}</span>
									)}
							</div>
						</Link>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map(renderMessageItem)
			) : (
				<div className="dataNoneTxt">
					<p className="txt01">{t('emptyList')}</p>
				</div>
			)}
		</div>
	);
};

export default MessageListView;
