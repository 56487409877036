import { useState } from 'react';
import useProfileRepo from "@dating/repository/profile/useProfileRepo";

export const useUserProfileStateRepo = () => {
    /***** useState *****/
    const [scrollOn, setScrollOn] = useState(true);

    // StepLastBoxView content
    const [profileDetails, setProfileDetails] = useState<
        { key: string; title: string; value: string | number }[]
        >([]);

    const [fadeIn, setFadeIn] = useState<boolean>(false);

    // 별점평가 별 색칠여부
    const [isChecked, setIsChecked] = useState<number | null>(null);

    // 추가인증 뱃지
    const { badgeData, setBadgeData } = useProfileRepo();

    return {
        scrollOn,
        setScrollOn,
        profileDetails,
        setProfileDetails,
        fadeIn,
        setFadeIn,
        isChecked,
        setIsChecked,
        badgeData,
        setBadgeData,
    };
};
