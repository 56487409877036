import { useSingleFeedService } from '@dating/ui/pages/lounge/singleFeed/useSingleFeedService';
import { CSSTransition } from 'react-transition-group';
import { useFeedListService } from '@dating/ui/pages/lounge/feedList/service/useFeedListService';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useFeedFormService } from '@dating/ui/components/feedFrom/service/useFeedFormService';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import FeedListView from '@dating/ui/pages/lounge/feedList/views/FeedListView';
import FeedCommentView from '@dating/ui/pages/lounge/feedComment/views/FeedCommentView';
import { useMyFeedListService } from '@dating/ui/pages/lounge/myFeed/useMyFeedListService';
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const MyFeedListContainer = () => {
	const {
		myPosts,
		srcPrefix,
		sliderSettings,
		fnSavePostLike,
		initFeedEditPops,
		fnFeedEditHandler,
		isClickedFeedMenu,
		commentValue,
		openComment,
		commentState,
		commentViewHandler,
		commentListHandler,
		commentResponse,
		fnDeleteComment,
		fnRemovePost,
		fnSaveComment,
		setCommentValue,
		focusedPostId,
		postUser,
	} = useMyFeedListService();

	const {
		fadeIn,
		setFadeIn,
		setUserProfileOpen,
		setMyProfileOpen,
		myProfile,
		setOpenFeedHidePop,
		setBlockPostId,
		feedEditPops,
		setFocusedPostId,
		observeCard,
		setObserveCard,
		isSearchPost,
		haveToRefresh,
		setHaveToRefresh,
	} = useFeedListService();

	const {
		openMenuPop,
		setOpenReport,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		setBlockUserUid,
		reportPopOpen,
		hidePopOpen,
	} = useBlockReportService();

	const { initFeedForm, setModifyPostId } = useFeedFormService();

	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();

	const { t } = useTranslation('lounge');

	useEffect(() => {
		setFadeIn(true);
	}, []);

	return (
		<div className={`${commentState ? 'wrap no-scroll footerNone' : 'wrap'}`}>
			<ButtonHeaderView title={t('myLounge')} right={false} />
			<div
				id="conWrap"
				className="metaPopWrap"
				onClick={() => {
					if (myPosts) {
						initFeedEditPops(myPosts);
					}
				}}
			>
				<div
					className="feedWrap"
					style={{
						overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
					}}
				>
					<div
						className={`metaPopContent feedList spinnerContent ${
							fadeIn ? 'fade-in' : ''
						}`}
					>
						{myPosts.length > 0
							? myPosts?.map((post) => {
								return (
									<FeedListView
										key={post.postId}
										post={post}
										deleteContent={fnRemovePost}
										myProfile={myProfile}
										menuDotClickHandler={menuDotClickHandler}
										setOpenFeedHidePop={setOpenFeedHidePop}
										commentListHandler={commentListHandler}
										openMenuPop={openMenuPop}
										sliderSettings={sliderSettings}
										srcPrefix={srcPrefix}
										commentCount={post.commentCount}
										commentViewHandler={commentViewHandler}
										setUserProfileOpen={setUserProfileOpen}
										fnSavePostLike={fnSavePostLike}
										setBlockUserUid={setBlockUserUid}
										currentUserUid={myProfile?.userUid}
										setModifyPostId={setModifyPostId}
										setMyProfileOpen={setMyProfileOpen}
										fnCommonConfirmPopOn={fnCommonConfirmPopOn}
										setBlockPostId={setBlockPostId}
										fnFeedEditHandler={fnFeedEditHandler}
										feedEditPops={feedEditPops}
										reportPopOpen={reportPopOpen}
										setFocusedPostId={setFocusedPostId}
										hidePopOpen={hidePopOpen}
										observeCard={observeCard}
										setObserveCard={setObserveCard}
										isSearchPost={isSearchPost}
										haveToRefresh={haveToRefresh}
										setHaveToRefresh={setHaveToRefresh}
									/>
								);
							})
							:
							<div className='dataNoneTxt'>
								<p className='txt01'>{t('emptyPost')}</p>
							</div>
						}
					</div>

					{/* 댓글창 */}
					<div className={`${openComment ? 'commentPopOn' : 'commentPop'}`}>
						<div
							className="commentDim"
							onClick={() => {
								commentListHandler('', { userUid: '', nickname: '' });
								commentViewHandler(false);
							}}
						></div>
						<CSSTransition
							in={openComment}
							classNames="commentInfo-transition"
							timeout={100}
						>
							<FeedCommentView
								key={focusedPostId}
								focusedPostId={focusedPostId}
								myProfile={myProfile}
								fnSaveComment={fnSaveComment}
								commentResponse={commentResponse}
								srcPrefix={srcPrefix}
								commentValue={commentValue}
								setCommentValue={setCommentValue}
								commentViewHandler={commentViewHandler}
								postUser={postUser}
								setMyProfileOpen={setMyProfileOpen}
								setUserProfileOpen={setUserProfileOpen}
								currentUserUid={myProfile?.userUid}
								fnCommonConfirmPopOn={fnCommonConfirmPopOn}
								fnDeleteComment={fnDeleteComment}
							/>
						</CSSTransition>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyFeedListContainer;
