import { useStoreRepo } from '@dating/repository/store/useStoreRepo';
import { usePointAdapter } from '@dating/adapter/point/usePointAdapter';
import { useEffect } from 'react';
import {useOrderRepo} from "@dating/repository/order/useOrderRepo";

export const useMyBerithService = () => {
	const {
		myBerith,
		setMyBerith,
		myBerithHistory,
		setMyBerithHistory,
	} = useStoreRepo();

	const {
		isSuccess,
		setIsSuccess,
	} = useOrderRepo()

	const {
		getPoint,
		getPointUsageHistory,
	} = usePointAdapter();

	useEffect(() => {
		getPoint().then(value => {
			setMyBerith(value.point);
		});

		getPointUsageHistory().then(value => {
			setMyBerithHistory(value.data);
		});
	}, []);

	useEffect(() => {
		if(isSuccess == 'success'){
			getPoint().then(value => {
				setMyBerith(value.point);
			});
			getPointUsageHistory().then(value => {
				setMyBerithHistory(value.data);
			});
		}
	}, [isSuccess]);

	return {
		myBerith,
		myBerithHistory,
	};
};