import {useEffect} from "react";
import {MobilePhoneOccupancyCIVO} from "@/site/api";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {usePhoneNumberStateRepo} from "@dating/ui/pages/register/phoneNumber/usePhoneNumberStateRepo";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

/*
 * /metaverse/dating/register/phone
 * PhoneNumberContainer
 * 핸드폰번호입력
 */
export const usePhoneNumberService = () => {
    const {
        footerBtnDisable,
        setFooterBtnDisable,
    } = usePhoneNumberStateRepo();

    const {
        phoneNumber,
        setPhoneNumber,
        verifyReqCount,
        setVerifyReqCount,
        resetRegisterInfo,
    } = useRegisterRepo();

    const {
        sendVerificationCode,
    } = useRegisterAdapter();

    const handlePhoneNumberChange = (inputText: string) => {
        if (inputText.length > 11) {
            return;
        }
        if (!isNaN(Number(inputText))) {
            setPhoneNumber(inputText);
        }
    };

    const fnSendVerificationCode = () => {
        const map: MobilePhoneOccupancyCIVO = {
            mobilePhoneNumber: phoneNumber
        }
        sendVerificationCode(map).then((value) => {
            if (value && verifyReqCount === 0) {
                setVerifyReqCount(verifyReqCount + 1);
            }
        }).catch((e) => {
            if(e?.msg != '') alert(e.msg)
            }
        );
    };

    const validatePhoneNumber = (phoneNumber: string) => {
        if(JSON.parse(import.meta.env.IOS_TESTER_ID).includes(phoneNumber)){
            // ios 테스트 계정 패스 처리
            return true
        }
        // 숫자만 허용
        const onlyNumbers = phoneNumber.replace(/\D/g, "");
        // 010으로 시작하도록 강제
        if (!onlyNumbers.startsWith("010")) return false
        // 11자리까지만 허용
        if (onlyNumbers.length == 11) return true
    }

    useEffect(() => {
        if (validatePhoneNumber(phoneNumber)) {
            setFooterBtnDisable(false);
        } else {
            setFooterBtnDisable(true);
        }
    }, [phoneNumber]);

    return {
        phoneNumber,
        handlePhoneNumberChange,
        footerBtnDisable,
        fnSendVerificationCode,
        setFooterBtnDisable,
        verifyReqCount,
        resetRegisterInfo,
    };
}