import {useTranslation} from "react-i18next";

interface props {
    btnActivate: boolean;
    fnSaveUserGender: () => void;
}
const GenderSelectFooterView = ({
                                    btnActivate,
                                    fnSaveUserGender,
}: props) => {
    const { t } = useTranslation('register')
  return (
    <footer>
      <div className="f-btnWrap">
        <button
          className="btn btnBig btnBlack"
          onClick={fnSaveUserGender}
          disabled={btnActivate}
        >
          <span>{t('common.next')}</span>
        </button>
      </div>
    </footer>
  );
};

export default GenderSelectFooterView;
