import { ProductService } from '@/site/api';

export const useProductAdapter = () => {

	/*
	 * getProducts
	 * 상품 목록 조회
	 */
	const getProducts = (payType?: 'CASH' | 'POINT', type?: Array<string>) => {
		return ProductService.getProducts(payType, type);
	};

	return {
		getProducts,
	};
};