import React, { useRef } from 'react';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useLikeHeaderService } from '@dating/ui/pages/like/views/useLikeHeaderService';
import { useLikeReceiveService } from '@dating/ui/pages/like/likeReceive/useLikeReceiveService';
import { Link } from 'react-router-dom';
import LikeTitleView from '@dating/ui/pages/like/views/LikeTitleView';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useManagerLikeSerivce } from '@dating/ui/pages/like/managerLike/useManagerLikeSerivce';
import {useTranslation} from "react-i18next";

export const ManagerLikeContainer = () => {

	const {
		isReceived,
		handleScroll,
		scrollYIndex,
		myProfile,
		srcPrefix,
		setMyProfileOpen,
		setUserProfileOpen,
	} = useLikeHeaderService();

	const {
		likeUsers,
		highlyRatedUsers,
		likedPosts,
		fadeIn,
		fnGetReceiveUserLike,
		noDataUserLike,
		noDataHighlyRated,
		noDataPost,
		setSingleFeedId,
		fnGetLikedPosts,
		fnGetReceiveUserHighScore,
	} = useManagerLikeSerivce();

	const likeContainer = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('like');

	return (
		<div className="wrap">
			<header className="cateHeader">
				<div className="headerWrap">
					<div className="h-left">
						<div className="headerTab">
							<button
								type="button"
								className={`tabButton tab01 ${isReceived ? 'active' : ''}`}
							>
								<span>{t('managerLike.like')}</span>
							</button>
						</div>
					</div>

					<div className="h-center" />

					<div className="h-right">
						<span className="headerMgBadge">{t('managerLike.manager')}</span>
						<Link to={PATH.MANAGER.MY_PROFILE}>
							<button className="profileSmImg">
								<img
									src={
										myProfile && myProfile?.profileUrl[0]
											? srcPrefix + myProfile?.profileUrl[0]
											: '/assets/img/test/boy.png'
									}
									alt="테스트이미지"
								/>
							</button>
						</Link>
					</div>
				</div>
			</header>

			<div
				className="toDayWrap"
				ref={likeContainer}
				onScroll={() => {
					handleScroll(likeContainer.current?.scrollTop);
				}}
			>
				<div className="inner">
					<div className="tabContent">
						<div className={`${isReceived ? 'active' : ''} tabCon tabCon01`}>
							{/*<div className="likeList">*/}
							<div
								className={`likeList spinnerContent ${fadeIn ? 'fade-in' : ''}`}
							>
								{likeUsers.length === 0 &&
								highlyRatedUsers.length === 0 &&
								likedPosts.length === 0 ? (
									<>
										<div className="dataNoneTxt">
											<p className="txt01">{t('managerLike.emptyLike')}</p>
										</div>
									</>
								) : null}

								{/*받은 좋아요*/}
								{likeUsers.length != 0 ? (
									<>
										<LikeTitleView
											titSet={t('managerLikeTitSets', { returnObjects: true })[0]}
											index={0}
											scrollYIndex={scrollYIndex}
										/>

										<div
											className={`likeProfileList ${
												likeUsers.length === 0 ? 'likeProfileList-empty' : ''
											}`}
										>
											{likeUsers.map((item, i) => {
												return (
													<div className="profileType" key={i}>
														<div className="proLeft">
															<div className="proImg">
																<Link
																	to={PATH.PROFILE.USER_PROFILE}
																	onClick={() => {
																		setUserProfileOpen(item.likeUserUid);
																	}}
																	state={{
																		isOtherProfile: true,
																	}}
																>
																	<img src={srcPrefix + item.profileUrl} />
																</Link>
															</div>
															<div className="proName margin-l12">
																<p className="bold padding-b4">
																	{item.nickName}
																</p>
																<p className="fz14 color-666">
																	{item.age + t('managerLike.age') +', ' + (item.region1 && item.region2 ? `${item.region1} ${item.region2}` : t('common.noRegion'))}
																</p>
															</div>
														</div>
														<div className="ProRight margin-b30">
															<span className="date">{item.receivedDate}</span>
														</div>
													</div>
												);
											})}

											<div
												className="padding-t8 padding-b16 text-c"
												style={{
													display: noDataUserLike ? 'block' : 'none',
												}}
											>
												<a
													className="color-999 underline bold-500"
													onClick={() => {
														fnGetReceiveUserLike();
													}}
												>
													{t('managerLike.seeMore')}
												</a>
											</div>
										</div>
									</>
								) : null}

								{/*높게 받은 평가*/}
								{highlyRatedUsers.length != 0 ? (
									<>
										<LikeTitleView
											titSet={t('managerLikeTitSets', { returnObjects: true })[1]}
											index={1}
											scrollYIndex={scrollYIndex}
										/>
										<div
											className={
												highlyRatedUsers.length === 0
													? 'likeProfileList-empty'
													: 'likeProfileList'
											}
										>
											{highlyRatedUsers.map((item, i) => {
												return (
													<div key={item.nickName + i} className="profileType">
														<div className="proLeft">
															<div className="proImg">
																<Link
																	to={PATH.PROFILE.USER_PROFILE}
																	onClick={() => {
																		setUserProfileOpen(item.scoreUserUid);
																	}}
																	state={{
																		isOtherProfile: true,
																	}}
																>
																	<img src={srcPrefix + item.profileUrl} />
																</Link>
															</div>
															<div className="proName margin-l12">
																<p className="bold padding-b4">
																	{item.nickName}
																</p>
																<p className="fz14 color-666">
																	{item.age + t('managerLike.age') +', ' + (item.region1 && item.region2 ? `${item.region1} ${item.region2}` : t('common.noRegion'))}
																</p>
															</div>
														</div>
														<div className="ProRight margin-b30">
															<span className="date">{item.receivedDate}</span>
														</div>
													</div>
												);
											})}

											<div
												className="padding-t8 padding-b16 text-c"
												style={{
													display: noDataHighlyRated ? 'block' : 'none',
												}}
											>
												<a
													className="color-999 underline bold-500"
													onClick={() => {
														fnGetReceiveUserHighScore();
													}}
												>
													{t('managerLike.seeMore')}
												</a>
											</div>
										</div>
									</>
								) : null}

								{/*좋아요 보낸 게시글*/}
								{likedPosts.length != 0 ? (
									<>
										<LikeTitleView
											titSet={t('managerLikeTitSets', { returnObjects: true })[2]}
											index={2}
											scrollYIndex={scrollYIndex}
										/>
										<div
											className={
												likedPosts.length === 0
													? 'likeProfileList-empty'
													: 'likeProfileList'
											}
										>
											{likedPosts.map((item, i) => {
												if (item.profileUrl) {
													return (
														<div
															key={item.likePostId + i}
															className="profileType"
														>
															<div className="proLeft">
																<div className="proImg">
																	<Link
																		to={`/metaverse/dating/profile${
																			item.userUid !== myProfile.userUid
																				? '/userProfile'
																				: '/myProfile'
																		}`}
																		onClick={() => {
																			item.userUid !== myProfile.userUid
																				? setUserProfileOpen(item.userUid)
																				: setMyProfileOpen(true);
																		}}
																		state={{
																			isOtherProfile:
																				item.userUid !== myProfile.userUid,
																		}}
																	>
																		<img src={srcPrefix + item.profileUrl} />
																	</Link>
																</div>
																<div className="proName margin-l12">
																	<p className="bold padding-b4">
																		{item.nickName}
																	</p>
																	<p className="fz14 color-666">
																		{item.age + t('managerLike.age') +', ' + (item.region1 && item.region2 ? `${item.region1} ${item.region2}` : t('common.noRegion'))}
																	</p>
																</div>
															</div>
															<div className="ProRight">
																<div className="likeSmImg">
																	<Link
																		to={PATH.LOUNGE.SINGLE}
																		onClick={() => {
																			setSingleFeedId(item.likePostId);
																		}}
																	>
																		<img
																			src={srcPrefix + item.postPhotoUrl}
																			alt={item.nickName}
																		/>
																		<span className="likeBadge"></span>
																	</Link>
																</div>
															</div>
														</div>
													);
												}
											})}
										</div>
										<div
											className="padding-t8 padding-b16 text-c"
											style={{
												display: noDataPost ? 'block' : 'none',
											}}
										>
											<a
												className="color-999 underline bold-500"
												onClick={() => {
													fnGetLikedPosts();
												}}
											>
												{t('managerLike.seeMore')}
											</a>
										</div>
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
