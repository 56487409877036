import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { Link } from 'react-router-dom';
import { useMyManagerOfflineListService } from './service/useMyManagerOfflineListService';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { useTranslation } from 'react-i18next';

const MyManagerOfflineListContainer = () => {
	const { t } = useTranslation('messenger');
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { myProfile, sortedMessages } = useMyManagerOfflineListService();

	const renderMessageItem = (message) => {
		const {
			billingStatus,
			dmRoomType,
			roomId,
			otherUserInfo,
			messages,
			latestCreatedAt,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			unreadCount,
			display,
			user1Info,
			productType,
			productSubType,
			useStatus,
			seq,
			matchedAt,
			validUses,
		} = message;

		const createMessageListState = () => {
			const isUser1 = user1Info?.userUid === myProfile.userUid;
			const sortedMessagesData = [...messages].sort(
				(a, b) => parseInt(a.createdAt) - parseInt(b.createdAt),
			);

			const state = {
				billingStatus,
				myPaymentStatus: isUser1 ? billingStatus === 1 : billingStatus === 2,
				otherPaymentStatus: isUser1 ? billingStatus === 2 : billingStatus === 1,
				dmRoomType,
				roomId,
				visibleTo,
				isUserLeft,
				isOtherUserLeft,
				user1Info: user1Info || {
					userUid: '',
					nickName: '',
					profileUrl: '',
					region1: '',
					region2: '',
					age: '',
					blockType: '',
				},
				otherUserInfo: otherUserInfo || {
					userUid: '',
					nickName: '',
					profileUrl: '',
					region1: '',
					region2: '',
					age: '',
					blockType: '',
				},
				createdAt: latestCreatedAt,
				messages: sortedMessagesData,
				productType,
				productSubType,
				useStatus,
				seq,
				matchedAt,
			};

			return state;
		};

		if (isUserLeft || visibleTo === -1) {
			return null;
		}

		const sortedMessages = [...messages].sort(
			(a, b) => parseInt(b.createdAt) - parseInt(a.createdAt),
		);
		const latestMessage = sortedMessages[0]?.content;

		const getStatusText = (status) => {
			switch (status) {
				case 'WAIT':
					return t('waitingUse');
				case 'USING':
					return t('using');
				case 'DONE':
					return t('usageComplete');
				default:
					return '';
			}
		};

		if (visibleTo !== -1) {
			return (
				<div
					className={`profileType ${useStatus === 'DONE' ? 'used' : ''}`}
					key={roomId}
				>
					<div className="proLeft width-100p">
						<Link
							className="proName margin-l8 flex-1 margin-l8 flex-1 margin-t5"
							style={{ width: 'calc(100% - 78px)', display: 'block' }}
							to={`${PATH.MESSENGER.MANAGER_OFFLINE}/${roomId}`}
							state={createMessageListState()}
							onClick={(e) => {
								e.stopPropagation();
								setOpenDirectMessageDetail(true, roomId);
								// 방 입장 이벤트 발생
								dmEventEmitter.emit('react-enter-dm-room', {
									roomId: roomId,
								});
								sessionStorage.setItem('isOnline', 'false');
							}}
						>
							<div className="proName flex-1 width-100p">
								<p className="d-flex justify-between align-center">
									<span className="bold">
										{t(
											productSubType === 'OFFLINE'
												? 'offlineMatching'
												: 'matching',
										)}{' '}
										{t('usageCount', { total: validUses, current: seq })}{' '}
										{getStatusText(useStatus)}
									</span>
								</p>
								<span className="fz10 color-999 bold-500 padding-b4">
									{messages.length > 0
										? new Date(parseInt(messages[0].createdAt))
												.toISOString()
												.split('T')[0]
										: latestCreatedAt
										? new Date(parseInt(latestCreatedAt))
												.toISOString()
												.split('T')[0]
										: ''}
								</span>
								<div className="d-flex justify-between">
									<div className="d-flex justify-between">
										<p className={`ellipsis fz12 ${display?.className || ''}`}>
											{display?.prefix || latestMessage || ''}
										</p>
										{unreadCount > 0 && (
											<span className="chatMsgOn margin-l5">{unreadCount}</span>
										)}
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map(renderMessageItem)
			) : (
				<div className="chatStartBox">
					<p className="partnerStart2 onoffLineBg">
						{t('noMatchHistory', { text: t('offline') })}
					</p>
				</div>
			)}
		</div>
	);
};

export default MyManagerOfflineListContainer;
