import Phaser from 'phaser';
import Item from '../../items/ItemAbst';
import { PlayerBehavior } from '../../types/PlayerBehavior';
import { MyDirectionCursor } from './MyDirectionCursor';
import MyPlayer from './MyPlayer';
import { useActionButtonRepo } from '@/stores/useActionButtonRepo';

const ITEM_FORWARD_POSITION = 10;

export class MyPlayerCollision extends Phaser.GameObjects.Zone {
	_selectedItem?: Item;

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		width: number,
		height: number,
		private myCursor: MyDirectionCursor,
	) {
		super(scene, x, y, width, height);

		// collision 박스를 추가하고, scene의 물리 엔진에 등록
		scene.physics.add.existing(this);
	}

	update(player: MyPlayer) {
		// 플레이어가 앉아있는 동안 플레이어 선택을 업데이트하지 않음
		if (player.playerBehavior === PlayerBehavior.SITTING) {
			return;
		}

		// 플레이어 선택 박스의 위치를 항상 플레이어 앞에 있도록 업데이트
		this._changeCollisionPosition(player);

		// 현재 아이템을 선택하고 있을 때,
		// 선택기와 선택한 아이템이 서로 겹치지 않는 경우 대화 상태와 선택 아이템을 초기화
		this._collisionCheck();
	}

	get selectedItem() {
		return this._selectedItem;
	}

	set selectedItem(item: Item | undefined) {
		this._selectedItem = item;
	}

	private _changeCollisionPosition(player: MyPlayer) {
		const { x, y } = player;

		if (this.myCursor.left) {
			this.setPosition(x - ITEM_FORWARD_POSITION, y);
		} else if (this.myCursor.right) {
			this.setPosition(x + ITEM_FORWARD_POSITION, y);
		} else if (this.myCursor.up) {
			this.setPosition(x, y - ITEM_FORWARD_POSITION);
		} else if (this.myCursor.down) {
			this.setPosition(x, y + ITEM_FORWARD_POSITION);
		}
	}

	private _collisionCheck() {
		const { setActionButtonActive } = useActionButtonRepo.getState();

		if (
			this.selectedItem &&
			!this.scene.physics.overlap(this, this.selectedItem)
		) {
			this.selectedItem.clearDialogBox();
			setActionButtonActive(false);
			this.selectedItem = undefined;
		} else if (this.selectedItem) {
			setActionButtonActive(true);
		}
	}
}
