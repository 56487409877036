import Slider from 'react-slick';
import { CSSTransition } from 'react-transition-group';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import React, { useEffect, useRef } from 'react';
import StepLastBoxView from '@dating/ui/pages/profile/views/StepLastBoxView';
import { useUserProfileService } from '@dating/ui/pages/userProfile/service/useUserProfileService';
import ProfileHeader from '@dating/ui/pages/userProfile/views/ProfileHeader';
import AuthAddBadgeView from '@dating/ui/components/authAddBadge/views/AuthAddBadgeView';
import { useTranslation } from 'react-i18next';

const UserProfileContainer = () => {
	const {
		userProfile,
		initProfile,
		profileDetails,
		isChecked,
		setIsChecked,
		scrollHandler,
		evalHandler,
		savedLikeProfile,
		setSavedLikeProfile,
		badgeData,
		clearPreviewURLs,
		sliderSettings,
		setSettingOpen,
		srcPrefix,
		myProfile,
		myProfileOpen,
		setMyProfileOpen,
	} = useUserProfileService();

	const {
		setOpenMenuPop,
		openMenuPop,
		openReport,
		setOpenReport,
		setOpenBlock,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		setBlockUserUid,
		reportPopClose,
		fnSaveReport,
		reportPopOpen,
	} = useBlockReportService();

	const { fnSendLikePopOpen, setOpenPaymentPop, setMessageTargetUser } =
		usePaymentService();

	const proInfo01 = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('profile');

	useEffect(() => {
		// today 좋아요 처리 완료 시 프로필카드 삭제
		if (savedLikeProfile) {
			return () => {
				setSavedLikeProfile(false);
			};
		}
	}, [savedLikeProfile]);

	useEffect(() => {
		setMyProfileOpen(false);
	}, []);

	return (
		<>
			<div
				className="wrap profileWrap footerNone"
				style={{
					overflowY: 'auto',
				}}
				onScroll={(e) => {
					if (proInfo01.current) {
						const y = e.currentTarget.scrollTop;
						let rectTop = proInfo01?.current.getBoundingClientRect().top;
						scrollHandler(y, rectTop);
					}
				}}
			>
				<ProfileHeader
					openMenuPop={openMenuPop}
					setOpenMenuPop={setOpenMenuPop}
					initProfile={initProfile}
					setSettingOpen={setSettingOpen}
					menuDotClickHandler={menuDotClickHandler}
					setBlockUserUid={setBlockUserUid}
					profile={userProfile}
					setOpenBlock={setOpenBlock}
					reportPopOpen={reportPopOpen}
					myProfileOpen={myProfileOpen}
				/>
				<div id="conWrap">
					<div className="profileBanner">
						<div className="inner">
							<div className="profileBox">
								<Slider className="profileSlide" {...sliderSettings}>
									{userProfile &&
										userProfile.profileUrl.map((item, i) => {
											return (
												<img
													key={item + i}
													src={srcPrefix + item}
													alt={userProfile.nickName}
												/>
											);
										})}
								</Slider>

								<div className="txtBox">
									{userProfile.hasBadged &&
																			<span className="addAuthBadge">{t('additionalCertification')}
										  {/* 추가인증 */}
                                        </span>
									}
									<p className="txt01 fz20 color-fff">{userProfile.nickName}</p>
									<p className="txt02 fz16 color-fff">
										{userProfile.age + t('age')}
										{/* 세 */}, {userProfile.region1 && userProfile.region2 ? `${userProfile.region1} ${userProfile.region2}` : t('noLocation')}
										{/* 위치정보 없음 */}
									</p>
									{myProfile.role === 'NORMAL' ? (
										<div className="profileLikeBox">
											<button
												type="button"
												className={`likeBtn ${userProfile.likeYn ? 'on' : ''}`}
												onClick={(e) => {
													if (
														!e.currentTarget.classList.contains('on') &&
														userProfile.userUid != ''
													) {
														fnSendLikePopOpen(
															userProfile.userUid,
															userProfile.profileUrl[0],
															userProfile.nickName,
															'profile',
														);
													}
												}}
											/>
											<button
												type="button"
												className="shareBtn"
												onClick={() => {
													if (userProfile.userUid != '') {
														setOpenPaymentPop(true);
														setMessageTargetUser(userProfile.userUid);
													}
												}}
											/>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>

					<div className="profileInfo" ref={proInfo01}>
						<div className="inner">
							{/*별점평가, 자기소개, 뱃지, 프로필요약, 추가인증정보, 내가 올린 라운지 게시글*/}
							<StepLastBoxView profileDetails={profileDetails} />

							<div className="starWarp margin-b20">
								<div className="starBox">
									{/*별점평가*/}
									{[5, 4, 3, 2, 1].map((value, i) => {
										const id = 'star' + value.toString() + i;
										return (
											<React.Fragment key={id}>
												<input
													type="radio"
													name="rating"
													id={id}
													value={value}
													checked={isChecked === value ? true : false}
													onChange={(e) => {
														setIsChecked(value);
														evalHandler(
															userProfile.userUid,
															Number(e.target.value),
														);
													}}
												/>
												<label htmlFor={id} />
											</React.Fragment>
										);
									})}
								</div>
								<p className="fz12 color-666 text-c margin-t8">
									{t('expressLike')}
									{/* 호감도를 표현하여 상대방에게 자신을 어필해 보세요 */}
								</p>
							</div>
							{badgeData && badgeData.length > 0 &&
								<AuthAddBadgeView
									clearPreviewURLs={clearPreviewURLs}
									myProfileOpen={myProfileOpen}
									badgeData={badgeData}
								/>
							}
						</div>
					</div>
				</div>

				{/* 신고하기 */}
				<CSSTransition
					in={openReport}
					classNames="popWrap-transition"
					timeout={100}
				>
					<ReportPopView
						reportPopClose={reportPopClose}
						selectedOption={selectedOption}
						handleOptionSelect={handleOptionSelect}
						commonReportCodes={commonReportCodes}
						reportValue={reportValue}
						reportInputHandler={reportInputHandler}
						inputDisabled={inputDisabled}
						fnSaveReport={fnSaveReport}
					/>
				</CSSTransition>
			</div>
		</>
	);
};

export default UserProfileContainer;
