import { Outlet } from 'react-router-dom';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useMyManagerListService } from '@/ui/components/dm/myManager/online/list/service/useMyManagerListService';
import { useMessageListService } from '../../../../../ui/components/dm/user/list/service/useMessageListService';
import MessageHeaderView from '../../../../../ui/components/dm/user/list/views/MessageHeaderView';
import { useMyManagerService } from '@/ui/components/dm/myManager/service/useMyManagerService';
import { useEffect } from 'react';

const MessengerLayout = () => {
	const { showDirectMessageList, managerEmpty, myProfile, srcPrefix } =
		useMessageListService();

	const {
		setShowOfflineMessageList,
		setShowOnlineMessageList,
		setIsOnline,
		isMessenger,
		setIsMessenger,
	} = useMyManagerListService();

	const { initManagerProfile } = useMyManagerService();

	const { setMyProfileOpen } = useHeaderService();

	useEffect(() => {
		initManagerProfile();
	}, []);

	return (
		<>
			{showDirectMessageList ? (
				<div className="wrap">
					<MessageHeaderView
						myProfile={myProfile}
						srcPrefix={srcPrefix}
						setMyProfileOpen={setMyProfileOpen}
						setShowOnlineMessageList={setShowOnlineMessageList}
						setShowOfflineMessageList={setShowOfflineMessageList}
						setIsOnline={setIsOnline}
						managerEmpty={managerEmpty}
						isMessenger={isMessenger}
						setIsMessenger={setIsMessenger}
					/>
					<Outlet />
				</div>
			) : (
				<Outlet />
			)}
		</>
	);
};

export default MessengerLayout;
