import { Link } from 'react-router-dom';
import {PATH} from "@dating/constants/RoutingEndPoints";

interface props {
	fnCommonAlertPopOn: (message: string) => void;
}

const LeftMenuListView = ({
							  fnCommonAlertPopOn,
						  }: props) => {
	return (
		<div className="leftMenuList">
			<ul>
				<li className="noti">
					<Link to={PATH.SIDE_BAR.NOTICE}>공지사항</Link>
				</li>
				{/*<li className="event">*/}
				{/*	<Link to={PATH.SIDE_BAR.EVENT}>이벤트</Link>*/}
				{/*</li>*/}
				{/*<li className="mission">*/}
				{/*	<Link to={PATH.SIDE_BAR.MISSION}>미션</Link>*/}
				{/*</li>*/}
				{/*<li className="store">*/}
				{/*	<Link to={PATH.SIDE_BAR.STORE}>스토어</Link>*/}
				{/*</li>*/}
				{/*<li className="charge">*/}
				{/*	<a>무료 충전소</a>*/}
				{/*</li>*/}
				<li className="help"
					onClick={() => {
						fnCommonAlertPopOn('메타버스 도움말 \r\n준비중 입니다.');
					}}
				>
					<a>메타버스 도움말</a>
				</li>
			</ul>
		</div>
	);
};

export default LeftMenuListView;
