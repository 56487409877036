import { useChatRepo } from '@/stores/useChatRepo';
import { useItemRepo } from '@/stores/useItemRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useVideoRepo } from '@/stores/useVideoRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoomListService } from '../components/room/roomList/service/useRoomListService';
import {PATH} from "@dating/constants/RoutingEndPoints";
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';

export const usePhaserAppService = () => {
	const {
		passwordError,
		joinedRoomData,
		roomDisposedPop,
		showPasswordInput,
		setPasswordError,
		setRoomDisposedPop,
		setShowPasswordInput,
	} = useRoomRepo();
	const isPublicRoom = joinedRoomData?.name.toLowerCase() === 'public';
	const { modal } = useItemRepo();
	const { showChat, focused, mobileChatInputClick, setFocused } = useChatRepo();
	const { openCamExpansion } = useVideoRepo();
	const isOpen = openCamExpansion.isOpen;
	const { myProfile, srcPrefix } = useHeaderService();
	const { showImgCrop } = useImgCropRepo();
	const { setMyProfileOpen } = useDatingRepo();
	const { handlePasswordSubmit } = useRoomListService();

	const {
		managerProducts,
	} = useProductsRepo();
	const navigate = useNavigate();

	useEffect(() => {
		if (showImgCrop) {
			navigate(PATH.COMMON.IMG_CROP);
		}
	}, [showImgCrop]);

	return {
		modal,
		isOpen,
		focused,
		showChat,
		myProfile,
		srcPrefix,
		isPublicRoom,
		passwordError,
		roomDisposedPop,
		showPasswordInput,
		mobileChatInputClick,
		setFocused,
		setMyProfileOpen,
		setPasswordError,
		setRoomDisposedPop,
		setShowPasswordInput,
		handlePasswordSubmit,
		managerProducts,
	};
};
