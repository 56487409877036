import {
	dmEventEmitter,
	REACT_BLOCK_USER,
	REACT_CHANGE_DIRECT_ROOM_TYPE,
	REACT_CHANGE_DM_INACTIVE,
	REACT_CHANGE_DM_IS_DELETED,
	REACT_CHANGE_DM_USE_STATUS,
	REACT_CHECK_USER_IN_ROOM,
	REACT_CREATE_DM_ROOM,
	REACT_ENTER_DM_ROOM,
	REACT_EXIT_DIRECT_MESSAGE,
	REACT_GET_DM_ROOMS,
	REACT_LEAVE_DIRECT_MESSAGE,
	REACT_LEAVE_DM_ROOM,
	REACT_MUTUAL_PAYMENT_COMPLETED,
	REACT_READ_DIRECT_MESSAGE,
	REACT_SEND_DIRECT_MESSAGE,
	REACT_SINGLE_PAYMENT_COMPLETED,
	REACT_UPDATE_DM_PROFILE_URL,
} from '@/events/ReactDMEventEmitter';

import { RoomService } from '@/services/Network';

export class DirectMessageEventController {
	private static instance: DirectMessageEventController | null = null;
	private listeners: Listener[];
	private isListening: boolean = false;

	constructor(private roomService: RoomService) {
		this.listeners = [
			new MyPlayerCreateDMRoomListener(this.roomService),
			new MyPlayerDMListener(this.roomService),
			new MyPlayerExitDMListener(this.roomService),
			new MyPlayerReadDMListener(this.roomService),
			new MyPlayerResetTotalUnreadCountListener(this.roomService),
			new MyPlayerLeaveDMListener(this.roomService),
			new MyPlayerPaymentCompletedListener(this.roomService),
			new MyPlayerMutualPaymentCompletedListener(this.roomService),
			new MyPlayerChangeDMRoomTypeListener(this.roomService),
			new MyPlayerChangeDMUseStatusListener(this.roomService),
			new MyPlayerLoadDMRoomListener(this.roomService),
			new MyPlayerEnterRoomListener(this.roomService),
			new MyPlayerLeaveRoomListener(this.roomService),
			new MyPlayerCheckUserSessionListener(this.roomService),
			new MyPlayerBlockUserListener(this.roomService),
			new MyPlayerUpdateDMProfileUrlListener(this.roomService),
			new MyPlayerChangeDMInactive(this.roomService),
			new MyPlayerChangeIsDeleted(this.roomService),
		];
	}

	public static getInstance(
		roomService: RoomService,
	): DirectMessageEventController {
		if (!DirectMessageEventController.instance) {
			DirectMessageEventController.instance = new DirectMessageEventController(
				roomService,
			);
		}
		return DirectMessageEventController.instance;
	}

	listen() {
		if (this.isListening) {
			return;
		}
		this.listeners.forEach((listener) => {
			listener.listen();
		});
		this.isListening = true;
	}

	removeAllListeners() {
		this.listeners.forEach((listener) => {
			if (listener.removeListeners) {
				listener.removeListeners();
			}
		});
		this.isListening = false;
	}
}

interface Listener {
	listen(): void;
	removeListeners?(): void;
}

// 기존 메시지 방 불러오기 리스너
class MyPlayerLoadDMRoomListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-load-dm-room" 이벤트 리스닝
		dmEventEmitter.on('react-get-dm-rooms', this.event, this);
	}

	removeListeners(): void {
		dmEventEmitter.off('react-get-dm-rooms', this.event);
	}
	event(content: REACT_GET_DM_ROOMS) {
		// 룸 서비스에 기존 메시지 방 불러오기
		this.roomService.getDmRooms();
	}
}

// 디엠 방 생성 리스너
class MyPlayerCreateDMRoomListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen(): void {
		// "react-create-dm-room" 이벤트 리스닝
		dmEventEmitter.on('react-create-dm-room', this.event, this);
	}

	removeListeners(): void {
		dmEventEmitter.off('react-create-dm-room', this.event);
	}
	event(content: REACT_CREATE_DM_ROOM) {
		// 룸 서비스에 디엠 방 생성
		this.roomService.createDirectMessageRoom(
			content.billingStatus,
			content.dmRoomType,
			content.roomId,
			content.user1Id,
			content.user2Id,
			content.user1Info,
			content.user2Info,
			content.createdAt,
			content.matchedAt,
			content.productType,
			content.productSubType,
			content.useStatus,
			content.seq,
			content.validUses,
			content.orderId,
		);
	}
}

// 디엠 메시지 리스너
class MyPlayerDMListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-add-dm-message" 이벤트 리스닝
		dmEventEmitter.on('react-send-direct-message', this.event, this);
	}

	removeListeners(): void {
		dmEventEmitter.off('react-send-direct-message', this.event);
	}
	event(content: REACT_SEND_DIRECT_MESSAGE) {
		// 룸 서비스에 디엠 메시지 추가
		this.roomService.sendDirectMessage(
			content.roomId,
			content.messageId,
			content.sender,
			content.receiver,
			content.content,
			content.createdAt,
			content.read,
		);
	}
}

// 디엠 읽음 리스너
class MyPlayerReadDMListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-read-dm-message" 이벤트 리스닝
		dmEventEmitter.on('react-read-direct-message', this.event, this);
	}

	removeListeners(): void {
		dmEventEmitter.off('react-read-direct-message', this.event);
	}
	event(content: REACT_READ_DIRECT_MESSAGE) {
		// 룸 서비스에 안읽은 디엠 카운트 초기화
		this.roomService.readDirectMessage(
			content.roomId,
			content.messageId,
			content.userUid,
		);
	}
}

// 디엠 전체 읽지 않은 메시지 카운트 0으로 초기화
class MyPlayerResetTotalUnreadCountListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-reset-all-unread', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-reset-all-unread', this.event);
	}
	event() {
		this.roomService.resetUnreadMessageCount();
	}
}

// 디엠 채팅방 나가기 리스너
class MyPlayerLeaveDMListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-exit-dm-message" 이벤트 리스닝
		dmEventEmitter.on('react-leave-direct-message', this.event, this);
	}

	removeListeners(): void {
		dmEventEmitter.off('react-leave-direct-message', this.event);
	}
	event(content: REACT_LEAVE_DIRECT_MESSAGE) {
		// 룸 서비스에 디엠 채팅방 나가기
		this.roomService.leaveDirectMessage(content.roomId, content.leaverId);
	}
}

// 디엠 채팅방 나가기 리스너
class MyPlayerExitDMListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-exit-dm-message" 이벤트 리스닝
		dmEventEmitter.on('react-exit-direct-message', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-exit-direct-message', this.event);
	}
	event(content: REACT_EXIT_DIRECT_MESSAGE) {
		// 룸 서비스에 디엠 채팅방 나가기
		this.roomService.exitDirectMessage(content.roomId, content.exit);
	}
}

// 디엠 단일 결제
class MyPlayerPaymentCompletedListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-single-payment-completed" 이벤트 리스닝
		dmEventEmitter.on('react-single-payment-completed', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-single-payment-completed', this.event);
	}
	event(content: REACT_SINGLE_PAYMENT_COMPLETED) {
		// 룸 서비스에 디엠 단일 결제
		this.roomService.singlePaymentCompleted(content.roomId, content.payerId);
	}
}

// 디엠 쌍방 결제
class MyPlayerMutualPaymentCompletedListener implements Listener {
	constructor(private roomService: RoomService) {}

	listen() {
		// "react-mutual-payment-completed" 이벤트 리스닝
		dmEventEmitter.on('react-mutual-payment-completed', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-mutual-payment-completed', this.event);
	}
	event(content: REACT_MUTUAL_PAYMENT_COMPLETED) {
		// 룸 서비스에 디엠 쌍방 결제
		this.roomService.mutualPaymentCompleted(content.roomId, content.paid);
	}
}

// 디엠 방 타입 변경
class MyPlayerChangeDMRoomTypeListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-change-direct-room-type', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-change-direct-room-type', this.event);
	}
	event(content: REACT_CHANGE_DIRECT_ROOM_TYPE) {
		this.roomService.changeDirectRoomType(content.roomId, content.dmRoomType);
	}
}

// 디엠 사용 상태 변경
class MyPlayerChangeDMUseStatusListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-change-dm-use-status', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-change-dm-use-status', this.event);
	}
	event(content: REACT_CHANGE_DM_USE_STATUS) {
		this.roomService.changeDMUseStatus(content.roomId, content.useStatus);
	}
}

// 디엠 유저 차단
class MyPlayerBlockUserListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-block-user', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-block-user', this.event);
	}
	event(content: REACT_BLOCK_USER) {
		this.roomService.blockUser(
			content.roomId,
			content.blockType,
			content.blockerUuid,
			content.targetUid,
		);
	}
}

// 디엠 프로필 변경
class MyPlayerUpdateDMProfileUrlListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-update-dm-profile-url', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-update-dm-profile-url', this.event);
	}
	event(content: REACT_UPDATE_DM_PROFILE_URL) {
		this.roomService.updateDMProfileUrl(content.userUid, content.profileUrl);
	}
}

// 디엠 휴면 상태 변경
class MyPlayerChangeDMInactive implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-change-dm-inactive', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-change-dm-inactive', this.event);
	}
	event(content: REACT_CHANGE_DM_INACTIVE) {
		this.roomService.changeDMInactive(content.userUid, content.inActive);
	}
}

// 디엠 탈퇴 상태 변경
class MyPlayerChangeIsDeleted implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-change-dm-is-deleted', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-change-dm-is-deleted', this.event);
	}
	event(content: REACT_CHANGE_DM_IS_DELETED) {
		this.roomService.changeDMIsDeleted(content.userUid, content.isDeleted);
	}
}

// 메시지 푸시 관련
class MyPlayerEnterRoomListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-enter-dm-room', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-enter-dm-room', this.event);
	}
	event(content: REACT_ENTER_DM_ROOM) {
		this.roomService.enterDirectMessageRoom(content.roomId);
	}
}

class MyPlayerLeaveRoomListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-leave-dm-room', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-leave-dm-room', this.event);
	}
	event(content: REACT_LEAVE_DM_ROOM) {
		this.roomService.leaveDirectMessageRoom();
	}
}

class MyPlayerCheckUserSessionListener implements Listener {
	constructor(private roomService: RoomService) {}
	listen() {
		dmEventEmitter.on('react-check-user-in-room', this.event, this);
	}
	removeListeners(): void {
		dmEventEmitter.off('react-check-user-in-room', this.event);
	}
	event(content: REACT_CHECK_USER_IN_ROOM) {
		this.roomService.checkUserInRoom(content.targetUid, content.roomId);
	}
}
