import { usePhaserAppService } from './service/usePhaserAppService';
import { CSSTransition } from 'react-transition-group';
import BlockPopView from '@dating/ui/components/blockReport/views/BlockPopView';
import { CommonAlertPopView } from '@dating/ui/components/commonAlertPop/views/CommonAlertPopView';
import { CommonConfirmPopView } from '@dating/ui/components/commonConfirmPop/views/CommonConfirmPopView';
import PaymentContainer from '@dating/ui/components/payment/PaymentContainer';
import { PaymentPopContainer } from '@dating/ui/components/payment/PaymentPopContainer';
import { PurchasePopView } from './components/dm/myManager/purchase/popup/views/PurchasePopView';
import PasswordInputView from './components/room/roomList/views/PasswordInputView';
import RoomDisposedPopView from './components/room/roomList/views/RoomDisposedPopView';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useUserProfileService } from '@dating/ui/pages/userProfile/service/useUserProfileService';
import { useManagerPopService } from './components/dm/myManager/purchase/popup/service/useManagerPopService';
import AvatarProfileContainer from './components/avatarProfile/AvatarProfileContainer';
import RoomListContainer from './components/room/roomList/RoomListContainer';

const CommonPops = () => {
	const {
		passwordError,
		roomDisposedPop,
		showPasswordInput,
		setPasswordError,
		setRoomDisposedPop,
		setShowPasswordInput,
		handlePasswordSubmit,
	} = usePhaserAppService();

	const { alertPopMessage, alertPopOpen, fnCommonAlertPopOff } =
		useCommonAlertPopService();

	const { fnPurchasePopOff, purchasePopOpen } = useManagerPopService();

	const {
		confirmPopMessage,
		confirmPopOpen,
		fnCommonConfirmPopExecute,
		fnCommonConfirmPopOff,
	} = useCommonConfirmPopService();

	const { userProfile } = useUserProfileService();

	const { openBlock, setOpenBlock, fnSaveBlockUser } = useBlockReportService();
	return (
		<>
			{/* 방 리스트 관련 */}
			<RoomListContainer />

			{/* 캐릭터 관련 */}
			<AvatarProfileContainer />

			{/* 결제 관련 */}
			<PaymentContainer />

			{/*공통 베리드 충전 안내, 베리드 충전 팝업*/}
			<PaymentPopContainer />

			{/* 룸 비밀번호 관련 */}
			{showPasswordInput && (
				<PasswordInputView
					onClose={() => {
						setShowPasswordInput(false);
						setPasswordError(null);
					}}
					onSubmit={handlePasswordSubmit}
					error={passwordError}
				/>
			)}

			{/* 폭파된 방 알림 관련 */}
			<div
				className={`${
					roomDisposedPop ? 'roomDisposedPopOn' : 'roomDisposedPop'
				}`}
			>
				<div className="roomDisposedDim" />
				<CSSTransition
					in={roomDisposedPop}
					classNames="roomDisposedWrap-transition"
					timeout={100}
				>
					<RoomDisposedPopView setRoomDisposedPop={setRoomDisposedPop} />
				</CSSTransition>
			</div>

			<div
				className={`${
					alertPopOpen || confirmPopOpen || openBlock
						? 'settingPopOn'
						: 'settingPop'
				}`}
			>
				<div className="settingDim" />
				<CSSTransition
					in={alertPopOpen}
					classNames="withdrawalWrap-transition"
					timeout={100}
				>
					<CommonAlertPopView
						alertPopMessage={alertPopMessage}
						fnCommonAlertPopOff={fnCommonAlertPopOff}
					/>
				</CSSTransition>
				<CSSTransition
					in={confirmPopOpen}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<CommonConfirmPopView
						confirmPopMessage={confirmPopMessage}
						fnCommonConfirmPopExecute={fnCommonConfirmPopExecute}
						fnCommonConfirmPopOff={fnCommonConfirmPopOff}
					/>
				</CSSTransition>

				{/* 차단하기 */}
				<CSSTransition
					in={openBlock}
					classNames="blockPopWrap-transition"
					timeout={100}
				>
					<BlockPopView
						userProfile={userProfile}
						setOpenBlock={setOpenBlock}
						fnSaveBlockUser={fnSaveBlockUser}
					/>
				</CSSTransition>
			</div>

			{/*매니저 연결-> 매니저 구매하기 팝업 */}
			<div
				className={`${
					purchasePopOpen ? 'managerPurchasePopOn' : 'managerPurchasePop'
				}`}
				onClick={() => {
					fnPurchasePopOff();
				}}
			>
				<CSSTransition
					in={purchasePopOpen}
					classNames="withdrawalWrap-transition"
					timeout={100}
				>
					<PurchasePopView />
				</CSSTransition>
			</div>
		</>
	);
};

export default CommonPops;
