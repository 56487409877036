import HeaderContainer from './components/header/HeaderContainer';
import JoyStickContainer from './components/joystick/JoyStickContainer';
import LowerRightButtonContainer from './components/lowerRightButton/LowerRightButtonContainer';
import CamWrapContainer from './components/videoUI/CamWrapContainer';
import CamExpansionView from './components/videoUI/views/CamExpansionView';
import ChatContainer from './components/chat/ChatContainer';
import ChatFormView from './components/chat/views/ChatFormView';
import { usePhaserAppService } from './service/usePhaserAppService';

export function PhaserApp() {
	const {
		isOpen,
		focused,
		showChat,
		myProfile,
		srcPrefix,
		isPublicRoom,
		mobileChatInputClick,
		setFocused,
		setMyProfileOpen,
	} = usePhaserAppService();

	return (
		<>
			<div className="IbizPlayGameInstance" id="phaser-container" />
			{getComponent(showChat)}
			<div className="PlayerResizer_container">
				<div
					className="Layout_layout"
					onClick={(event) => {
						const target = event.target as HTMLElement;
						const targetTagName = target.tagName.toLowerCase();
						// // 클릭된 요소가 input인 경우 아무것도 하지 않음
						if ('input' === targetTagName) return;

						if (showChat && focused) setFocused(false);
						else if (showChat && !focused) setFocused(false);
					}}
				>
					<main
						style={{
							flex: '1 1 auto',
						}}
					>
						<HeaderContainer
							myProfile={myProfile}
							srcPrefix={srcPrefix}
							isPublicRoom={isPublicRoom}
							setMyProfileOpen={setMyProfileOpen}
						/>

						{!isPublicRoom && (
							<>
								<CamWrapContainer />
								{isOpen && <CamExpansionView />}
							</>
						)}
					</main>
				</div>
			</div>
			<ChatFormView />
			{!mobileChatInputClick && (
				<>
					<JoyStickContainer />
					<LowerRightButtonContainer />
				</>
			)}
		</>
	);
}

function getComponent(showChat: boolean) {
	if (showChat) return <ChatContainer />;
}
