import { useState } from 'react';

export const useMyFeedListStateRepo = () => {

	// fnInitFeedEditHandler이 실행되었는지 여부
	const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{postId: string, state: boolean}>({postId: "", state: false});

	// 피드 작성자 닉네임, userUid
	const [postUser, setPostUser] = useState<{userUid: string, nickname: string}>({userUid: "", nickname: ""});

	return {
		isClickedFeedMenu,
		setIsClickedFeedMenu,
		postUser,
		setPostUser,
	};
};