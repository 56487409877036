import {useTranslation} from "react-i18next";

interface props {
    fnSaveNickname: () => void;
    btnDisabled: boolean;
}

const NicknameFooterView = ({
                                fnSaveNickname,
                                btnDisabled,
                            }: props) => {

    const { t } = useTranslation('register')

    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnSaveNickname}
                    disabled={btnDisabled}
                >
                    <span>{t('common.next')}</span>
                </button>
            </div>
        </footer>
    );
};

export default NicknameFooterView;