import {useState} from "react";

export const useFeedFormStateRepo = () => {
    // 해시태그 입력
    const [inputHashTag, setInputHashTag] = useState<string>("");


    const [modifyPostId, setModifyPostId] = useState<string>("");

    return {
        inputHashTag,
        setInputHashTag,
        modifyPostId,
        setModifyPostId,
    };
};