import React from "react";
import {useTranslation} from "react-i18next";

interface props {
    setRecommendCode: (recommendCode: string) => void;
    codeFailed: boolean;
}

const RecommendCodeContentsView = ({
                                       setRecommendCode,
                                       codeFailed,
                                   }: props) => {
    const { t } = useTranslation('register')
    return (
        <div className="content">
            <div className="inputWrap">
                <div className="input">
                    <input
                        id="firstFocus"
                        type="text"
                        placeholder={t('codeStep.inputCode2')}
                        onChange={(e) => {
                            setRecommendCode(e.target.value)
                        }}
                    />
                </div>
                {
                    codeFailed ?
                        (
                            <p className="sub-color fz12 padding-t8">
                                {t('codeStep.codeValidation')}
                            </p>
                        )
                        : ""
                }
            </div>
            <div className="exTxtWrap margin-t24" dangerouslySetInnerHTML={{ __html: t('codeStep.codeGuide') }}>
            </div>
        </div>
    );
};

export default RecommendCodeContentsView;