import {useTranslation} from "react-i18next";

interface props {
    footerBtnDisable: boolean;
    fnSendVerificationCode: () => void;
}

const PhoneNumberFooterView = ({
                                   fnSendVerificationCode,
                                   footerBtnDisable,
                               }: props) => {
    const { t } = useTranslation('register')
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnSendVerificationCode();
                    }}
                    disabled={footerBtnDisable}
                >
                    <span>{t('phoneStep.getNumber')}</span>
                </button>
            </div>
        </footer>
    );
};

export default PhoneNumberFooterView;
