import { Link } from 'react-router-dom';
import { ReceivePostLikeCOVO } from '@/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

interface props {
	likePosts: ReceivePostLikeCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (UserProfileOpen: string) => void;
	currentUserUid: string;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	setSingleFeedId: (singleFeedId: string) => void;
}

const LikedPostListView = ({
	likePosts,
	srcPrefix,
	setUserProfileOpen,
	currentUserUid,
	setMyProfileOpen,
	setSingleFeedId,
}: props) => {

	const { t } = useTranslation('like');

	return (
		<div
			className={
				likePosts.length === 0 ? 'likeProfileList-empty' : 'likeProfileList'
			}
		>
			{likePosts.map((item, i) => {
				if (item.profileUrl) {
					return (
						<div key={item.likePostId + i} className="profileType">
							<div className="proLeft">
								<div className="proImg">
									<Link
										to={`/metaverse/dating/profile${
											item.userUid !== currentUserUid
												? '/userProfile'
												: '/myProfile'
										}`}
										onClick={() => {
											item.userUid !== currentUserUid
												? setUserProfileOpen(item.userUid)
												: setMyProfileOpen(true);
										}}
										state={{
											isOtherProfile: item.userUid !== currentUserUid,
										}}
									>
										<img src={srcPrefix + item.profileUrl} />
									</Link>
								</div>
								<div className="proName margin-l12">
									<p className="bold padding-b4">{item.nickName}</p>
									<p className="fz14 color-666">
										{item.age + t('common.age') +', ' + (item.region1 && item.region2 ? `${item.region1} ${item.region2}` : t('common.noRegion'))}
									</p>
								</div>
							</div>
							<div className="ProRight">
								<div className="likeSmImg">
									<Link
										to={PATH.LOUNGE.SINGLE}
										onClick={() => {
											setSingleFeedId(item.likePostId);
										}}
									>
										<img
											src={srcPrefix + item.postPhotoUrl}
											alt={item.nickName}
										/>
										<span className="likeBadge"></span>
									</Link>
								</div>
							</div>
						</div>
					);
				}
			})}
		</div>
	);
};

export default LikedPostListView;
