import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { isCensored } from '@/utils/censor';
import { formatTime } from '@/utils/util';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDirectMessage } from '../../../../../../../../types/IDirectMessageState';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import {
	NavigationState,
	GroupedMessage,
} from '../../../types/navigationTypes';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import { useCommonDMService } from '../../../service/useCommonDMService';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useAppFooterService } from '@/ui/components/footer/service/useAppFooterService';
import {useTranslation} from "react-i18next";

export const useMessageDetailService = () => {
	const { t } = useTranslation('messenger')
	const {
		openBlock,
		groupedMessages,
		focused,
		openDirectMessageDetail,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		messagesEndRef,
		setOpenReport,
		setOpenBlock,
		modifyMenuPop,
		setOpenLeaveChat,
		refreshDirectMessageList,
	} = useCommonDMService();
	const { saveMessage, leaveMessageRoom, updateMessageReadStatus } =
		useMessageAdapter();
	const { setMessageInputValue, setShowDirectMessageList } =
		useDirectMessageRepo();
	const { fnValidateOrder, fnOpenMessageRoom } = usePaymentService();
	const location = useLocation();
	const navigate = useNavigate();
	const locationState = location.state;
	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const {
		myProfile,
		userProfile,
		srcPrefix,
		userProfileOpen,
		setUserProfileOpen,
		setUserProfile,
	} = useDatingRepo();

	const { orderResult } = useOrderRepo();

	const { handleMessageSendClick } = useAppFooterService();

	useEffect(() => {
		setShowDirectMessageList(false);
	}, []);

	const { getUserProfile } = useProfileAdapter();
	useEffect(() => {
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {});
		}
	}, []);

	const navigationRoom = useMemo(() => {
		const baseNavigationState: NavigationState = {
			billingStatus: 0,
			dmRoomType: '',
			roomId: '',
			visibleTo: 0,
			isUserLeft: false,
			isOtherUserLeft: false,
			user1Info: {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				role: '',
				inActive: false,
				isDeleted: false,
				blockType: '',
			},
			otherUserInfo: {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				role: '',
				inActive: false,
				isDeleted: false,
				blockType: '',
			},
			createdAt: '',
			matchedAt: '',
		};

		// MessageListView에서 온 state 확인
		if (locationState?.messageListState) {
			return {
				...baseNavigationState,
				...locationState.messageListState,
			};
		}

		// 중첩된 navigationState 처리
		if (locationState?.navigationState?.navigationState) {
			return {
				...baseNavigationState,
				...locationState.navigationState.navigationState,
			};
		}

		// 단일 navigationState 처리
		if (locationState?.navigationState) {
			return {
				...baseNavigationState,
				...locationState.navigationState,
			};
		}

		// 기본 state 확인
		if (locationState) {
			return {
				...baseNavigationState,
				...locationState,
			};
		}

		return baseNavigationState;
	}, [locationState]);

	const roomData = useMemo(() => {
		if (!navigationRoom?.roomId) return null;

		const found = directMessageList.find(
			(room) => room.roomId === navigationRoom.roomId,
		);

		if (found) {
			return {
				...found,
				billingStatus: found.billingStatus ?? navigationRoom.billingStatus,
				dmRoomType: found.dmRoomType || navigationRoom.dmRoomType,
				visibleTo: found.visibleTo ?? navigationRoom.visibleTo,
				isUserLeft: found.isUserLeft ?? navigationRoom.isUserLeft,
				isOtherUserLeft:
					found.isOtherUserLeft ?? navigationRoom.isOtherUserLeft,
			};
		}

		return navigationRoom;
	}, [directMessageList, navigationRoom]);

	const currentRoomMessages = useMemo(() => {
		const messages = roomData?.messages
			? [...roomData.messages].sort(
					(a, b) => parseInt(a.createdAt) - parseInt(b.createdAt),
			  )
			: [];
		return messages;
	}, [roomData]);

	const getLatestMessageId = useCallback(() => {
		if (!currentRoomMessages || currentRoomMessages.length === 0) {
			return 1; // 첫 메시지인 경우
		}

		// messageId가 있는 메시지만 필터링하고 최대값 찾기
		const messageIds = currentRoomMessages
			.filter((message) => message?.messageId != null)
			.map((message) => parseInt(message.messageId.toString()));

		if (messageIds.length === 0) {
			return 1;
		}

		return Math.max(...messageIds) + 1;
	}, [currentRoomMessages]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessageInputValue(event.target.value);
	};

	const handleSubmit = async (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();

		// 1. 유효성 검사 먼저 수행
		const trimmedValue = messageInputValue.trim();
		if (
			!trimmedValue ||
			!myProfile?.userUid ||
			!navigationRoom?.otherUserInfo?.userUid
		) {
			return;
		}

		const hasCensoredWord = isCensored(trimmedValue.replace(/\s/g, ''));
		if (hasCensoredWord) {
			fnCommonAlertPopOn(t('forbiddenWord'));
			setMessageInputValue('');
			return;
		}

		const checkUserInRoom = () => {
			return new Promise<boolean>((resolve) => {
				// 상태 응답을 받을 이벤트 핸들러
				const handleRoomStatus = (status: {
					targetUid: string;
					isInRoom: boolean;
				}) => {
					if (status.targetUid === navigationRoom.otherUserInfo.userUid) {
						resolve(status.isInRoom);
						// 이벤트 리스너 제거
						dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					}
				};

				// USER_IN_ROOM_STATUS 이벤트 리스너 등록
				dmEventEmitter.on('react-user-in-room-status', handleRoomStatus);

				// 상태 확인 요청
				dmEventEmitter.emit('react-check-user-in-room', {
					targetUid: navigationRoom.otherUserInfo.userUid,
					roomId: navigationRoom.roomId,
				});

				// 타임아웃 설정 (3초)
				setTimeout(() => {
					dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					resolve(false);
				}, 3000);
			});
		};

		// 상대방의 채팅방 상태를 기다림
		const isInRoom = await checkUserInRoom();

		// 2. 메시지 데이터 준비
		const messageData = {
			roomId: navigationRoom.roomId,
			messageId: getLatestMessageId(),
			sender: {
				userUid: myProfile.userUid,
				nickName: myProfile.nickName,
				profileUrl: myProfile.profileUrl[0],
				region1: myProfile?.region1,
				region2: myProfile?.region2,
				age: myProfile.age,
			},
			receiver: {
				userUid: navigationRoom.otherUserInfo.userUid,
				nickName: navigationRoom.otherUserInfo.nickName,
				profileUrl: navigationRoom.otherUserInfo.profileUrl,
				region1: navigationRoom.otherUserInfo.region1,
				region2: navigationRoom.otherUserInfo.region2,
				age: navigationRoom.otherUserInfo.age,
			},
			content: trimmedValue,
			createdAt: Date.now().toString(),
			read: isInRoom,
		};

		try {
			// 3. 메시지 전송 및 저장 동시에 처리
			await Promise.all([
				// Colyseus 이벤트 발송
				new Promise<void>((resolve) => {
					dmEventEmitter.emit('react-send-direct-message', messageData);
					resolve();
				}),
				// DB 저장
				saveMessage({
					roomId: navigationRoom.roomId,
					content: trimmedValue,
					read: isInRoom,
					createdAt: messageData.createdAt,
				}),
			]);

			// 4. 입력값 초기화는 성공 후에만
			setMessageInputValue('');
		} catch (error) {
			console.error('메시지 전송 실패:', error);
			fnCommonAlertPopOn('메시지 전송에 실패했습니다.');
		}
	};

	const handleLeaveChat = () => {
		dmEventEmitter.emit('react-leave-direct-message', {
			roomId: navigationRoom.roomId,
			leaverId: myProfile?.userUid,
		});

		leaveMessageRoom(navigationRoom.roomId);
		navigate(PATH.MESSENGER.ROOT, {
			replace: true,
		});
		setOpenLeaveChat(false);
		handleMessageSendClick();
	};

	const handleExitChat = () => {
		dmEventEmitter.emit('react-exit-direct-message', {
			roomId: navigationRoom.roomId,
			exit: true,
		});

		leaveMessageRoom(navigationRoom.roomId);
		navigate(PATH.MESSENGER.ROOT, {
			replace: true,
		});
		setOpenLeaveChat(false);
		handleMessageSendClick();
	};

	/**
	 * 상호 결제 처리 함수
	 * validateOrder 성공 및 주문 완료 후 이벤트를 발생
	 */
	const handleMutualPayment = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		const isUser1 = currentRoom?.user1Id === myProfile.userUid;

		// 상호 결제 가능 상태 체크
		const canCompleteMutualPayment =
			(isUser1 && currentRoom.billingStatus === 2) ||
			(!isUser1 && currentRoom.billingStatus === 1);

		if (canCompleteMutualPayment) {
			// fnOpenMessageRoom을 콜백으로 wrapping
			const handlePaymentCallback = (params) => {
				fnOpenMessageRoom(params);

				// 결제 완료 후 이벤트 발생
				dmEventEmitter.emit('react-mutual-payment-completed', {
					roomId: navigationRoom.roomId,
					paid: true,
				});

				// 방 타입 변경 이벤트 발생
				dmEventEmitter.emit('react-change-direct-room-type', {
					roomId: navigationRoom.roomId,
					dmRoomType: 'LAST',
				});
			};

			// 결제 검증 및 주문 처리 시작
			fnValidateOrder(handlePaymentCallback, {
				roomId: navigationRoom.roomId,
				billingStatus: 3,
				orderId: orderResult.orderId,
				productId: 2,
			});
		}
	};

	/**
	 * 단일 결제 처리 함수 (LIKE 타입에서만 사용)
	 */
	const handleSinglePayment = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		const isUser1 = currentRoom?.user1Id === myProfile.userUid;

		// LIKE 타입이 아닌 경우
		if (!currentRoom || currentRoom.dmRoomType !== 'LIKE') {
			return;
		}

		// 이미 결제한 경우 체크
		if (
			(isUser1 && currentRoom.billingStatus === 1) ||
			(!isUser1 && currentRoom.billingStatus === 2)
		) {
			return;
		}

		// 초기 상태 (아무도 결제하지 않은 상태)
		if (currentRoom.billingStatus === 0) {
			const newBillingStatus = isUser1 ? 1 : 2;

			dmEventEmitter.emit('react-single-payment-completed', {
				roomId: navigationRoom.roomId,
				payerId: myProfile.userUid,
			});

			fnValidateOrder(fnOpenMessageRoom, {
				roomId: navigationRoom.roomId,
				billingStatus: newBillingStatus,
				orderId: orderResult.orderId,
				productId: 2,
			});
		}
	};

	// 결제 버튼 클릭 핸들러
	const handlePaymentClick = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		try {
			// currentRoom이 없는 경우 navigation state 사용
			const roomState = currentRoom || navigationRoom;

			// LIKE 타입일 경우
			if (roomState?.dmRoomType === 'LIKE') {
				// 초기 상태 (아무도 결제하지 않은 상태)
				if (roomState.billingStatus === 0) {
					handleSinglePayment();
				}
				// 상대방이 이미 결제한 경우
				else if (
					roomState.billingStatus === 1 ||
					roomState.billingStatus === 2
				) {
					handleMutualPayment();
				}
			}
			// FIRST 타입일 경우
			else if (roomState?.dmRoomType === 'FIRST') {
				handleMutualPayment();
			}
		} catch (error) {
			console.error('Payment error:', error);
		}
	};

	const processGroupedMessagesDetail = useCallback(() => {
		const result = {
			my: [] as GroupedMessage[],
			other: [] as GroupedMessage[],
		};

		let lastGroupedMessage: GroupedMessage | undefined;

		// 메시지를 시간 순으로 정렬
		const sortedMessages = [...currentRoomMessages].sort((a, b) => {
			return parseInt(a.createdAt) - parseInt(b.createdAt);
		});

		sortedMessages.forEach((message: IDirectMessage) => {
			const isMyMessage = message.sender?.userUid === myProfile?.userUid;
			const groupKey = isMyMessage ? 'my' : 'other';
			const time = formatTime(message.createdAt);

			// 읽음 처리 수정
			if (
				message.receiver?.userUid === myProfile?.userUid &&
				openDirectMessageDetail &&
				myProfile?.userUid &&
				!message.read // 읽지 않은 메시지만 처리
			) {
				try {
					// 메시지 업데이트 전 읽음 상태 먼저 업데이트
					updateMessageReadStatus({
						roomId: navigationRoom.roomId,
						read: true,
					});

					// 이벤트 발생
					dmEventEmitter.emit('react-read-direct-message', {
						roomId: navigationRoom.roomId,
						messageId: message.messageId,
						userUid: myProfile.userUid,
					});
				} catch (error) {
					console.error('Error updating read status:', error);
				}
			}

			// 메시지 그룹화
			if (
				lastGroupedMessage &&
				lastGroupedMessage.time === time &&
				lastGroupedMessage.isMyMessage === isMyMessage
			) {
				lastGroupedMessage.messages.push(message);
			} else {
				lastGroupedMessage = {
					isMyMessage,
					time,
					messages: [message],
					visibleTo: roomData?.visibleTo || 0,
				};
				result[groupKey].push(lastGroupedMessage);
			}
		});

		return result;
	}, [
		currentRoomMessages,
		myProfile?.userUid,
		openDirectMessageDetail,
		navigationRoom.roomId,
		roomData?.visibleTo,
	]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
	};

	// 메시지 상세 정보
	const groupedMessagesDetail = useMemo(() => {
		return processGroupedMessagesDetail();
	}, [processGroupedMessagesDetail]);

	// 스크롤 처리
	useEffect(() => {
		if (
			groupedMessagesDetail.my.length > 0 ||
			groupedMessagesDetail.other.length > 0
		) {
			scrollToBottom();
		}
	}, [groupedMessagesDetail]);

	const shouldExitChat = useMemo(() => {
		return roomData?.visibleTo === 1 || roomData?.visibleTo === 2;
	}, [roomData?.visibleTo]);

	// 현재 방의 상태를 확인
	const currentRoomState = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];

		// groupedMessages에 방이 없는 경우 navigation state 사용
		if (!currentRoom && navigationRoom) {
			const isUser1 = navigationRoom.otherUserInfo?.userUid
				? myProfile.userUid !== navigationRoom.otherUserInfo.userUid
				: false;

			return {
				dmRoomType: navigationRoom.dmRoomType,
				billingStatus: navigationRoom.billingStatus,
				isUser1,
			};
		}

		// groupedMessages에 방이 있는 경우
		if (currentRoom) {
			const isUser1 = currentRoom.user1Id === myProfile.userUid;
			return {
				dmRoomType: currentRoom.dmRoomType,
				billingStatus: currentRoom.billingStatus,
				isUser1,
			};
		}

		// 둘 다 없는 경우 null 반환
		return null;
	}, [groupedMessages, navigationRoom, myProfile.userUid, location.state]);

	const showPaymentView = useMemo(() => {
		// navigation state가 없으면 기본적으로 결제 화면 표시
		if (!currentRoomState) return true;

		const { dmRoomType, billingStatus, isUser1 } = currentRoomState;

		// LIKE 타입이고 초기 상태일 때만 결제 화면 표시
		const isInitialLikeState =
			dmRoomType === 'LIKE' &&
			(billingStatus === 0 || // 초기 상태
				(isUser1 && billingStatus === 2) || // 상대방만 결제 (내가 user1인 경우)
				(!isUser1 && billingStatus === 1)); // 상대방만 결제 (내가 user2인 경우)

		// FIRST 타입이고 아직 결제하지 않은 상태일 때
		const isUnpaidFirstState =
			dmRoomType === 'FIRST' &&
			((isUser1 && billingStatus === 2) || (!isUser1 && billingStatus === 1));

		const shouldShow = isInitialLikeState || isUnpaidFirstState;

		return shouldShow;
	}, [currentRoomState]);

	const isOtherUserLeft = useMemo(() => {
		const isUser1 = roomData?.user1Id === myProfile.userUid;
		const currentVisibleTo = roomData?.visibleTo;
		return (
			(isUser1 && currentVisibleTo === 2) ||
			(!isUser1 && currentVisibleTo === 1)
		);
	}, [
		groupedMessagesDetail,
		roomData?.user1Id,
		myProfile.userUid,
		roomData?.visibleTo,
	]);

	useEffect(() => {
		scrollToBottom();
		inputRef.current?.focus();
	}, [groupedMessages]);

	const matchingTime = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		return currentRoom ? currentRoom.createdAt : null;
	}, [roomData]);
	const currentMessageLength = useMemo(() => {
		return currentRoomMessages.length;
	}, [currentRoomMessages, navigationRoom]);
	// roomData 초기화 useEffect 추가
	useEffect(() => {
		if (navigationRoom?.roomId) {
			const found = directMessageList.find(
				(room) => room.roomId === navigationRoom.roomId,
			);
			if (!found) {
				// roomData가 없으면 서버에서 다시 조회
				refreshDirectMessageList();
			}
		}
	}, [navigationRoom?.roomId]);

	return {
		showPaymentView,
		userProfileOpen,
		isOtherUserLeft,
		navigationRoom,
		groupedMessagesDetail,
		handlePaymentClick,
		setUserProfileOpen,
		handleMutualPayment,
		setOpenReport,
		setOpenLeaveChat,
		setOpenBlock,
		modifyMenuPop,
		handleSubmit,
		handleLeaveChat,
		handleExitChat,
		handleChange,
		shouldExitChat,
		focused,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		openBlock,
		userProfile,
		srcPrefix,
		messagesEndRef,
		myProfile,
		matchingTime,
		currentMessageLength,
		roomData,
	};
};
