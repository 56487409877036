import {useHomeRepo} from "@dating/repository/home/useHomeRepo";
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useEffect } from 'react';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';

export const useHomeHeaderService = () => {
    const {
        hushHome,
        setHushHome,
    } = useHomeRepo();

    const {
        srcPrefix,
        myProfile,
        setMyProfile,
    } = useDatingRepo();

    const {
        initAuthToken,
    } = useAuthRepo();

    const { getMyProfile } = useProfileAdapter();

    const changeHomeType = (type: boolean) => {
        setHushHome(type);
    };

    const navigate = useNavigate();
    useEffect(() => {
        getMyProfile(myProfile.phoneNumber).then(value => {
            if (value.profileUrl.length > 0) {
                setMyProfile(value);
            } else {
                // profileUrl.length === 0 인 경우 에러처리
                // 토큰초기화 및 메인으로 이동
                // initAuthToken();
                navigate(PATH.REGISTER.REGISTER);
            }
        });
    }, []);

    return {
        hushHome,
        changeHomeType,
        srcPrefix,
        myProfile,
    };
};