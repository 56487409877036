import LikeMatchingContainer from '@dating/ui/pages/like/likeMatcing/LikeMatchingContainer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Spinner from '@dating/ui/components/spinner/Spinner';
import { LikeLayout } from '@dating/ui/components/layout/LikeLayout';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlerUtils } from '@/utils/errorHandlerUtils';

const LikeReceiveContainer = lazy(() => import('@dating/ui/pages/like/likeReceive/LikeReceiveContainer'));
const LikeSendContainer = lazy(() => import('@dating/ui/pages/like/likeSend/LikeSendContainer'));

const LikeRouter = () => {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<>
			<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
				<Suspense fallback={<Spinner />}>
					<Routes location={location}>
						<Route element={<LikeLayout />}>
							<Route path="/receivedLike" element={<LikeReceiveContainer />} />
							<Route path="/sentLike" element={<LikeSendContainer />} />
						</Route>
					</Routes>
				</Suspense>

				<Routes location={location}>
					<Route path="/matchingSuccess" element={<LikeMatchingContainer />} />
				</Routes>
			</ErrorBoundary>
		</>
	);
};

export default LikeRouter;
