import {useTranslation} from "react-i18next";

interface props {
    fnSaveBlockPost: () => void;
    hidePopClose:() => void;
};

const FeedHidePopView = ({
                             fnSaveBlockPost,
                             hidePopClose,
                         }: props) => {
    const { t } = useTranslation('lounge');
    return (
        <div className="blockPopWrap">
            <div className="popupInfo">
                <p className="fz20 bold-500 text-c">{t('hideQuote1')}</p>
                <p className="fz16Fw500 color-999 text-c padding-t8">{t('hideQuote2')}</p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            hidePopClose()
                        }}
                    >
                        <span>{t('hideBtn1')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnSaveBlockPost();
                        }}
                    >
                        <span>{t('hideBtn2')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedHidePopView;
