import { useCommonDMService } from '@/ui/components/dm/service/useCommonDMService';
import {
	MessageDetailViewProps,
	MessagesByDate,
	MessageGroup,
	Message,
} from '@/ui/components/dm/types/navigationTypes';
import MessageTimeView from '@/ui/components/dm/user/detail/views/MessageTimeView';
import { formatMatchedTime, formatTime } from '@/utils/util';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MyManagerOfflineDetailView = ({
	groupedMessagesDetail,
	srcPrefix,
	myProfile,
	navigationRoom,
	isOtherUserLeft,
	messagesEndRef,
	setUserProfileOpen,
	blockStatus,
	matchedAt,
}) => {
	const { t } = useTranslation('messenger');
	const { getStatusMessage, formatMessageDate } = useCommonDMService();

	const organizeMessages = (
		groupedMessagesDetail: MessageDetailViewProps['groupedMessagesDetail'],
	): MessagesByDate => {
		// 1. 모든 메시지를 하나의 배열로 합치고 정렬
		const sortedMessages = [
			...groupedMessagesDetail.my.flatMap((group) => group.messages),
			...groupedMessagesDetail.other.flatMap((group) => group.messages),
		].sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt));

		// 2. 날짜별로 먼저 그룹화
		const messagesByDate: MessagesByDate = {};
		sortedMessages.forEach((message) => {
			const date = new Date(parseInt(message.createdAt));
			const dateKey = `${date.getFullYear()}.${String(
				date.getMonth() + 1,
			).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;

			if (!messagesByDate[dateKey]) {
				messagesByDate[dateKey] = {
					date: dateKey,
					messages: [],
					groups: [],
				};
			}
			messagesByDate[dateKey].messages.push(message);
		});

		// 3. 각 날짜별로 발신자/시간 그룹화 진행
		Object.keys(messagesByDate).forEach((dateKey) => {
			const dateMessages = messagesByDate[dateKey].messages;
			let currentGroup: MessageGroup = {
				sender: null,
				messages: [],
				time: null,
			};

			dateMessages.forEach((message, index) => {
				const messageTime = formatTime(message.createdAt);

				if (
					currentGroup?.sender === message.sender?.userUid &&
					currentGroup.time === messageTime
				) {
					// 같은 발신자의 연속된 메시지이고 같은 시간대면 현재 그룹에 추가
					currentGroup.messages.push(message);
				} else {
					// 새로운 그룹 시작
					if (currentGroup.messages.length > 0) {
						messagesByDate[dateKey].groups.push({ ...currentGroup });
					}
					currentGroup = {
						sender: message.sender?.userUid ?? null,
						messages: [message],
						time: messageTime,
					};
				}

				// 마지막 메시지 처리
				if (
					index === dateMessages.length - 1 &&
					currentGroup.messages.length > 0
				) {
					messagesByDate[dateKey].groups.push({ ...currentGroup });
				}
			});
		});

		return messagesByDate;
	};

	const renderMessageContent = (message: Message) => {
		return <span>{message.content}</span>;
	};

	const renderMessageGroup = (
		group: MessageGroup,
		index: number,
		allGroups: MessageGroup[],
	) => {
		const isMyMessage = group?.sender === myProfile?.userUid;

		return group.messages.map((message, msgIndex) => {
			const isFirstInGroup = msgIndex === 0;
			const isLastInGroup = msgIndex === group.messages.length - 1;

			return (
				<div key={`${message.messageId}`}>
					<div className={`chatBox ${isMyMessage ? 'chatRight' : 'chatLeft'}`}>
						{!isMyMessage && isFirstInGroup ? (
							<div className="proImg Sm">
								{isOtherUserLeft || (blockStatus && blockStatus !== 'none') ? (
									<img
										src="/assets/img/common/profile_no.svg"
										alt="테스트이미지"
									/>
								) : (
									<Link
										to={PATH.PROFILE.USER_PROFILE}
										onClick={() => {
											setUserProfileOpen(navigationRoom.otherUserInfo.userUid);
										}}
										state={{
											isOtherProfile:
												navigationRoom.otherUserInfo?.userUid !==
												myProfile.userUid,
										}}
									>
										<img
											src={srcPrefix + message.sender?.profileUrl}
											alt={message.sender?.nickName}
										/>
									</Link>
								)}
							</div>
						) : (
							<div className="proImg Sm" />
						)}
						<div className="chat">
							<div className={`bubbleBox ${isFirstInGroup ? 'tail' : ''}`}>
								<div className="bubble">{renderMessageContent(message)}</div>
								{isLastInGroup && <MessageTimeView time={message.createdAt} />}
							</div>
						</div>
					</div>
				</div>
			);
		});
	};
	const organizedMessages = organizeMessages(groupedMessagesDetail);

	return (
		<>
			<div className="inner chatRoom">
				<div className="chatList">
					{Object.keys(organizedMessages).length === 0 && (
						<div className="chatStartBox">
							<p className="partnerStart">{t('startWarmChat')}</p>
						</div>
					)}
					{/* 날짜별 메시지 그룹 렌더링 */}
					{Object.entries(organizedMessages).map(([dateKey, dateData]) => (
						<div key={dateKey} className="messageTime">
							<span className="messageTimeSpan">
								{formatMessageDate(dateKey)}
							</span>

							{/* 해당 날짜의 메시지 그룹들 렌더링 */}
							{dateData.groups.map((group, groupIndex) =>
								renderMessageGroup(group, groupIndex, dateData.groups),
							)}
						</div>
					))}
					{matchedAt && (
						<p className="matchingTrue">
							{t('offlineMatchConnected', {
								time: formatMatchedTime(matchedAt),
							})}
						</p>
					)}
					{blockStatus && blockStatus !== 'none' && (
						<p
							className="partnerOut"
							dangerouslySetInnerHTML={{
								__html: getStatusMessage(blockStatus),
							}}
						/>
					)}
				</div>
				<div ref={messagesEndRef} style={{ height: '1px' }} />
			</div>
		</>
	);
};

export default MyManagerOfflineDetailView;
