import GenderSelectView from "./views/GenderSelectView";
import GenderSelectFooterView from "./views/GenderSelectFooterView";
import StepProgressView from "../common/stepProgress/views/StepProgressView";
import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import {useGenderSelectService} from "@dating/ui/pages/register/gender/service/useGenderSelectService";
import React, {useEffect} from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const GenderSelectContainer = () => {
    const {
        selectedGender,
        setSelectedGender,
        btnActivate,
        fnSaveUserGender,
        response,
        registerSteps,
    } = useGenderSelectService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();

    const { t } = useTranslation('register')

    useEffect(() => {
        if (response) {
            navigate(registerSteps[2].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView/>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">{t('genderStep.selectGender')}</h2>
                    <p className="titleSub01">{t('genderStep.selectGender2')}</p>
                </div>
                <GenderSelectView
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                />
            </div>

            <GenderSelectFooterView
                btnActivate={btnActivate}
                fnSaveUserGender={fnSaveUserGender}
            />
        </div>
    );
};

export default GenderSelectContainer;
