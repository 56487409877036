import { EventService } from '@/site/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useEventAdapter = () => {
	const queryClient = useQueryClient();

	/*
	* getDailyAttendance
	* 출석체크내역조회
	*/
	const getDailyAttendance = useQuery({
		queryKey: ['getDailyAttendance'],
		queryFn: () => EventService.getDailyAttendance(),
	});

	/*
	* checkDailyAttendance
	* 출석체크
	*/
	const checkDailyAttendance = useMutation({
		mutationFn: () => EventService.checkDailyAttendance(),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getDailyAttendance'] });
		},
	});

	return {
		getDailyAttendance: getDailyAttendance.data,
		statusGetDailyAttendance: getDailyAttendance.status,
		checkDailyAttendance: checkDailyAttendance.mutate,
		dataCheckDailyAttendance: checkDailyAttendance.data,
		statusCheckDailyAttendance: checkDailyAttendance.status,
	};
};