import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import ManagerMessageDetailHeaderView from '../../views/ManagerMessageDetailHeaderView';
import OfflineManagerDetailView from './views/OfflineManagerDetailView';
import OfflineManagerInputView from './views/OfflineManagerInputView';
import { useOfflineManagerDetailService } from '@/ui/components/dm/manager/offline/detail/service/useOfflineManagerDetailService';

const OfflineManagerDetailContainer = () => {
	const {
		isOtherUserLeft,
		isUseStatusDone,
		groupedMessagesDetail,
		setUserProfileOpen,
		handleSubmit,
		handleChange,
		focused,
		messageInputValue,
		srcPrefix,
		messagesEndRef,
		myProfile,
		navigationRoom,
		matchedAt,
		handleMessageSendClick,
	} = useOfflineManagerDetailService();
	const { setShowOfflineMessageList } = useDirectMessageRepo();

	const blockStatus = navigationRoom.otherUserInfo.blockType;

	return (
		<div className="wrap footerNone">
			<ManagerMessageDetailHeaderView
				navigationRoom={navigationRoom}
				setShowDirectMessageList={setShowOfflineMessageList}
				handleMessageSendClick={handleMessageSendClick}
			/>
			<div id="conWrap" className="chatWrap">
				<div className="messengerWrap">
					<form onSubmit={handleSubmit}>
						<OfflineManagerDetailView
							setUserProfileOpen={setUserProfileOpen}
							groupedMessagesDetail={groupedMessagesDetail}
							srcPrefix={srcPrefix}
							myProfile={myProfile}
							navigationRoom={navigationRoom}
							isOtherUserLeft={isOtherUserLeft}
							messagesEndRef={messagesEndRef}
							blockStatus={blockStatus}
							matchedAt={matchedAt}
						/>
						<OfflineManagerInputView
							handleSubmit={handleSubmit}
							messageInputValue={messageInputValue}
							handleChange={handleChange}
							focused={focused}
							isOtherUserLeft={isOtherUserLeft}
							blockStatus={blockStatus}
							isUseStatusDone={isUseStatusDone}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default OfflineManagerDetailContainer;
