import React from 'react';
import { useTranslation } from 'react-i18next';

interface props {
	profileDetails: { key: string; title: string; value: string | number }[];
}

const StepLastBoxView = ({ profileDetails }: props) => {
	const { t } = useTranslation('profile');
	return (
		<div className="stepLastBox">
			<ul className="infoList">
				{profileDetails.length > 0
					? profileDetails.map((detail, i) => {
							return (
								<React.Fragment key={detail.key + i}>
									{detail.key != 'pen' &&
									<li
										className={`ic-${detail.key} ${detail.value ? 'on' : ''}`}
									>
										{detail.value ? detail.value : detail.title + t('notRegistered')}
									</li>
									}
									{detail.key == 'pen' &&
									<>
										<li key={detail.key + i}
											className={`ic-${detail.key} ${detail.value ? 'on' : ''}`}>
											{detail.title}
										</li>
										<li className="letter">
											{detail.value}
										</li>
									</>
									}
								</React.Fragment>
							);
					  })
					: null}
			</ul>
		</div>
	);
};

export default StepLastBoxView;
