import { HomePastMatchingUserCOVO, HomePastUserSOVO } from '@/site/api';
import { create } from "zustand";

interface HushPickState {
    // 이성 추천 과금 팝업 타이틀 및 메세지
    hushPickPopMessage: {title: string, detail: string};
    setHushPickPopMessage: (hushPickPopMessage: {title: string, detail: string}) => void;
    clearHushPickPopMessage: () => void;

    // 이성 추천 과금 팝업 open/clolse
    hushPickPopOpen: boolean;
    setHushPickPopOpen: (hushPickPopOpen: boolean) => void;

    // 이성 추천 과금 팝업 확인 함수
    hushPickPopFunc: {param: any, func: (param?: any) => any | void};
    setHushPickPopFunc: (hushPickPopFunc: {param: any, func: () => any}) => void;
    initHushPickPopFunc: () => void;

    // 과금으로 추천받은 이상형 내역
    recentProfiles: HomePastUserSOVO[];
    setRecentProfiles: (recentProfiles: HomePastUserSOVO[]) => void;

    // 추천받기 ui handler
    hushPickOn: {};
    setHushPickOn: (hushPickOn: {}) => void;
};
export const useHushPickRepo = create<HushPickState>(
    (set) => ({
        // 이성 추천 과금 팝업 타이틀 및 메세지
        hushPickPopMessage: {title: "", detail: ""},
        setHushPickPopMessage: (hushPickPopMessage: {title: string, detail: string}) => set({hushPickPopMessage}),
        clearHushPickPopMessage: () => set({hushPickPopMessage: {title: "", detail: ""}}),

        // 이성 추천 과금 팝업 open/clolse
        hushPickPopOpen: false,
        setHushPickPopOpen: (hushPickPopOpen: boolean) => set({hushPickPopOpen}),

        // 이성 추천 과금 팝업 확인 함수
        hushPickPopFunc: {param: null, func: (param: any) => null},
        setHushPickPopFunc: (hushPickPopFunc: {param: any, func: (param: any) => any}) => set({hushPickPopFunc}),
        initHushPickPopFunc: () => set({hushPickPopFunc: {param: null, func: (param: any) => null}}),

        // 과금으로 추천받은 이상형 내역
        recentProfiles: [],
        setRecentProfiles: (recentProfiles: HomePastUserSOVO[]) => set({recentProfiles}),

        // 추천받기 ui handler
        hushPickOn: {},
        setHushPickOn: (hushPickOn: {}) => set({hushPickOn}),
    }),
);