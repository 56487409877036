import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import PhotoFooterView from "./views/PhotoFooterView";
import { usePhotoService } from "./service/usePhotoService";
import React, { useEffect } from "react";
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import PhotoImageGuideView from "@dating/ui/pages/register/photo/views/PhotoImageGuideView";
import { useNavigate } from "react-router-dom";
import { useImgCropService } from "@dating/ui/pages/imgCrop/service/useImgCropService";
import { ImgAddBoxView } from '@dating/ui/components/imgAddBox/ImgAddBoxView';
import { useImgAddBoxService } from '@dating/ui/components/imgAddBox/useImgAddBoxService';
import {useTranslation} from "react-i18next";

const PhotoContainer = () => {
    const {
        fnProfileUpload,
        previewURLs,
        btnActivate,
        response,
        registerSteps,
    } = usePhotoService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
        step,
    } = useRegisterHeaderService();

    const {
        updateFile,
    } = useImgCropService();

    const {
        removeImg,
        osType,
        arrayToBlob,
    } = useImgAddBoxService();

    const navigate = useNavigate();

    const { t } = useTranslation('register')
    useEffect(() => {
        if (response) {
            navigate(registerSteps[7].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter" style={{overflowY: "auto"}}>
            <RegisterHeaderView/>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">{t('photoStep.inputPhoto')}</h2>
                    <p className="titleSub01">{t('photoStep.inputPhoto2')}</p>
                </div>

                <div className="content">
                    <div className="imgAddBox">
                        {
                            [0, 1].map(boxIndex => {
                                return (
                                    <ImgAddBoxView
                                        key={boxIndex}
                                        boxIndex={boxIndex}
                                        previewURLs={previewURLs}
                                        osType={osType}
                                        arrayToBlob={arrayToBlob}
                                        updateFile={updateFile}
                                        removeImg={removeImg}
                                    />
                                );
                            })
                        }
                    </div>

                    <PhotoImageGuideView />
                </div>
            </div>

            <PhotoFooterView
                fnProfileUpload={fnProfileUpload}
                btnActivate={btnActivate}
            />
        </div>
    );
};

export default PhotoContainer;
