export const PATH = {
	METAVERSE: {
		ROOT: '/metaverse',
	},
	MESSENGER: {
		ROOT: '/metaverse/messenger',
		//매니저 없을 때
		MANAGER_NONE: '/metaverse/messenger/managerNone',

		// 매니저 서비스 경로
		MANAGER_ONLINE: '/metaverse/messenger/managerOnline',
		MANAGER_OFFLINE: '/metaverse/messenger/managerOffline',
	},
	MANAGER: {
		HOME: '/metaverse/manager/home',
		MESSENGER: {
			ONLINE: '/metaverse/manager/messenger/online',
			OFFLINE: '/metaverse/manager/messenger/offline',
		},
		LIKE: '/metaverse/manager/like',
		MY_PROFILE: '/metaverse/manager/myProfile',
	},
	DATING: {
		HOME: '/metaverse/dating/home/hushHome',
		PICK: '/metaverse/dating/home/hushPick',
	},
	LOUNGE: {
		BASE_PATH: '/metaverse/dating/lounge',
		LIST: '/metaverse/dating/lounge/list',
		SEARCH: '/metaverse/dating/lounge/search',
		SEARCH_LIST: '/metaverse/dating/lounge/searchList',
		FORM: '/metaverse/dating/lounge/form',
		MY: '/metaverse/dating/lounge/myFeedList',
		SINGLE: '/metaverse/dating/lounge/singleFeed',
	},
	LIKE: {
		BASE_PATH: '/metaverse/dating/like',
		RECEIVED: '/metaverse/dating/like/receivedLike',
		SEND: '/metaverse/dating/like/sentLike',
		MATCHED: '/metaverse/dating/like/matchingSuccess',
	},
	PROFILE: {
		MY_PROFILE: '/metaverse/dating/profile/myProfile',
		USER_PROFILE: '/metaverse/dating/profile/userProfile',
		EDIT_PROFILE: '/metaverse/dating/profile/myProfile/editProfile',
		EDIT_PROFILE_IMG: '/metaverse/dating/profile/myProfile/editProfileImg',
		ADD_PROFILE: '/metaverse/dating/profile/myProfile/addProfile',
		MANAGER_PROFILE: '/metaverse/dating/profile/managerProfile',
	},
	REGISTER: {
		REGISTER: '/metaverse/dating/register',
		PHONE: '/metaverse/dating/register/phone',
		TERMS: '/metaverse/dating/register/terms',
		GENDER: '/metaverse/dating/register/gender',
		NICKNAME: '/metaverse/dating/register/nickname',
		BIRTH: '/metaverse/dating/register/birth',
		HEIGHT: '/metaverse/dating/register/height',
		JOB: '/metaverse/dating/register/job',
		PHOTO: '/metaverse/dating/register/photo',
		IDEAL: '/metaverse/dating/register/ideal',
		LIFE_STYLE: '/metaverse/dating/register/lifeStyle',
		RECOMMEND_CODE: '/metaverse/dating/register/code',
		LOCATION: '/metaverse/dating/register/location',
		LOCATION_UNABLE: '/metaverse/dating/register/locationUnable',
		WAITING: '/metaverse/dating/register/waiting',
		OCCUPANCY: '/metaverse/dating/register/occupancy',
	},
	AUTH: {
		IN_ACTIVE: '/metaverse/dating/auth/inactive',
	},
	SETTINGS: {
		HOME: '/metaverse/dating/settings',
		ACCOUNT: '/metaverse/dating/settings/accountSetting',
		MISSION: '/metaverse/dating/content/mission',
		EVENT: '/metaverse/dating/content/event',
		EVENT_ATTEND: '/metaverse/dating/content/event/attend',
		EVENT_REFERRAL: '/metaverse/dating/content/event/referral',
		IDEAL_EDIT: '/metaverse/dating/settings/idealSettingEdit',
		NOTICE: '/metaverse/dating/content/notice',
		BLOCK_LIST: '/metaverse/dating/settings/blockList',
		BLOCK_ACQUAINTANCE: '/metaverse/dating/settings/blockAcquaintance',
		WITHDRAWAL: '/metaverse/dating/settings/withdrawal',
		INACTIVE_ACCOUNT: '/metaverse/dating/settings/inactiveAccount',
	},
	STORE: {
		HOME: '/metaverse/dating/store',
		MY_BERITH: '/metaverse/dating/store/myBerith',
		OFFLINE: '/metaverse/dating/store/offlineManager',
		SCREEN: '/metaverse/dating/store/screenManager',
		MANAGER_PURCHASE_HISTORY: '/metaverse/dating/store/managerPurchaseHistory',
	},
	COMMON: {
		IMG_CROP: '/metaverse/dating/imgCrop',
	},
	//side_bar 사용인지 미사용인지 모르겠음.. 일단 정리
	SIDE_BAR: {
		HOME: '/metaverse/dating/sideBar',
		MAKE_ROOM: '/metaverse/dating/sideBar/makeRoom',
		STORE: '/metaverse/dating/sideBar/store',
		NOTICE: '/metaverse/dating/sideBar/notice',
		INVITE: '/metaverse/dating/sideBar/friendInviteEvent',
		EVENT: '/metaverse/dating/sideBar/event',
		MISSION: '/metaverse/dating/sideBar/mission',
		AVATAR: '/metaverse/dating/sideBar/avatar',
	},
};
