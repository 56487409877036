import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LoungeService } from '@/site/api/services/LoungeService';
import { CommentCIVO, DeleteCommentCIVO, PostIdCIVO, SearchPostCIVO } from '@/site/api';
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";

export const useLoungeAdapter = () => {
	const queryClient = useQueryClient();

	const {
		myProfileOpen,
	} = useDatingRepo();

	const {
		keyword,
		setSearchResult,
		setPostListEvent,
		setFeedDetailEvent,
		setPageArrayReset,
		setHasMore,
		focusedPostId,
		setFocusedPostId,
		commentResponse,
		setCommentResponse,
		setHaveToRefresh,
		setIsHiddenDelete,
	} = useLoungeRepo();

	/*
	 * getPosts
	 * 라운지 포스트 리스트 조회
	 */
	const getPosts = () => {
		return LoungeService.getPosts();
	};

	/*
	 * getPosts2
	 * 라운지 포스트 리스트 조회(인피니티스크롤용)
	 */
	const getPosts2 = (page:number) => {
		return LoungeService.getPosts2(page, 10);
	};

	/*
	 * getSearchPosts
	 * 키워드로 포스트 조회
	 */
	const getSearchPosts = (map: SearchPostCIVO) => {
		return LoungeService.getSearchPost(map);
	};
	/*
	 * removePost
	 * 포스트 삭제
	 */
	const removePost = useMutation({
		mutationFn: (map: PostIdCIVO) => {
			const res = LoungeService.removePost(map)
			if(res) setPostListEvent({flag: 'remove', id: map.postId})
			return res
		},
		onSuccess: async ({res, data}) => {
			queryClient.invalidateQueries({ queryKey: ['getMyPosts'] });
			// queryClient.invalidateQueries({ queryKey: ['getPosts2'] });
			const getSearchPostsRefetch = await queryClient.fetchQuery({ queryKey: ['getSearchPosts', {searchWord: keyword.trim()}] })
			setSearchResult(getSearchPostsRefetch)
			setIsHiddenDelete(true)
		},
	});

	/*
	 * savePost
	 * 신규 포스트 작성 및 저장
	 */
	const savePost = useMutation({
		mutationFn: (contents: {}) => {
			return LoungeService.savePost(contents);
		},
		onSuccess: (data) => {
			setHaveToRefresh(true)
			queryClient.invalidateQueries({ queryKey: ['getPosts2'] });
			queryClient.invalidateQueries({ queryKey: ['getSearchPosts', {searchWord: keyword.trim()}] });
			setPageArrayReset()
		},
	});

	/*
	 * saveComment
	 * 댓글 작성
	 */
	const saveComment = useMutation({
		mutationFn: (registComment: CommentCIVO) => {
			return LoungeService.saveComment(registComment);
		},
		onSuccess: async (data) => {
			// queryClient.invalidateQueries({ queryKey: ['getPosts2'] });
			setPostListEvent({flag: 'comment', id: focusedPostId})
			setFeedDetailEvent({flag: 'comment'})
			const getCommentRefetch = await queryClient.fetchQuery({ queryKey: ['getComment', {menuType:myProfileOpen?'profile':'lounge', postId: focusedPostId.toString()}] })
			setCommentResponse(getCommentRefetch && getCommentRefetch.length > 0 ? getCommentRefetch : []);
			queryClient.invalidateQueries({ queryKey: ['getSearchPosts', {searchWord: keyword.trim()}] });
			queryClient.invalidateQueries({ queryKey: ['getMyPosts'] });
		},
	});

	/*
	 * deleteComment
	 * 댓글 삭제
	 */
	const deleteComment = (map: DeleteCommentCIVO) => {
		const res = LoungeService.deleteComment(map)
		if(res) {
			setPostListEvent({flag: 'commentDelete', id: focusedPostId})
			setFeedDetailEvent({flag: 'commentDelete'})
			queryClient.invalidateQueries({ queryKey: ['getMyPosts'] });
		}
		return res
	};

	/*
	 * getComment
	 * 특정 포스트 댓글리스트 조회
	 */
	const getComment = async (postId:string, menuType:string) => {
		if (postId != undefined && postId != '' && menuType != undefined && menuType != '') {
			return LoungeService.getComment(postId, menuType);
		}
		return null;
	};

	/*
	 * getMyPosts
	 * 내 프로필 화면에서 내가 작성한 포스트 조회
	 */
	const getMyPosts = () => {
		return LoungeService.getMyPosts();
	};


	/*
	 * getPostsByGender
	 * 게시글 성별 필터
	 */
	const getPostsByGender = (gender: string) => {
		return LoungeService.getFilteredPostsByGender(gender);
	}

	/*
	 * getPostDetail
	 * 게시물 상세조회 (1건)
	 */
	const getPostDetail = (postId: string) => {
		return LoungeService.getPostDetail(postId);
	}

	return {
		getPosts,
		getPosts2,
		getSearchPosts,
		//
		removePost: removePost.mutate,
		statusRemovePost: removePost.status,
		dataRemovePost: removePost.data,
		//
		savePost: savePost.mutate,
		statusSavePost: savePost.status,
		dataSavePost: savePost.data,
		//
		saveComment: saveComment.mutate,
		statusSaveComment: saveComment.status,
		dataSaveComment: saveComment.data,
		//
		getComment,
		getMyPosts,
		getPostsByGender,
		getPostDetail,
		deleteComment,
	};
};
