import { useNavigate } from 'react-router-dom';

interface props {
	fnCommonAlertPopOn: (message: string) => void;
}

const BerithBoxView = ({ fnCommonAlertPopOn }: props) => {
	return (
		<div className="bizBox">
			<div className="myBiz">
				<span>내 베리드</span>
				<p className="ic-berith">0</p>
			</div>
			<div className="bizBuy">
				<a
					className="fz16Fw700"
					onClick={() => {
						fnCommonAlertPopOn('베리드 구매 준비중 입니다.');
					}}
				>
					베리드 구매
				</a>
			</div>
		</div>
	);
};

export default BerithBoxView;
