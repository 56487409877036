import { useEffect, useRef } from 'react';

interface props {
	inputValues: string[];
	handleInputKeyDown: (index: number, eventKey: string) => void;
	activeIndex: number;
	input: string,
	setInput: (input: string) => void;
}

const PhoneVerificationInputView = ({
										inputValues,
										handleInputKeyDown,
										activeIndex,
										input,
										setInput,
									}: props) => {
	const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
	useEffect(() => {
		inputRefs.current[activeIndex]?.focus();
	}, [activeIndex]);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, []);
	return (
		<div className="input">
			{/*{inputValues.map((value, index) => (*/}
			{/*	<input*/}
			{/*		type="number"*/}
			{/*		pattern="\d*"*/}
			{/*		className="text-c padding-0"*/}
			{/*		key={index}*/}
			{/*		value={value}*/}
			{/*		autoComplete="one-time-code"*/}
			{/*		onChange={(e) => {*/}

			{/*		}}*/}
			{/*		onKeyDown={(e) => {*/}
			{/*			const eventKey: string = e.key;*/}
			{/*			handleInputKeyDown(index, eventKey);*/}
			{/*		}}*/}
			{/*		ref={(ref) => {*/}
			{/*			inputRefs.current ? (inputRefs.current[index] = ref) : null;*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*))}*/}
			<input
				ref={inputRef}
				type="number"
				pattern="\d*"
				className="text-c padding-0"
				value={input}
				autoComplete="one-time-code"
				onChange={(e) => {
					setInput(e.target.value.toString());
				}}
			/>
		</div>
	);
};

export default PhoneVerificationInputView;
