import {useTranslation} from "react-i18next";

interface props {
    fnSaveUserPrefer: () => void;
}

const IdealSettingEditFooterView = ({
                                        fnSaveUserPrefer
                                    }: props) => {
    const { t } = useTranslation('setting')

    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack btnBack2"
                    onClick={() => {
                        fnSaveUserPrefer();
                    }}
                >
                    <span>{t('confirm')}</span>
                </button>
            </div>
        </footer>
    );
};

export default IdealSettingEditFooterView;
