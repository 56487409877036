import { useTranslation } from 'react-i18next';

interface props {
	setOpenBlock: (openBlock: boolean) => void;
	openMenuPop: boolean;
	targetUid: string;
	reportPopOpen: (
		location: string,
		targetUid: string,
		category: string,
	) => void;
}

const BlockReportButtonView = ({
	setOpenBlock,
	openMenuPop,
	targetUid,
	reportPopOpen,
}: props) => {
	const { t } = useTranslation('common');

	return (
		<div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
			<button
				type="button"
				className="popOnBtn"
				onClick={() => {
					reportPopOpen(location.pathname, targetUid, 'User');
				}}
			>
				{t('profilePop.report')}
			</button>
			<a
				onClick={() => {
					setOpenBlock(true);
				}}
			>
				{t('profilePop.block')}
			</a>
		</div>
	);
};

export default BlockReportButtonView;
