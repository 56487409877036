import { useState } from 'react';
import { ReceiveLikeUserCOVO, ReceivePostLikeCOVO, ReceiveUserHighScoreCOVO } from '@/site/api';

export const useManagerLikeStateRepo = () => {

	// 받은 좋아요 리스트
	const [likeUsers, setLikeUsers] = useState<ReceiveLikeUserCOVO[]>([]);

	// 받은 좋아요 페이징
	const [likeUserPage, setLikeUserPage] = useState<number>(1);

	// 받은 좋아요 더보기 버튼
	const [noDataUserLike, setNoDataUserLike] = useState<boolean>(false);

	const [fadeIn, setFadeIn] = useState<boolean>(false);

	// 높게 받은 평가 더보기 버튼
	const [noDataHighlyRated, setNoDataHighlyRated] = useState<boolean>(false);

	// 높게 받은 평가 리스트
	const [highlyRatedUsers, setHighlyRatedUsers] = useState<ReceiveUserHighScoreCOVO[]>([]);

	// 높게 받은 평가 페이징
	const [highlyRatedPage, setHighlyRatedPage] = useState<number>(1);

	// 좋아요 보낸 포스트 더보기 버튼
	const [noDataPost, setNoDataPost] = useState<boolean>(false);

	// 좋아요 보낸 리스트
	const [likedPosts, setLikedPosts] = useState<ReceivePostLikeCOVO[]>([]);

	// 좋아요 보낸 포스트 페이징
	const [likedPostPage, setLikedPostPage] = useState<number>(1);

	// 받은 좋아요 리턴 리스트 길이가 0인 경우 호출 그만
	const [stopGetReceivedLike, setStopGetReceivedLike] = useState(false);

	// 높게 받은 평가 리턴 리스트 길이가 0인 경우 호출 그만
	const [stopGetReceivedRating, setStopGetReceivedRating] = useState(false);

	return {
		likeUsers,
		setLikeUsers,
		likeUserPage,
		setLikeUserPage,
		setNoDataUserLike,
		setNoDataHighlyRated,
		setHighlyRatedUsers,
		highlyRatedPage,
		setHighlyRatedPage,
		setNoDataPost,
		setLikedPosts,
		likedPostPage,
		setLikedPostPage,
		stopGetReceivedLike,
		setStopGetReceivedLike,
		stopGetReceivedRating,
		setStopGetReceivedRating,
		highlyRatedUsers,
		likedPosts,
		fadeIn,
		setFadeIn,
		noDataUserLike,
		noDataHighlyRated,
		noDataPost,
	};
};