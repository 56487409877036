import { RoomReceiveProcess } from '@/services/metaverse/RoomReceiveProcess';
import { createRoomService, RoomService } from '@/services/Network';
import GameScene from '../GameScene';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export class GameSceneNetwork {
	private scene: GameScene;
	public roomService: RoomService;
	public roomReceiveProcess!: RoomReceiveProcess;

	constructor(scene: GameScene) {
		this.scene = scene;
		this.roomService = createRoomService(scene);
	}

	// RoomReceiveProcess 등록
	networkCallback() {
		this.roomReceiveProcess = new RoomReceiveProcess(
			this.scene,
			this.roomService,
		);
		this.roomReceiveProcess.listen();
	}

	async setupNetwork(arg: any) {
		if (arg.roomType === 'PUBLIC') {
			if (arg.roomId) {
				await this.roomService.joinCustomById(
					arg.roomId,
					arg.password,
					arg.uuid,
					arg.nickName,
					arg.gender,
					arg.profileUrl,
					arg.role,
					arg.x,
					arg.y,
					arg.anim,
					arg.readyToConnect,
					arg.mediaConnected,
					arg.statusMessage,
					arg.audioStatus,
				);
			} else {
				await this.roomService.joinOrCreatePublic(
					arg.uuid,
					arg.nickName,
					arg.gender,
					arg.profileUrl,
					arg.role,
					arg.x,
					arg.y,
					arg.anim,
					arg.readyToConnect,
					arg.mediaConnected,
					arg.statusMessage,
					arg.audioStatus,
				);
			}
		} else if (arg.roomType === 'CUSTOM') {
			if (arg.roomId) {
				// 기존 커스텀 룸에 참가
				await this.roomService.joinCustomById(
					arg.roomId,
					arg.password,
					arg.uuid,
					arg.nickName,
					arg.gender,
					arg.profileUrl,
					arg.role,
					arg.x,
					arg.y,
					arg.anim,
					arg.readyToConnect,
					arg.mediaConnected,
					arg.statusMessage,
					arg.audioStatus,
				);
			} else if (arg.room) {
				// 새 커스텀 룸 생성
				await this.roomService.createCustomRoom(
					arg.room,
					arg.uuid,
					arg.nickName,
					arg.gender,
					arg.profileUrl,
					arg.role,
					arg.x,
					arg.y,
					arg.anim,
					arg.readyToConnect,
					arg.mediaConnected,
					arg.statusMessage,
					arg.audioStatus,
				);
			} else {
				throw new Error('Invalid CUSTOM room parameters');
			}
		} else {
			throw new Error('Invalid room type');
		}
	}
}
