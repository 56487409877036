import { Link } from 'react-router-dom';
import { PostInfoCOVO } from "@/site/api";
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useTranslation} from "react-i18next";

interface props {
    post: PostInfoCOVO;
    deleteContent: (id: string) => void;
    openMenuPop: boolean;
    setModifyPostId: (postId: string) => void;
    fnCommonConfirmPopOn: (message: string, okFunc: any, okParam: any, noFunc: any, noParam: any) => any;
}

const FeedEditMenuView = ({
                              post,
                              deleteContent,
                              openMenuPop,
                              setModifyPostId,
                              fnCommonConfirmPopOn,
                          }: props) => {
    const { t } = useTranslation('lounge')

    return (
        <div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
            {
                post.isEditable ?
                    (
                        <Link
                            to={PATH.LOUNGE.FORM}
                            onClick={() => {
                                setModifyPostId(post.postId);
                            }}
                        >
                            <button
                                type="button"
                                className="popOnBtn"
                            >
                                {t('edit')}
                            </button>
                        </Link>
                    ) :
                    null
            }

            {
                post.isDeletable ?
                    (
                        <button
                            type="button"
                            className="popOnBtn"
                            // onClick={() => deleteContent(post.postId)}
                            onClick={() => fnCommonConfirmPopOn(t('deletePost'), deleteContent, post.postId, null, null)}
                        >
                            {t('delete')}
                        </button>
                    )
                    :
                    null
            }
        </div>
    );
};

export default FeedEditMenuView;
