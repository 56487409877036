import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { bridgeCopyToClipboard } from '@/utils/bridgeCallHandler';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoomManagementService } from '../../service/useRoomManagementService';

export const useRoomListService = () => {
	const { t } = useTranslation('metaverse');
	const { myProfile } = useDatingRepo();
	const { myMetaUser } = useMetaUserRepo();
	const myGender = myMetaUser.gender;
	const {
		availableRooms,
		joinedRoomData,
		roomListOpen,
		currentRoomData,
		setRoomListOpen,
	} = useRoomRepo();
	const { joinRoom } = useRoomManagementService();
	const [roomItem, setRoomItem] = useState(false);
	const { setOpenDirectMessageDetail, setShowDirectMessageList } =
		useDirectMessageRepo();

	// 클립보드에 방 정보 복사
	const copyRoomInfoToClipboard = async (roomInfo: {
		roomId: string;
		roomTheme: any;
		hasPassword: boolean;
	}) => {
		const formattedRoomInfo = {
			roomId: roomInfo.roomId,
			roomTheme: roomInfo.roomTheme,
			hasPassword: roomInfo.hasPassword,
		};
		try {
			if ((window as any).flutter_inappwebview) {
				await bridgeCopyToClipboard(JSON.stringify(formattedRoomInfo));
			} else {
				await navigator.clipboard.writeText(JSON.stringify(formattedRoomInfo));
			}
		} catch (err) {
			console.error(t('clipboardCopyFailed'), err);
		}
	};

	const handlePasswordSubmit = (password: number) => {
		joinRoom({
			roomId: currentRoomData.roomKey,
			password,
			roomTheme: currentRoomData.roomTheme,
			roomType: 'CUSTOM',
			x: currentRoomData.roomTheme.setUpPlayer.x,
			y: currentRoomData.roomTheme.setUpPlayer.y,
		});
		setOpenDirectMessageDetail(false, '');
		setShowDirectMessageList(false);
	};

	// 사용 가능한 방을 필터링하고 정렬
	const sortedRooms = availableRooms
		.filter(
			(room) => room.metadata.name !== 'PUBLIC' && room.metadata.name !== 'DM',
		)
		.sort((a, b) => {
			if (a.roomId === joinedRoomData?.id) return -1;
			if (b.roomId === joinedRoomData?.id) return 1;

			return (
				new Date((b as any).createdAt).getTime() -
				new Date((a as any).createdAt).getTime()
			);
		});

	// 방 참여 처리
	const handleJoinClick = useCallback(
		async (roomId: string, password: number | undefined, roomTheme: any) => {
			try {
				if (!roomId) {
					throw new Error(t('invalidLink'));
				}
				await joinRoom({
					roomId,
					password,
					roomTheme,
					roomType: 'CUSTOM',
					x: roomTheme.setUpPlayer.x,
					y: roomTheme.setUpPlayer.y,
				});
			} catch (error: any) {
				throw new Error(error.message || t('failedToJoin'));
			}
		},
		[joinRoom, t],
	);

	return {
		t,
		myProfile,
		myMetaUser,
		myGender,
		sortedRooms,
		roomListOpen,
		availableRooms,
		roomItem,
		joinedRoomData,
		setRoomListOpen,
		setRoomItem,
		handleJoinClick,
		copyRoomInfoToClipboard,
		handlePasswordSubmit,
	};
};
