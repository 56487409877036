import { CSSTransition } from 'react-transition-group';
import RoomListInfoView from './views/RoomListInfoView';
import { useRoomListService } from './service/useRoomListService';
import { usePublicRoomService } from '../publicRoom/service/usePublicRoomService';

const RoomListContainer = () => {
	const {
		t,
		myProfile,
		myMetaUser,
		myGender,
		sortedRooms,
		roomListOpen,
		roomItem,
		joinedRoomData,
		setRoomListOpen,
		setRoomItem,
		handleJoinClick,
		copyRoomInfoToClipboard,
	} = useRoomListService();
	const { handleClickMeta } = usePublicRoomService();
	return (
		<div className={`${roomListOpen ? 'roomListPopOn' : 'roomListPop'}`}>
			<div
				className="roomListDim"
				onClick={() => {
					setRoomListOpen(false);
				}}
			/>
			<CSSTransition
				in={roomListOpen}
				classNames="roomListInfo-transition"
				timeout={100}
			>
				<RoomListInfoView
					t={t}
					role={myProfile.role}
					myMetaUser={myMetaUser}
					myGender={myGender}
					roomItem={roomItem}
					sortedRooms={sortedRooms}
					roomListOpen={roomListOpen}
					joinedRoomData={joinedRoomData}
					setRoomItem={setRoomItem}
					handleClickMeta={handleClickMeta}
					handleJoinClick={handleJoinClick}
					copyRoomInfoToClipboard={copyRoomInfoToClipboard}
				/>
			</CSSTransition>
		</div>
	);
};

export default RoomListContainer;
