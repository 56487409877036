import {useTranslation} from "react-i18next";

interface props {
    hidePopup: (action: string, type: string) => void;
    handleReligionSelect: (religion: { code: string, value: string }) => void;
    initReligion: {code: string, value: string}[] | string[];
    religion: {code: string, value: string},
}

const IdealReligionPopView = ({
                                  hidePopup,
                                  religion,
                                  handleReligionSelect,
                                  initReligion,
                              }: props) => {

    const { t } = useTranslation('register')
    return (
        <div className="popupInfo">
            <p className="fz20 bold-500 text-c padding-t8">{t('idealDetail.religion')}</p>
            <ul className="popCheckList">

                {initReligion && initReligion.length > 0 ?
                    initReligion.map((item, i) => {
                        const key = item.code + "-religions";
                        return (
                            <li key={key}>
                                <span className="bold-500">{item.value}</span>
                                <div>
                                    <input
                                        id={key}
                                        className="checkbox"
                                          name="religion-option"
                                        type="checkbox"
                                        checked={religion.code === item.code}
                                        onChange={() => handleReligionSelect(item)}
                                    />
                                    <label htmlFor={key} className="checkbox-label"></label>
                                </div>
                            </li>
                        );
                    })
                    : null
                }

            </ul>
            <div className="popFtBtnWrap">
                <button
                    className="popCloseBtn btn btnBig btnCCC"
                    onClick={() => {
                        hidePopup("cancel", "religion");
                    }}
                >
                    <span>{t('common.cancel')}</span>
                </button>
                <button
                    className="popCloseBtn btn btnBig btnBlack"
                    onClick={() => {
                        hidePopup("save", "religion");
                    }}
                >
                    <span>{t('common.confirm')}</span>
                </button>
            </div>
        </div>
    );
};

export default IdealReligionPopView;
