import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PurchaseHeaderView = () => {
	const { t } = useTranslation('messenger');
	const navigate = useNavigate();
	const onClickBack = () => {
		navigate(-1);
	};
	return (
		<div className="headerWrap">
			<div className="h-left"></div>
			<div className="h-center">
				<p className="title02">{t('purchaseHistory')}</p>
			</div>
			<div className="h-right">
				<button className="btnClose" onClick={onClickBack} />
			</div>
		</div>
	);
};

export default PurchaseHeaderView;
