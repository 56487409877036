import RegisterMainView from '@dating/ui/pages/register/main/views/RegisterMainView';
import { useAuthService } from '@dating/ui/components/auth/useAuthService';
import { useEffect } from 'react';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import {
	bridgeGetNativeDataLogin,
	bridgeGetNotiOnOffState,
	bridgeGetOSType,
	bridgeSetNotiOnOffState,
	bridgeGetLanguageCode,
} from '@/utils/bridgeCallHandler';
import { useRegisterService } from './useRegisterService';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useSettingsService } from '@dating/ui/pages/settings/useSettingsService';
import { useTranslation } from 'react-i18next';

const RegisterMainContainer = () => {
	// signUpSteps
	// 0. 이용약관 동의
	// 1. 성별 선택
	// 2. 닉네임
	// 3. 생년월일 등록
	// 4. 키선택
	// 5. 직업선택
	// 6. 사진등록
	// 7. 이상형선택
	// 8. 추천인코드입력
	// 9. 위치정보동의설정

	const { resetRegisterInfo, isDeleted, setIsDeleted } = useRegisterService();

	const {
		fnOauthLoginInfos,
		fnAutoLogin,
		authToken,
		setFcmToken,
		setDeviceId,
		osType,
		setOsType,
		langCode,
		setLangCode,
		isAppleSignInVisible,
		setAuthToken,
		userSettings,
	} = useAuthService();

	const { deviceId, fcmToken } = useAuthRepo();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const { i18n } = useTranslation();

	useEffect(() => {
		if (i18n && langCode) i18n.changeLanguage(langCode);
	}, [i18n, langCode]);

	const { t } = useTranslation('register');

	useEffect(() => {
		if (!(window as any).flutter_inappwebview) {
			// 웹에서 토큰을 가지고 있으면 autoLogin 호출, 없으면 점유인증
			if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
				fnAutoLogin();
			}
		} else {
			// 앱에서 osType get
			bridgeGetOSType().then((response) => {
				if (response != '' && response != null) {
					setOsType(response);
				}
			});

			bridgeGetLanguageCode().then((response) => {
				if (response != '' && response != null) {
					setLangCode(response);
				} else {
					setLangCode('en');
				}
			});

			// 앱에서 로그인시 필요한 데이터 받아와서 자동로그인 처리
			bridgeGetNativeDataLogin().then((res) => {
				// deviceId set
				if (res?.deviceId) setDeviceId(res.deviceId);
				// fcmToken set
				if (res?.fcmToken) setFcmToken(res.fcmToken);
				// app 자동로그인 처리
				if (
					res?.accessJmt &&
					res?.refreshJmt &&
					res?.deviceId &&
					res?.fcmToken
				) {
					// 1. zustand 세팅
					setAuthToken({
						accessJmt: res.accessJmt,
						refreshJmt: res.refreshJmt,
					});
					// 2. sessionStorage setting
					const authInfo = JSON.parse(
						sessionStorage.getItem('authInfo') || '{}',
					);
					authInfo.state.authToken.accessJmt = res.accessJmt;
					authInfo.state.authToken.refreshJmt = res.refreshJmt;
					sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
					// 3. 헤더 token은 이제 axiosInstance 생성 시, setting 중
					fnAutoLogin(res.deviceId, res.fcmToken);
				}
			});
		}
	}, []);

	useEffect(() => {
		if (isDeleted) {
			// 삭제된 계정인 경우 알림 팝업 호출
			fnCommonAlertPopOn(t('phoneStep.cannotRegister'));
		}
		return setIsDeleted(false);
	}, [isDeleted]);

	useEffect(() => {
		if (!(window as any).flutter_inappwebview) return;
		bridgeGetNotiOnOffState().then((onOff) => {
			// 기기의 소리 및 진동 값이 없거나 서버에서 가져온 값과 다르면 서버 값을 기기에 브릿지로 저장한다.
			if (
				onOff == null ||
				onOff == '' ||
				(userSettings?.soundAlarmYn && onOff == 'on') ||
				(!userSettings?.soundAlarmYn && onOff == 'off')
			)
				bridgeSetNotiOnOffState(userSettings?.soundAlarmYn ? 'on' : 'off');
		});
	}, [userSettings, osType]);

	return (
		<div className="wrap noFooter">
			<div className="mainBg text-c">
				<div className="mainLogo">
					<img src="/assets/img/common/main-logo.svg" alt="HUSH metaverse" />
				</div>

				<RegisterMainView
					resetRegisterInfo={resetRegisterInfo}
					fnOauthLoginInfos={fnOauthLoginInfos}
					isAppleSignInVisible={isAppleSignInVisible}
					authToken={authToken}
					fnAutoLogin={fnAutoLogin}
					deviceId={deviceId}
					fcmToken={fcmToken}
				/>

				<div className="text-c">
					<p>
						<br />
					</p>
					{/*<img*/}
					{/*	src="/assets/img/common/logo-beaith.png"*/}
					{/*	width="80px"*/}
					{/*	alt="베리드 로고"*/}
					{/*	style={{*/}
					{/*		margin: '0 auto',*/}
					{/*	}}*/}
					{/*/>*/}
				</div>
			</div>
		</div>
	);
};

export default RegisterMainContainer;
