import { PointService } from '@/site/api';

export const usePointAdapter = () => {
	/*
	 * getPoint
	 * 내 포인트 조회
	 */
	const getPoint = () => {
		return PointService.getPoint();
	};

	/*
	 * getPointUsageHistory
	 * 포인트 사용 내역 조회
	 */
	const getPointUsageHistory = () => {
		return PointService.getPointUsageHistory();
	};

	return {
		getPoint,
		getPointUsageHistory,
	};
};