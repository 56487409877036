/**
 * bridgeHandler 송신 구현 함수 ( app으로 브릿지를 보낸다 )
 */

/*
 * 연락처 가져오기
 * */
export const bridgeRequestContacts = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'requestContacts',
		);
	} catch (error) {
		console.error('bridge requestContacts Error : ', error);
	}
};

/*
* bridgeSetNativeDataLogin 로그인+결제(app)에서 필요한 data 담아 bridge 송신
* */
export const bridgeSetNativeDataLogin = async ({accessJmt, refreshJmt}) => {
	try {
		await (window as any).flutter_inappwebview.callHandler(
			'setNativeData',
			'accessJmt',
			accessJmt
		);
		await (window as any).flutter_inappwebview.callHandler(
			'setNativeData',
			'refreshJmt',
			refreshJmt
		);
		return true
	} catch (error) {
		console.error('Error calling bridgeSetNativeDataLogin:', error);
		return false
	}
};


/*
* bridgeSetNativeDataToken
* token 재발급 시, 네이티브에 세팅
* */
export const bridgeSetNativeDataToken = async ({accessJmt, refreshJmt}) => {
	try {
		if(!(window as any).flutter_inappwebview) return false

		await (window as any).flutter_inappwebview.callHandler(
			'setNativeData',
			'accessJmt',
			accessJmt
		);
		await (window as any).flutter_inappwebview.callHandler(
			'setNativeData',
			'refreshJmt',
			refreshJmt
		);
		return true
	} catch (error) {
		console.error('Error calling bridgeSetNativeDataToken:', error);
		return false
	}
};

/*
* bridgeGetNativeDataLogin
* app 자동 로그인 처리를 위해 deviceId, fcmToken 가져온다
* */
export const bridgeGetNativeDataLogin = async () => {
	try {
		const accessJmt = await (window as any).flutter_inappwebview.callHandler(
			'getNativeData',
			'accessJmt',
		);
		const refreshJmt = await (window as any).flutter_inappwebview.callHandler(
			'getNativeData',
			'refreshJmt',
		);
		const deviceId = await (window as any).flutter_inappwebview.callHandler(
			'getDeviceId',
		);
		const fcmToken = await (window as any).flutter_inappwebview.callHandler(
			'getFCMToken',
		);
		console.log(`deviceId : ${deviceId} , fcmToken : ${fcmToken} , access : ${accessJmt} , refresh : ${refreshJmt}`)
		return {accessJmt:accessJmt, refreshJmt:refreshJmt, deviceId:deviceId, fcmToken:fcmToken}
	} catch (error) {
		console.error('Error calling bridgeGetNativeDataLogin:', error);
	}
};
/*
* bridgeGetNotiData
* 앱 꺼진 상태에서 noti click 시, push 데이터 받아서 처리 하기 위함.
* */
export const bridgeGetNotiData = async () => {

	return new Promise((resolve, reject) => {
		const flutterInAppWebview = (window as any).flutter_inappwebview;

		if (!flutterInAppWebview) {
			resolve(null); // 기본값 반환
			return;
		}

		flutterInAppWebview
			.callHandler('getNotiData')
			.then(resolve)
			.catch(() => resolve(null));
	});

}

//
// bridgeGetDeviceId
// 앱에서 deviceId 가져오기
//
export const bridgeGetDeviceId = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'getDeviceId',
		);
	} catch (error) {
		console.error('Error calling bridgeGetDeviceId:', error);
	}
};

//
// bridgeGetFcmToken
// 앱에서 fcmToken 가져오기
//
export const bridgeGetFcmToken = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'getFCMToken',
		);
	} catch (error) {
		console.error('Error calling bridgeGetFcmToken:', error);
	}
};

/**
 * bridgeGetOSType
 * @returns ios, android
 */
export const bridgeGetOSType = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'getOsType',
		);
	} catch (error) {
		console.error('Error calling bridgeGetOSType:', error);
	}
};

/**
 * bridgeGetLanguageCode
 * @returns langCode('en', 'ko','vi' ...)
 */
export const bridgeGetLanguageCode = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'getLanguageCode',
		);
	} catch (error) {
		console.error('Error calling bridgeGetLanguageCode:', error);
	}
};

//
// bridgeRequestLocation
// 앱에서 위치정보 가져오기
//
export const bridgeRequestLocation = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'requestLocation',
			'Hello from Web',
		);
	} catch (error) {
		console.error('Error calling bridgeRequestLocation:', error);
	}
};

//
// bridgeLogoutData
// 로그아웃 시 앱에서 세션 및 페이지 초기화, 네이티브 로컬데이터 제거
//
export const bridgeLogoutData = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('logoutData');
	} catch (error) {
		console.error('Error calling bridgeLogoutData:', error);
	}
};

/**
 * bridgeCopyToClipboard
 * 방 정보 클립보드에 복사
 */
export const bridgeCopyToClipboard = async (text: string) => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'copyToClipboard',
			text,
		);
	} catch (error) {
		console.error('Error calling bridgeCopyToClipboard:', error);
	}
};

//
// bridgeGoogleLogin
// 앱으로 구글로그인 요청
//
export const bridgeGoogleLogin = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler(
			'googleSignin',
		);
	} catch (error) {
		console.error('Error calling bridgeGoogleLogin:', error);
	}
};

//
// bridgeShowToast
// 토스트 호출
//
export const bridgeShowToast = async (text: string) => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('showToast', text, 1);
	} catch (error) {
		alert('Error calling bridgeShowToast ===> ' + error);
	}
};


//
// bridgePickImage
// 갤러리에서 선택한 이미지를 unit8Array로 가져온다
//
export const bridgePickImage = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('pickImage', 'gallery');
	} catch (error) {
		alert(`Error calling bridgePickImage [gallery] ===> ` + error);
	}
};

//
// bridgeOnLoading
// loading 화면을 켠다
//
export const bridgeOnLoading = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('onLoading');
	} catch (error) {
		alert('Error calling bridgeOnLoading ===> ' + error);
	}
};

//
// bridgeOffLoading
// loading 화면을 끈다
//
export const bridgeOffLoading = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('offLoading');
	} catch (error) {
		alert('Error calling bridgeOffLoading ===> ' + error);
	}
};

//
// bridgeKakaoShare
// 카카오톡 공유하기를 위한 브릿지를 호출
//
export const bridgeKakaoShare = async (content: string) => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('kakaoFeedTemplateShare', content);
	} catch (error) {
		alert('Error calling bridgeKakaoShare ===> ' + error);
	}
};

//
// bridgePurchase
// 인앱 결제 호출
//
export const bridgePurchase = async (productKey: string) => {
	try {
		const map = JSON.stringify({
			productKey: productKey,
			isConsumable: 'Y',
		});

		//purchaseResult: 인앱 결제 결과 받을 브릿지명, SetBridgeProvider에 등록
		return await (window as any).flutter_inappwebview.callHandler('purchase', map, 'purchaseResult');
	} catch (error) {
		alert(`결제가 실패하였습니다.`);
	}
};


export const bridgeSetNotiOnOffState = async (onOff: string) => {
	try {
		console.log(`SET!!!!!!! setNotiOnOffState onOff value ===> ${onOff}`)
		return await (window as any).flutter_inappwebview.callHandler('setNotiOnOffState', onOff);
	} catch (error) {
		console.log(`setNotiOnOffState BRIDGE ERROR ====> `, error);
	}
};


export const bridgeGetNotiOnOffState  = async () => {
	try {
		return await (window as any).flutter_inappwebview.callHandler('getNotiOnOffState');
	} catch (error) {
		console.log(`getNotiOnOffState BRIDGE ERROR ====> `, error);
	}
};