import { useAccountSettingService } from '@dating/ui/pages/settings/accountSetting/useAccountSettingService';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { bridgeLogoutData } from '@/utils/bridgeCallHandler';
import { useTranslation } from 'react-i18next';

const AccountSettingContainer = () => {
	const {
		title,
		myProfile,
		fnLogout,
		responseLogout,
		userSettings,
		authToken,
		initUserData,
	} = useAccountSettingService();

	const { fnCommonConfirmPopOn, fnCommonConfirmPopOff } =
		useCommonConfirmPopService();

	const navigate = useNavigate();
	const { t } = useTranslation('setting');

	useEffect(() => {
		if (responseLogout) {
			// 로그아웃 확인 팝엉 닫기
			fnCommonConfirmPopOff();

			if (!(window as any).flutter_inappwebview) {
				// 웹
				initUserData();
			} else {
				// 앱
				bridgeLogoutData().then((value) => {
					if (value) {
						initUserData();
					}
				});
			}
		}
	}, [responseLogout]);

	useEffect(() => {
		if (authToken.refreshJmt == '' && authToken.accessJmt == '') {
			navigate(PATH.REGISTER.REGISTER);
			window.location.reload();
		}
	}, [authToken]);

	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />
				<div className="popConWrap">
					<div className="inner">
						<div className="accoList">
							<div className="leftRightTxt margin-t24">
                                <span>
                                    {t('phoneNumber')}
									{/* 휴대전화번호 */}
                                </span>
								<button type="button" className="color-999">
									{myProfile.phoneNumber}
								</button>
							</div>

							<div className="leftRightTxt margin-t24">
                                <span>
                                    {t('snsIntegration')}
									{/* SNS 계정 연동 */}
                                </span>
								<button type="button" className="color-999">
									{userSettings?.snsConnect.length === 1 ? userSettings?.snsConnect : userSettings?.snsConnect.join(', ')}
								</button>
							</div>

							<div className="margin-t24">
								<button
									type="button"
									className="bold-500 popOnBtn"
									onClick={() => {
										fnCommonConfirmPopOn(
											t('logoutConfirm'),
											// 로그아웃 하시겠습니까?
											fnLogout,
											null,
											null,
											null,
										);
									}}
								>
									{t('logout')}
									{/* 로그아웃 */}
								</button>
							</div>

							<div className="margin-t24">
								<Link to={PATH.SETTINGS.WITHDRAWAL}>
									<button type="button" className="bold-500">
										{t('deleteAccount')}
										{/* 계정 삭제 */}
									</button>
								</Link>
							</div>

							<div className="margin-t24">
								<Link to={PATH.SETTINGS.INACTIVE_ACCOUNT}>
									<button type="button" className="bold-500">
										{t('inactiveAccount')}
										{/* 휴면 계정 */}
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AccountSettingContainer;
