import { Room } from 'colyseus.js';
import { NonFunctionPropNames } from 'colyseus.js/dist/colyseus';
import { IMetaverseState, IPlayer } from '../../../../types/IMetaverseState';
import { MetaMessageType } from '../../../../types/MetaMessageType';
import { phaserEventEmitter } from '../../events/PhaserEventEmitter';
import { getShareScreenManager } from '../../stores/SharedScreenKeeper';
import WebRTC from '../../web/WebRTC';
import { StoreService } from '../StoreService';
import { reactEventEmitter } from '@/events/ReactEventEmitter';

export class RoomEventSetup {
	private storeService: StoreService;
	constructor(
		private room: Room<IMetaverseState>,
		private sessionId: string,
		private webRTC?: WebRTC,
	) {
		this.storeService = new StoreService();
	}

	setup() {
		this.setupRoomDataEvents();
		this.setupPlayerEvents();
		this.setupChatEvents();
		this.setupScreenShareEvents();
		this.setupEmojiEvents();
	}

	private setupRoomDataEvents() {
		this.room.onMessage(MetaMessageType.SEND_ROOM_DATA, (content) => {
			reactEventEmitter.emit('react-leave-room', '');
			reactEventEmitter.emit('react-change-room-data', true);
			this.storeService.setRoomData(content);
		});
	}

	private setupPlayerEvents() {
		this.room.state.players.onAdd((player, key) => {
			if (key === this.sessionId) return;

			this.setupPlayerFieldListeners(player, key);

			// 새 플레이어가 추가되면 전체 룸 데이터 갱신 요청
			this.room.send(MetaMessageType.SEND_ROOM_DATA);
		});

		this.room.state.players.onRemove((player, key) => {
			this.handlePlayerRemoval(player, key);
			// 플레이어가 제거되어도 전체 룸 데이터 갱신 요청
			this.room.send(MetaMessageType.SEND_ROOM_DATA);
		});
	}

	private setupPlayerFieldListeners(player: IPlayer, key: string) {
		const fields: NonFunctionPropNames<IPlayer>[] = [
			'uuid',
			'name',
			'x',
			'y',
			'anim',
			'readyToConnect',
			'mediaConnected',
			'statusMessage',
			'audioStatus',
		];

		fields.forEach((field) => {
			player.listen(field, (value) => {
				if (field === 'name' && value !== '') {
					this.handleNewPlayer(player, key, value as string);
				}
				if (value !== undefined) {
					this.handlePlayerUpdate(key, field, value);
				}
			});
		});
	}

	private handleNewPlayer(player: IPlayer, key: string, name: string) {
		phaserEventEmitter.emit('player-joined', { player, key });
		this.storeService.playerJoined(
			key,
			player.uuid,
			name,
			player.anim,
			player.statusMessage,
			player.audioStatus,
			player.profileUrl,
			player.role,
		);
	}

	private handlePlayerUpdate(key: string, field: string, value: any) {
		phaserEventEmitter.emit('player-updated', { field, value, key });
		this.storeService.playerUpdated(key, field, value);
	}

	private handlePlayerRemoval(player: IPlayer, key: string) {
		phaserEventEmitter.emit('player-left', key);
		this.webRTC?.deleteVideoStream(key);
		this.webRTC?.deleteOnCalledVideoStream(key);
		this.storeService.playerLeft({ key });
	}

	private setupChatEvents() {
		this.room.state.chatMessages.onAdd((item) => {
			this.storeService.setChatMessage(item);
		});

		this.room.onMessage(MetaMessageType.ADD_CHAT_MESSAGE, ({ clientId, content }) => {
			phaserEventEmitter.emit('update-dialog-bubble', {
				playerId: clientId,
				content,
			});
		});
	}

	private setupScreenShareEvents() {
		this.room.onMessage(MetaMessageType.DISCONNECT_STREAM, (clientId: string) => {
			this.webRTC?.deleteOnCalledVideoStream(clientId);
		});

		this.room.onMessage(MetaMessageType.STOP_SCREEN_SHARE, (clientId: string) => {
			getShareScreenManager()?.onUserLeft(clientId);
		});
	}

	private setupEmojiEvents() {
		this.room.onMessage(MetaMessageType.SEND_EMOJI_MESSAGE, ({ senderId, emoji }) => {
			phaserEventEmitter.emit('update-emoji-bubble', {
				playerId: senderId,
				emoji,
			});
		});

		this.room.state.emojis?.onAdd((item) => {
			this.storeService.setEmojiMessage(item);
		});
	}
}
