import { isCensored } from '@/utils/censor';
import { useCommonAlertPopService } from '../../commonAlertPop/service/useCommonAlertPopService';
import {useTranslation} from "react-i18next";

interface props {
	messageInputValue: string;
	handleChange: (value: string) => void;
	handlePaymentClick: (orderId: number) => void;
	messageTargetUser: string;
	fnValidateOrder: (callback: any, param: any) => void;
	productPack: {
		id: number;
		name: string;
		price: number;
		productType: string;
		productSubType: string;
	};
	fnCloseMessagePop: () => void;
	setMessageInputValue: (value: string) => void;
}

const MessagePopView = ({
	messageInputValue,
	handleChange,
	handlePaymentClick,
	fnValidateOrder,
	productPack,
	fnCloseMessagePop,
	setMessageInputValue,
}: props) => {
	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	const { t } = useTranslation('common');

	const handleClick = async () => {
		try {
			// 금칙어 체크
			const hasCensoredWord = isCensored(messageInputValue.replace(/\s/g, ''));

			if (hasCensoredWord) {
				fnCommonAlertPopOn(t('msgPop.badWordQuote'));
				setMessageInputValue('');
				return;
			}

			// 금칙어가 없는 경우에만 결제 진행
			await new Promise((resolve) => {
				fnValidateOrder(
					(orderId) => {
						handlePaymentClick(orderId.orderId);
						resolve(orderId);
					},
					{
						productId: productPack.id,
					},
				);
			});

			// 성공적으로 처리된 후에만 팝업 닫기
			fnCloseMessagePop();
		} catch (error) {
			console.error('메시지 전송 실패:', error);
			fnCommonAlertPopOn(t('msgPop.msgSendFail'));
		}
	};

	return (
		<div className="messageWrap">
			<div className="popupInfo padding-b16">
				<form
					onSubmit={(e) => {
						if (e) {
							e.preventDefault();
						}
					}}
				>
					<p
						className="fz20 bold-500 text-c"
						style={{
							padding: '16px',
						}}
					>
						{t('msgPop.msgSendUsingBerith')}
					</p>
					<div
						className="paymentBox"
						style={{
							padding: '0px 16px 24px 16px',
						}}
					>
						<input
							type="text"
							value={messageInputValue}
							maxLength={192}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							placeholder={t('msgPop.msgPlaceHolder')}
						/>
					</div>
					<button
						type="button"
						className={`btnBig radiusNone flexXYcenter ${
							messageInputValue.trim() ? 'btnBlack' : 'btnCCC'
						}`}
						onClick={() => handleClick()}
						disabled={!messageInputValue.trim()}
					>
						<span
							className="ic-berith"
							style={{
								fontSize: '14px',
							}}
						>
							{productPack?.price ? productPack.price : 0}
						</span>
						&nbsp;&nbsp;
						<span className="fz16">{t('msgPop.sendBerith')}</span>
					</button>
				</form>
			</div>
		</div>
	);
};

export default MessagePopView;
