/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MessageRoomCOVO = {
    /**
     * 방 아이디
     */
    roomId: string;
    /**
     * user1의 정보
     */
    user1Info?: Record<string, any>;
    /**
     * user2의 정보
     */
    user2Info?: Record<string, any>;
    /**
     * 방 생성 유형
     */
    roomType: string;
    /**
     * 구매 상품 타입(어떤 상품 구매하여 방이 생성되었는지)
     */
    productType: MessageRoomCOVO.productType;
    /**
     * 구매 상품 타입의 서브타입(구매한 상품 타입의 서브 타입)
     */
    productSubType: MessageRoomCOVO.productSubType;
    /**
     * 구매 상품 사용 상태값
     */
    useStatus: MessageRoomCOVO.useStatus;
    /**
     * 주문 번호
     */
    orderId: number;
    /**
     * 몇 번째 사용 중인지
     */
    seq: number;
    /**
     * 상품 총 사용 가능 횟수
     */
    validUses: number;
    /**
     * 마지막 메시지 내용
     */
    lastMessage: string;
    /**
     * 마지막 메시지 시간
     */
    lastMessageAt: string;
    /**
     * User1의 읽지 않은 메시지 개수
     */
    unReadCnt1: number;
    /**
     * User2의 읽지 않은 메시지 개수
     */
    unReadCnt2: number;
    /**
     * 0: 모두 보임, 1: user1만 나감, 2: user2만 나감, -1: 모두 나감
     */
    visibleTo: number;
    /**
     * [과금여부] 0: NONE, 1: USER1, 2: USER2, 3: BOTH
     */
    billingStatus: number;
    /**
     * 메시지 방 생성시간
     */
    createdAt: string;
    /**
     * 메타버스 온라인 매칭 여부
     */
    matchedAt: string | null;
};
export namespace MessageRoomCOVO {
    /**
     * 구매 상품 타입(어떤 상품 구매하여 방이 생성되었는지)
     */
    export enum productType {
        LIKE = 'LIKE',
        REVIEW = 'REVIEW',
        MESSENGER = 'MESSENGER',
        RECOMMEND = 'RECOMMEND',
        MANAGER = 'MANAGER',
    }
    /**
     * 구매 상품 타입의 서브타입(구매한 상품 타입의 서브 타입)
     */
    export enum productSubType {
        LIKE = 'LIKE',
        REVIEW = 'REVIEW',
        OPEN = 'OPEN',
        MESSAGE = 'MESSAGE',
        PREFER = 'PREFER',
        HUSH = 'HUSH',
        TOP10 = 'TOP10',
        RECENT = 'RECENT',
        HEIGHT = 'HEIGHT',
        SPECIAL = 'SPECIAL',
        OFFICIAL = 'OFFICIAL',
        MEDICAL = 'MEDICAL',
        BUSINESS = 'BUSINESS',
        FREELANCER = 'FREELANCER',
        SELFEMPLOYED = 'SELFEMPLOYED',
        ONLINE = 'ONLINE',
        OFFLINE = 'OFFLINE',
    }
    /**
     * 구매 상품 사용 상태값
     */
    export enum useStatus {
        WAIT = 'WAIT',
        USING = 'USING',
        DONE = 'DONE',
    }
}

