import { useSingleFeedService } from '@dating/ui/pages/lounge/singleFeed/useSingleFeedService';
import { CSSTransition } from 'react-transition-group';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import FeedListView from '@dating/ui/pages/lounge/feedList/views/FeedListView';
import { useFeedListService } from '@dating/ui/pages/lounge/feedList/service/useFeedListService';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useFeedFormService } from '@dating/ui/components/feedFrom/service/useFeedFormService';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import FeedCommentView from '@dating/ui/pages/lounge/feedComment/views/FeedCommentView';
import ReportPopView from "@dating/ui/components/blockReport/views/ReportPopView";
import FeedHidePopView from "@dating/ui/pages/lounge/feedList/views/FeedHidePopView";
import {useTranslation} from "react-i18next";

export const SingleFeedContainer = () => {
	const {
		feedDetail,
		sliderSettings,
		srcPrefix,
		initFeedEditPops,
		fnFeedEditHandler,
		fnSinglePostLike,
		commentListHandler,
		focusedPostId,
		commentResponse,
		postUser,
		fnDeleteComment
	} = useSingleFeedService();

	const {
		deleteContent,
		commentViewHandler,
		openComment,
		fnSaveComment,
		commentValue,
		setCommentValue,
		setUserProfileOpen,
		setMyProfileOpen,
		myProfile,
		openFeedHidePop,
		setOpenFeedHidePop,
		fnSaveBlockPost,
		setBlockPostId,
		feedEditPops,
		commentState,
		setFocusedPostId,
		observeCard,
		setObserveCard,
		isSearchPost,
		haveToRefresh,
		setHaveToRefresh,
	} = useFeedListService();

	const {
		openMenuPop,
		openReport,
		reportPopClose,
		setOpenReport,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		setBlockUserUid,
		reportPopOpen,
		fnSaveReport,
		hidePopOpen,
		hidePopClose,
	} = useBlockReportService();

	const { initFeedForm, setModifyPostId } = useFeedFormService();

	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();

	const { t } = useTranslation('lounge')

	return (
		<div className={`${commentState ? 'wrap no-scroll footerNone' : 'wrap'}`}>
			<ButtonHeaderView title={t('post')} right={false} />
			<div
				id="conWrap"
				className="metaPopWrap"
				onClick={() => {
					if (feedDetail) {
						initFeedEditPops(feedDetail);
					}
				}}
			>
				<div className="feedWrap">
					{feedDetail ? (
						<FeedListView
							post={feedDetail}
							deleteContent={deleteContent}
							myProfile={myProfile}
							menuDotClickHandler={menuDotClickHandler}
							setOpenFeedHidePop={setOpenFeedHidePop}
							commentListHandler={commentListHandler}
							openMenuPop={openMenuPop}
							sliderSettings={sliderSettings}
							srcPrefix={srcPrefix}
							commentCount={feedDetail.commentCount}
							commentViewHandler={commentViewHandler}
							setUserProfileOpen={setUserProfileOpen}
							fnSavePostLike={fnSinglePostLike}
							setBlockUserUid={setBlockUserUid}
							currentUserUid={myProfile?.userUid}
							setModifyPostId={setModifyPostId}
							setMyProfileOpen={setMyProfileOpen}
							fnCommonConfirmPopOn={fnCommonConfirmPopOn}
							setBlockPostId={setBlockPostId}
							fnFeedEditHandler={fnFeedEditHandler}
							feedEditPops={feedEditPops}
							reportPopOpen={reportPopOpen}
							setFocusedPostId={setFocusedPostId}
							hidePopOpen={hidePopOpen}
							observeCard={observeCard}
							setObserveCard={setObserveCard}
							isSearchPost={isSearchPost}
							haveToRefresh={haveToRefresh}
							setHaveToRefresh={setHaveToRefresh}
						/>
					) : null}

					{/* 댓글창 */}
					<div className={`${openComment ? 'commentPopOn' : 'commentPop'}`}>
						<div
							className="commentDim"
							onClick={() => {
								commentListHandler('', { userUid: '', nickname: '' });
								commentViewHandler(false);
							}}
						></div>
						<CSSTransition
							in={openComment}
							classNames="commentInfo-transition"
							timeout={100}
						>
							<FeedCommentView
								key={focusedPostId}
								focusedPostId={focusedPostId}
								myProfile={myProfile}
								fnSaveComment={fnSaveComment}
								commentResponse={commentResponse}
								srcPrefix={srcPrefix}
								commentValue={commentValue}
								setCommentValue={setCommentValue}
								commentViewHandler={commentViewHandler}
								postUser={postUser}
								setMyProfileOpen={setMyProfileOpen}
								setUserProfileOpen={setUserProfileOpen}
								currentUserUid={myProfile?.userUid}
								fnCommonConfirmPopOn={fnCommonConfirmPopOn}
								fnDeleteComment={fnDeleteComment}
							/>
						</CSSTransition>
					</div>
				</div>
				{/* 신고하기 */}
				<CSSTransition
					in={openReport}
					classNames="popWrap-transition"
					timeout={100}
				>
					<ReportPopView
						reportPopClose={reportPopClose}
						selectedOption={selectedOption}
						handleOptionSelect={handleOptionSelect}
						commonReportCodes={commonReportCodes}
						reportValue={reportValue}
						reportInputHandler={reportInputHandler}
						inputDisabled={inputDisabled}
						fnSaveReport={fnSaveReport}
					/>
				</CSSTransition>
			</div>
			<div className={`${openFeedHidePop ? 'settingPopOn' : 'settingPop'}`}>
				<div className="settingDim" />
				{/* 게시글 숨기기 */}
				<CSSTransition
					in={openFeedHidePop}
					classNames="blockPopWrap-transition"
					timeout={100}
				>
					<FeedHidePopView
						fnSaveBlockPost={fnSaveBlockPost}
						hidePopClose={hidePopClose}
					/>
				</CSSTransition>
			</div>
		</div>
	);
};
