import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useMessageRouteHandler = () => {
	const location = useLocation();
	const prevPathRef = useRef(location.pathname);
	const isInitialMount = useRef(true); // 초기 마운트 체크용

	useEffect(() => {
		// 초기 마운트 시에는 이벤트를 발생시키지 않음
		if (isInitialMount.current) {
			isInitialMount.current = false;
			prevPathRef.current = location.pathname;
			return;
		}
		const isMessageDetailPath = (pathname: string): boolean => {
			// 기본 경로들은 false 반환
			const basePaths = [
				'/metaverse/messenger',
				'/metaverse/messenger/managerOnline',
				'/metaverse/messenger/managerOffline',
				'/metaverse/manager/messenger/online',
				'/metaverse/manager/messenger/offline',
			];

			if (basePaths.includes(pathname)) {
				return false;
			}
			const detailPatterns = [
				/^\/metaverse\/messenger\/managerOnline\/[^/]+$/,
				/^\/metaverse\/messenger\/managerOffline\/[^/]+$/,
				/^\/metaverse\/messenger\/[^/]+$/,
				/^\/metaverse\/manager\/messenger\/online\/[^/]+$/,
				/^\/metaverse\/manager\/messenger\/offline\/[^/]+$/,
			];
			return detailPatterns.some((pattern) => pattern.test(pathname));
		};

		const handleBeforeUnload = () => {
			if (isMessageDetailPath(location.pathname)) {
				dmEventEmitter.emit('react-leave-dm-room');
			}
		};

		// 경로가 변경될 때마다 체크
		if (prevPathRef.current !== location.pathname) {
			if (isMessageDetailPath(prevPathRef.current)) {
				dmEventEmitter.emit('react-leave-dm-room');
			}
			prevPathRef.current = location.pathname;
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [location.pathname]);
};
