import { create } from 'zustand';

interface BlockReportState {
	// 케밥메뉴 open/close
	openMenuPop: boolean;
	setOpenMenuPop: (openMenuPop) => void;

	// 선택한 신고내용 => stateRepo로 바꾸기
	selectedOption: string;
	setSelectedOption: (selectedOption: string) => void;

	// 신고 기타 입력 input
	inputDisabled: boolean;
	setInputDisabled: (inputDisabled: boolean) => void;

	// 차단확인팝업 open/close
	openBlock: boolean;
	setOpenBlock: (openBlock: boolean) => void;

	// 차단할 userUid
	blockUserUid: string;
	setBlockUserUid: (blockUserUid: string) => void;

	// 차단 목록
	blockedNumberList: { blockName: string; blockPhoneNumber: string }[];
	setBlockedNumberList: (blockPhone:{ blockName: string; blockPhoneNumber: string }[]) => void;

	blockCount: number;
	blockTf: boolean;
	setBlockCount: (cnt:number) => void;
	setBlockTf: (tf:boolean) => void;

	unBlockCount:number;
	unBlockTf: boolean;
	setUnBlockCount: (cnt:number) => void;
	setUnBlockTf: (tf:boolean) => void;


	// 신고 정보
	reportInfo: {
		targetId: string,
		category: string,
		location: string,
	};
	setReportInfo: (reportInfo: {
		targetId: string,
		category: string,
		location: string,
	}) => void;
}

export const useBlockReportRepo = create<BlockReportState>(
	(set) => ({
		openMenuPop: false,
		setOpenMenuPop: (openMenuPop: boolean) => set({ openMenuPop }),

		selectedOption: '',
		setSelectedOption: (selectedOption: string) => set({ selectedOption }),

		inputDisabled: true,
		setInputDisabled: (inputDisabled: boolean) => set({ inputDisabled }),

		openBlock: false,
		setOpenBlock: (openBlock: boolean) => set({ openBlock }),

		blockUserUid: '',
		setBlockUserUid: (blockUserUid) => set({ blockUserUid }),

		blockedNumberList: [],
		setBlockedNumberList: (blockPhone) => set({blockedNumberList:blockPhone}),

		// 차단 갯수, 상태
		blockCount: 0,
		blockTf: false,
		setBlockCount: (cnt) => set({blockCount:cnt}),
		setBlockTf: (tf) => set({blockTf:tf}),

		// 차단해제 갯수, 상태
		unBlockCount:0,
		unBlockTf: false,
		setUnBlockCount: (cnt) => set({unBlockCount:cnt}),
		setUnBlockTf: (tf) => set({unBlockTf:tf}),

		// 신고 정보
		reportInfo: {
			targetId: '',
			category: '',
			location: '',
		},
		setReportInfo: (reportInfo: {
			targetId: string,
			category: string,
			location: string,
		}) => set({ reportInfo }),
	}),
);