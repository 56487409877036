import {useLifeStyleStateRepo} from "@dating/ui/pages/register/lifeStyle/useLifeStyleStateRepo";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import type {UserStyleCIVO} from "@/site/api";
import {useEffect} from "react";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export default function useLifeStyleService() {
    const {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        customVal,
        setCustomVal,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        lifeStyleList,
        setLifeStyleList,
        interestList,
        setInterestList,
        isPopOn,
        setIsPopOn,
        initDrinkingState,
        setInitDrinkingState,
        initSmokingState,
        setInitSmokingState,
    } = useLifeStyleStateRepo();

    const {
        userWaiting,
    } = useRegisterRepo();

    const {
        saveUserStyle,
        statusSaveUserStyle,
        dataSaveUserStyle,
        getUserStyle,
    } = useRegisterAdapter();

    const {
        getStyles,
        getPrefer,
    } = useSettingAdapter();

    /////////////////////////////////////////////////////

    useEffect(() => {
        getStyles().then((response) => {
            if (response.interest.length > 0) {
                setInterestList(response.interest);
            }
            if (response.lifeStyle.length > 0) {
                setLifeStyleList(response.lifeStyle);
            }
        }).catch(
            reason => {
                
            }
        );

        getPrefer().then((response) => {
            setInitDrinkingState(response.drink);
            setInitSmokingState(response.smoke);
        }).catch(
            reason => {
                
            }
        );

        if (userWaiting.lifeStyle.length > 0) {
            const newItem = lifeStyleValue;
            userWaiting.lifeStyle.forEach((el) => {
                newItem[el] = true;
            });
            setLifeStyleValue(newItem);
        }

        getUserStyle().then(value => {
            if (value.length > 0) {
                value.map(item =>  {
                    switch (item.styleKind) {
                        case 'lifeStyle':
                            lifeStyleHandler(item.styleId);
                            break;
                        case 'interest':
                            interestHandler(item.styleId);
                            break;
                        case 'smoke':
                            setSmokingValue(item.styleId);
                            break;
                        case 'drink':
                            setDrinkingValue(item.styleId);
                            break;
                    }
                });
            }
        })
    }, []);

    /*
     * fnSaveUserStyle
     * 저장 (확인 버튼) - 나의 라이프스타일, 관심사 저장
     */
    const fnSaveUserStyle = () => {
        // 공통 라이프 스타일
        const commonLifeStyleCodes: string[] = [];
        for (const key in lifeStyleValue) {
            if (lifeStyleValue[key]) {
                commonLifeStyleCodes.push(key);
            }
        }

        // 공통 관심사
        const commonInterestCodes: string[] = [];
        for (const key in interestValue) {
            if (interestValue[key]) {
                commonInterestCodes.push(key);
            }
        }

        const map: UserStyleCIVO = {
            commonLifeStyleId: commonLifeStyleCodes,
            commonInterestId: commonInterestCodes,
            smokeId: smokingValue,
            drinkId: drinkingValue,
            religionId: '',
            extraLifeStyleValue: [],
            extraInterestValue: [],
        }

        saveUserStyle(map);
    };


    const interestHandler = (id: string) => {
        const newInterest = [...interestValue];
        if (interestValue[id]) {
            newInterest[id] = !newInterest[id];
        } else {
            newInterest[id] = true;
        }
        setInterestValue(newInterest);
    }

    const lifeStyleHandler = (id: string) => {
        const newLifeStyle = [...lifeStyleValue];
        if (lifeStyleValue[id]) {
            newLifeStyle[id] = !newLifeStyle[id];
        } else {
            newLifeStyle[id] = true;
        }
        setLifeStyleValue(newLifeStyle);
    }

    return {
        fnSaveUserStyle,
        interestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setDrinkingValue,
        lifeStyleValue,
        interestValue,
    }
}