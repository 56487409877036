import { Link } from "react-router-dom";
import { PATH } from "@dating/constants/RoutingEndPoints";
import { useTranslation } from 'react-i18next';

interface props {

};

export const SettingsBlockView = ({}: props) => {
    const { t } = useTranslation('setting');
    return (
        <>
            <Link to={PATH.SETTINGS.BLOCK_LIST}>
                <div className="leftRightTxt arrowBg">
                    <span className="fz16Fw500">
                        {t('blockManagement')}
                        {/* 차단 관리 */}
                    </span>
                </div>
            </Link>

            <Link to={PATH.SETTINGS.BLOCK_ACQUAINTANCE}>
                <div className="leftRightTxt arrowBg margin-b32">
                    <span className="fz16Fw500">
                        {t('blockAcquaintances')}
                        {/* 지인 차단하기 */}
                    </span>
                </div>
            </Link>
        </>
    );
};
