import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import useProfileRepo from "@dating/repository/profile/useProfileRepo";

export const useImgCropService = () => {
    const {
        imgCropFile,
        setImgCropFile,
        showImgCrop,
        setShowImgCrop,
        imgIndex,
        previewURLs,
        setImgIndex,
        setPreviewURLs,
        cropFiles,
        setCropFiles,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        feedForm,
        setNewFeedForm,
        setSaveBtnDisabled,
        saveBtnDisabled,
    } = useLoungeRepo();

    const {
        setAuthBadgeForm,
    } = useProfileRepo()

    /*
     * updateFile
     * 이미지 크롭 결과 repo에 저장 및 state 변경
     */
    const updateFile = (index: number, file: File | Blob) => {
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    setImgCropFile(reader.result as string);
                    setImgIndex(index);
                    setShowImgCrop(true); //크롭창열기
                };
                return;
            }
            alert("이미지 파일만 업로드 가능합니다.");
        }
    };

    /*
    * setCropData
    * 완료 버튼 클릭, 크롭 데이터 스토어에 set
    */
    const setCropData = async (fileURL: string, file: Blob) => {
        if (file) {
            let newFiles: Blob[] = cropFiles;
            newFiles.splice(imgIndex, 1, file);
            let newUrls = previewURLs;
            newUrls.splice(imgIndex, 1, fileURL);
            setPreviewURLs(newUrls); //미리보기 url set
            setImgCropFile(""); //크롭 완료 후 초기화

            if (feedForm.postId != undefined) {
                setNewFeedForm({files: newFiles});
            }
            setAuthBadgeForm({files: newFiles})

            const resizedFiles = await Promise.all(
                newFiles.map((file) => imgResize(file))
            );
            setCropFiles(resizedFiles);
            setShowImgCrop(false);
            setSaveBtnDisabled(false);
        }
    };

    const imgResize = (file):Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = URL.createObjectURL(file)
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800;
                const maxHeight = 600;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth || height > maxHeight) {
                    const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
                    width = width * scaleFactor;
                    height = height * scaleFactor;
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('이미지 변환 실패'));
                    }
                }, 'image/jpeg', 0.7);
                img.onerror = () => reject(new Error('이미지 로드 실패'));
            }
        })
    }


    return {
        setShowImgCrop,
        showImgCrop,
        imgCropFile,
        setCropData,
        previewURLs,
        imgIndex,
        setImgIndex,
        setPreviewURLs,
        saveBtnDisabled,
        setSaveBtnDisabled,
        updateFile,
        clearCropFiles,
        cropFiles,
    };
};
