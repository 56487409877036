import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import RoomThemeBoxView from './views/RoomThemeBoxView';
import RoomNameBoxView from './views/RoomNameBoxView';
import RoomHeadCountBoxView from './views/RoomHeadCountBoxView';
import RoomPwdSettingBoxView from './views/RoomPwdSettingBoxView';
import { useMakeRoomService } from './service/useMakeRoomService';
import { useTranslation } from 'react-i18next';

const MakeRoomContainer = () => {
	const {
		title,
		role,
		customRoomNameResult,
		activeHeadCountIndex,
		headCountSelectedList,
		roomThemeSelectedList,
		roomPwdResult,
		activeRoomThemeIndex,
		handleRoomNameChange,
		handleHeadCountChange,
		handleRoomThemeChange,
		handleRoomPwdChange,
		handleSubmit,
		isFormValid,
	} = useMakeRoomService();
	const { t } = useTranslation('metaverse');

	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<div className="popConWrap">
				<div className="roomSettingWrap">
					<div className="inner">
						<form onSubmit={handleSubmit}>
							<RoomThemeBoxView
								t={t}
								roomThemeSelectedList={roomThemeSelectedList}
								activeRoomThemeIndex={activeRoomThemeIndex}
								handleRoomThemeChange={handleRoomThemeChange}
							/>
							<RoomNameBoxView
								t={t}
								handleRoomNameChange={handleRoomNameChange}
								customRoomNameResult={customRoomNameResult}
							/>
							<RoomHeadCountBoxView
								t={t}
								headCountSelectedList={headCountSelectedList}
								activeHeadCountIndex={activeHeadCountIndex}
								handleHeadCountChange={handleHeadCountChange}
							/>
							{role === 'MANAGER' && (
								<RoomPwdSettingBoxView
									roomPwdResult={roomPwdResult}
									handleRoomPwdChange={handleRoomPwdChange}
								/>
							)}
							<button
								className={`btnBlack btnBig ${isFormValid() ? '' : 'btnCCC'}`}
								type="submit"
								role="button"
							>
								{t('save')}
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MakeRoomContainer;
