import React, { useEffect, useRef, useState } from 'react';
import FeedContentView from './FeedContentView';
import FeedImgView from './FeedImgView';
import { PostInfoCOVO, UserProfileCOVO } from '@/site/api';
import FeedProfBoxView from '@dating/ui/pages/lounge/feedList/views/FeedProfBoxView';

interface props {
	post: PostInfoCOVO;
	deleteContent: (id: string) => void;
	myProfile: UserProfileCOVO;
	menuDotClickHandler: () => void;
	setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
	commentListHandler: (
		postId: string,
		postUser: { userUid: string; nickname: string },
	) => void;
	commentViewHandler: (state: boolean) => void;
	openMenuPop: boolean;
	sliderSettings: {};
	srcPrefix: string;
	commentCount: number | undefined;
	setUserProfileOpen: (userProfileOpen: string) => void;
	fnSavePostLike: (postId: string, likeYn: boolean) => void;
	setBlockUserUid: (blockUserUid: string) => void;
	currentUserUid: string;
	setModifyPostId: (postId: string) => void;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	fnCommonConfirmPopOn: (
		message: string,
		okFunc: any,
		okParam: any,
		noFunc: any,
		noParam: any,
	) => any;
	setBlockPostId: (blockPostId: string) => void;
	fnFeedEditHandler: (postId: string) => void;
	feedEditPops: {};
	reportPopOpen: (
		location: string,
		targetUid: string,
		category: string,
	) => void;
	setFocusedPostId: (val:string) => void;
	hidePopOpen: (postId: string) => void
	observeCard: string;
	setObserveCard: (id:string) => void;
	isSearchPost: boolean;
	haveToRefresh: boolean;
	setHaveToRefresh: (val:boolean) => void;
}

const FeedListView = ({
						  post,
						  deleteContent,
						  myProfile,
						  menuDotClickHandler,
						  setOpenFeedHidePop,
						  commentListHandler,
						  openMenuPop,
						  sliderSettings,
						  srcPrefix,
						  commentCount,
						  commentViewHandler,
						  setUserProfileOpen,
						  fnSavePostLike,
						  setBlockUserUid,
						  currentUserUid,
						  setModifyPostId,
						  setMyProfileOpen,
						  fnCommonConfirmPopOn,
						  setBlockPostId,
						  fnFeedEditHandler,
						  feedEditPops,
						  reportPopOpen,
						  setFocusedPostId,
						  hidePopOpen,
						  observeCard,
						  setObserveCard,
						  isSearchPost,
						  haveToRefresh,
						  setHaveToRefresh,
					  }: props) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isVisible, setIsVisible] = useState(false);


	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				}
			},
			{ threshold: 0.05 } // 5% 보일 때 활성화 - 스크롤 빠르게 내리면 너무 느려서 5% 변경
		);
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, []);

	const cardObserver = useRef<IntersectionObserver | null>(null);

	const [scrollFlag, setScrollFlag] = useState(false);


	useEffect(() => {
		if (isSearchPost) return

		if (scrollFlag || haveToRefresh) {
			cardObserver.current?.unobserve(ref.current)
		}

		cardObserver.current = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting && ref.current) {
					setObserveCard(ref.current.id)
				}
			},
			{threshold: 0.6}
		);

		if (ref.current && !scrollFlag && !haveToRefresh) {
			cardObserver.current?.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				cardObserver.current?.unobserve(ref.current)
			}
		};
	}, [scrollFlag, haveToRefresh])


	useEffect(() => {
		if (isSearchPost) return

		if (scrollFlag || !observeCard || observeCard == '' || haveToRefresh) return

		setScrollFlag(true)
		const tgtElement = document.getElementById(observeCard)

		if (tgtElement) {
			tgtElement.scrollIntoView({block: 'center'});

			setTimeout(() => {
				if (ref.current) {
					cardObserver.current?.observe(ref.current);
				}
			}, 300);
		}else{
			setScrollFlag(false)
			setHaveToRefresh(false)
		}
	}, [observeCard]);

	return (
		<div
			className={`feedBox ${isVisible ? 'fade-in' : ''}`}
			ref={ref}
			key={post.postId}
			id={post.postId}
		>
			<FeedImgView
				post={post}
				sliderSettings={sliderSettings}
				srcPrefix={srcPrefix}
				fnSavePostLike={fnSavePostLike}
			/>
			<div className="inner padding-t8">
				<FeedProfBoxView
					key={post.postId}
					post={post}
					srcPrefix={srcPrefix}
					menuDotClickHandler={menuDotClickHandler}
					setOpenFeedHidePop={setOpenFeedHidePop}
					deleteContent={deleteContent}
					setUserProfileOpen={setUserProfileOpen}
					setBlockUserUid={setBlockUserUid}
					currentUserUid={currentUserUid}
					setModifyPostId={setModifyPostId}
					setMyProfileOpen={setMyProfileOpen}
					fnCommonConfirmPopOn={fnCommonConfirmPopOn}
					setBlockPostId={setBlockPostId}
					fnFeedEditHandler={fnFeedEditHandler}
					feedEditPops={feedEditPops}
					reportPopOpen={reportPopOpen}
					hidePopOpen={hidePopOpen}
				/>
				<FeedContentView
					post={post}
					srcPrefix={srcPrefix}
					commentListHandler={commentListHandler}
					commentViewHandler={commentViewHandler}
					myProfile={myProfile}
					commentCount={commentCount}
					setFocusedPostId={setFocusedPostId}
				/>
			</div>
		</div>
	);
};

export default FeedListView;