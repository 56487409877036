import { create } from 'zustand';
import type { BlockUserCOVO, UserSettingCOVO } from '@/site/api';

interface SettingState {
	openWithdrawalPop: boolean;
	setOpenWithdrawalPop: (openWithdrawalPop: boolean) => void;

	openBlockAcquaintancePop: boolean;
	setOpenBlockAcquaintancePop: (openBlockAcquaintancePop: boolean) => void;

	blockUsers: BlockUserCOVO[];
	setBlockUsers: (blockUsers: BlockUserCOVO[]) => void;

	userSettings: UserSettingCOVO | null;
	setUserSettings: (userSettings: UserSettingCOVO | null) => void;

	getWhoBlock: string;
	setGetWhoBlock: (getWhoBlock: string) => void;
}

export const useSettingRepo = create<SettingState>((set) => ({
	openWithdrawalPop: false,
	setOpenWithdrawalPop: (openWithdrawalPop: boolean) =>
		set({ openWithdrawalPop }),

	openBlockAcquaintancePop: false,
	setOpenBlockAcquaintancePop: (openBlockAcquaintancePop: boolean) =>
		set({ openBlockAcquaintancePop }),

	blockUsers: [],
	setBlockUsers: (blockUsers: BlockUserCOVO[]) => set({ blockUsers }),

	userSettings: null,
	setUserSettings: (updatedSettings: Partial<UserSettingCOVO>) =>
		set((state) => ({
			userSettings: {
				...state.userSettings,
				...updatedSettings,
			},
		})),
	getWhoBlock: '',
	setGetWhoBlock: (getWhoBlock: string) => set({ getWhoBlock }),
}));
