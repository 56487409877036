/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPreferUserCIVO } from '../models/AddPreferUserCIVO';
import type { HomeMatchingCOVO } from '../models/HomeMatchingCOVO';
import type { HomePastMatchingUserCOVO } from '../models/HomePastMatchingUserCOVO';
import type { HomePastUserCOVO } from '../models/HomePastUserCOVO';
import type { MatchTypeAndCodeCIVO } from '../models/MatchTypeAndCodeCIVO';
import type { RandomReviewUserCOVO } from '../models/RandomReviewUserCOVO';
import type { RandomUserCIVO } from '../models/RandomUserCIVO';
import type { RandomUserOrderInfoCIVO } from '../models/RandomUserOrderInfoCIVO';
import type { RandomUserScoreCIVO } from '../models/RandomUserScoreCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HomeService {
    /**
     * 선호정보에 맞는 이상형 3명 혹은 그 이하
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getPreferUser(): CancelablePromise<Array<HomeMatchingCOVO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/getPreferUser',
        });
    }
    /**
     * 선호정보에 맞는 이상형 추천 구매
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static addPreferUser(
        requestBody: AddPreferUserCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addPreferUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 선호 정보에 맞는 이상형 히스토리
     * @returns HomePastMatchingUserCOVO
     * @throws ApiError
     */
    public static getPastPreferUser(): CancelablePromise<Array<HomePastMatchingUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastPreferUser',
        });
    }
    /**
     * 인기 있는 이성 추천 받기
     * @returns boolean
     * @throws ApiError
     */
    public static addPopularUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addPopularUser',
        });
    }
    /**
     * 랜덤 추천 상대 5명
     * @returns RandomReviewUserCOVO
     * @throws ApiError
     */
    public static getRandomUser(): CancelablePromise<Array<RandomReviewUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getRandomUser',
        });
    }
    /**
     * 랜덤 추천 상대 5명 추가
     * @param requestBody 주문 정보
     * @returns boolean
     * @throws ApiError
     */
    public static addRandomUser(
        requestBody: RandomUserOrderInfoCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 랜덤 추천 상대 삭제
     * @param requestBody 랜덤  회원 ID
     * @returns boolean
     * @throws ApiError
     */
    public static deleteRandomUser(
        requestBody: RandomUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/deleteRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 랜덤 추천 상대 점수 평가
     * @param requestBody 랜덤 회원 ID및 점수
     * @returns boolean
     * @throws ApiError
     */
    public static saveScoreRandomUser(
        requestBody: RandomUserScoreCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/saveScoreRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * [matchType]에 따라 이성 추천 받기
     * @param requestBody 매칭 타입 및 코드
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getRecommendUser(
        requestBody: MatchTypeAndCodeCIVO,
    ): CancelablePromise<HomeMatchingCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/getRecommendUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * [matchType]에 따라 추천 받은 이성 히스토리 조회
     * @returns HomePastUserCOVO
     * @throws ApiError
     */
    public static getPastRecommendUsers(): CancelablePromise<Array<HomePastUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastRecommendUsers',
        });
    }
}
