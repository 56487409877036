import { UserProfileCOVO } from '@/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	userProfile: UserProfileCOVO;
	setOpenBlock: (openBlock: boolean) => void;
	fnSaveBlockUser: (UserProfileCOVO) => void;
}

const BlockPopView = ({
	userProfile,
	setOpenBlock,
	fnSaveBlockUser,
}: props) => {
	const { t } = useTranslation('common');
	return (
		<div className="blockPopWrap">
			<div className="popupInfo">
				<p className="fz20 bold-500 text-c">{t('blockPop.title')}</p>
				<p className="fz16Fw500 color-999 text-c padding-t8">
					{t('blockPop.description')}{' '}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							setOpenBlock(false);
						}}
					>
						<span>{t('blockPop.no')}</span>
					</button>
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={() => {
							fnSaveBlockUser(userProfile.userUid);
						}}
					>
						<span>{t('blockPop.block')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default BlockPopView;
