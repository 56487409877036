// 프로필 헤더
import React from 'react/index';
import { Link, useNavigate } from 'react-router-dom';
import { UserProfileCOVO } from '@/site/api';
import BlockReportButtonView from '@dating/ui/components/blockReport/views/BlockReportButtonView';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

interface props {
	openMenuPop: boolean;
	setOpenMenuPop: (openMenuPop) => void;
	initProfile: () => void;
	setSettingOpen?: (settingOpen: boolean) => void;
	menuDotClickHandler: () => void;
	setBlockUserUid: (blockUserUid: string) => void;
	profile: UserProfileCOVO;
	setOpenBlock: (openBlock: boolean) => void;
	reportPopOpen: (
		location: string,
		targetUid: string,
		category: string,
	) => void;
	myProfileOpen: boolean;
}

const ProfileHeader = ({
	openMenuPop,
	setOpenMenuPop,
	initProfile,
	setSettingOpen,
	menuDotClickHandler,
	setBlockUserUid,
	profile,
	setOpenBlock,
	reportPopOpen,
    myProfileOpen,
}: props) => {
	const navigate = useNavigate();
	const { t } = useTranslation('profile');
	return (
		<>
			<header>
				<div className="headerWrap">
					<div className="h-left">
						<button
							type="button"
							className="btnBack"
							onClick={() => {
								setOpenMenuPop(false);
								initProfile();
								navigate(-1);
							}}
						/>
					</div>

					{ myProfileOpen && (
						<>
							<div className="h-center">
								<p className="title02">{t('myPage')}
								{/*	마이페이지 */}
								</p>
							</div>
							<div className="h-right">
								<Link
									to={PATH.SETTINGS.HOME}
									onClick={() => {
										if (setSettingOpen) {
											setSettingOpen(true);
										}
									}}
								>
									<button type="button" className="btnSetting" />
								</Link>
							</div>
						</>
					)}

					{ !myProfileOpen && (
						<>
							<div className="h-center"></div>
							<div className="h-right">
								<div className="menuDotWap">
									<button
										className="menuDot"
										onClick={(e) => {
											e.stopPropagation();
											menuDotClickHandler();
										}}
									/>
									<BlockReportButtonView
										setOpenBlock={setOpenBlock}
										openMenuPop={openMenuPop}
										targetUid={profile.userUid}
										reportPopOpen={reportPopOpen}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</header>
		</>
	);
};

export default ProfileHeader;
