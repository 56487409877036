import { useEditProfileRepo } from '@dating/repository/profile/useEditProfileRepo';
import { useEffect } from 'react';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useEditProfilePopStateRepo } from '@dating/ui/components/editProfilePop/useEditProfilePopStateRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import type {
	ProfileDrinkCIVO,
	ProfileHeightCIVO,
	ProfileInterestCIVO,
	ProfileIntroCIVO,
	ProfileJobCIVO,
	ProfileLifeStyleCIVO,
	ProfileNicknameCIVO,
	ProfileReligionCIVO,
	ProfileSmokingCIVO,
} from '@/site/api';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { isCensored } from '@/utils/censor';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import {useTranslation} from "react-i18next";

export const useEditProfilePopService = () => {
	const { t } = useTranslation('messenger')
	const {
		customLifeStyleList,
		setCustomLifeStyleList,
		customInterestValue,
		setCustomInterestValue,
		lifeStyleValue,
		setLifeStyleValue,
		interestValue,
		setInterestValue,
		customVal,
		setCustomVal,
		isPopOn,
		setIsPopOn,
		heightValue,
		setHeightValue,
		activeHeightIndex,
		setActiveHeightIndex,
		interestList,
		setInterestList,
		lifeStyleList,
		setLifeStyleList,
		customInterestList,
		setCustomInterestList,
		customLifeStyleValue,
		setCustomLifeStyleValue,
		jobList,
		setJobList,
		selectedJob,
		setSelectedJob,
		nicknameValue,
		setNicknameValue,
		introduceValue,
		setIntroduceValue,
		drinkingValue,
		setDrinkingValue,
		smokingValue,
		setSmokingValue,
		religionValue,
		setReligionValue,
		lengthOver,
		setLengthOver,
		isValueEntered,
		setIsValueEntered,
		isAvailable,
		setIsAvailable,
		nickError,
		setNickError,
	} = useEditProfilePopStateRepo();

	const { focusItem, editPop, setEditPop, myModifyProfile } =
		useEditProfileRepo();

	const { getJobList, getStyles } = useSettingAdapter();

	const {
		modifyJob,
		statusModifyJob,
		dataModifyJob,
		modifyHeight,
		statusModifyHeight,
		dataModifyHeight,
		modifyDrink,
		statusModifyDrink,
		dataModifyDrink,
		modifySmoking,
		statusModifySmoking,
		dataModifySmoking,
		modifyReligion,
		statusModifyReligion,
		dataModifyReligion,
		modifyIntro,
		statusModifyIntro,
		dataModifyIntro,
		modifyProfileInterest,
		statusModifyProfileInterest,
		dataModifyProfileInterest,
		modifyProfileLifeStyle,
		statusModifyProfileLifeStyle,
		dataModifyProfileLifeStyle,
		modifyNickName,
		statusModifyNickname,
		dataModifyNickname,
	} = useProfileAdapter();

	const { getMyMetaUser } = useMetaUserAdapter();

	const { setMyMetaUser } = useMetaUserRepo();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	///////////////////////////////////////////////////

	/*
	 * fnModifyProfileLifeStyle
	 * 라이프스타일 수정
	 */
	const fnModifyProfileLifeStyle = () => {
		const commonLifeStyleCodes: string[] = [];
		for (const key in lifeStyleValue) {
			if (lifeStyleValue[key]) {
				commonLifeStyleCodes.push(key);
			}
		}

		const extraLifeStyleValue: string[] = [];
		for (const key in customLifeStyleValue) {
			if (customLifeStyleValue[key]) {
				extraLifeStyleValue.push(customLifeStyleList[key].styleValue);
			}
		}

		const map: ProfileLifeStyleCIVO = {
			commonLifeStyleCode: commonLifeStyleCodes,
			extraLifeStyleValue: extraLifeStyleValue,
		};
		modifyProfileLifeStyle(map);
	};

	/*
	 * fnModifyProfileInterest
	 * 관심사 수정
	 */
	const fnModifyProfileInterest = () => {
		const commonInterestCodes: string[] = [];
		for (const key in interestValue) {
			if (interestValue[key]) {
				commonInterestCodes.push(key);
			}
		}

		const extraInterestValue: string[] = [];
		for (const key in customInterestValue) {
			if (customInterestValue[key]) {
				extraInterestValue.push(customInterestList[key].styleValue);
			}
		}

		const map: ProfileInterestCIVO = {
			commonInterestCode: commonInterestCodes,
			extraInterestValue: extraInterestValue,
		};
		modifyProfileInterest(map);
	};

	/*
	 * fnModifyProfileIntroduce
	 * 자기소개 수정
	 */
	const fnModifyProfileIntroduce = () => {
		// 금칙어 포함되어 있는 경우 값 초기화 및 안내 팝업 호출
		const val = isCensored(introduceValue.replace(/\s/g, ''));
		// const val2 = censor(introduceValue.trim());
		if (val) {
			fnCommonAlertPopOn(t('forbiddenWord'));
			setIntroduceValue('');
		} else if (introduceValue != undefined && introduceValue != '') {
			const map: ProfileIntroCIVO = {
				intro: introduceValue,
			};
			modifyIntro(map);
			reactEventEmitter.emit(
				'react-my-player-change-status-message',
				introduceValue,
			);
		}
	};

	/*
	 * fnModifyProfileSmoking
	 * 흡연 정보 수정
	 */
	const fnModifyProfileSmoking = () => {
		if (smokingValue != '') {
			const map: ProfileSmokingCIVO = {
				smokeCode: smokingValue,
			};
			modifySmoking(map);
		}
	};

	/*
	 * fnModifyProfileReligion
	 * 종교 정보 수정
	 */
	const fnModifyProfileReligion = () => {
		if (religionValue != '') {
			const map: ProfileReligionCIVO = {
				religionCode: religionValue,
			};
			modifyReligion(map);
		}
	};

	/*
	 * fnModifyProfileDrink
	 * 음주 정보 수정
	 */
	const fnModifyProfileDrink = () => {
		if (drinkingValue != '') {
			const map: ProfileDrinkCIVO = {
				drinkCode: drinkingValue,
			};
			modifyDrink(map);
		}
	};

	/*
	 * fnModifyProfileNickname
	 * 닉네임 수정
	 */
	const fnModifyProfileNickname = () => {
		if (nicknameValue != '') {
			const map: ProfileNicknameCIVO = {
				nickname: nicknameValue,
			};
			modifyNickName(map);
			reactEventEmitter.emit('react-my-player-change-name', nicknameValue);
		}
	};

	/*
	 * fnModifyProfileJob
	 * 직업 수정
	 */
	const fnModifyProfileJob = () => {
		if (selectedJob != '') {
			const map: ProfileJobCIVO = {
				job: selectedJob,
			};
			modifyJob(map);
		}
	};

	/*
	 * fnModifyProfileHeight
	 * 키 수정
	 */
	const fnModifyProfileHeight = () => {
		if (heightValue != null) {
			const map: ProfileHeightCIVO = {
				height: Number(heightValue.join('')),
			};
			modifyHeight(map);
		}
	};

	/*
	 * closeEditPopup
	 * 팝업 닫기
	 */
	const closeEditPopup = (key: string) => {
		const newItem = {
			...editPop,
			[key]: false,
		};
		setEditPop(newItem);

		if (key === 'interest') {
			setInterestValue([]);
			setCustomInterestList([]);
		} else if (key === 'lifeStyle') {
			setLifeStyleValue([]);
		}
	};

	useEffect(() => {
		if (statusModifyIntro === 'success' && dataModifyIntro) {
			closeEditPopup('introduce');
		}
	}, [statusModifyIntro, dataModifyIntro]);

	useEffect(() => {
		if (statusModifyReligion === 'success' && dataModifyReligion) {
			closeEditPopup('religion');
		}
	}, [statusModifyReligion, dataModifyReligion]);

	useEffect(() => {
		if (statusModifySmoking === 'success' && dataModifySmoking) {
			closeEditPopup('smoking');
		}
	}, [statusModifySmoking, dataModifySmoking]);

	useEffect(() => {
		if (statusModifyDrink === 'success' && dataModifyDrink) {
			closeEditPopup('drinking');
		}
	}, [statusModifyDrink, dataModifyDrink]);

	useEffect(() => {
		if (statusModifyHeight === 'success' && dataModifyHeight) {
			closeEditPopup('height');
		}
	}, [statusModifyHeight, dataModifyHeight]);

	useEffect(() => {
		if (statusModifyJob === 'success' && dataModifyJob) {
			closeEditPopup('job');
		}
	}, [statusModifyJob, dataModifyJob]);

	useEffect(() => {
		if (statusModifyNickname === 'success' && dataModifyNickname) {
			if (dataModifyNickname.result) {
				setIsAvailable(true);
				closeEditPopup('nickName');
				getMyMetaUser().then((value) => {
					setMyMetaUser(value);
				});
			} else {
				setIsAvailable(false);
				setNickError(true);
			}
		}
	}, [statusModifyNickname, dataModifyNickname]);

	useEffect(() => {
		if (
			statusModifyProfileInterest === 'success' &&
			dataModifyProfileInterest
		) {
			closeEditPopup('interest');
		}
	}, [statusModifyProfileInterest, dataModifyProfileInterest]);

	useEffect(() => {
		if (
			statusModifyProfileLifeStyle === 'success' &&
			dataModifyProfileLifeStyle
		) {
			closeEditPopup('lifeStyle');
		}
	}, [statusModifyProfileLifeStyle, dataModifyProfileLifeStyle]);

	useEffect(() => {
		if (editPop.job) {
			if (jobList.length === 0) {
				getJobList()
					.then((response) => {
						if (response.length > 0) {
							setJobList(response);
						}
					})
					.catch((reason) => {});
			}
			setSelectedJob(myModifyProfile?.jobCode);
		}

		if (editPop.nickName) {
			setNicknameValue(myModifyProfile?.nickName);
			setIsAvailable(true);
			setLengthOver(false);
		}

		if (editPop.introduce) {
			setIntroduceValue(myModifyProfile?.introduce);
		}

		if (editPop.drinking) {
			setDrinkingValue(myModifyProfile?.drinkCode.toString());
		}

		if (editPop.smoking) {
			setSmokingValue(myModifyProfile?.smokingCode.toString());
		}

		if (editPop.religion) {
			setReligionValue(myModifyProfile?.religionCode.toString());
		}

		if (editPop.interest || editPop.lifeStyle) {
			getStyles()
				.then((response) => {
					if (response.interest.length > 0) {
						// 공통 관심사 리스트 전체 set
						setInterestList(response.interest);

						// 사용자가 추가한 관심사 리스트
						if (myModifyProfile?.extraInterest.length > 0) {
							let list: { id: string; styleValue: string }[] = [];
							const newInterest = [...customInterestValue];
							myModifyProfile?.extraInterest.forEach((item, index) => {
								if (item !== '') {
									list.push({ id: index.toString(), styleValue: item });
									newInterest[index.toString()] = true;
								}
							});
							setCustomInterestList(list);
							setCustomInterestValue(newInterest);
						}
					}
					if (response.lifeStyle.length > 0) {
						// 공통 라이프스타일 리스트 전체 set
						setLifeStyleList(response.lifeStyle);

						// 사용자가 추가한 라이프스타일 리스트
						if (myModifyProfile?.extraLifeStyle.length > 0) {
							let list: { id: string; styleValue: string }[] = [];
							const newCustomLifeStyle = [...customLifeStyleValue];
							myModifyProfile?.extraLifeStyle.forEach((item, index) => {
								if (item !== '') {
									list.push({ id: index.toString(), styleValue: item });
									newCustomLifeStyle[index.toString()] = true;
								}
							});
							setCustomLifeStyleList(list);
							setCustomLifeStyleValue(newCustomLifeStyle);
						}
					}
				})
				.catch((reason) => {});
		}

		if (editPop.interest) {
			if (editPop.interest && typeof focusItem.value === 'object') {
				const newItem = interestValue;
				focusItem.value.forEach((el) => {
					newItem[el] = true;
				});
				setInterestValue(newItem);
			}
		}

		if (editPop.lifeStyle && typeof focusItem.value === 'object') {
			const newItem = lifeStyleValue;
			focusItem.value.forEach((el) => {
				newItem[el] = true;
			});
			setLifeStyleValue(newItem);
		}
	}, [editPop]);

	/*
	 * handleHeightChange
	 * 키 입력값 변경 시 빈 줄 제거 후 set, input index값 수정
	 */
	const handleHeightChange = (index: number, newValue: string) => {
		if (
			/^\d$/.test(newValue) &&
			!(index == 0 && (Number(newValue) < 1 || Number(newValue) > 3))
		) {
			setHeightValue((preValues) => {
				const newInputValues = [...preValues];
				newInputValues[index] = newValue;
				return newInputValues;
			});

			if (index < heightValue.length - 1 && newValue !== '') {
				setActiveHeightIndex(index + 1);
			}
		}
	};

	/*
	 * handleHeightKeyDown
	 * 키 입력 input에 Backspace 또는 Delete 키 입력 시 키 값 수정 및 input index 수정
	 */
	const handleHeightKeyDown = (index: number, key: string) => {
		if (key === 'Backspace' || key === 'Delete') {
			setHeightValue((preValues) => {
				const newInputValues = [...preValues];
				newInputValues[index] = '';
				return newInputValues;
			});
			setActiveHeightIndex(index - 1);
		}

		if (key === 'ArrowLeft' && index > 0) {
			setActiveHeightIndex(index - 1);
		} else if (key === 'ArrowRight' && index < heightValue.length - 1) {
			setActiveHeightIndex(index + 1);
		}
	};

	/*
	 * interestValueHandler
	 * 관심사 선택 시 useState set, UI 변경
	 */
	const interestValueHandler = (id: string) => {
		const newInterest = [...interestValue];
		if (interestValue[id]) {
			newInterest[id] = !newInterest[id];
		} else {
			newInterest[id] = true;
		}
		setInterestValue(newInterest);
	};

	/*
	 * customInterestHandler
	 * 관심사 나만의 항목 추가 시 입력값 useState set, UI 변경
	 */
	const customInterestHandler = (id: string) => {
		const newInterest = [...customInterestValue];
		if (customInterestValue[id]) {
			newInterest[id] = !newInterest[id];
		} else {
			newInterest[id] = true;
		}
		setCustomInterestValue(newInterest);
	};

	/*
	 * lifeStyleValueHandler
	 * 라이프스타일 선택 시 useState set, UI 변경
	 */
	const lifeStyleValueHandler = (id: string) => {
		const newLifeStyle = [...lifeStyleValue];
		if (lifeStyleValue[id]) {
			newLifeStyle[id] = !newLifeStyle[id];
		} else {
			newLifeStyle[id] = true;
		}
		setLifeStyleValue(newLifeStyle);
	};

	/*
	 * customLifeStyleHandler
	 * 라이프스타일 나만의 항목 추가 시 입력값 useState set, UI 변경
	 */
	const customLifeStyleHandler = (id: string) => {
		const newCustomLifeStyle = [...customLifeStyleValue];
		if (customLifeStyleValue[id]) {
			newCustomLifeStyle[id] = !newCustomLifeStyle[id];
		} else {
			newCustomLifeStyle[id] = true;
		}
		setCustomLifeStyleValue(newCustomLifeStyle);
	};

	/*
	 * popOnHandler
	 * 나만의항목추가 팝업 show, hide 핸들러
	 */
	const popOnHandler = (id: string) => {
		setIsPopOn({ id: id, active: !isPopOn.active });
		setCustomVal('');
	};

	/*
	 * popOnSaveHandler
	 * 나만의 항목 추가 팝업 확인 버튼 클릭
	 */
	const popOnSaveHandler = (value: string) => {
		if (value === '') {
			setIsPopOn({ id: isPopOn.id, active: false });
			return;
		}
		if (value && value.length < 2) {
			setIsPopOn({ id: isPopOn.id, active: false });
			return;
		}

		if (value && value.length > 1) {
			if (isPopOn.id === 'interest') {
				const newInterest = [
					...customInterestList,
					{ id: customInterestList.length.toString(), styleValue: value },
				];
				setCustomInterestList(newInterest);
				customInterestHandler(customInterestList.length.toString());
			} else {
				const newLifeStyle = [
					...customLifeStyleList,
					{ id: customLifeStyleList.length.toString(), styleValue: value },
				];
				setCustomLifeStyleList(newLifeStyle);
				customLifeStyleHandler(customLifeStyleList.length.toString());
			}

			setIsPopOn({ id: isPopOn.id, active: false });
			setCustomVal('');
		}
	};

	/*
	 * handleInputChange
	 * 입력값이 변경될 때 호출되는 핸들러
	 */
	const handleInputChange = (newValue: string) => {
		setNicknameValue(newValue);

		// 닉네임 길이 2~8자 제한
		if (newValue.length < 2 || newValue.length > 8) {
			setLengthOver(true);
		} else {
			setLengthOver(false);
		}

		if (!isAvailable) {
			setIsAvailable(true);
		}
	};

	return {
		focusItem,
		editPop,
		setEditPop,
		handleHeightChange,
		handleHeightKeyDown,
		heightValue,
		setHeightValue,
		activeHeightIndex,
		setActiveHeightIndex,
		interestList,
		lifeStyleList,
		customInterestList,
		customLifeStyleList,
		customLifeStyleValue,
		jobList,
		selectedJob,
		setSelectedJob,
		nicknameValue,
		setNicknameValue,
		introduceValue,
		setIntroduceValue,
		drinkingValue,
		setDrinkingValue,
		smokingValue,
		setSmokingValue,
		religionValue,
		setReligionValue,
		fnModifyProfileNickname,
		fnModifyProfileJob,
		fnModifyProfileHeight,
		fnModifyProfileDrink,
		fnModifyProfileSmoking,
		fnModifyProfileReligion,
		fnModifyProfileIntroduce,
		interestValueHandler,
		interestValue,
		closeEditPopup,
		lifeStyleValue,
		lifeStyleValueHandler,
		fnModifyProfileInterest,
		fnModifyProfileLifeStyle,
		customInterestHandler,
		customVal,
		setCustomVal,
		isPopOn,
		popOnHandler,
		popOnSaveHandler,
		customLifeStyleHandler,
		customInterestValue,
		handleInputChange,
		isValueEntered,
		lengthOver,
		isAvailable,
		nickError,
	};
};
