import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useManagerRepo } from '@dating/repository/manager/useManagerRepo';
import { useEffect, useState } from 'react';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { UserLikeCIVO, type UserScoreCIVO } from '@/site/api';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';

export const useManagerProfileService = () => {
	// 별점평가 별 색칠여부
	const [isChecked, setIsChecked] = useState<number | null>(null);

	const { managerProfile, setManagerProfile } = useManagerRepo();

	const { srcPrefix, sliderSettings, userProfileOpen, setUserProfileOpen } =
		useDatingRepo();

	const { saveUserLike } = useLikeAdapter();

	const { getManagerProfile, saveScoreUser } = useProfileAdapter();
	//////////////////////////////////////

	useEffect(() => {
		fnGetManagerProfile();
		return setUserProfileOpen('');
	}, []);

	/*
	 * fnGetManagerProfile
	 * 매니저 프로필 조회 및 별점 set
	 */
	const fnGetManagerProfile = () => {
		if (userProfileOpen != '') {
			getManagerProfile(userProfileOpen).then((value) => {
				if (value.userUid) {
					setManagerProfile(value);
					setIsChecked(value.score);
				}
			});
		} else if (managerProfile.userUid != '') {
			getManagerProfile(managerProfile.userUid).then((value) => {
				if (value.userUid) {
					setManagerProfile(value);
					setIsChecked(value.score);
				}
			});
		}
	};

	/*
	 * fnSaveManagerLike
	 * 매니저 좋아요 저장
	 */
	const fnSaveManagerLike = (userUid: string, state: boolean) => {
		const map: UserLikeCIVO = {
			likeUserUid: userUid,
			likeYn: state,
		};
		saveUserLike(map).then((value) => {
			if (value) {
				fnGetManagerProfile();
			}
		});
	};

	/*
	 * evalHandler
	 * 별점평가 및 애니메이션 처리
	 */
	let timer: NodeJS.Timeout;
	const evalHandler = (userUid: string, score: number) => {
		timer = setTimeout(
			() => {
				const map: UserScoreCIVO = {
					userUid: userUid,
					score: score,
				};
				saveScoreUser(map).then((value) => {
					setIsChecked(null);
					fnGetManagerProfile();
				});
			},
			1000,
			userUid,
			score,
		);
	};

	return {
		srcPrefix,
		sliderSettings,
		fnSaveManagerLike,
		managerProfile,
		isChecked,
		setIsChecked,
		evalHandler,
		fnGetManagerProfile,
	};
};
