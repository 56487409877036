import {useTranslation} from "react-i18next";

interface props {
    hidePopup: (action: string, type: string) => void;
    smokingState: { code: string, value: string };
    handleSmokingStateSelect: (smokingState: { code: string, value: string }) => void;
    initSmokingState: {code: string, value: string}[] | string[];
}

const IdealSmokingStatusPopView = ({
                                       hidePopup,
                                       smokingState,
                                       handleSmokingStateSelect,
                                       initSmokingState,
                                   }: props) => {
    const { t } = useTranslation('register')
    return (
        <div className="popupInfo">
            <p className="fz20 bold-500 text-c padding-t8">{t('idealDetail.smoking')}</p>
            <ul className="popCheckList">
                {
                    initSmokingState && initSmokingState.length > 0 ?
                    initSmokingState.map((item, i) => {
                        const key = item.value + "-smoking";
                        return (
                            <li key={key}>
                                <span className="bold-500">{item.value}</span>
                                <div>
                                    <input
                                        id={key}
                                        className="checkbox"
                                        name="smoking-option"
                                        type="checkbox"
                                        checked={smokingState.code === item.code}
                                        onChange={() => handleSmokingStateSelect(item)}
                                    />
                                    <label htmlFor={key} className="checkbox-label"></label>
                                </div>
                            </li>
                        );
                    })
                    : null
                }

            </ul>
            <div className="popFtBtnWrap">
                <button
                    className="popCloseBtn btn btnBig btnCCC"
                    onClick={() => {
                        hidePopup("cancel", "smoking");
                    }}
                >
                    <span>{t('common.cancel')}</span>
                </button>
                <button
                    className="popCloseBtn btn btnBig btnBlack"
                    onClick={() => {
                        hidePopup("save", "smoking");
                    }}
                >
                    <span>{t('common.confirm')}</span>
                </button>
            </div>
        </div>
    );
};

export default IdealSmokingStatusPopView;
