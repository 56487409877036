import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { useCallback, useRef } from 'react';

/** 기본적인 방 출입 상태 관리를 위한 서비스 */
export const useMetaRoomCommonService = () => {
	const { joinedRoomData, setPreLeftRoomId } = useRoomRepo();
	const { myMetaUser } = useMetaUserRepo();
	const { getMetaRoom } = useMetaRoomAdapter();

	const hasCalledSaveRoomUserOut = useRef(false);
	// 방 퇴장 함수
	const handleSaveRoomUserOut = useCallback(async () => {
		const currentRoomId = joinedRoomData?.id;
		if (currentRoomId && !hasCalledSaveRoomUserOut.current) {
			hasCalledSaveRoomUserOut.current = true;
			try {
				setPreLeftRoomId(currentRoomId);
				return true; // 성공적으로 방을 나갔을 때 true 반환
			} catch (error) {
				return false;
			} finally {
				hasCalledSaveRoomUserOut.current = false;
			}
		}
		return false;
	}, [joinedRoomData, setPreLeftRoomId, myMetaUser.userUid]);

	// 방 참여 상태를 확인하는 함수 추가
	const checkRoomJoinStatus = useCallback(
		async (roomId: string) => {
			try {
				const joinedRoomResult = await getMetaRoom(roomId);
				return joinedRoomResult;
			} catch (error) {
				return null;
			}
		},
		[getMetaRoom],
	);

	return { handleSaveRoomUserOut, checkRoomJoinStatus };
};
