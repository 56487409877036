import { usePhoneNumberService } from "@dating/ui/pages/register/phoneNumber/service/usePhoneNumberService";
import PhoneNumberInputView from "@dating/ui/pages/register/phoneNumber/views/PhoneNumberInputView";
import PhoneNumberFooterView from "@dating/ui/pages/register/phoneNumber/views/PhoneNumberFooterView";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useTranslation} from "react-i18next";

const PhoneNumberContainer = () => {
    const {
        phoneNumber,
        handlePhoneNumberChange,
        footerBtnDisable,
        fnSendVerificationCode,
        verifyReqCount,
        resetRegisterInfo,
    } = usePhoneNumberService();

    const navigate = useNavigate();

    const{ t } = useTranslation('register')

    useEffect(() => {
        if (verifyReqCount > 0) {
            navigate(PATH.REGISTER.OCCUPANCY);
        }
    }, [verifyReqCount]);

    return (
        <div className="wrap">
            <header>
                <div className="headerWrap">
                    <div className="h-left">
                    </div>
                    <div className="h-center">

                    </div>
                    <div className="h-right">
                        <Link to={PATH.REGISTER.REGISTER}
                              onClick={() => {
                                  resetRegisterInfo();
                              }}
                        >
                            <button
                                className="btnClose"
                                type="button"
                            />
                        </Link>
                    </div>
                </div>
            </header>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <h2 className="title01">{t('phoneStep.inputPhone')}</h2>
                    <p className="color-999 fz16 margin-t16">
                        {t('phoneStep.securityQuote')}
                    </p>
                </div>
                <PhoneNumberInputView
                    phoneNumber={phoneNumber || ''}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                />
            </div>
            <PhoneNumberFooterView
                fnSendVerificationCode={fnSendVerificationCode}
                footerBtnDisable={footerBtnDisable}
            />
        </div>
    );
};

export default PhoneNumberContainer;
