import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { Link } from 'react-router-dom';
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useTranslation} from "react-i18next";

const EventContainer = () => {
	const { t } = useTranslation('setting')
	const title = t('event');

	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />

				<div className="popConWrap">
					<div className="inner">
						<div className="eventList margin-top223">
							<Link to={PATH.SETTINGS.EVENT_ATTEND}>
								<img src="/assets/img/common/event1.png" alt="출석체크 매일 매일 출석하고 포인트 받자!" />
							</Link>
							<Link to={PATH.SETTINGS.EVENT_REFERRAL}>
								<img src="/assets/img/common/event2.png" alt="신규회원 추천하고 같이 적립금 받으세요~" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EventContainer;
