import Phaser from 'phaser';
import ItemAbst from './ItemAbst';

export class TextBox {
	textContainer: Phaser.GameObjects.Container;

	constructor(private itemAbst: ItemAbst) {
		this.textContainer = itemAbst.scene.add.container().setDepth(10000);
	}

	setContent(
		content:
			| string
			| Phaser.GameObjects.Image
			| (string | Phaser.GameObjects.Image)[],
	) {
		this.clearText();
		const { scene, x, y, height } = this.itemAbst;

		const contentArray = Array.isArray(content) ? content : [content];
		let totalWidth = 0;
		let maxHeight = 0;

		const elements: Phaser.GameObjects.GameObject[] = [];

		contentArray.forEach((item) => {
			if (typeof item === 'string') {
				const text = scene.add
					.text(0, 0, item, {
						fontSize: '13px',
						fontFamily: 'VT323',
						color: '#ffffff',
						align: 'center',
						wordWrap: { width: 170, useAdvancedWrap: true },
					})
					.setOrigin(0.5)
					.setResolution(10);
				elements.push(text);
				totalWidth += text.width;
				maxHeight = Math.max(maxHeight, text.height);
			} else if (item instanceof Phaser.GameObjects.Image) {
				const targetSize = 12;
				const scale = Math.min(
					targetSize / item.height,
					targetSize / item.width,
				);
				item.setScale(scale);
				elements.push(item);
				totalWidth += item.displayWidth;
				maxHeight = Math.max(maxHeight, item.displayHeight);
			}
		});

		const padding = { x: 8, y: 4 };
		const spacing = 4;
		const containerWidth = Math.min(
			totalWidth + padding.x * 2 + (elements.length - 1) * spacing,
			150,
		);
		const containerHeight = Math.max(maxHeight + padding.y * 2, 24);
		const containerX = x - containerWidth * 0.5;
		const containerY = y + height;

		const graphics = scene.add.graphics();
		graphics.fillStyle(0x000000, 0.6);
		graphics.lineStyle(1, 0x333333, 0.5);
		graphics.fillRoundedRect(
			containerX,
			containerY,
			containerWidth,
			containerHeight,
			8,
		);
		graphics.strokeRoundedRect(
			containerX,
			containerY,
			containerWidth,
			containerHeight,
			8,
		);

		this.textContainer.add(graphics);

		let xOffset = containerX + padding.x;
		elements.forEach((element, index) => {
			if (element instanceof Phaser.GameObjects.Text) {
				element.setPosition(
					xOffset + element.width / 2,
					containerY + containerHeight / 2,
				);
				xOffset += element.width;
			} else if (element instanceof Phaser.GameObjects.Image) {
				element.setPosition(
					xOffset + element.displayWidth / 2,
					containerY + containerHeight / 2,
				);
				xOffset += element.displayWidth;
			}
			this.textContainer.add(element);

			if (index < elements.length - 1) {
				xOffset += spacing;
			}
		});
	}

	setText(text: string) {
		this.setContent(text);
	}

	clearText() {
		this.textContainer.removeAll(true);
	}
}
