import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { bridgeRequestLocation } from '@/utils/bridgeCallHandler';
import {coords} from "@dating/constants/TempGpsCoordinates";

interface props {
	fnSaveUserLocation: (response: any) => void;
}

const LocationInfoUnableFooterView = ({
										  fnSaveUserLocation,
									  }: props) => {
	const navigate = useNavigate();
	return (
		<div className="inner padding-t16 padding-b16">
			<button className="btn btnBig btnBlack" onClick={() => {
				if (!(window as any).flutter_inappwebview) {
					// 웹
					const randomIdx = Math.floor(Math.random() * coords.length)
					fnSaveUserLocation(coords[randomIdx]);
				} else {
					// 앱
					bridgeRequestLocation().then(response => {
						if (response == null) {
							navigate(PATH.REGISTER.LOCATION_UNABLE);
							return;
						} else {
							const map: {latitude:string,longitude:string} = {
								latitude: response.latitude,
								longitude: response.longitude,
							};

							// 서버로 전송
							fnSaveUserLocation(map);
						}
					});
				}
			}}>
				<span>설정 열기</span>
			</button>
		</div>
	);
};

export default LocationInfoUnableFooterView;