import { usePurchaseHistoryService } from './service/usePurchaseHistoryService';
import PurchaseHeaderView from './views/PurchaseHeaderView';
import PurchaseListView from './views/PurchaseListView';

const PurchaseHistoryContainer = () => {
	const { managerHistory } = usePurchaseHistoryService();

	return (
		<div className="wrap rightShow footerNone active">
			<header className="cateHeader">
				<PurchaseHeaderView />
			</header>

			<div className="popConWrap managerhWrap">
				<div className="inner">
					<div className="managerHistory">
						<PurchaseListView managerHistoryList={managerHistory} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PurchaseHistoryContainer;
