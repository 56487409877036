import BlockAcquaintanceFooterView from './views/BlockAcquaintanceFooterView';
import BlockAcquaintanceWrapView from './views/BlockAcquaintanceWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import {useBlockAcquaintanceService} from "@dating/ui/pages/settings/blockAcquaintance/useBlockAcquaintanceService";
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import { useCommonConfirmPopService } from "@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService";

const BlockAcquaintanceContainer = () => {
    const {
        title,
        fnSaveBlockAcquaintance,
        blockCount,
        blockTf,
        setBlockTf,
        unBlockCount,
        unBlockTf,
        setUnBlockTf,
        fnSaveUnblockPhoneNumberUser,
        blockedNumberList,
    } = useBlockAcquaintanceService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    const {
        fnCommonConfirmPopOn,
    } = useCommonConfirmPopService();

    return (
        <div className="rightShow active">
            <ButtonHeaderView title={title} right={false}/>
            <BlockAcquaintanceWrapView/>
            <BlockAcquaintanceFooterView
                fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                fnSaveBlockAcquaintance={fnSaveBlockAcquaintance}
                fnCommonAlertPopOn={fnCommonAlertPopOn}
                fnSaveUnblockPhoneNumberUser={fnSaveUnblockPhoneNumberUser}
                blockedNumberList={blockedNumberList}
                blockCount={blockCount}
                blockTf={blockTf}
                setBlockTf={setBlockTf}
                unBlockCount={unBlockCount}
                unBlockTf={unBlockTf}
                setUnBlockTf={setUnBlockTf}
            />
        </div>
    );
};
export default BlockAcquaintanceContainer;
