import HeaderRollingView from "@dating/ui/pages/header/views/HeaderRollingView";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React from "react/index";
import {useLoungeHeaderService} from "@dating/ui/pages/lounge/useLoungeHeaderService";
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useTranslation} from "react-i18next";

export const FeedListHeaderView = () => {

    const {
        myProfile,
        srcPrefix,
        setMyProfileOpen,
        openSearchBox,
        setOpenSearchBox,
        keyword,
        setKeyword,
    } = useLoungeHeaderService();

    const navigate = useNavigate();

    const location = useLocation();

    const { t } = useTranslation('lounge');

    return (
        <>
            {
                location.pathname != PATH.LOUNGE.SEARCH_LIST &&
                <header className="cateHeader">
                    <div className="headerWrap">
                        <div className="h-left">
                            <HeaderRollingView/>
                        </div>
                        <div className="h-center">

                        </div>
                        <div className="h-right">
                            <div className={`schWrap ${openSearchBox ? 'on' : ''}`}>
                                <input
                                    type="text"
                                    className="sch"
                                    value={keyword}
                                    onKeyUp={(e) => {
                                        const input = e.target.value;
                                        if (e.keyCode === 8 && input.length === 0) {
                                            return;
                                        }
                                    }}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (input.length > 0) {
                                            setKeyword(input.trim());
                                        } else if (input.length === 0) {
                                            setKeyword("");
                                        }
                                    }}
                                />
                                <a
                                    className="schBtn schOpen"
                                    onClick={(e) => {
                                        setOpenSearchBox(!openSearchBox);
                                        setKeyword("")
                                    }}
                                >
                                    <span className="hide">펼치기</span>
                                </a>
                            </div>

                            <Link
                                to={myProfile.role === 'NORMAL' ? PATH.PROFILE.MY_PROFILE : PATH.MANAGER.MY_PROFILE}
                                onClick={() => {
                                    //내 프로필 open
                                    setMyProfileOpen(true);
                                }}
                            >
                                <button className="profileSmImg">
                                    <img
                                        src={
                                            myProfile && myProfile?.profileUrl[0]
                                                ? srcPrefix + myProfile?.profileUrl[0]
                                                : '/assets/img/test/boy.png'
                                        }
                                        alt={myProfile.nickName}
                                    />
                                </button>
                            </Link>
                        </div>
                    </div>
                </header>
            }
            {
                location.pathname == PATH.LOUNGE.SEARCH_LIST &&
                // 나중에 헤더 통합
                <header>
                    <div className="headerWrap">
                        <div className="h-left">
                            <button type="button"
                                    className="btnBack"
                                    onClick={() => {
                                        // setOpenMenuPop(false);
                                        // initProfile();
                                        navigate(-1);
                                    }}>
                                <span className="hide">뒤로가기</span>
                            </button>
                        </div>

                        <div className="h-center">
                            <p className="title02">{t('lookingLounge')}</p>
                        </div>
                        <div className="h-right">
                        </div>
                    </div>
                </header>
            }
        </>
    );
};
