import Phaser from 'phaser';
import { phaserEventEmitter } from '../../events/PhaserEventEmitter';
import WebRTC from '../../web/WebRTC';
import MyPlayer from '../myplayer/MyPlayer';
import Player from '../player/Player';
import { OtherPlayerMove } from './OtherPlayerMove';
import { OtherPlayerState } from './OtherPlayerState';

export default class OtherPlayer extends Player {
	private targetPosition: [number, number];
	private lastUpdateTimestamp?: number;
	private connectionBufferTime = 0;
	private connected = false;
	private myPlayer?: MyPlayer;
	public otherPlayerMove: OtherPlayerMove;
	public otherPlayerState: OtherPlayerState;

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		texture: string,
		id: string,
		uuid: string,
		name: string,
		statusMessage: string,
		audioStatus: boolean,
		profileUrl: string,
		role: string,
		frame?: string | number,
	) {
		super(
			scene,
			x,
			y,
			texture,
			id,
			uuid,
			name,
			statusMessage,
			audioStatus,
			profileUrl,
			role,
			frame,
		);

		this.targetPosition = [x, y];
		this.playerUUID = uuid;
		this.playerType = role;
		this.playerName!.textName = name;
		this.playerStatusMessage = statusMessage;
		this.audioStatus = audioStatus;
		this.profileUrl = profileUrl;
		this.otherPlayerMove = new OtherPlayerMove(this, this.targetPosition);
		this.otherPlayerState = new OtherPlayerState(this);
	}

	// 다른 플레이어와의 연결 시도
	makeCall(myPlayer: MyPlayer, webRTC?: WebRTC) {
		this.myPlayer = myPlayer;
		if (
			!this.connected &&
			this.connectionBufferTime >= 750 &&
			myPlayer.readyToConnect &&
			this.readyToConnect &&
			myPlayer.mediaConnected
			// myPlayer.mediaConnected &&
			// myPlayerId > this.id
		) {
			if (!webRTC) return;
			webRTC.connectToNewUser(this.id);
			this.connected = true;
			this.connectionBufferTime = 0;
		}
	}

	// 다른 플레이어의 상태 업데이트
	updateOtherPlayer(field: string, value: number | string | boolean) {
		switch (field) {
			case 'uuid':
				if (typeof value === 'string') {
					this.playerUUID = value;
				}
				break;
			case 'name':
				if (typeof value === 'string') {
					this.playerName!.textName = value;
				}
				break;

			case 'x':
				if (typeof value === 'number') {
					this.targetPosition[0] = value;
				}
				break;

			case 'y':
				if (typeof value === 'number') {
					this.targetPosition[1] = value;
				}
				break;

			case 'anim':
				if (typeof value === 'string') {
					this.playWrab(value, true);
				}
				break;

			case 'readyToConnect':
				if (typeof value === 'boolean') {
					this.readyToConnect = value;
				}
				break;

			case 'mediaConnected':
				if (typeof value === 'boolean') {
					this.mediaConnected = value;
				}
				break;

			case 'statusMessage':
				if (typeof value === 'string') {
					this.playerStatusMessage = value;
				}
				break;
			case 'audioStatus':
				if (typeof value === 'boolean') {
					this.audioStatus = value;
				}
				break;
			case 'profileUrl':
				if (typeof value === 'string') {
					this.profileUrl = value;
				}
				break;
			case 'role':
				if (typeof value === 'string') {
					this.playerType = value;
				}
				break;
		}
	}

	// 객체가 파괴될 때 호출
	destroy(fromScene?: boolean) {
		this.playerContainer!.destroy();

		super.destroy(fromScene);
	}

	/** PreUpdate는 모든 게임 객체에 대해 모든 프레임으로 호출 됨 */
	preUpdate(t: number, dt: number) {
		super.preUpdate(t, dt);

		// Phaser가 1초 이상 캔버스를 업데이트하지 않은 경우(게임 탭이 활성화되지 않은 경우) 플레이어를 현재 위치로 직접 스냅
		if (this.lastUpdateTimestamp && t - this.lastUpdateTimestamp > 750) {
			this.lastUpdateTimestamp = t;
			this.x = this.targetPosition[0];
			this.y = this.targetPosition[1];
			this.playerContainer!.x = this.targetPosition[0];
			this.playerContainer!.y = this.targetPosition[1] - 30;
			return;
		}

		this.lastUpdateTimestamp = t;
		// player.depth를 player.y를 기준으로 변경
		this.setDepth(this.y);
		const { vx, vy } = this.otherPlayerMove.getVelocity(dt);
		this.setVelocity(vx, vy);
		this.body?.velocity.setLength(this.otherPlayerMove.speed);

		// 현재 내 플레이어에 연결되어 있는 동안 내 플레이어와 다른 플레이어가 겹치지 않으면 비디오 스트림 삭제
		this.connectionBufferTime += dt;
		if (
			this.connected &&
			!this.body?.embedded &&
			this.body?.touching.none &&
			this.connectionBufferTime >= 750
		) {
			if (
				this.x < 1000 &&
				this.y > 1000 &&
				this.myPlayer!.x < 1000 &&
				this.myPlayer!.y > 1000
			)
				return;
			phaserEventEmitter.emit('player-disconnected', this.id);
			this.connectionBufferTime = 0;
			this.connected = false;
		}
		if (!this.anims.currentAnim) return;

		this.otherPlayerState.update();
	}
}
