import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useEffect } from 'react';

export const useReferralCodeService = () => {
	const {
		myInviteCode,
		setMyInviteCode,
	} = useDatingRepo();

	const {
		getMyRecommender,
	} = useRegisterAdapter();

	useEffect(() => {
		getMyRecommender().then(value => {
			setMyInviteCode(value);
		});
	}, []);

	return {
		myInviteCode,
	};
};