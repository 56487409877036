import { useEffect } from 'react';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useAuthService } from '@dating/ui/components/auth/useAuthService';
import { useNavigate } from 'react-router-dom';
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useAuthRepo} from "@dating/repository/auth/useAuthRepo";

//
// AuthSuccessContainer.tsx
// 로그인(+SNS) 결과 처리
//
export const AuthSuccessContainer = () => {

	const {
		authToken,
		deviceId,
		fcmToken,
	} = useAuthRepo();

	const {
		SNSInfo,
		setSNSInfo,
		setAuthToken,
		toMain,
		toPhone,
		fnAutoLogin,
	} = useAuthService();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const {
		fnCommonConfirmPopOn,
	} = useCommonConfirmPopService();

	const navigate = useNavigate();

	useEffect(() => {
		if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
			navigate(PATH.DATING.HOME);
		}
	},[authToken])

	useEffect(() => {
		const resultCode = new URLSearchParams(location.search).get('resultCode');

		if (resultCode === 'success') {
			const accessToken = new URLSearchParams(location.search).get('accessToken');
			const refreshToken = new URLSearchParams(location.search).get('refreshToken');

			if (accessToken && refreshToken) {
				const token = {accessJmt: accessToken, refreshJmt: refreshToken,}
				setAuthToken(token);
			}

		} else if (resultCode === 'off') {
			// 연동 해제된 게정
			fnCommonAlertPopOn(
				'연결되어 있는 계정이 없습니다.\r\n'+
				'전화번호 로그인 후\r\n'+
				'설정>계정관리에서\r\n'+
				'SNS계정을 연결해 주세요.'
			);
			toMain();

		} else if (resultCode === 'empty') {
			const tmp = SNSInfo;
			new URLSearchParams(location.search).forEach((value, key) => {
				tmp[key] = value;
			});
			if (tmp.id != '' && tmp.email != '') {
				setSNSInfo(tmp);
				// 미가입 사용자 or 연동정보가 없는 사용자
				fnCommonConfirmPopOn(
					'SNS연동 로그인을 하려면\r\n'+
					'회원임이 확인되어야 합니다.\r\n'+
					'로그인을 진행해 주세요.'
					, toPhone, null
					, toMain, null);
			}
		} else if (resultCode === 'fail') {
			// 기타 에러
			fnCommonAlertPopOn(
				'오류가 발생했습니다.\r\n'+
				'관리자에게 문의하세요.'
			);
			toMain();
		}
	}, []);


	useEffect(() => {
		if ((window as any).flutter_inappwebview) {
			//앱
			if (
				deviceId != '' &&
				fcmToken != '' &&
				authToken.accessJmt != '' &&
				authToken.refreshJmt != ''
			) {
				fnAutoLogin(deviceId, fcmToken);
			}
		} else {
			// 웹
			if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
				fnAutoLogin(deviceId, fcmToken);
			}
		}
	}, [deviceId, fcmToken, authToken]);

	return null;
};