import {PostInfoCOVO} from "@/site/api";
import {Link} from "react-router-dom";
import {PATH} from "@dating/constants/RoutingEndPoints";

interface props {
    post: PostInfoCOVO;
    srcPrefix: string;
    setSingleFeedId: (singleFeedId: string) => void;
    formatDate: (isoDate: string) => string;
}

const ProfileFeedView = ({
                             post,
                             srcPrefix,
                             setSingleFeedId,
                             formatDate
                         }: props) => {
    return (
        <>
            <div className="loungeItem">

                <Link to={PATH.LOUNGE.SINGLE}
                      onClick={() => {
                          setSingleFeedId(post.postId);
                      }}
                >
                <div className="imgBox">
                    <img src={srcPrefix + post?.filesUrl[0]} />;
                </div>
                <div className="txtBox">
                    <p className="txt01 ellipsis4">{post.content}</p>
                    <div className="tagList ellipsis">
                        {post.tag?.map((item, i) => {
                            return (
                                <span key={item + i}>
                                    {item}
                                </span>
                            );
                        })}
                    </div>
                    <p className="date">{formatDate(post.createTime)}</p>
                </div>
                </Link>
            </div>
        </>
    );
};

export default ProfileFeedView;