import useLeaveChatStore from '@dating/stores/LeaveChatStore';
import { useTranslation } from 'react-i18next';

interface props {
	setOpenLeaveChat: (openLeaveChat: boolean) => void;
}
const LeaveChatContainer = ({ setOpenLeaveChat }: props) => {
	const { t } = useTranslation('messenger');
	const { openChatMenuPop } = useLeaveChatStore();
	return (
		<div className={`${openChatMenuPop ? 'menuPop active' : 'menuPop'}`}>
			<button
				className="color-red"
				type="button"
				onClick={() => {
					setOpenLeaveChat(true);
				}}
			>
				{t('leaveRoom')}
			</button>
		</div>
	);
};

export default LeaveChatContainer;
