import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import {useTranslation} from "react-i18next";

interface props {
	fnSaveUserLike: (param: {
		userUid: string;
		state: boolean;
		productId: number;
		orderId: number;
	}) => void;
	srcPrefix: string;
	activeItem: {
		userUid: string;
		profileUrl: string;
		nickName: string;
	};
	fnSendLikePopClose: () => void;
	fnValidateOrder: (callback: any, param: any) => void;
	productPack: {
		id: number;
		name: string;
		price: number;
		productType: string;
		productSubType: string;
	};
}

const LikeConfirmView = ({
	fnSaveUserLike,
	srcPrefix,
	activeItem,
	fnSendLikePopClose,
	fnValidateOrder,
	productPack,
}: props) => {
	const { orderResult } = useOrderRepo();
	const { t } = useTranslation('common');

	return (
		<div className="likeWrap">
			<div className="popupInfo padding-b16">
				<p
					style={{
						margin: '10px',
						fontSize: '24px',
						fontWeight: '700',
					}}
				>
					{t('likePop.likeSend')}
				</p>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '16px',
					}}
				>
					<div className="proImg">
						<img
							src={srcPrefix + activeItem?.profileUrl}
							alt={activeItem?.nickName}
						/>
					</div>
				</div>
				<p
					className="proFz20 margin-b8"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingBottom: '50px',
					}}
				>
					{activeItem?.nickName}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="btnBig btnCCC radiusNone"
						onClick={() => {
							fnSendLikePopClose();
						}}
					>
						<span
							style={{
								fontSize: '16px',
							}}
						>
							{t('likePop.later')}
						</span>
					</button>
					<button
						type="button"
						className="btnBig btnBlack radiusNone"
						onClick={() => {
							fnValidateOrder(fnSaveUserLike, {
								productId: productPack.id,
								userUid: activeItem.userUid,
								state: true,
								orderId: orderResult.orderId,
							});
							fnSendLikePopClose();
						}}
					>
						<span
							className="flexXYcenter"
							style={{
								fontSize: '16px',
							}}
						>
							{t('likePop.like')}&nbsp;
							<span
								style={{
									fontSize: '10px',
								}}
							>
								&nbsp;
								{/*<span>100</span>*/}
							</span>
							<span className="ic-berith">
								{productPack?.price ? productPack.price : 0}
							</span>
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LikeConfirmView;
