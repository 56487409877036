import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';

interface ButtonHeaderContainerProps {
	right: boolean;
	title?: string;
	berith?: number;
	link?: string;
}
const ButtonHeaderView = ({
	right,
	title,
	berith,
	link,
}: ButtonHeaderContainerProps) => {
	const navigate = useNavigate();

	const onClickBack = () => {
		navigate(-1);
	};

	return (
		<header>
			<div className="headerWrap">
				<div className="h-left">
					{!right && (
						<button type="button" className="btnBack" onClick={onClickBack} />
					)}
				</div>
				<div className="h-center">
					<p className="title02">{title}</p>
				</div>
				<div className="h-right">
					{link && (
						<Link to={PATH.SIDE_BAR.MAKE_ROOM}>
							<p className="ic-roomPlus">{link}</p>
						</Link>
					)}
					{berith && <p className="ic-berith sm">{berith}</p>}
					{right && (
						<button type="button" className="btnClose" onClick={onClickBack} />
					)}
				</div>
			</div>
		</header>
	);
};

export default ButtonHeaderView;
