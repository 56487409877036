import {
	MetaService,
	MetaUserAvatarCIVO,
	MetaUserCIVO,
	MetaUserMediaCIVO,
	MetaUserWebRTCCIVO,
} from '@/site/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useMetaUserAdapter = () => {
	const queryClient = useQueryClient();

	const saveMetaUser = useMutation({
		mutationFn: (map: MetaUserCIVO) => {
			return MetaService.saveMetaUser(map);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMetaUser'] });
			return data;
		},
	});

	/*
	 * getMyMetaUser
	 * 내 메타버스 유저 프로필 상세조회
	 */
	const getMyMetaUser = () => {
		return MetaService.getMyMetaUser();
	};

	/*
	 * getMetaUser
	 * 메타버스 유저 프로필 상세조회
	 */
	const getMetaUser = (findUserUid: string) => {
		return MetaService.getMetaUser(findUserUid);
	};

	/*
	 * saveMetaUserAvatar
	 * 메타버스 유저 아바타 정보 저장
	 */
	const saveMetaUserAvatar = useMutation({
		mutationFn: (map: MetaUserAvatarCIVO) =>
			MetaService.saveMetaUserAvatar(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMyMetaUser'] });
			return data;
		},
	});

	/*
	 * saveUserMediaConnected
	 * 메타버스 회원 미디어 권한 설정 저장
	 */
	// const saveUserMediaConnected = useMutation({
	// 	mutationFn: (map: MetaUserMediaCIVO) =>
	// 		MetaService.saveUserMediaConnected(map),
	// 	onSuccess: (data) => {
	// 		queryClient.invalidateQueries({ queryKey: ['getUserMediaConnected'] });
	// 		return data;
	// 	},
	// });

	/*
	 * getUserMediaConnected
	 * 메타버스 회원 미디어 권한 설정 조회
	 */
	// const getUserMediaConnected = useQuery({
	// 	queryKey: ['getUserMediaConnected'],
	// 	queryFn: () => MetaService.getUserMediaConnected(),
	// });

	/*
	 * saveUserWebRtcConnect
	 * 메타버스 회원 WebRTC 연결 설정 저장
	 */
	// const saveUserWebRtcConnect = useMutation({
	// 	mutationFn: (map: MetaUserWebRTCCIVO) =>
	// 		MetaService.saveUserWebRtcConnect(map),
	// 	onSuccess: (data) => {
	// 		queryClient.invalidateQueries({ queryKey: ['getUserWebRtcConnect'] });
	// 		return data;
	// 	},
	// });

	/*
	 * getUserWebRtcConnect
	 * 메타버스 회원 WebRTC 연결 설정 조회
	 */
	// const getUserWebRtcConnect = useQuery({
	// 	queryKey: ['getUserWebRtcConnect'],
	// 	queryFn: () => MetaService.getUserWebRtcConnect(),
	// });

	return {
		getMyMetaUser,
		getMetaUser,
		// statusGetUserMediaConnected: getUserMediaConnected.status,
		// getUserMediaConnected: getUserMediaConnected.data,
		// statusGetUserWebRtcConnect: getUserWebRtcConnect.status,
		// getUserWebRtcConnect: getUserWebRtcConnect.data,
		statusMetaUserAvatar: saveMetaUserAvatar.status,
		getMetaUserAvatar: saveMetaUserAvatar.data,

		saveMetaUser: saveMetaUser.mutate,
		saveMetaUserAvatar: saveMetaUserAvatar.mutate,
		// saveUserMediaConnected: saveUserMediaConnected.mutate,
		// saveUserWebRtcConnect: saveUserWebRtcConnect.mutate,
	};
};
