import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useEffect } from 'react';
import { useEditProfileRepo } from '@dating/repository/profile/useEditProfileRepo';
import { useQuery } from '@tanstack/react-query';
import { bufferArrayToBlobArray } from '@/utils/fileUtils';

export const useProfileEditService = () => {
	const {
		setFocusItem,
		editPop,
		setEditPop,
		clearEditPop,
		myModifyProfile,
		setMyModifyProfile,
		title,
		setModifyProfileUrl,
	} = useEditProfileRepo();

	const { previewURLs, setPreviewURLs, clearPreviewURLs, setCropFiles } =
		useImgCropRepo();

	const {
		myProfileOpen,
		srcPrefix,
		setMyProfileOpen,
		myProfile,
	} = useDatingRepo();

	const { getModifyMyProfile } = useProfileAdapter();

	const { status: statusGetModifyMyProfile, data: dataGetModifyMyProfile } = useQuery({
		queryKey: ['getModifyMyProfile'],
		queryFn: () => {
			return getModifyMyProfile();
		},
		enabled: myProfileOpen,
	});
	////////////////////////////////////////////////

	/*
	 * openPopupHandler
	 * 항목별 팝업 호출
	 */
	const openPopupHandler = (detail) => {
		if (detail.editable && editPop[detail.key] != null) {
			// popup handle
			clearEditPop();
			const newItem = {
				...editPop,
				[detail.key]: true,
			};
			setEditPop(newItem);

			//content handle
			setFocusItem({
				label: detail.key,
				value: detail.code ? detail.code : detail.value,
			});
		}
	};

	useEffect(() => {
		if (statusGetModifyMyProfile === 'success' && dataGetModifyMyProfile) {
			setMyModifyProfile(dataGetModifyMyProfile);
			// if (previewURLs.length === 0 && myProfile?.profileUrl.length > 0) {
			const result = bufferArrayToBlobArray(
				dataGetModifyMyProfile.profileFiles,
			);
			setPreviewURLs(result.urls);
			setCropFiles(result.blobs);
			// }

			setModifyProfileUrl(true);
		}
	}, [statusGetModifyMyProfile, dataGetModifyMyProfile]);

	return {
		title,
		previewURLs,
		clearPreviewURLs,
		myModifyProfile,
		srcPrefix,
		openPopupHandler,
		setMyProfileOpen,
		myProfile,
	};
};
