import { useEffect, useRef } from 'react';
import MeetingPeopleListView from './MeetingPeopleListView';

const RoomListItemView = ({
	t,
	role,
	myMetaUser,
	myGender,
	headCount,
	locked,
	roomTheme,
	roomKey,
	roomTitle,
	remainingTime,
	players,
	roomItem,
	hasPassword,
	joinedRoomData,
	setRoomItem,
	roomListOpen,
	handleJoinClick,
	handleClickMeta,
	isJoinedRoom,
	copyRoomInfoToClipboard,
	createdBy,
}) => {
	const selectedItem = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!roomListOpen) {
			setRoomItem(false);
		} else {
			selectedItem.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}
	}, [roomListOpen]);

	const openRoomInfo = () => {
		const clickedItem = selectedItem.current;
		const isActive = clickedItem?.classList.contains('active');

		if (isActive) {
			clickedItem?.classList.remove('active');
			return;
		}

		const roomItems = document.querySelectorAll('.roomListItem');
		roomItems.forEach((roomItem) => {
			roomItem.classList.remove('active');
		});
		clickedItem?.classList.add('active');

		selectedItem.current?.scrollIntoView({
			block: 'center',
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (remainingTime === 1 && roomKey === joinedRoomData.id) {
			handleClickMeta(true);
		}
	}, [remainingTime]);

	const getStatusClass = () => {
		if (isJoinedRoom) return 'ing';
		if (locked) return 'ing';
		if (headCount === 3) {
			const menCount = players.filter((player) => player.gender === 'M').length;
			const womenCount = players.filter(
				(player) => player.gender === 'F',
			).length;
			if (menCount >= 1 && womenCount >= 1) return 'ing';
		}
		return t('waiting');
	};

	const getStatusText = () => {
		if (isJoinedRoom) return t('participating');
		if (locked) return t('inProgress');
		if (headCount === 3) {
			const menCount = players.filter((player) => player.gender === 'M').length;
			const womenCount = players.filter(
				(player) => player.gender === 'F',
			).length;
			if (menCount >= 1 && womenCount >= 1) return t('inProgress');
		}
		return t('waiting');
	};

	const showCopyButton = role === 'MANAGER' && createdBy === 'MANAGER';

	return (
		<div
			className={`roomListItem ${roomItem ? 'active' : ''}`}
			ref={selectedItem}
		>
			<div className="roomListItemTit" onClick={openRoomInfo}>
				<div className="left">
					<div className={`statusBag ${getStatusClass()}`}>
						<span>{getStatusText()}</span>
					</div>
					<p className="fz14Fw700 roomNames">
						{roomTitle}
						{showCopyButton && (
							<button
								className="ic-copy"
								onClick={(e) => {
									e.stopPropagation(); // 이벤트 전파 중단
									copyRoomInfoToClipboard({
										roomId: roomKey,
										roomTheme,
										hasPassword,
									});
								}}
							/>
						)}
					</p>
				</div>
				<div className="right">
					<span className="hdCount">
						{headCount === 4 ? '2:2' : headCount === 3 ? '1:1' : '3:3'}
					</span>
				</div>
			</div>
			<MeetingPeopleListView
				t={t}
				createdBy={createdBy}
				myMetaUser={myMetaUser}
				myGender={myGender}
				roomKey={roomKey}
				locked={locked}
				roomTheme={roomTheme}
				players={players}
				hasPassword={hasPassword}
				headCount={headCount}
				joinedRoomData={joinedRoomData}
				handleClickMeta={handleClickMeta}
				handleJoinClick={handleJoinClick}
				remainingTime={remainingTime}
			/>
		</div>
	);
};

export default RoomListItemView;
