import { HomeMatchingCOVO } from '@/site/api';
import React, { MutableRefObject, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

interface props {
	index: number;
	location: 'hushPick' | 'today';
	likeIndex: MutableRefObject<number>;
	profile: HomeMatchingCOVO;
	srcPrefix: string;
	setUserProfileOpen: (userProfileOpen: string) => void;
	currentUserUid: string;
	setOpenPaymentPop: (openPaymentPop: boolean) => void;
	fnSendLikePopOpen: (userUid: string, profileUrl: string, nickName: string, location: '' | 'hushPick' | 'today' | 'receive' | 'highRated' | 'send' | 'profile') => void;
	sliderSettings: {};
	setMessageTargetUser: (messageTargetUser: string) => void;
}

const ProfileBoxView = ({
							index,
							likeIndex,
							location,
							profile,
							srcPrefix,
							setUserProfileOpen,
							currentUserUid,
							setOpenPaymentPop,
							fnSendLikePopOpen,
							sliderSettings,
							setMessageTargetUser,
						}: props) => {

	const [likeBtnOn, setLikeBtnOn] = useState<boolean>(false);

	const { t } = useTranslation('profile');

	return (
		<>
			<div className="profileBox">
				<div className="profileSlide">
					<Link
						to={PATH.PROFILE.USER_PROFILE}
						onClick={() => {
							setUserProfileOpen(profile.matchingUserUid);
						}}
						state={{
							isOtherProfile: profile.matchingUserUid !== currentUserUid,
						}}
					>
						<Slider {...sliderSettings}>
							{
								profile.profileUrls.map((item, i) => {
									return (
										<img
											key={item + i}
											src={srcPrefix + item}
											alt={profile.nickName}
										/>
									);
								})
							}
						</Slider>
					</Link>

				</div>
				<div className="txtBox">
					{
						profile.hasBadged ?
							<span className="addAuthBadge">{t('profileBox.addBadge')}</span>
							: null
					}
					<p className="txt01 fz20 color-fff">{profile.nickName}</p>
					<p className="txt02 fz16 color-fff">
						{profile.age + t('profileBox.age')}, {profile.region1 && profile.region2  ? `${profile.region1} ${profile.region2}` : t('profileBox.noRegion')}
					</p>
					<div className="profileLikeBox">
						{/*좋아요 버튼*/}
						<button
							type="button"
							className={`likeBtn ${profile.likeYn || likeBtnOn ? 'on' : ''}`}
							onClick={(e) => {
								if (!e.currentTarget.classList.contains('on') && profile.matchingUserUid !== '') {
									setLikeBtnOn(true);
									likeIndex.current = index;
									fnSendLikePopOpen(profile.matchingUserUid, profile.profileUrls[0], profile.nickName, location);
								}
							}}
						/>

						{/*메세지 보내기 버튼*/}
						<button
							type="button"
							className="shareBtn"
							onClick={() => {
								if (profile.matchingUserUid !== '') {
									setOpenPaymentPop(true);
									setMessageTargetUser(profile.matchingUserUid);
								}
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileBoxView;
