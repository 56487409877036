import {
	Route,
	Routes,
} from 'react-router-dom';
import { AuthSuccessContainer } from '@dating/ui/components/auth/AuthSuccessContainer';
import { InactiveUserContainer } from '@dating/ui/components/auth/InactiveUserContainer';
import { errorHandlerUtils } from '@/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';

const AuthRouter = () => {
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Routes location={location}>
				<Route path="success" element={<AuthSuccessContainer />} />
				<Route path="inactive" element={<InactiveUserContainer />} />
			</Routes>
		</ErrorBoundary>
	);
};

export default AuthRouter;