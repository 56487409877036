import MediaBtnWrapContainer from './mediaBtn/MediaBtnWrapContainer';
import { UserProfileCOVO } from '@/site/api';
import RoomListWrapView from './views/RoomListWrapView';
import SmallAvatarProfileView from './views/SmallAvatarProfileView';

interface props {
	myProfile: UserProfileCOVO;
	srcPrefix: string;
	isPublicRoom: boolean;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
}

// 메타버스 헤더
const HeaderContainer = ({ isPublicRoom }: props) => {
	return (
		<header className="metaHeader">
			<div className="headerWrap">
				<div className="h-left" />
				<div className="h-right">
					{!isPublicRoom && <MediaBtnWrapContainer />}
					<RoomListWrapView />
					<SmallAvatarProfileView />
				</div>
			</div>
		</header>
	);
};

export default HeaderContainer;
