/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppleTokenSIVO } from '../models/AppleTokenSIVO';
import type { GoogleLoginCIVO } from '../models/GoogleLoginCIVO';
import type { JwtCOVO } from '../models/JwtCOVO';
import type { SocialLoginStatusCOVO } from '../models/SocialLoginStatusCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * 자동 로그인
     * @param deviceId device Id
     * @param fcmToken fcm token
     * @returns any 자동 로그인 - success : 성공, fail : 실패
     * @throws ApiError
     */
    public static autoLogin(
        deviceId?: string,
        fcmToken?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/autoLogin',
            query: {
                'deviceId': deviceId,
                'fcmToken': fcmToken,
            },
        });
    }
    /**
     * SNS 로그인 링크
     * @param provider
     * @returns any SNS 로그인 링크
     * @throws ApiError
     */
    public static oauthLoginInfos(
        provider: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/oauth/links/{provider}',
            path: {
                'provider': provider,
            },
        });
    }
    /**
     * SNS 로그인 결과
     * @param provider
     * @param code oauth 인증 코드
     * @returns any SNS 로그인 결과 - 성공 | 연동해제된 계정 | SNS 로그인 실패
     * @throws ApiError
     */
    public static oauthLogin(
        provider: string,
        code: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/oauth/{provider}',
            path: {
                'provider': provider,
            },
            query: {
                'code': code,
            },
        });
    }
    /**
     * Apple 로그인 결과
     * @param requestBody
     * @returns any Apple 로그인 결과 - 성공 | 연동해제된 계정 | SNS 로그인 실패
     * @throws ApiError
     */
    public static appleLogin(
        requestBody: AppleTokenSIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/oauth/apple',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 토큰 갱신
     * @param refreshtoken 리프레시 토큰
     * @returns JwtCOVO 토큰 갱신 결과
     * @throws ApiError
     */
    public static refreshToken(
        refreshtoken: string,
    ): CancelablePromise<JwtCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/token/refresh',
            headers: {
                'refreshtoken': refreshtoken,
            },
        });
    }
    /**
     * 구글 로그인 정보 저장 후 결과 반환
     * @param requestBody 구글 로그인 정보
     * @returns SocialLoginStatusCOVO 구글 로그인 연동 정보
     * @throws ApiError
     */
    public static googleLogin(
        requestBody: GoogleLoginCIVO,
    ): CancelablePromise<SocialLoginStatusCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/oauth/googleLogin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
