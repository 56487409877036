import { CSSTransition } from 'react-transition-group';
import FeedListView from './views/FeedListView';
import { useFeedListService } from '@dating/ui/pages/lounge/feedList/service/useFeedListService';
import { Link, useLocation } from 'react-router-dom';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import { useFeedFormService } from '@dating/ui/components/feedFrom/service/useFeedFormService';
import FeedCommentView from '@dating/ui/pages/lounge/feedComment/views/FeedCommentView';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import FeedHidePopView from '@dating/ui/pages/lounge/feedList/views/FeedHidePopView';
import {useEffect, useRef} from 'react';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import {PullToRefresh} from "@/utils/PullToRefreshUtils";
import {InfiniteScroll} from "@/utils/scrollUtils";
import {useTranslation} from "react-i18next";

const FeedListContainer = () => {
	const {
		fadeIn,
		postList,
		deleteContent,
		commentListHandler,
		commentViewHandler,
		openComment,
		fnSaveComment,
		focusedPostId,
		commentResponse,
		commentValue,
		setCommentValue,
		postUser,
		setUserProfileOpen,
		fnSavePostLike,
		openFeedHidePop,
		setOpenFeedHidePop,
		fnSaveBlockPost,
		setBlockPostId,
		fnGetPosts,
		sliderSettings,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		fnDeleteComment,
		handleScrollY,
		setIsTopBtnVisible,
		isTopBtnVisible,
		scrollY,
		hasMore,
		setHasMore,
		setFadeIn,
		setPostList,
		setPostListAdd,
		pageArray,
		setPageArray,
		setPageArrayReset,
		myProfileOpen,
		setFocusedPostId,
		observeCard,
		setObserveCard,
		isSearchPost,
		setIsSearchPost,
	} = useFeedListService();

	const {
		openMenuPop,
		openReport,
		reportPopClose,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		setBlockUserUid,
		reportPopOpen,
		fnSaveReport,
		hidePopOpen,
		hidePopClose,
	} = useBlockReportService();

	const { myProfile, srcPrefix, setMyProfileOpen } = useDatingRepo();

	const { focusingId, setFocusingId, scrollWrapRef, target, setTarget, haveToRefresh, setHaveToRefresh} = useLoungeRepo();

	const { initFeedForm, setModifyPostId } = useFeedFormService();

	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();

	const feedTop = useRef<HTMLDivElement>(null);

	const location = useLocation();

	const { t } = useTranslation('lounge')

	useEffect(() => {
		const feedWrap = scrollWrapRef.current;
		if (feedWrap) {
			if (feedWrap.scrollTop > 200) {
				setIsTopBtnVisible(true);
			} else {
				setIsTopBtnVisible(false);
			}
		}
	}, [scrollY]);

	useEffect(() => {
		const feedWrap = scrollWrapRef.current;
	
		// 스크롤 이벤트 핸들러 정의
		const onScroll = () => {
			if (feedWrap) {
				handleScrollY(feedWrap.scrollTop);
	
				// 200px 이상 스크롤 시 버튼 보이기
				if (feedWrap.scrollTop > 200) {
					setIsTopBtnVisible(true);
				} else {
					setIsTopBtnVisible(false);
				}
			}
		};
	
		// 이벤트 리스너 추가
		if (feedWrap) {
			feedWrap.addEventListener('scroll', onScroll);
		}
	
		// 정리(cleanup) 함수
		return () => {
			if (feedWrap) {
				feedWrap.removeEventListener('scroll', onScroll);
			}
		};
	}, [scrollWrapRef, handleScrollY]);
	
	// 스크롤 상단 이동 함수
	const handleScrollToTop = () => {
		const feedWrap = scrollWrapRef.current;
		if (feedWrap) {
			feedWrap.scrollTo({
				top: 0,
				behavior: 'smooth', // 부드럽게 스크롤
			});
		}
	};

	const urlParams = new URLSearchParams(location.search);
	const id: string = urlParams.get('id') ?? '';
	useEffect(() => {
		if (location.pathname == PATH.LOUNGE.SEARCH_LIST) {
			const element = document.getElementById(focusingId ? focusingId : id);
			setFocusingId("")	//초기화
			element?.scrollIntoView({ behavior: 'auto' });
		}
	}, [postList]);

	useEffect(()=>{
		setFadeIn(true)
		setMyProfileOpen(false);
	},[])


	return (
		<>
			<InfiniteScroll
				fnGetPosts={fnGetPosts}
				hasMore={hasMore}
				target={target}
				setTarget={setTarget}
				myProfileOpen={myProfileOpen}
				setFadeIn={setFadeIn}
				postList={postList}
				setPostList={setPostList}
				setPostListAdd={setPostListAdd}
				pageArray={pageArray}
				setPageArray={setPageArray}
				initFeedEditPops={initFeedEditPops}
				isSearchPost={isSearchPost}
				setIsSearchPost={setIsSearchPost}
				haveToRefresh={haveToRefresh}
			>
			{postList.length > 0 &&
			<PullToRefresh
				setList={fnGetPosts}
				setPageArrayReset={setPageArrayReset}
				hasMore={hasMore}
				setHasMore={setHasMore}
				scrollWrapRef={scrollWrapRef}
				setObserveCard={setObserveCard}
			>
				<div
					id="conWrap"
					className="metaPopWrap"
					ref={feedTop}
					onScroll={(e) => {
						handleScrollY(e.currentTarget?.scrollTop);
					}}
					style={{
						overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
					}}
				>
					<div className="feedWrap">
						<div
							className={`metaPopContent feedList spinnerContent ${
								fadeIn ? 'fade-in' : ''
							}`}
							onClick={() => {
								initFeedEditPops(postList);
							}}
						>
							{postList?.map((post,i) => {
									return (
										<FeedListView
											key={i + post.postNickName + post.postId}
											post={post}
											deleteContent={deleteContent}
											myProfile={myProfile}
											menuDotClickHandler={menuDotClickHandler}
											setOpenFeedHidePop={setOpenFeedHidePop}
											commentListHandler={commentListHandler}
											openMenuPop={openMenuPop}
											sliderSettings={sliderSettings}
											srcPrefix={srcPrefix}
											commentCount={post.commentCount}
											commentViewHandler={commentViewHandler}
											setUserProfileOpen={setUserProfileOpen}
											fnSavePostLike={fnSavePostLike}
											setBlockUserUid={setBlockUserUid}
											currentUserUid={myProfile?.userUid}
											setModifyPostId={setModifyPostId}
											setMyProfileOpen={setMyProfileOpen}
											fnCommonConfirmPopOn={fnCommonConfirmPopOn}
											setBlockPostId={setBlockPostId}
											fnFeedEditHandler={fnFeedEditHandler}
											feedEditPops={feedEditPops}
											reportPopOpen={reportPopOpen}
											setFocusedPostId={setFocusedPostId}
											hidePopOpen={hidePopOpen}
											observeCard={observeCard}
											setObserveCard={setObserveCard}
											isSearchPost={isSearchPost}
											haveToRefresh={haveToRefresh}
											setHaveToRefresh={setHaveToRefresh}
										/>
									);
								}
							)
							}
							{
								!isSearchPost &&
								<div ref={target} style={{width: '100%', height: 50}}/>
							}

							{/* 댓글창 */}
							<div className={`${openComment ? 'commentPopOn' : 'commentPop'}`}>
								<div
									className="commentDim"
									onClick={() => {
										commentListHandler('', { userUid: '', nickname: '' });
										commentViewHandler(false);
									}}
								></div>
								<CSSTransition
									in={openComment}
									classNames="commentInfo-transition"
									timeout={100}
								>
									<FeedCommentView
										key={focusedPostId}
										focusedPostId={focusedPostId}
										myProfile={myProfile}
										fnSaveComment={fnSaveComment}
										commentResponse={commentResponse}
										srcPrefix={srcPrefix}
										commentValue={commentValue}
										setCommentValue={setCommentValue}
										commentViewHandler={commentViewHandler}
										postUser={postUser}
										setMyProfileOpen={setMyProfileOpen}
										setUserProfileOpen={setUserProfileOpen}
										currentUserUid={myProfile?.userUid}
										fnCommonConfirmPopOn={fnCommonConfirmPopOn}
										fnDeleteComment={fnDeleteComment}
									/>
								</CSSTransition>
							</div>

							{/* 새 피드 작성 및 상단 이동 버튼 */}
							<div className="topBtnWrap">
								{isTopBtnVisible && (
									<button
										id="topBtn"
										className="topBtn"
										onClick={handleScrollToTop}
										style={{ display: 'block' }}
									>
										<span className="hide">{t('goUp')}</span>
									</button>
								)}
								{ !isSearchPost && (
									<Link to={PATH.LOUNGE.FORM} onClick={initFeedForm}>
										<button type="button" className="plusBtn" />
									</Link>
								)}
							</div>
						</div>
					</div>

					{/* 신고하기 */}
					<CSSTransition
						in={openReport}
						classNames="popWrap-transition"
						timeout={100}
					>
						<ReportPopView
							reportPopClose={reportPopClose}
							selectedOption={selectedOption}
							handleOptionSelect={handleOptionSelect}
							commonReportCodes={commonReportCodes}
							reportValue={reportValue}
							reportInputHandler={reportInputHandler}
							inputDisabled={inputDisabled}
							fnSaveReport={fnSaveReport}
						/>
					</CSSTransition>
				</div>

				<div className={`${openFeedHidePop ? 'settingPopOn' : 'settingPop'}`}>
					<div className="settingDim" />
					{/* 게시글 숨기기 */}
					<CSSTransition
						in={openFeedHidePop}
						classNames="blockPopWrap-transition"
						timeout={100}
					>
						<FeedHidePopView
							fnSaveBlockPost={fnSaveBlockPost}
							hidePopClose={hidePopClose}
						/>
					</CSSTransition>
				</div>
			</PullToRefresh>
			}
			{!(postList.length > 0) &&
			<>
				<div id="conWrap"
					 className="metaPopWrap"
					 style={{ overflow: 'hidden' }}
				>
					<div className='dataNoneTxt'>
						<p className='txt01'>{t('emptyPost')}</p>
					</div>
					<div className="feedWrap">
						<div className={`metaPopContent feedList`}>
							<div className="topBtnWrap">
								{isTopBtnVisible && (
									<button
										id="topBtn"
										className="topBtn"
										style={{display: 'block'}}
									>
										<span className="hide">{t('goUp')}</span>
									</button>
								)}
								{!isSearchPost && (
									<Link to={PATH.LOUNGE.FORM} onClick={initFeedForm}>
										<button type="button" className="plusBtn"/>
									</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
			}
			</InfiniteScroll>
		</>
	);
};

export default FeedListContainer;
