import {useTranslation} from "react-i18next";

interface props {
    fnVerifyVerificationCode: () => void;
    occupancyFooterViewStyle: {
        notReturnNumber: {
            display: string;
        };
        checkBtn: {
            display: string;
        };
    };
    fnSendVerificationCode: () => void;
}

const PhoneVerificationFooterView = ({
                                         fnVerifyVerificationCode,
                                         occupancyFooterViewStyle,
                                         fnSendVerificationCode,
                                     }: props) => {

    const { t } = useTranslation('register')

    return (
        <footer>
            <div
                className="f-btnWrap"
                style={occupancyFooterViewStyle.notReturnNumber}
            >
					<span
                        className="underline fz16 color-999 btn btnBig"
                        onClick={() => {
                            fnSendVerificationCode();
                        }}
                    >
						{t('occupancyStep.canNotGet')}
					</span>
            </div>
            <div className="f-btnWrap" style={occupancyFooterViewStyle.checkBtn}>
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnVerifyVerificationCode();
                    }}
                >
                    <span>{t('common.confirm')}</span>
                </button>
            </div>
        </footer>
    );
};

export default PhoneVerificationFooterView;
