import {useTranslation} from "react-i18next";

interface props {
    fnGetData: () => void;
    btnHandle: boolean;
};

const LikeMoreButtonView = ({
                                fnGetData,
                                btnHandle,
                            }: props) => {

    const { t } = useTranslation('like');

    return (
        <div className="padding-t8 padding-b16 text-c"
            style={{
                "display": btnHandle? "block" : "none"
            }}
        >
            <a
                className="color-999 underline bold-500"
                onClick={() => {
                    fnGetData();
                }}>
                {t('common.seeMore')}
            </a>
        </div>
    );
};

export default LikeMoreButtonView;
