import { create } from 'zustand';
import { AllSendUserLikeCOVO, HomeMatchingCOVO } from '@/site/api';

interface HomeState {
	// 탭 클릭 (home/pick)
	hushHome: boolean;
	setHushHome: (hushHome: boolean) => void;

	// 포인트 사용 안내 팝업 open/hide
	pointConfirmPopOpen: boolean;
	setPointConfirmPopOpen: (pointConfirmPopOpen: boolean) => void;

	// 포인트 사용 안내 팝업 message
	pointConfirmMessage: {tit1: string; tit2: string; price: number;};
	setPointConfirmMessage: (confirmPopMessage: {tit1: string; tit2: string; price: number;}) => void;
	initPointConfirmMessage: () => void;

	// 포인트 사용 안내 팝업 확인 함수
	pointConfirmFunc: {param: any, func: (param?: any) => any | void};
	setPointConfirmFunc: (pointConfirmFunc: {param: any, func: () => any}) => void;
	initPointConfirmFunc: () => void;
};

export const useHomeRepo = create<HomeState>(
	(set) => ({
		hushHome: true,
		setHushHome: (hushHome: boolean) => set({ hushHome }),

		// 포인트 사용 안내 팝업 open/hide
		pointConfirmPopOpen: false,
		setPointConfirmPopOpen: (pointConfirmPopOpen: boolean) => set({ pointConfirmPopOpen }),

		// 포인트 사용 안내 팝업 message
		pointConfirmMessage: {tit1: '', tit2: '', price: 30},
		setPointConfirmMessage: (pointConfirmMessage: {tit1: string; tit2: string; price: number;}) => set({pointConfirmMessage}),
		initPointConfirmMessage: () => set({pointConfirmMessage: {tit1: '', tit2: '', price: 30}}),

		// 포인트 사용 안내 팝업 확인 함수
		pointConfirmFunc: {param: null, func: (param: any) => null},
		setPointConfirmFunc: (pointConfirmFunc: {param: any, func: (param: any) => any}) => set({pointConfirmFunc}),
		initPointConfirmFunc: () => set({pointConfirmFunc: {param: null, func: (param: any) => null}}),
	}),
);