import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {LoungeService} from "@/site/api";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";

export const useLoungeModifyAdapter = (modifyPostId: string) => {
    const queryClient = useQueryClient();

    const {
        setHaveToRefresh,
        setPageArrayReset,
        setHasMore,
    } = useLoungeRepo();

    /*
     * modifyPost
     * 수정하기
     */
    const modifyPost = useMutation({
        mutationFn: (
            formData: {
                files?: Array<Blob>;
                postId?: string;
                content?: string;
                tag?: any[];
            }
        ) => {
            return LoungeService.modifyPost(formData);
        },
        onSuccess: (data) => {
            setHaveToRefresh(true)
            queryClient.invalidateQueries({queryKey: ['getPosts2']});
            setPageArrayReset()
        }
    });

    /*
     * getModifyPost
     * 수정할 포스트 조회
     */
    const getModifyPost = useQuery({
        queryKey: ["getModifyPost"],
        queryFn: () => {
            if (modifyPostId && modifyPostId != "") {
                return LoungeService.getModifyPost(modifyPostId);
            } else {
                return null;
            }
        },
    });

    /*
     * getSingleFeedDetail
     * 좋아요 탭 게시글 조회 (1건)
     */
    const getSingleFeedDetail = (postId: string) => {
        return LoungeService.getModifyPost(postId);
    };

    return {
        modifyPost: modifyPost.mutate,
        statusModifyPost: modifyPost.status,
        dataModifyPost: modifyPost.data,
        statusGetModifyPost: getModifyPost.status,
        dataGetModifypost: getModifyPost.data,
        getSingleFeedDetail,
    };
};