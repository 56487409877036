import {useState} from "react";
import type {
    ReceiveLikeUserCOVO,
    SendCommentPostCOVO,
    SendPostLikeCOVO,
    SendUserHighScoreCOVO, SendUserLikeCOVO,
} from '@/site/api';

export const useLikeSendStateRepo = () => {
    // 보낸 좋아요 더보기 버튼
    const [noDataUserLike, setNoDataUserLike] = useState<boolean>(false);

    // 보낸 좋아요 리스트
    const [likeUsers, setLikeUsers] = useState<SendUserLikeCOVO[]>([]);

    // 보낸 좋아요 페이징
    const [likeUserPage, setLikeUserPage] = useState<number>(1);

    // 높게 보낸 평가 더보기 버튼
    const [noDataHighlyRated, setNoDataHighlyRated] = useState<boolean>(false);

    // 높게 보낸 평가 리스트
    const [highlyRatedUsers, setHighlyRatedUsers] = useState<SendUserHighScoreCOVO[]>([]);

    // 높게 보낸 평가 페이징
    const [highlyRatedPage, setHighlyRatedPage] = useState<number>(1);

    // 좋아요 보낸 포스트 더보기 버튼
    const [noDataPost, setNoDataPost] = useState<boolean>(false);

    // 좋아요 보낸 포스트 리스트
    const [likedPosts, setLikedPosts] = useState<SendPostLikeCOVO[]>([]);

    // 좋아요 보낸 포스트 페이징
    const [likedPostPage, setLikedPostPage] = useState<number>(1);

    // 댓글 단 포스트 더보기 버튼
    const [noDataComments, setNoDataComments] = useState<boolean>(false);

    // 댓글 단 포스트 리스트
    const [commentPosts, setCommentPosts] = useState<SendCommentPostCOVO[]>([]);

    // 댓글 단 포스트 페이징
    const [commentPostsPage, setCommentPostsPage] = useState<number>(1);

    const [fadeIn, setFadeIn] = useState<boolean>(false);

    // 보낸 좋아요 리턴 리스트 길이가 0인 경우 호출 그만
    const [stopGetReceivedLike, setStopGetReceivedLike] = useState(false);

    // 높게 보낸 평가 리턴 리스트 길이가 0인 경우 호출 그만
    const [stopGetReceivedRating, setStopGetReceivedRating] = useState(false);

    return {
        stopGetReceivedRating,
        setStopGetReceivedRating,
        stopGetReceivedLike,
        setStopGetReceivedLike,
        noDataUserLike,
        setNoDataUserLike,
        likeUsers,
        setLikeUsers,
        likeUserPage,
        setLikeUserPage,
        noDataHighlyRated,
        setNoDataHighlyRated,
        highlyRatedUsers,
        setHighlyRatedUsers,
        highlyRatedPage,
        setHighlyRatedPage,
        noDataPost,
        setNoDataPost,
        likedPosts,
        setLikedPosts,
        likedPostPage,
        setLikedPostPage,
        noDataComments,
        setNoDataComments,
        commentPosts,
        setCommentPosts,
        commentPostsPage,
        setCommentPostsPage,
        fadeIn,
        setFadeIn,
    };
};