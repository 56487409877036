import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMessageListService } from '../user/list/service/useMessageListService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { NavigationState } from '../types/navigationTypes';
import { useCommonDMService } from './useCommonDMService';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';
import { useMyManagerOfflineListService } from '../myManager/offline/list/service/useMyManagerOfflineListService';
import { useMyManagerOnlineListService } from '../myManager/online/list/service/useMyManagerOnlineListService';
import { useAppFooterService } from '../../footer/service/useAppFooterService';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';

export const useMessageNavigation = () => {
	const navigate = useNavigate();
	const { handleMessageSendClick } = useAppFooterService();
	const [pendingRoomId, setPendingRoomId] = useState(null);
	const [pendingProductSubType, setPendingProductSubType] =
		useState<string>('');
	// 일반 메시지
	const { sortedMessages } = useMessageListService();
	// 매니저 메시지
	const { sortedMessages: onlineManagerMessages } =
		useMyManagerOnlineListService();
	const { sortedMessages: offlineManagerMessages } =
		useMyManagerOfflineListService();
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { setUserProfileOpen, myProfile } = useDatingRepo();
	const { setIsMessenger } = useDirectMessageRepo();
	const { refreshDirectMessageList } = useCommonDMService();
	const { openMessageRoom } = useLikeMatchingService();

	const handleUserMatchPush = async () => {
		try {
			// 메시지 목록을 먼저 새로고침
			await refreshDirectMessageList();

			// UI 상태 업데이트 및 네비게이션
			openMessageRoom();
			setIsMessenger(true);
			navigate(PATH.MESSENGER.ROOT);
		} catch (error) {
			console.error('Failed to handle user match push:', error);
			// 에러가 발생해도 기본 네비게이션은 수행
			openMessageRoom();
			setIsMessenger(true);
			navigate(PATH.MESSENGER.ROOT);
		}
	};

	// 메인 메시지 목록으로 가는 함수 추가
	const navigateToRoot = () => {
		openMessageRoom();
		setIsMessenger(true);
		navigate(PATH.MESSENGER.ROOT);
	};
	// 메시지룸 state 생성 함수
	const createMessageListState = (message): NavigationState => {
		const {
			billingStatus,
			dmRoomType,
			roomId,
			otherUserInfo,
			messages,
			latestCreatedAt,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			user1Info,
			orderId,
			useStatus,
			matchedAt,
		} = message;

		return {
			billingStatus,
			dmRoomType,
			roomId,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			user1Info: {
				userUid: user1Info?.userUid || '',
				nickName: user1Info?.nickName || '',
				profileUrl: user1Info?.profileUrl || '',
				region1: user1Info?.region1,
				region2: user1Info?.region2,
				age: user1Info?.age || '',
				role: user1Info?.role,
				inActive: user1Info?.inActive,
				isDeleted: user1Info?.isDeleted,
				blockType: user1Info?.blockType || '',
			},
			otherUserInfo: {
				userUid: otherUserInfo?.userUid || '',
				nickName: otherUserInfo?.nickName || '',
				profileUrl: otherUserInfo?.profileUrl || '',
				region1: otherUserInfo?.region1,
				region2: otherUserInfo?.region2,
				age: otherUserInfo?.age || '',
				role: otherUserInfo?.role,
				inActive: otherUserInfo?.inActive,
				isDeleted: otherUserInfo?.isDeleted,
				blockType: otherUserInfo?.blockType || '',
			},
			createdAt: latestCreatedAt?.toString() || '',
			orderId,
			useStatus,
			matchedAt: matchedAt || null,
		};
	};
	// 실제 네비게이션 수행 함수
	const navigateToRoom = (roomId, messageData, productSubType) => {
		// 먼저 네비게이션
		let path;
		switch (productSubType) {
			case 'ONLINE':
				path =
					myProfile.role === 'NORMAL'
						? `${PATH.MESSENGER.MANAGER_ONLINE}/${roomId}`
						: `${PATH.MANAGER.MESSENGER.ONLINE}/${roomId}`;
				break;
			case 'OFFLINE':
				path =
					myProfile.role === 'NORMAL'
						? `${PATH.MESSENGER.MANAGER_OFFLINE}/${roomId}`
						: `${PATH.MESSENGER.MANAGER_OFFLINE}/${roomId}`;
				break;
			case 'MESSAGE':
			default:
				path = `${PATH.MESSENGER.ROOT}/${roomId}`;
				break;
		}
		// 방 입장 이벤트 발생
		dmEventEmitter.emit('react-enter-dm-room', { roomId });
		navigate(path, {
			state: createMessageListState(messageData),
		});

		// 네비게이션 후 약간의 지연을 두고 상태 업데이트
		setTimeout(() => {
			setOpenDirectMessageDetail(true, roomId);
			if (messageData?.otherUserInfo?.userUid) {
				setUserProfileOpen(messageData.otherUserInfo.userUid);
			}
		}, 0);
	};

	// sortedMessages가 업데이트될 때마다 pending된 roomId가 있는지 확인
	useEffect(() => {
		if (pendingRoomId) {
			let messageData;
			switch (pendingProductSubType) {
				case 'ONLINE':
					messageData = onlineManagerMessages.find(
						(msg) => msg.roomId === pendingRoomId,
					);
					break;
				case 'OFFLINE':
					messageData = offlineManagerMessages.find(
						(msg) => msg.roomId === pendingRoomId,
					);
					break;
				default:
					messageData = sortedMessages.find(
						(msg) => msg.roomId === pendingRoomId,
					);
			}

			if (messageData) {
				navigateToRoom(pendingRoomId, messageData, pendingProductSubType);
				setPendingRoomId(null);
				setPendingProductSubType('');
			}
		}
	}, [
		pendingRoomId,
		sortedMessages,
		onlineManagerMessages,
		offlineManagerMessages,
	]);

	// 푸시 알림으로 들어올 때 호출할 함수
	const handlePushNavigation = (roomId, productSubType) => {
		if (!roomId) {
			navigateToRoot();
			return;
		}

		// productSubType에 따라 적절한 메시지 리스트에서 찾기
		let messageData;
		switch (productSubType) {
			case 'ONLINE':
				messageData = onlineManagerMessages.find(
					(msg) => msg.roomId === roomId,
				);
				break;
			case 'OFFLINE':
				messageData = offlineManagerMessages.find(
					(msg) => msg.roomId === roomId,
				);
				break;
			default:
				messageData = sortedMessages.find((msg) => msg.roomId === roomId);
		}

		if (messageData) {
			navigateToRoom(roomId, messageData, productSubType);
			handleMessageSendClick();
		} else {
			setPendingRoomId(roomId);
			setPendingProductSubType(productSubType);
		}
	};

	return { handlePushNavigation, navigateToRoot, handleUserMatchPush };
};
