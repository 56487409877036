import { useState } from 'react';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import type { deleteCIVO } from '@/site/api';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';

export const useDeleteUserService = () => {
	const [deleted, setDeleted] = useState<boolean>(false);
	const [pointLeft, setPointLeft] = useState<boolean>(false);

	const { authToken, initAuthToken, initDeviceInfo } = useAuthRepo();

	const { saveDeleteUser } = useSettingAdapter();

	const { myProfile } = useDatingRepo();

	/*
	 * fnSaveDeleteAccount
	 * 계정삭제
	 */
	const fnSaveDeleteAccount = () => {
		if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
			const map: deleteCIVO = {
				isDelete: true,
			};

			saveDeleteUser(authToken.accessJmt, authToken.refreshJmt, map)
				.then((value) => {
					if (value) {
						// 서버 탈퇴 처리 완료
						setDeleted(true);
						initAuthToken();
						initDeviceInfo();
						dmEventEmitter.emit('react-change-dm-is-deleted', {
							userUid: myProfile.userUid,
							isDeleted: true,
						});
					} else {
						// 잔여 포인트 또는 매니저 구매권이 존재하는 경우 탈퇴 불가
						setPointLeft(true);
					}
				})
				.catch((reason) => {
					alert(reason.body.message);
				});
		}
	};

	return {
		fnSaveDeleteAccount,
		deleted,
		pointLeft,
	};
};
