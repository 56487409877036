import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import BerithBoxView from './views/BerithBoxView';
import LeftMenuListView from './views/LeftMenuListView';
import MetaProfileView from './views/MetaProfileView';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';

const LeftMenuContainer = () => {
	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	return (
		<div id="left-menu" className="leftShow active">
			<ButtonHeaderView right={true} />
			<div className="popConWrap bdNone">
				<div className="inner">
					<MetaProfileView />
					<BerithBoxView fnCommonAlertPopOn={fnCommonAlertPopOn} />
					<LeftMenuListView fnCommonAlertPopOn={fnCommonAlertPopOn} />
				</div>
			</div>
		</div>
	);
};

export default LeftMenuContainer;
