import { handleJoystickAction } from '@/utils/joystickUtils';
import { useEffect, useRef, useCallback } from 'react';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useMetaverse } from '@/MetaverseProvider';
import { useChatRepo } from '@/stores/useChatRepo';
import { useJoystickRepo } from '@/stores/useJoystickRepo';

export const useAndroidJoyStickService = () => {
	const { isInMetaverseRoot } = useMetaverse();
	const { showChat } = useChatRepo();
	const { position, setJoystickPosition } = useJoystickRepo();
	const joystickRef = useRef<any>(null);
	const isDraggingRef = useRef(false);
	const startPositionRef = useRef({ x: 0, y: 0 });

	const resetJoystickPosition = useCallback(() => {
		if (showChat) {
			setJoystickPosition({ x: 20, y: 244 });
		} else {
			setJoystickPosition({ x: 20, y: 136 });
		}
	}, [showChat]);

	useEffect(() => {
		if (showChat) {
			setJoystickPosition({ x: 20, y: 244 });
		} else {
			resetJoystickPosition();
		}
	}, [showChat, resetJoystickPosition]);

	const createFakeEvent = useCallback(
		(clientX, clientY) => ({
			clientX,
			clientY,
			preventDefault: () => {},
			stopPropagation: () => {},
		}),
		[],
	);

	const handleJoystickStart = useCallback(
		(x, y) => {
			if (joystickRef.current) {
				const joystickComponent = joystickRef.current;
				const joystickRect =
					joystickComponent._baseRef.current.getBoundingClientRect();
				const centerX = joystickRect.left + joystickRect.width / 2;
				const centerY = joystickRect.top + joystickRect.height / 2;
				joystickComponent._pointerDown(createFakeEvent(centerX, centerY));
				isDraggingRef.current = true;
				startPositionRef.current = { x, y };
			}
		},
		[createFakeEvent],
	);

	const handleJoystickMove = useCallback(
		(x, y) => {
			if (joystickRef.current && isDraggingRef.current) {
				const joystickComponent = joystickRef.current;
				const deltaX = x - startPositionRef.current.x;
				const deltaY = y - startPositionRef.current.y;
				joystickComponent._pointerMove(
					createFakeEvent(
						joystickComponent._baseRef.current.getBoundingClientRect().left +
							joystickComponent._baseRef.current.offsetWidth / 2 +
							deltaX,
						joystickComponent._baseRef.current.getBoundingClientRect().top +
							joystickComponent._baseRef.current.offsetHeight / 2 +
							deltaY,
					),
				);
			}
		},
		[createFakeEvent],
	);

	const handleJoystickStop = useCallback(() => {
		if (joystickRef.current) {
			const joystickComponent = joystickRef.current;
			joystickComponent._pointerUp(createFakeEvent(0, 0));
			isDraggingRef.current = false;
			resetJoystickPosition();
		}
	}, [createFakeEvent, resetJoystickPosition]);

	useEffect(() => {
		if (!isInMetaverseRoot) return;
		const handleMouseDown = (event) => {
			handleJoystickStart(event.clientX, event.clientY);
		};

		const handleMouseMove = (event) => {
			handleJoystickMove(event.clientX, event.clientY);
		};

		const handleMouseUp = () => {
			handleJoystickStop();
		};

		const handleTouchStart = (event) => {
			const touch = event.touches[0];
			handleJoystickStart(touch.clientX, touch.clientY);
		};

		const handleTouchMove = (event) => {
			const touch = event.touches[0];
			handleJoystickMove(touch.clientX, touch.clientY);
		};

		const handleTouchEnd = () => {
			handleJoystickStop();
		};

		const handleClickScreen = (event) => {
			handleMouseDown(event);
		};

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
		document.addEventListener('touchstart', handleTouchStart);
		document.addEventListener('touchmove', handleTouchMove);
		document.addEventListener('touchend', handleTouchEnd);
		reactEventEmitter.on('react-click-screen', handleClickScreen);

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
			document.removeEventListener('touchstart', handleTouchStart);
			document.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('touchend', handleTouchEnd);
			reactEventEmitter.off('react-click-screen', handleClickScreen);
		};
	}, [
		isInMetaverseRoot,
		handleJoystickStart,
		handleJoystickMove,
		handleJoystickStop,
	]);

	const handleJoyStick = (event) => {
		handleJoystickAction(event);
	};

	return {
		position,
		joystickRef,
		handleJoyStick,
	};
};
