import { Key } from 'react';
import RoomListItemView from './RoomListItemView';

const RoomListInfoView = ({
	t,
	role,
	myMetaUser,
	myGender,
	roomItem,
	setRoomItem,
	roomListOpen,
	joinedRoomData,
	sortedRooms,
	handleJoinClick,
	handleClickMeta,
	copyRoomInfoToClipboard,
}) => {
	const filteredRooms = sortedRooms
		.filter(
			(room: { metadata: { name: string } }) => room.metadata.name !== 'PUBLIC',
		)
		.filter(
			(room: { metadata: { name: string } }) => room.metadata.name !== 'DM',
		);

	return (
		<div className="roomListInfo">
			<p className="commonTit">
				<span>{t('meetingRooms')}</span>
			</p>
			<div className="roomList">
				{filteredRooms.length > 0 ? (
					filteredRooms.map(
						(
							room: { roomId: any; locked: any; metadata: any },
							index: Key | null | undefined,
						) => {
							const { roomId, locked, metadata } = room;
							const {
								name,
								hasPassword,
								headCount,
								roomTheme,
								remainingTime,
								createdBy,
								players,
							} = metadata;
							const safePlayersArray = Array.isArray(players) ? players : [];
							const isJoinedRoom = roomId === joinedRoomData?.id;
							return (
								<RoomListItemView
									t={t}
									key={index}
									roomKey={roomId}
									locked={locked}
									roomTitle={name}
									headCount={headCount}
									remainingTime={remainingTime}
									createdBy={createdBy}
									players={safePlayersArray}
									hasPassword={hasPassword}
									roomTheme={roomTheme}
									isJoinedRoom={isJoinedRoom}
									role={role}
									myMetaUser={myMetaUser}
									myGender={myGender}
									roomItem={roomItem}
									setRoomItem={setRoomItem}
									roomListOpen={roomListOpen}
									handleClickMeta={handleClickMeta}
									handleJoinClick={handleJoinClick}
									joinedRoomData={joinedRoomData}
									copyRoomInfoToClipboard={copyRoomInfoToClipboard}
								/>
							);
						},
					)
				) : (
					<div className="dataNoneTxt">
						<p className="txt01">{t('noRooms')}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default RoomListInfoView;
