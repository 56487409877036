import {
	type AddPreferUserCIVO,
	HomeService,
	type MatchTypeAndCodeCIVO, type RandomUserOrderInfoCIVO,
	RandomUserScoreCIVO,
} from '@/site/api';
import { useMutation } from '@tanstack/react-query';

export const useHomeAdapter = () => {

	//이상형찾기 - 선호정보에 맞는 이상형
	const getPreferUser = () => {
		return HomeService.getPreferUser();
	};

	//최근 추천 이성 - 과거 이상형 10명 조회
	const getPastPreferUser = () => {
		return HomeService.getPastPreferUser();
	};

	// 별점 평가 이성 (무료)
	const getRandomUser = () => {
		return HomeService.getRandomUser();
	};

	// 별점 평가 이성 (과금)
	 const addRandomUser = useMutation({
		 mutationFn: (map: RandomUserOrderInfoCIVO) => HomeService.addRandomUser(map)
	 });

	// [matchType]에 따라 이성 추천 받기 (과금)
	const getRecommendUser = (map: MatchTypeAndCodeCIVO) => {
		return HomeService.getRecommendUser(map);
	};

	// [matchType]에 따라 추천받은 이성 히스토리
	const getPastRecommendUsers = () => {
		return HomeService.getPastRecommendUsers();
	};

	// 유저 별점 평가
	const saveEvalUser = (map: RandomUserScoreCIVO) => {
		return HomeService.saveScoreRandomUser(map);
	};

	// 더 많은 이성 보기(과금)
	const addPreferUser = (map: AddPreferUserCIVO) => {
		return HomeService.addPreferUser(map);
	};



	return {
		getPreferUser,
		getPastPreferUser,
		getRandomUser,
		addRandomUser: addRandomUser.mutateAsync,
		getRecommendUser,
		saveEvalUser,
		getPastRecommendUsers,
		addPreferUser,
	};
};
