/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InAppResultInfoCIVO } from '../models/InAppResultInfoCIVO';
import type { OrderInfoCIVO } from '../models/OrderInfoCIVO';
import type { OrderResultCOVO } from '../models/OrderResultCOVO';
import type { PurchaseResultCIVO } from '../models/PurchaseResultCIVO';
import type { PurchaseResultCOVO } from '../models/PurchaseResultCOVO';
import type { TemporayPointChargeCIVO } from '../models/TemporayPointChargeCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrderService {
    /**
     * 주문 가능 여부 확인
     * @param productId 상품 아이디
     * @returns any 주문 가능 여부 확인
     * @throws ApiError
     */
    public static validateOrder(
        productId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order/validateOrder',
            query: {
                'productId': productId,
            },
        });
    }
    /**
     * 주문 하기 (포인트 상품)
     * @param requestBody 주문 정보
     * @returns OrderResultCOVO 주문 결과
     * @throws ApiError
     */
    public static createOrder(
        requestBody: OrderInfoCIVO,
    ): CancelablePromise<OrderResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/createOrder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 인앱 결제 구매 영수증 확인
     * @param requestBody 인앱 결제 완료 정보
     * @returns PurchaseResultCOVO 인앱 결제 구매 영수증 확인
     * @throws ApiError
     */
    public static verifyPurchase(
        requestBody: InAppResultInfoCIVO,
    ): CancelablePromise<PurchaseResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/verifyPurchase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 인앱 결제
     * @param requestBody 인앱 결제 완료 정보
     * @returns OrderResultCOVO 인앱 결제
     * @throws ApiError
     */
    public static saveInAppPurchase(
        requestBody: PurchaseResultCIVO,
    ): CancelablePromise<OrderResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/in-app',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 임시 포인트 충전 (삭제예정)
     * @param requestBody 임시 포인트 충전 정보
     * @returns any 임시 포인트 충전 (삭제예정)
     * @throws ApiError
     */
    public static temporaryPointCharge(
        requestBody: TemporayPointChargeCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/order/temporaryPointCharge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
