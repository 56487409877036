import {useTranslation} from "react-i18next";

interface props {
    fnSaveUserStyle: () => void;
};

const LifeStyleFooterView = ({
                                 fnSaveUserStyle,
                             }: props) => {
    const { t } = useTranslation('register')
    return (
        <footer>
        <div className="f-btnWrap">
          <button className="btn btnBig btnBlack" onClick={fnSaveUserStyle}>
            <span>{t('common.confirm')}</span>
          </button>
        </div>
      </footer>
    )
};

export default LifeStyleFooterView;




