import { useTranslation } from 'react-i18next';

interface props {
	focusItem: { label: string; value: string | string[] };
	nicknameValue: string;
	fnModifyProfileNickname: () => void;
	closeEditPopup: (key: string) => void;
	handleInputChange: (newValue: string) => void;
	lengthOver: boolean;
	isAvailable: boolean;
	isValueEntered: boolean;
	nickError: boolean;
}

const EditNicknamePopView = ({
	focusItem,
	nicknameValue,
	fnModifyProfileNickname,
	closeEditPopup,
	handleInputChange,
	lengthOver,
	isAvailable,
	isValueEntered,
	nickError,
}: props) => {
	const { t } = useTranslation('register');
	return (
		<div className="popWrap editProfilePop">
			<div className="popupInfo padding-b16">
				<div className="frameWrap">
					<div className="titleWrap">
						<h2 className="title01">{t('modifyProfile.nickname')}</h2>
						<p className="titleSub01">{t('modifyProfile.nicknameQuote')}</p>
					</div>
					<div className="content">
						<div className="inputWrap">
							<div className="input">
								<input
									id="firstFocus"
									type="text"
									name="name"
									placeholder={t('modifyProfile.nicknamePlaceholder')}
									value={nicknameValue}
									onChange={(e) => {
										// setNicknameValue(e.target.value);
										handleInputChange(e.target.value);
									}}
								/>
							</div>
							<p className="sub-color fz12 padding-t8 padding-b5">
								{lengthOver ? t('modifyProfile.nicknameValidation') : ''}
								{!isAvailable ? t('modifyProfile.nicknameValidation2') : ''}
							</p>
						</div>
					</div>
				</div>

				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							closeEditPopup(focusItem.label);
						}}
					>
						<span>{t('common.cancel')}</span>
					</button>
					<button
						className="popCloseBtn btn btnBig btnBlack"
						type="button"
						onClick={() => {
							if (!lengthOver && isAvailable) {
								fnModifyProfileNickname();
							}
						}}
					>
						<span>{t('common.confirm')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditNicknamePopView;
