import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	type BlockPostCIVO,
	type BlockUserCIVO,
	type deleteCIVO,
	type InActiveCIVO,
	type ReportCIVO,
	SettingService,
} from '@/site/api';
import type { SettingOnOffCIVO } from '@/site/api';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { BlockPhoneCIVO } from '@/site/api';
import { useBlockReportRepo } from '@dating/repository/dating/useBlockReportRepo';
import { UnblockContactsCOVO } from '@/site/api';
import {useSettingRepo} from "@dating/repository/setting/useSettingRepo";
import {bridgeSetNotiOnOffState} from "@/utils/bridgeCallHandler";

export const useSettingAdapter = () => {
	const queryClient = useQueryClient();

	const { keyword, setPostListEvent, setIsHiddenDelete } = useLoungeRepo();

	const {
		blockedNumberList,
		setBlockedNumberList,
		blockCount,
		blockTf,
		unBlockCount,
		unBlockTf,
	} = useBlockReportRepo();

	const {
		userSettings,
		setUserSettings,
	} = useSettingRepo();

	//설정 정보 조회
	const getUserSetting = () => {
		return SettingService.getUserSetting();
	};

	const getBlockedNumberList = async () => {
		return SettingService.getBlockContacts();
	};

	//프로필 공개 저장
	const saveSetProfileOpen = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetProfileOpen(map);
		},
		onSuccess: (data) => {
			setUserSettings({openProfileYn:!userSettings.openProfileYn})
			return data;
		},
	});

	//소리 및 진동 저장
	const saveSetSound = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			// android일때, 브릿지로 해당 소리 및 진동 값 저장
			if ((window as any).flutter_inappwebview)
				bridgeSetNotiOnOffState(map.onOff ? 'on' : 'off')
			return SettingService.saveSetSound(map);
		},
		onSuccess: (data) => {
			setUserSettings({soundAlarmYn:!userSettings.soundAlarmYn})
			return data;
		},
	});

	//오늘의 추천 저장
	const saveSetTodaySuggest = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetTodaySuggest(map);
		},
		onSuccess: (data) => {
			setUserSettings({todaySuggestYn:!userSettings.todaySuggestYn})
			return data;
		},
	});

	//좋아요 받음 저장
	const saveSetReceivedLike = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetReceivedLike(map);
		},
		onSuccess: (data) => {
			setUserSettings({receivedLikeYn:!userSettings.receivedLikeYn})
			return data;
		},
	});

	//보낸 좋아요 확인 저장
	const saveSetSendLikeChk = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetSendLikeChk(map);
		},
		onSuccess: (data) => {
			setUserSettings({sendLikeChkYn:!userSettings.sendLikeChkYn})
			return data;
		},
	});

	//대화 메세지 저장
	const saveSetChatMessage = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetChatMessage(map);
		},
		onSuccess: (data) => {
			setUserSettings({chatMessageYn:!userSettings.chatMessageYn})
			return data;
		},
	});

	// saveSetConnectedManager
	// 매니저가 회원이 연결되었을 때 알림 설정
	const saveSetConnectedManager = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetConnectedManager(map);
		},
		onSuccess: (data) => {
			setUserSettings({connectedManagerYn:!userSettings.connectedManagerYn})
			return data;
		},
	});

	//라운지 저장
	const saveSetLounge = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetLounge(map);
		},
		onSuccess: (data) => {
			setUserSettings({loungeYn:!userSettings.loungeYn})
			return data;
		},
	});

	//라운지 저장
	const saveSetLoungeComment = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetLoungeComment(map);
		},
		onSuccess: (data) => {
			setUserSettings({loungeCommentYn:!userSettings.loungeCommentYn})
			return data;
		},
	});

	//마케팅 수신 동의 저장
	const saveSetMarketing = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetMarketing(map);
		},
		onSuccess: (data) => {
			setUserSettings({marketingYn:!userSettings.marketingYn})
			return data;
		},
	});

	/*
	 * getCommonCode
	 * 신고 항목 조회
	 */
	const getCommonCode = async () => {
		return SettingService.getCommonCode();
	};

	/*
	 * getJobList
	 * 직업 항목 조회
	 */
	const getJobList = () => {
		return SettingService.getJobList();
	};

	/*
	 * getTerms
	 * 이용약관 가져오기
	 */
	const getTerms = () => {
		return SettingService.getTerms();
	};

	/*
	 * getStyles
	 * 스타일(음주, 흡연, 종교, 관심사, 라이프스타일) 항목 조회
	 */
	const getStyles = () => {
		return SettingService.getStyles();
	};

	/*
	 * getPrefer
	 * 이상형 항목 조회
	 */
	const getPrefer = () => {
		return SettingService.getPrefer();
	};

	/*
	 * saveBlockUser
	 * 유저 차단하기
	 */
	const saveBlockUser = useMutation({
		mutationFn: (map: { blockUserUid: string }) => {
			return SettingService.saveBlockUser(map);
		},
	});

	/*
	 * getBlockUser
	 * 차단한 유저 조회
	 */
	const getBlockUser = () => {
		return SettingService.getBlockUser('block');
	};

	/*
	 * getWhoBlock
	 * 서로 누가 차단했는지 확인
	 */
	const getWhoBlock = (findUserUid: string) => {
		return SettingService.getWhoBlock(findUserUid);
	};

	/*
	 * saveSetReceivedHighScore
	 * 받은 관심(높게 받은 평가)
	 */
	const saveSetReceivedHighScore = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetReceivedHighScore(map);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getUserSetting'] });
			return data;
		},
	});

	/*
	 * saveSetConnectedLike
	 * 연결됨(매칭됨)
	 */
	const saveSetConnectedLike = useMutation({
		mutationFn: (map: SettingOnOffCIVO) => {
			return SettingService.saveSetConnectedLike(map);
		},
		onSuccess: (data) => {
			setUserSettings({connectedLikeYn:!userSettings.connectedLikeYn})
			return data;
		},
	});

	/*
	 * saveBlockPhoneNumberUser
	 * 연락처 목록 차단하기
	 */
	const saveBlockPhoneNumberUser = useMutation<void, Error, BlockPhoneCIVO>({
		mutationFn: (map: BlockPhoneCIVO) => {
			return SettingService.saveBlockPhoneNumberUser(map);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['blockedNumberList'] });
		},
	});

	/*
	 * saveUnblockPhoneNumberUser
	 * 연락처 목록 차단 해제
	 */
	const saveUnblockPhoneNumberUser = useMutation<
		UnblockContactsCOVO,
		Error,
		void
	>({
		mutationFn: () => {
			return SettingService.saveUnblockPhoneNumberUser();
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['blockedNumberList'] });
			return data;
		},
	});

	/*
	 * saveUnblockUser
	 * 유저 차단 해제
	 */
	const saveUnblockUser = useMutation({
		mutationFn: (map: BlockUserCIVO) => {
			return SettingService.saveUnblockUser(map);
		},
	});

	/*
	 * logout
	 * 로그아웃
	 */
	const logout = (accessToken, refreshToken) => {
		return SettingService.logout(`Bearer ${accessToken}`, `Bearer ${refreshToken}`);
	};

	/*
	 * saveBlockPost
	 * 게시글 숨기기
	 */
	const saveBlockPost = useMutation({
		mutationFn: (map: BlockPostCIVO) => {
			setPostListEvent({ flag: 'hide', id: map.postId });
			return SettingService.saveBlockPost(map);
		},
		onSuccess: () => {
			// queryClient.invalidateQueries({ queryKey: ['getPosts2'] });
			queryClient.invalidateQueries({
				queryKey: ['getSearchPosts', { searchWord: keyword.trim() }],
			});
			setIsHiddenDelete(true)
		},
	});

	/*
	 * saveInActiveUser
	 * 휴면 계정 상태 변경
	 */
	const saveInActiveUser = (
		accessToken: string,
		refreshToken: string,
		map: InActiveCIVO,
	) => {
		return SettingService.saveInActiveUser(`Bearer ${accessToken}`, `Bearer ${refreshToken}`, map);
	};

	/*
	 * saveDeleteUser
	 * 계정 삭제
	 */
	const saveDeleteUser = (
		accessToken: string,
		refreshToken: string,
		map: deleteCIVO,
	) => {
		return SettingService.saveDeleteUser(`Bearer ${accessToken}`, `Bearer ${refreshToken}`, map);
	};

	/*
	 * saveReport
	 * 신고하기
	 */
	const saveReport = (map: ReportCIVO) => {
		return SettingService.saveReport(map);
	};

	return {
		saveDeleteUser,
		saveInActiveUser,
		saveBlockPhoneNumberUser: saveBlockPhoneNumberUser.mutate,
		statusSaveBlockPhoneNumberUser: saveBlockPhoneNumberUser.status,
		dataSaveBlockPhoneNumberUser: saveBlockPhoneNumberUser.data,
		//
		saveUnblockUser: saveUnblockUser.mutate,
		statusSaveUnblockUser: saveUnblockUser.status,
		dataSaveUnblockUser: saveUnblockUser.data,
		//
		saveUnblockPhoneNumberUser: saveUnblockPhoneNumberUser.mutate,
		statusSaveUnblockPhoneNumberUser: saveUnblockPhoneNumberUser.status,
		dataSaveUnblockPhoneNumberUser: saveUnblockPhoneNumberUser.data,
		//
		saveSetConnectedLike: saveSetConnectedLike.mutate,
		statusSaveSetConnectedLike: saveSetConnectedLike.status,
		dataSaveSetConnectedLike: saveSetConnectedLike.data,
		//
		saveSetReceivedHighScore: saveSetReceivedHighScore.mutate,
		statusSaveSetReceivedHighScore: saveSetReceivedHighScore.status,
		dataSaveSetReceivedHighScore: saveSetReceivedHighScore.data,
		//
		saveSetProfileOpen: saveSetProfileOpen.mutate,
		statusSaveSetProfileOpen: saveSetProfileOpen.status,
		dataSaveSetProfileOpen: saveSetProfileOpen.data,
		//
		saveSetSound: saveSetSound.mutate,
		statusSaveSetSound: saveSetSound.status,
		dataSaveSetSound: saveSetSound.data,
		//
		saveSetTodaySuggest: saveSetTodaySuggest.mutate,
		statusSaveSetTodaySuggest: saveSetTodaySuggest.status,
		dataSaveSetTodaySuggest: saveSetTodaySuggest.data,
		//
		saveSetReceivedLike: saveSetReceivedLike.mutate,
		statusSetReceivedLike: saveSetReceivedLike.status,
		dataSetReceivedLike: saveSetReceivedLike.data,
		//
		saveSetSendLikeChk: saveSetSendLikeChk.mutate,
		statusSetSendLikeChk: saveSetSendLikeChk.status,
		dataSetSendLikeChk: saveSetSendLikeChk.data,
		//
		saveSetChatMessage: saveSetChatMessage.mutate,
		statusSetChatMessage: saveSetChatMessage.status,
		dataSetChatMessage: saveSetChatMessage.data,
		//
		saveSetConnectedManager: saveSetConnectedManager.mutate,
		statusSetConnectedManager: saveSetConnectedManager.status,
		dataSetConnectedManager: saveSetConnectedManager.data,
		//
		saveSetLounge: saveSetLounge.mutate,
		statusSetLounge: saveSetLounge.status,
		dataSetLounge: saveSetLounge.data,
		//
		//
		saveSetLoungeComment: saveSetLoungeComment.mutate,
		statusSetLoungeComment: saveSetLoungeComment.status,
		dataSetLoungeComment: saveSetLoungeComment.data,
		//
		saveSetMarketing: saveSetMarketing.mutate,
		statusSetMarketing: saveSetMarketing.status,
		dataSetMarketing: saveSetMarketing.data,
		//
		getUserSetting,
		getCommonCode,
		getJobList,
		getStyles,
		getPrefer,
		getBlockUser,
		getTerms,
		//
		saveBlockUser: saveBlockUser.mutate,
		statusSaveBlockUser: saveBlockUser.status,
		dataSaveBlockUser: saveBlockUser.data,
		//
		saveBlockPost: saveBlockPost.mutate,
		statusSaveBlockPost: saveBlockPost.status,
		dataSaveBlockPost: saveBlockPost.data,
		getWhoBlock,
		logout,
		saveReport,
		blockCount,
		blockTf,
		unBlockCount,
		unBlockTf,
		getBlockedNumberList,
		blockedNumberList,
		setBlockedNumberList,
	};
};
