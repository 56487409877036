import {useTranslation} from "react-i18next";

interface props {
    fnSaveJob: () => void;
    btnActivate: boolean;
}

const JobSelectFooterView = ({
                                 fnSaveJob,
                                 btnActivate,
                             }: props) => {

    const { t } = useTranslation('register')

    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnSaveJob}
                    disabled={btnActivate}
                >
                    <span>{t('common.next')}</span>
                </button>
            </div>
        </footer>
    );
};

export default JobSelectFooterView;
