import NoticeListView from './views/NoticeListView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import {useTranslation} from "react-i18next";

const NoticeContainer = () => {
	const { t } = useTranslation('setting')
	const title = t('notice');
	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<NoticeListView />
		</div>
	);
};

export default NoticeContainer;
