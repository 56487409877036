import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import React from 'react';
import { useManagerProfileService } from '@/ui/components/dm/manager/managerProfile/service/useManagerProfileService';
import { useTranslation } from 'react-i18next';

export const ManagerProfileContainer = () => {
	const {
		managerProfile,
		srcPrefix,
		sliderSettings,
		fnSaveManagerLike,
		isChecked,
		setIsChecked,
		evalHandler,
	} = useManagerProfileService();

	const navigate = useNavigate();

	const { t } = useTranslation('profile');

	return (
		<div className="wrap profileWrap footerNone">
			<header>
				<div className="headerWrap">
					<div className="h-left">
						<button
							type="button"
							className="btnBack"
							onClick={() => {
								navigate(-1);
							}}
						>
                            <span className="hide">{t('back')}
								{/* 뒤로가기 */}
                            </span>
						</button>
					</div>
					<div className="h-center"></div>
					<div className="h-right"></div>
				</div>
			</header>
			<div id="conWrap">
				<div className="profileBanner">
					<div className="inner">
						<div className="profileBox">
							<div className="profileSlide">
								<Slider {...sliderSettings}>
									{managerProfile.profileUrl.map((item, i) => {
										return (
											<img
												key={item + i}
												src={srcPrefix + item}
												alt={managerProfile.nickName}
											/>
										);
									})}
								</Slider>
							</div>
							<div className="txtBox">
								<p className="txt01 fz20 color-fff">
									{managerProfile.nickName}
								</p>
								<p className="txt02 fz16 color-fff">{t('matchmaker')}
									{/* 커플매니저 */}
								</p>
								<div className="profileLikeBox">
									<button
										type="button"
										// className="likeBtn"
										className={`likeBtn ${managerProfile.likeYn ? 'on' : ''}`}
										onClick={(e) => {
											const state = e.currentTarget.classList.contains('on') ? false : true;
											fnSaveManagerLike(managerProfile.userUid, state);
										}}
									>
                                        <span className="hide">{t('profileLike')}
											{/* 프로필 좋아요 */}
                                        </span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="profileInfo">
					<div className="inner">
						<div className="stepLastBox">
							<ul className="infoList">
								<li className="ic-pen on">{t('selfIntroduction')}
									{/* 자기소개 */}
								</li>
								<li className="letter fz16">
									{managerProfile.intro
										? managerProfile.intro
										: ''}
								</li>
							</ul>
						</div>

						<p className="ic-mgLove">
							{t('successCount')}: {managerProfile.successCnt}
							{/* 성공 건수 */}
							{/*건*/}
						</p>

						<div className="starWarp margin-tb10">
							<div className="starBox">
								{/*별점평가*/}
								{[5, 4, 3, 2, 1].map((value, i) => {
									const id = 'star' + value.toString() + i;
									return (
										<React.Fragment key={id}>
											<input
												type="radio"
												name="rating"
												id={id}
												value={value}
												checked={isChecked === value ? true : false}
												onChange={(e) => {
													if (isChecked) {
														e.preventDefault();
													} else {
														setIsChecked(value);
														evalHandler(
															managerProfile.userUid,
															Number(e.target.value),
														);
													}
												}}
											/>
											<label htmlFor={id} />
										</React.Fragment>
									);
								})}
							</div>
							<p className="fz12 color-666 text-c margin-t8">
								{t('evaluations')}
								{/* 회원님은 담당 매니저의 평가를 할 수 있습니다. */}
								<br />
								{t('evaluationNotice')}
								{/* 평가 점수는 공개 되지 않습니다. */}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
