import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {useEffect} from "react";
import {useHeaderRepo} from "@dating/repository/dating/useHeaderRepo";
import {SettingOnOffCIVO, SettingService} from "@/site/api";
import {useQuery} from "@tanstack/react-query";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';

export const useSettingsService = () => {
    const title = '설정';

    const {
        userSettings,
        setUserSettings,
    } = useSettingRepo();

    const {
        termsList,
        setTermsList,
        setSelectedTerm,
    } = useRegisterRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    const {
        settingOpen,
    } = useHeaderRepo();

    const {
        getTerms,
    } = useSettingAdapter();

    useEffect(() => {
        if (termsList.length === 0) {
            getTerms().then(value => {
                setTermsList(value);
            }).catch(
                reason => {
                    
                }
            );
        }
    }, []);

    const {
        saveSetProfileOpen,
        saveSetSound,
        saveSetTodaySuggest,
        saveSetReceivedLike,
        saveSetSendLikeChk,
        saveSetChatMessage,
        saveSetLounge,
        saveSetLoungeComment,
        saveSetMarketing,
        saveSetConnectedLike,
        saveSetReceivedHighScore,

    } = useSettingAdapter();

    const {status: statusGetUserSetting, data: dataGetUserSetting} = useQuery({
        queryKey: ['getUserSetting'],
        queryFn: () => {
            if (settingOpen) {
                return SettingService.getUserSetting();
            } else {
                return null;
            }
        },
    });
    /////////////////////////////////////////////

    useEffect(() => {
        if (statusGetUserSetting === "success" && dataGetUserSetting) {
            setUserSettings(dataGetUserSetting);
        }
    }, [statusGetUserSetting, dataGetUserSetting]);

    const settingsSaveHandler = (type: string, state: boolean) => {
        const map: SettingOnOffCIVO = {
            onOff: state
        }

        switch (type) {
            case "openProfile" :
                saveSetProfileOpen(map);
                break;
            case "soundAlarm" :
                saveSetSound(map);
                break;
            case "todaySuggest" :
                saveSetTodaySuggest(map);
                break;
            case "sendLikeChk" :
                saveSetSendLikeChk(map);
                break;
            case "receivedLike" :
                saveSetReceivedLike(map);
                break;
            case "receivedHighScoreYn" :
                saveSetReceivedHighScore(map);
                break;
            case "connectedLikeYn" :
                saveSetConnectedLike(map);
                break;
            case "chatMessage" :
                saveSetChatMessage(map);
                break;
            case "lounge" :
                saveSetLounge(map);
                break;
            case "loungeComment" :
                saveSetLoungeComment(map);
                break;
            case "marketing" :
                saveSetMarketing(map);
                break;
            default :
                break;
        }
    };

    return {
        userSettings,
        srcPrefix,
        title,
        settingsSaveHandler,
        termsList,
        setSelectedTerm,
    };
};
