import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';

export const useRegisterService = () => {
	const { resetRegisterInfo, isDeleted, setIsDeleted } = useRegisterRepo();

	const { approvedAccount } = useRegisterRepo();

	return {
		resetRegisterInfo,
		isDeleted,
		setIsDeleted,
		approvedAccount,
	};
};
