import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import type { UserRecommenderCIVO } from '@/site/api';
import { useEffect } from 'react';
import { useRecommendCodeStateRepo } from '@dating/ui/pages/register/recommendCode/useRecommendCodeStateRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import {useTranslation} from "react-i18next";

export const useRecommendCodeService = () => {
	const {
		recommendCode,
		setRecommendCode,
		codeFailed,
		setCodeFailed,
		response,
		setResponse,
	} = useRecommendCodeStateRepo();

	const {
		setStep,
		registerSteps,
	} = useRegisterRepo();

	const {
		saveRecommender,
		statusSaveRecommender,
		dataSaveRecommender,
	} = useRegisterAdapter();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const { t } = useTranslation('register')
	/////////////////////////////////////////////

	const fnSaveRecommender = () => {
		if (recommendCode.length === 0) {
			fnCommonAlertPopOn(t('codeStep.inputCode'));
			return;
		}
		const map: UserRecommenderCIVO = {
			recommenderCode: recommendCode,
		};
		saveRecommender(map);
	};

	useEffect(() => {
		if (statusSaveRecommender === 'success') {
			if (dataSaveRecommender && dataSaveRecommender.result) {
				setStep(9);
				setResponse(true);
			} else {
				setCodeFailed(true);
			}
		}
	}, [statusSaveRecommender, dataSaveRecommender]);

	return {
		setRecommendCode,
		fnSaveRecommender,
		codeFailed,
		response,
		registerSteps,
	};
};