import {useTranslation} from "react-i18next";

interface props {
    fnSaveRecommender: () => void;
}
const RecommendCodeFooterView = ({
                                     fnSaveRecommender,
                                 }: props) => {
    const { t } = useTranslation('register')
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnSaveRecommender}
                >
                    <span>{t('common.inputBtn')}</span>
                </button>
            </div>
        </footer>
    );
};

export default RecommendCodeFooterView;