import { useEffect } from 'react';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';

export const useOnlineManagerMessageService = () => {
	const {
		setIsOnline,
		isOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setIsFirst,
		isFirst,
		setShowDirectMessageList
	} = useDirectMessageRepo();

	useEffect(() => {
		setShowDirectMessageList(true)
		setIsOnline(false)
	}, []);

	return {
		setIsOnline,
		isOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setIsFirst,
		isFirst,
	};
};
