import { useEffect, useRef } from 'react';
import { useChatService } from './service/useChatService';
import ChatListView from './views/ChatListView';

const ChatContainer = () => {
	const { focused, showChat, chatMessages, handleCloseChat } = useChatService();
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (focused) {
			// focus()를 직접 호출하는 대신 readOnly 속성을 활용
			if (inputRef.current) {
				inputRef.current.readOnly = false;
				inputRef.current.focus();
				// 포커스는 주되 키보드는 올라오지 않게 함
				inputRef.current.blur();
				inputRef.current.focus();
			}
		} else {
			if (inputRef.current) {
				inputRef.current.readOnly = true;
				inputRef.current.blur();
			}
		}
	}, [focused]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [chatMessages]);

	return (
		<div className="PlayChat_chat_container">
			{showChat && (
				<ChatListView
					handleCloseChat={handleCloseChat}
					messagesEndRef={messagesEndRef}
					chatMessages={chatMessages}
				/>
			)}
		</div>
	);
};

export default ChatContainer;
