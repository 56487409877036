import IdealRangeSettingView from '../../register/Ideal/views/IdealRangeSettingView';
import IdealSettingEditFooterView from './views/IdealSettingEditFooterView';
import IdealPopUpView from '../../register/Ideal/views/IdealPopUpView';
import IdealCateListView from "@dating/ui/pages/register/Ideal/views/IdealCateListView";
import {useIdealSettingEditService} from "@dating/ui/pages/settings/idealSettingEdit/useIdealSettingEditService";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import {useTranslation} from "react-i18next";

const IdealSettingEditContainer = () => {
    const {
        isPopupVisible,
        popupType,
        setPopupType,
        showPopup,
        hidePopup,
        formatAgeValue,
        ageRange,
        handleAgeChange,
        formatDistanceValue,
        nearRange,
        handleNearChange,
        formatHeightValue,
        heightRange,
        handleHeightChange,
        religion,
        handleReligionSelect,
        drinkingState,
        handleDrinkingStateSelect,
        smokingState,
        handleSmokingStateSelect,
        fnSaveUserPrefer,
        initAgeRange,
        initNearRange,
        initHeightRange,
        initReligion,
        initDrinkingState,
        initSmokingState,
        statusSaveUserPrefer,
        dataSaveUserPrefer,
        nearOverRange,
        ageOverRange,
        heightOverRange,
    } = useIdealSettingEditService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    const navigate = useNavigate();

    const { t } = useTranslation('register')

    useEffect(() => {
        if (statusSaveUserPrefer === "success" && dataSaveUserPrefer) {
            if (dataSaveUserPrefer.result) {
                fnCommonAlertPopOn(t('modifyProfile.completeIdeal'));
            } else {
                alert(dataSaveUserPrefer.resultCode);
            }
        } else if (statusSaveUserPrefer === "error") {

        }
    }, [statusSaveUserPrefer, dataSaveUserPrefer]);

    return (
        <>
            <div className="wrap">
                <header className="h-fixed">
                    <div className="headerWrap">
                        <div className="h-left"></div>
                        <div className="h-center">
                            <p className="title02">{t('modifyProfile.idealTitle')}</p>
                        </div>
                        <div className="h-right">
                            <button
                                className="btnClose"
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                        </div>
                    </div>
                </header>

                <div className="contentsWrap">
                    <div className="titleWrap">
                        <p className="color-999 fz16 margin-t16">{t('modifyProfile.idealQuote')}</p>
                    </div>
                    <IdealRangeSettingView
                        formatAgeValue={formatAgeValue}
                        ageRange={ageRange}
                        handleAgeChange={handleAgeChange}
                        formatDistanceValue={formatDistanceValue}
                        nearRange={nearRange}
                        handleNearChange={handleNearChange}
                        formatHeightValue={formatHeightValue}
                        heightRange={heightRange}
                        handleHeightChange={handleHeightChange}
                        initAgeRange={initAgeRange}
                        initNearRange={initNearRange}
                        initHeightRange={initHeightRange}
                        nearOverRange={nearOverRange}
                        ageOverRange={ageOverRange}
                        heightOverRange={heightOverRange}
                    />
                    <IdealCateListView
                        showPopup={showPopup}
                        setPopupType={setPopupType}
                        religion={religion}
                        drinkingState={drinkingState}
                        smokingState={smokingState}
                    />
                </div>
                <IdealSettingEditFooterView
                    fnSaveUserPrefer={fnSaveUserPrefer}
                />
            </div>
            {
                isPopupVisible ? (
                    <IdealPopUpView
                        isPopupVisible={isPopupVisible}
                        hidePopup={hidePopup}
                        popupType={popupType}
                        religion={religion}
                        handleReligionSelect={handleReligionSelect}
                        drinkingState={drinkingState}
                        handleDrinkingStateSelect={handleDrinkingStateSelect}
                        smokingState={smokingState}
                        handleSmokingStateSelect={handleSmokingStateSelect}
                        initReligion={initReligion}
                        initDrinkingState={initDrinkingState}
                        initSmokingState={initSmokingState}
                    />
                ) : null
            }
        </>
    );
};

export default IdealSettingEditContainer;
