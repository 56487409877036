import { useHushPickRepo } from '@dating/repository/hushPick/useHushPickRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useEffect, useState } from 'react';
import { useHomeAdapter } from '@dating/adapter/home/useHomeAdapter';
import { HomeMatchingCOVO, MatchTypeAndCodeCIVO } from '@/site/api';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useHushHomeRepo } from '@dating/repository/hushHome/useHushHomeRepo';
import {useTranslation} from "react-i18next";

export const useHushPickService = () => {
	const [response, setResponse] = useState<boolean>(false);

	const {
		hushPickUsers,
		setHushPickUsers,
		hushPickPacks,
		setHushPickPacks,
	} = useHushHomeRepo();

	const {
		hushPickPopMessage,
		setHushPickPopMessage,
		clearHushPickPopMessage,
		hushPickPopOpen,
		setHushPickPopOpen,
		hushPickPopFunc,
		setHushPickPopFunc,
		initHushPickPopFunc,
		hushPickOn,
		setHushPickOn,
		recentProfiles,
		setRecentProfiles,
	} = useHushPickRepo();

	const {
		setUserProfileOpen,
	} = useDatingRepo();

	const {
		getRecommendUser,
		getPreferUser,
		getPastRecommendUsers,
	} = useHomeAdapter();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const { t } = useTranslation('home');

	////////////////////////////////////

	useEffect(() => {
		setHushPickOn(initHushPickOn());
		getPastRecommendUsers().then(value => {
			setRecentProfiles(value);
		});
	}, []);

	useEffect(()=>{
		setHushPickPacksTranslated()
	},[t])

	/*
	 * initHushPickOn
	 * 허쉬픽 UI 초기화
	 */
	const initHushPickOn = () => {
		const newItem = hushPickOn;
		hushPickPacks.map(value => {
			newItem[value.title] = false;
		});

		return newItem;
	};

	/*
	 * fnHushPickPopOn
	 * 팝업 열기 - 메세지, 실행함수 세팅
	 */
	const fnHushPickPopOn = (message1: string, message2: string, func: any, param: any) => {
		setHushPickPopMessage({
			title: message1,
			detail: message2,
		});
		setHushPickPopFunc({ param: param, func: func });
		setHushPickPopOpen(true);
	};

	/*
	 * fnHushPickPopOff
	 * 팝업 닫기(취소) - 메세지, 실행함수 초기화
	 */
	const fnHushPickPopOff = () => {
		clearHushPickPopMessage();
		initHushPickPopFunc();
		setHushPickPopOpen(false);
	};

	/*
	 * fnHushPickPopExecute
	 * 확인 버튼 클릭 (함수 실행 및 팝업 닫기)
	 */
	const fnHushPickPopExecute = () => {
		if (hushPickPopFunc.param != null) {
			hushPickPopFunc.func(hushPickPopFunc.param);
		} else {
			hushPickPopFunc.func();
		}
		setHushPickPopOpen(false);
	};

	/*
	 * hushPickPacksHandle
	 * 과금 추천 항목 ui 핸들러
	 */
	const hushPickPacksHandle = (key: string) => {
		initHushPickOn();
		const item = hushPickOn;
		item[key] = true;
		setHushPickOn(item);
	};

	const setHushPickPacksTranslated = () => {
		const translatedTitles = hushPickPacks.reduce((acc, pack) => {
			acc[pack.titleEng] = t(`pickPacks.${pack.titleEng}`); // i18n 번역 처리
			return acc;
		}, {} as { [key: number]: string });

		// 상태 업데이트
		setHushPickPacks(translatedTitles);
	}
	/*
	 * fnGetRecommendUsers
	 * matchType에 따른 이성 추천 (과금)
	 */
	const fnGetRecommendUsers = (param: {
		productId: number,
		matchType: string,
		jobCode?: string,
		orderId: number
	}) => {
		const map: MatchTypeAndCodeCIVO = {
			matchType: param.matchType,
			orderId: param.orderId,
			jobCode: param.jobCode,
		};
		if (param.matchType === 'random') {
			map.matchType = 'prefer';
		}
		getRecommendUser(map).then(response2 => {
			if (response2) {
				let newArray = [response2, ...hushPickUsers];
				setHushPickUsers(newArray);
			}

		}).catch(reason => {
			if (reason.body.statusCode === 400) {
				fnCommonAlertPopOn('조건에 맞는 회원이 없습니다.');
			}
		}).finally(() => {
			initHushPickOn();
		});
	};

	return {
		hushPickPopMessage,
		hushPickPopOpen,
		setHushPickPopOpen,
		fnHushPickPopOn,
		fnHushPickPopOff,
		fnHushPickPopExecute,
		recentProfiles,
		hushPickPacks,
		hushPickOn,
		hushPickPacksHandle,
		setUserProfileOpen,
		hushPickUsers,
		fnGetRecommendUsers,
		response,
	};
};
