import { useTranslation } from "react-i18next";

interface props {
    btnActivate: boolean;
    fnSaveAddProfile: () => void;
}

const AddProfileFooterView = ({
                                  btnActivate,
                                  fnSaveAddProfile,
                              }: props) => {
    const { t } = useTranslation('profile')

    return (
        <footer>
            <div className="f-btnWrap noFixed">
                <button
                    type="button"
                    className={`btn btnBig btnBlack ${btnActivate ? '':'disabled'}`}
                    onClick={fnSaveAddProfile}
                >
                    <span>{t('badge.submit')}</span>
                </button>
            </div>
        </footer>
    );
};

export default AddProfileFooterView;
