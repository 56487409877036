import Phaser from 'phaser';
import {
	REACT_START_ROOM,
	reactEventEmitter,
} from '../events/ReactEventEmitter';
import { SceneName } from '../types/SceneName';
import GameScene from './GameScene';
import { useRoomRepo } from '@/stores/useRoomRepo';

export default class BootstrapScene extends Phaser.Scene {
	startRoomListener!: StartRoomListener;

	constructor() {
		super(SceneName.BootstrapScene);
	}

	create() {
		this.startRoomListener = new StartRoomListener(this);
	}
}

class StartRoomListener {
	constructor(private scene: BootstrapScene) {
		reactEventEmitter.on('react-start-room', this.startGameScene, this);
	}

	startGameScene = (arg: REACT_START_ROOM) => {
		const { roomThemeSelected, roomThemeSelectedList } = useRoomRepo.getState();
		let roomTheme;

		if (arg.roomType === 'PUBLIC') {
			roomTheme = roomThemeSelectedList[4];
		} else if (arg.roomType === 'CUSTOM') {
			if (arg.roomId) {
				roomTheme = arg.roomTheme;
			} else if (arg.room) {
				roomTheme = roomThemeSelected;
			}
		}

		// 기존 GameScene이 존재하면 완전히 제거
		if (this.scene.scene.get(SceneName.GameScene) as GameScene) {
			const gameScene = this.scene.scene.get(SceneName.GameScene) as GameScene;
			gameScene.leaveCurrentRoom();
			this.scene.scene.stop(SceneName.GameScene);
			this.scene.scene.remove(SceneName.GameScene);
		}

		// 새로운 GameScene 인스턴스 생성
		const newGameScene = new GameScene();

		// 새로운 GameScene을 씬 매니저에 추가
		this.scene.scene.add(SceneName.GameScene, newGameScene, false);

		// 전환 효과를 위한 화면 덮개 생성
		const cover = this.scene.add.graphics();
		cover.fillStyle(0xffffff, 1);
		cover.fillRect(
			0,
			0,
			this.scene.sys.game.config.width as number,
			this.scene.sys.game.config.height as number,
		);
		cover.setDepth(10000);

		// GameScene 시작 (알파값 0으로 시작)
		this.scene.scene.launch(SceneName.GameScene, { ...arg, roomTheme });
		const gameScene = this.scene.scene.get(SceneName.GameScene) as GameScene;
		gameScene.cameras.main.alpha = 0;

		// 전환 효과 적용
		this.scene.tweens.add({
			targets: cover,
			alpha: 0,
			duration: 500,
			ease: 'Power2',
			onComplete: () => {
				cover.destroy();
			},
		});

		// GameScene 페이드 인
		this.scene.tweens.add({
			targets: gameScene.cameras.main,
			alpha: 1,
			duration: 500,
			ease: 'Power2',
			delay: 100,
		});
	};
}
