import { create } from 'zustand';

interface LeaveChatState {
	openChatMenuPop: boolean;
	openLeaveChat: boolean;
	setOpenChatMenuPop: (openChatMenuPop: boolean) => void;
	setOpenLeaveChat: (openLeaveChat: boolean) => void;
}

const useLeaveChatStore = create<LeaveChatState>((set) => ({
	openChatMenuPop: false,
	openLeaveChat: false,
	setOpenChatMenuPop: (openChatMenuPop: boolean) => set({ openChatMenuPop }),
	setOpenLeaveChat: (openLeaveChat: boolean) => set({ openLeaveChat }),
}));

export default useLeaveChatStore;
