import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import MenListView from './MenListView';
import RoomListItemBtnView from './RoomListItemBtnView';
import WomenListView from './WomenListView';
import ManagerListView from './ManagerListView';

const MeetingPeopleListView = ({
	t,
	createdBy,
	myMetaUser,
	myGender,
	roomKey,
	locked,
	roomTheme,
	headCount,
	hasPassword,
	players,
	joinedRoomData,
	remainingTime,
	handleClickMeta,
	handleJoinClick,
}) => {
	const { srcPrefix } = useDatingRepo();
	// manager 플레이어 필터링
	const managerPlayers = players.filter(
		(player: { role: string }) => player.role === 'MANAGER',
	);
	// manager가 있을 때만 mgIn 클래스 추가
	const peopleListClassName = `mtPeopleList ${
		createdBy === 'MANAGER' || managerPlayers.length > 0 ? 'mgIn' : ''
	}`.trim();
	return (
		<>
			<div className={peopleListClassName}>
				{managerPlayers.length > 0 && (
					<ManagerListView players={players} srcPrefix={srcPrefix} />
				)}
				<MenListView
					headCount={headCount}
					players={players}
					srcPrefix={srcPrefix}
				/>
				<WomenListView
					headCount={headCount}
					players={players}
					srcPrefix={srcPrefix}
				/>
			</div>
			<RoomListItemBtnView
				t={t}
				createdBy={createdBy}
				myMetaUser={myMetaUser}
				myGender={myGender}
				roomKey={roomKey}
				headCount={headCount}
				players={players}
				roomTheme={roomTheme}
				remainingTime={remainingTime}
				hasPassword={hasPassword}
				joinedRoomData={joinedRoomData}
				handleClickMeta={handleClickMeta}
				handleJoinClick={handleJoinClick}
				locked={locked}
			/>
		</>
	);
};

export default MeetingPeopleListView;
