import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import {useNicknameService} from "@dating/ui/pages/register/nickname/service/useNicknameService";
import NicknameView from "@dating/ui/pages/register/nickname/views/NicknameView";
import NicknameFooterView from "@dating/ui/pages/register/nickname/views/NicknameFooterView";
import React, {useEffect} from "react";
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";

import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NicknameContainer = () => {
    const {
        inputValue,
        setInputValue,
        handleInputChange,
        fnSaveNickname,
        btnDisabled,
        lengthOver,
        isValueEntered,
        isAvailable,
        response,
        registerSteps,
    } = useNicknameService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();

    const { t } = useTranslation('register')

    useEffect(() => {
        if (response) {
            navigate(registerSteps[3].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView/>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">{t('nicknameStep.inputNickname')}</h2>
                    <p className="titleSub01">{t('nicknameStep.inputNickname2')}</p>
                </div>
                <NicknameView
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handleInputChange={handleInputChange}
                    lengthOver={lengthOver}
                    isAvailable={isAvailable}
                    isValueEntered={isValueEntered}
                />
            </div>

            <NicknameFooterView
                fnSaveNickname={fnSaveNickname}
                btnDisabled={btnDisabled}
            />
        </div>
    );
};

export default NicknameContainer;
