import {useTranslation} from "react-i18next";

interface props {
    backStep: () => void;
    postId: string | undefined;
}

const FeedFormHeaderView = ({
                                backStep,
                                postId,
                            }: props) => {

    const { t } = useTranslation('lounge');

    return (
        <header>
            <div className="headerWrap">
                <div className="h-left"></div>
                <div className="h-center">
                    <span className="fz18">
                        {
                            postId === "" || postId === undefined ?
                                t('newPost')
                                : t('modiPost')
                        }
                    </span>
                </div>
                <div className="h-right">
                    <button
                        type="button"
                        className="btnClose"
                        onClick={backStep}
                    />
                </div>
            </div>
        </header>
    );
};

export default FeedFormHeaderView;
