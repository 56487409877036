import { useEffect, useRef } from 'react';
import { usePhoneVerificationStateRepo } from '@dating/ui/pages/register/phoneVerification/usePhoneVerificationStateRepo';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import {
	MobilePhoneOccupancyCIVO,
	MobilePhoneOccupancyVerificationCIVO,
	MobilePhoneOccupancyVerificationCOVO,
} from '@/site/api';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useNavigate } from 'react-router-dom';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useProductAdapter } from '@dating/adapter/product/useProductAdapter';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { useOrderUtils } from '@/utils/orderUtils';
import {
	bridgeGetNotiData,
	bridgeSetNativeDataLogin,
} from '@/utils/bridgeCallHandler';
import { goPushPage } from '@/SetBridgeContextProvider';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useMessageNavigation } from '@/ui/components/dm/service/useMessageNavigation';
import { useMetaverse } from '@/hooks/useMetaverse';
import Network from '@/services/Network';

export const usePhoneVerificationService = () => {
	const {
		inputValues,
		setInputValues,
		time,
		setTime,
		occupancyFooterViewStyle,
		setOccupancyFooterViewStyle,
		activeIndex,
		setActiveIndex,
		formattedTime,
		setFormattedTime,
		phoneVeriFailed,
		setPhoneVeriFailed,
		phoneVeriFailedCnt,
		setPhoneVeriFailedCnt,
		inputTimeout,
		setInputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
		input,
		setInput,
	} = usePhoneVerificationStateRepo();

	const {
		phoneNumber,
		registerSteps,
		setStep,
		verifyReqCount,
		setVerifyReqCount,
		resetRegisterInfo,
		SNSInfo,
		setIsDeleted,
		setApproveAccount,
	} = useRegisterRepo();

	const { deviceId, fcmToken, setAuthToken, initAuthToken, setTempToken } =
		useAuthRepo();

	const { setMyProfile } = useDatingRepo();

	const { setSingleFeedId } = useLoungeRepo();

	const { sendVerificationCode, verifyAuthCode } = useRegisterAdapter();

	const { getMyProfile } = useProfileAdapter();

	const { myMetaUserOpen, setMyMetaUser, setMyMetaUserOpen } =
		useMetaUserRepo();

	const { handlePushNavigation, handleUserMatchPush } = useMessageNavigation();

	const { getMyMetaUser } = useMetaUserAdapter();

	const { getProducts } = useProductAdapter();
	const { setProductItems } = useOrderUtils();

	const { initializeGame } = useMetaverse();
	//////////////////////////////////////////////////

	/*
	 * handleInputKeyDown
	 * input에 이벤트 키 입력 시 input입력값 변경, input index 변경
	 */
	const handleInputKeyDown = (index: number, eventKey: string) => {
		if (/^\d$/.test(eventKey)) {
			setInputValues((preValues) => {
				const newInputValues = [...preValues];
				newInputValues[index] = eventKey;
				return newInputValues;
			});

			if (index < inputValues.length - 1 && eventKey !== '') {
				setActiveIndex(index + 1);
			}
		}
	};

	useEffect(() => {
		if (input.length < 6) {
			setOccupancyFooterViewStyle({
				notReturnNumber: {
					display: 'block',
				},
				checkBtn: {
					display: 'none',
				},
			});
		} else {
			setOccupancyFooterViewStyle({
				notReturnNumber: {
					display: 'none',
				},
				checkBtn: {
					display: 'block',
				},
			});
		}
	}, [input]);

	/*
	 * timeFormatting
	 * 인증번호 입력 제한시간 표시형식 set
	 */
	function timeFormatting() {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		setFormattedTime(
			String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'),
		);
	}

	/*
	 * fnSendVerificationCode
	 * 인증번호 요청 횟수 체크 후 인증번호 발송
	 */
	const fnSendVerificationCode = () => {
		if (verifyReqCount > 5) {
			resetRegisterInfo();
			setPhoneVeriFailed(true);
			return;
		}

		const map: MobilePhoneOccupancyCIVO = {
			mobilePhoneNumber: phoneNumber,
		};
		sendVerificationCode(map).then((value) => {
			if (value && verifyReqCount > 0) {
				setCodeResend(true);
				setVerifyReqCount(verifyReqCount + 1);
			}
		});
	};

	/*
	 * fnStartRegister
	 * 회원가입 시작(약관동의)
	 */
	const fnStartRegister = () => {
		setStep(0);
		navigate(registerSteps[0].url);
	};

	useEffect(() => {
		timeFormatting();
	}, [time]);

	// 인증번호 입력 제한시간 set
	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime((prevTime) => {
				if (prevTime > 0) {
					return prevTime - 1;
				} else {
					clearInterval(intervalId);
					setInputTimeout(true);
					return 0;
				}
			});
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const navigate = useNavigate();
	/*
	 * fnVerifyVerificationCode
	 * 확인 버튼 클릭, 인증번호 인증 요청 및 처리
	 */
	const fnVerifyVerificationCode = () => {
		let code: string = input;
		const map: MobilePhoneOccupancyVerificationCIVO = {
			mobilePhoneNumber: phoneNumber,
			verificationCode: code,
			deviceId: deviceId,
			fcmToken: fcmToken,
		};

		if (SNSInfo.resultCode == 'empty') {
			map.id = SNSInfo.id;
			map.email = SNSInfo.email;
			map.socialType = SNSInfo.socialType;
		}

		verifyAuthCode(map)
			.then((response) => {
				if (response.isDeleted) {
					// 삭제계정
					// '현재 회원가입을 할 수 없는 계정입니다.' 팝업 호출 및 메인으로 이동
					setIsDeleted(true);
					navigate(PATH.REGISTER.REGISTER, { replace: true });
				} else if (response.inActive) {
					// 휴면계정
					// 토큰 저장 후 휴면해제 안내 화면으로 이동
					if (response.accessToken && response.refreshToken) {
						/*
						setAuthToken({
							accessJmt: response.accessToken,
							refreshJmt: response.refreshToken,
						});
						* */
						// 휴면계정 로그인시, 임시로 tempToken에 담았다가, 해제 성공하면 authToken에 담기로
						setTempToken({
							accessJmt: response.accessToken,
							refreshJmt: response.refreshToken,
						});
					}

					navigate(PATH.AUTH.IN_ACTIVE, { replace: true });
				} else if (!response.signUpStatus && response.signUpSteps) {
					// 가입미완료 (회원가입 정보 입력중인 회원) 일 때
					setStep(Number(response.signUpSteps));
					navigate(registerSteps[response.signUpSteps].url);
				} else if (response.signUpSteps == '' || !response.signUpStatus) {
					// 회원가입 정보가 없는 신규회원일 때
					fnStartRegister();
				} else if (
					response.signUpStatus &&
					!response.isDeleted &&
					!response.inActive
				) {
					// 회원가입 완료 및 승인 완료
					// 토큰값 저장
					if (response.accessToken && response.refreshToken) {
						setAuthToken({
							accessJmt: response.accessToken,
							refreshJmt: response.refreshToken,
						});
					}

					// 로그인 시, native에 accessJmt, refreshJmtapp에 세팅
					if ((window as any).flutter_inappwebview) {
						bridgeSetNativeDataLogin({
							accessJmt: response.accessToken,
							refreshJmt: response.refreshToken,
						}).catch((reason) => {
							alert(reason.body);
						});
					}

					if (!response.startButtonYn) {
						// 시작하기 버튼 안누른 사람
						// 대기화면으로 이동 및 시작하기 버튼 활성화
						setApproveAccount(true);
						navigate(PATH.REGISTER.WAITING);
					} else {
						// 정상 회원 (회원가입 완료 및 승인완료)
						// 1. getMyProfile 및 메타버스 init
						// 2. 대기화면 노출 여부(시작버튼 클릭 여부)에 따라 화면 이동
						signInWithAuthCode(response);
					}
				}
			})
			.catch((error) => {
				if (error.body) {
					// 인증번호가 유효하지 않은 경우
					setNotValidCode(true);
					setInputValues(Array(6).fill(''));
					setPhoneVeriFailedCnt(phoneVeriFailedCnt + 1);
				}
			});
	};

	/*
	 * signInWithAuthCode
	 * 정상 회원 (회원가입 완료 및 승인완료) 클라이언트 서버 로그인 처리
	 */
	async function signInWithAuthCode(
		response: MobilePhoneOccupancyVerificationCOVO,
	) {
		getMyProfile(phoneNumber).then(async (value) => {
			if (value.profileUrl.length > 0) {
				setMyProfile(value);
				if (Network.instance) {
					await Network.instance.initializeLobby();

					// 로비 연결 성공 후 DM 이벤트 발생
					dmEventEmitter.emit('react-join-or-create-dm-room', {
						userUid: value.userUid,
						nickName: value.nickName,
						profileUrl: value.profileUrl[0],
						age: value.age,
						region1: value.region1,
						region2: value.region2,
						role: value.role,
						inActive: false,
						isDeleted: false,
						blockType: '',
					});
					dmEventEmitter.emit('react-get-dm-rooms');
				}

				try {
					const metaUserData = await getMyMetaUser();

					// 메타유저 데이터가 있고 객체인지 확인
					if (metaUserData && typeof metaUserData === 'object') {
						// 메타유저 데이터 설정 (profileUrl 체크하지 않음)
						setMyMetaUser(metaUserData);
					} else {
						console.warn('Invalid meta user data:', metaUserData);
					}
				} catch (metaError) {
					console.error('Failed to fetch meta user data:', metaError);
				}

				const getNotiData = await bridgeGetNotiData();
				if (
					getNotiData != null &&
					getNotiData != '' &&
					getNotiData.methodName != ''
				) {
					goPushPage(
						getNotiData,
						navigate,
						setSingleFeedId,
						handleUserMatchPush,
						handlePushNavigation,
					);
				} else if (value.role === 'NORMAL') {
					navigate(PATH.DATING.HOME);
				} else if (value.role === 'MANAGER') {
					navigate(PATH.MANAGER.HOME);
				}
			} else {
				navigate(PATH.REGISTER.REGISTER);
				return;
			}
		});

		initializeGame();
		setProductItems();
	}

	return {
		fnStartRegister,
		inputValues,
		formattedTime,
		occupancyFooterViewStyle,
		handleInputKeyDown,
		fnVerifyVerificationCode,
		activeIndex,
		fnSendVerificationCode,
		resetRegisterInfo,
		phoneVeriFailed,
		phoneVeriFailedCnt,
		inputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
		phoneNumber,
		myMetaUserOpen,
		setMyMetaUserOpen,
		registerSteps,
		setInputValues,
		setPhoneVeriFailedCnt,
		setStep,
		input,
		setInput,
	};
};
