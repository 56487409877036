export enum DirectMessageType {
  SEND_DIRECT_MESSAGE,
  RECEIVE_DIRECT_MESSAGE,
  READ_DIRECT_MESSAGE,
  REQUEST_UNREAD_COUNT,
  UNREAD_COUNT_UPDATE,
  LEAVE_DIRECT_MESSAGE,
  EXIT_DIRECT_MESSAGE,
  FIRST_DIRECT_MESSAGE,
  RECEIVE_FIRST_DIRECT_MESSAGE,
  DM_SESSION_INFO,
  CREATE_DM_ROOM,
  GET_DM_ROOMS,
  DM_ROOM_ADDED,
  DM_ROOM_UPDATED,
  DM_ROOM_REMOVED,
  SINGLE_PAYMENT_COMPLETED,
  MUTUAL_PAYMENT_COMPLETED,
  CHANGE_DM_ROOM_TYPE,
  FORCE_DISCONNECT_DM,
  BLOCK_USER,
  UPDATE_DM_PROFILE_URL,
  CHANGE_DM_USE_STATUS,
  CHANGE_DM_INACTIVE,
  CHANGE_DM_IS_DELETED,
  TOTAL_UNREAD_COUNT,
  RESET_ALL_UNREAD,
  USER_IN_ROOM_STATUS,
  CHECK_USER_SESSION,
  ENTER_DM_ROOM,
  LEAVE_DM_ROOM,
}