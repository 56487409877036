import { useNavigate } from 'react-router-dom';
import {PATH} from "@dating/constants/RoutingEndPoints";
import { bridgeRequestLocation } from '@/utils/bridgeCallHandler';
import {coords} from "@dating/constants/TempGpsCoordinates";
import {useTranslation} from "react-i18next";

interface props {
	fnSaveUserLocation: (response: any) => void;
}

const LocationInfoFooterView = ({
									fnSaveUserLocation,
								}: props) => {

	const navigate = useNavigate();
	const { t } = useTranslation('register')

	return (
		<div className="inner padding-t16 padding-b16">
				<button
					type="button"
					className="btn btnBig btnBlack"
					onClick={() => {
						if (!(window as any).flutter_inappwebview) {
							// 웹
							const randomIdx = Math.floor(Math.random() * coords.length)
							fnSaveUserLocation(coords[randomIdx]);
						} else {
							// 앱
							bridgeRequestLocation().then(response => {
								if (response == null) {
									navigate(PATH.REGISTER.LOCATION_UNABLE);
									return;
								} else {
									const map: {latitude:string,longitude:string} = {
										latitude: response.latitude,
										longitude: response.longitude,
									};

									// 서버로 전송
									fnSaveUserLocation(map);
								}
							});
						}
					}}>
					<span>{t('locationStep.accessBtn')}</span>
				</button>
			</div>
	);
};

export default LocationInfoFooterView;