import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useCommonAlertPopService} from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";

interface props {
	isOnline: boolean;
}
const PurchaseButtonView = ({ isOnline }: props) => {
	const navigate = useNavigate();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();
	return (
		<div className="mgFoot">
			<button
				type="button"
				className="mgPayBtn"
				onClick={() => {
					if (import.meta.env.MODE == 'prd') {
						// 운영
						fnCommonAlertPopOn('서비스 준비 중입니다.');

					} else {
						// 개발
						const path = isOnline ? PATH.STORE.SCREEN : PATH.STORE.OFFLINE;
						navigate(path);
					};
				}}
			/>
		</div>
	);
};

export default PurchaseButtonView;
