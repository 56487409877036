import { Client, Room } from 'colyseus.js';
import {
	IDirectMessageState,
	IDMRoom,
	IUser,
} from '../../../../types/IDirectMessageState';
import { RoomType } from '../../../../types/Rooms';
import { CreateClient } from '../network/CreateClient';
import { DirectMessageType } from '../../../../types/DirectMessageType';
import { StoreService } from '../StoreService';
import { DirectMessageEventSetup } from './DirectMessageEventSetup';
import {
	dmEventEmitter,
	REACT_JOIN_OR_CREATE_DM_ROOM,
} from '@/events/ReactDMEventEmitter';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export default class DirectMessageManager {
	private static instance: DirectMessageManager | null = null;
	private client: Client;
	public dmRoom: Room<IDirectMessageState> | null = null;
	public storeService: StoreService;
	private constructor() {
		this.client = CreateClient.create();
		this.storeService = new StoreService();
	}
	private boundJoinOrCreateDMRoom:
		| ((args: REACT_JOIN_OR_CREATE_DM_ROOM) => void)
		| null = null;

	public static getInstance(): DirectMessageManager {
		if (!DirectMessageManager.instance) {
			DirectMessageManager.instance = new DirectMessageManager();
		}
		return DirectMessageManager.instance;
	}

	get sessionId() {
		return this.dmRoom?.sessionId ?? '';
	}

	async joinOrCreateDMRoom(
		userUid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		region1: string,
		region2: string,
		role?: string,
		inActive?: boolean,
		isDeleted?: boolean,
		blockType?: string,
	): Promise<void> {
		if (!this.dmRoom) {
			this.dmRoom = await this.client.joinOrCreate(RoomType.DM, {
				userUid,
				nickName,
				profileUrl,
				age,
				region1,
				region2,
				role,
				inActive,
				isDeleted,
				blockType,
			});
			const eventSetup = new DirectMessageEventSetup(
				this.dmRoom,
				this.storeService,
			);
			eventSetup.setup();
		}
	}

	setupEventListeners() {
		dmEventEmitter.on('react-join-or-create-dm-room', (args) => {
			const {
				userUid,
				nickName,
				profileUrl,
				region1,
				region2,
				age,
				role,
				inActive,
				isDeleted,
				blockType,
			} = args;
			this.joinOrCreateDMRoom(
				userUid,
				nickName,
				profileUrl,
				region1,
				region2,
				age,
				role,
				inActive,
				isDeleted,
				blockType,
			);
		});
	}

	leaveDMRoom() {
		if (this.dmRoom) {
			this.dmRoom.leave();
			if (this.boundJoinOrCreateDMRoom) {
				dmEventEmitter.off(
					'react-join-or-create-dm-room',
					this.boundJoinOrCreateDMRoom,
				);
				this.boundJoinOrCreateDMRoom = null;
			}
			this.dmRoom = null;
		}
	}

	// direct message 방 생성
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
		matchedAt: string | null,
		productType?: string,
		productSubType?: string,
		useStatus?: string,
		seq?: number,
		validUses?: number,
		orderId?: number,
	) {
		this.dmRoom?.send(DirectMessageType.CREATE_DM_ROOM, {
			billingStatus,
			dmRoomType,
			roomId,
			user1Id,
			user2Id,
			user1Info,
			user2Info,
			createdAt,
			matchedAt,
			productType,
			productSubType,
			useStatus,
			seq,
			validUses,
			orderId,
		});
	}

	// direct message 방 조회
	getDirectMessageRoom() {
		this.dmRoom?.send(DirectMessageType.GET_DM_ROOMS);
	}

	// direct message 방 참여
	enterDirectMessageRoom(roomId: string) {
		if (!roomId) {
			console.warn('enterDirectMessageRoom: roomId is required');
			return;
		}
		this.dmRoom?.send(DirectMessageType.ENTER_DM_ROOM, { roomId }); // roomId를 객체로 전달
	}

	// direct message 방 나가기
	leaveDirectMessageRoom() {
		this.dmRoom?.send(DirectMessageType.LEAVE_DM_ROOM); // roomId를 객체로 전달
	}

	// direct message 세션 체크
	checkUserInRoom(targetUid: string, roomId: string) {
		this.dmRoom?.send(DirectMessageType.CHECK_USER_SESSION, {
			targetUid,
			roomId,
		});
	}

	// direct message 추가
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	) {
		this.dmRoom?.send(DirectMessageType.SEND_DIRECT_MESSAGE, {
			roomId,
			messageId,
			sender,
			receiver,
			content,
			createdAt,
			read,
		});
	}

	// direct message 수신
	receiveDirectMessage() {
		this.dmRoom?.onMessage(
			DirectMessageType.RECEIVE_DIRECT_MESSAGE,
			(message) => {
				this.storeService.setDirectMessageReceived(message);
			},
		);
	}

	// direct message 읽음
	readDirectMessage(roomId: string, messageId: number, userUid: string) {
		this.dmRoom?.send(DirectMessageType.READ_DIRECT_MESSAGE, {
			roomId,
			messageId,
			userUid,
		});
	}

	// 읽지 않은 메시지 수 요청
	requestUnreadMessageCount(roomId: string) {
		this.dmRoom?.send(DirectMessageType.REQUEST_UNREAD_COUNT, { roomId });
	}

	// 전체 메시지 카운트 초기화
	resetUnreadMessageCount() {
		this.dmRoom?.send(DirectMessageType.RESET_ALL_UNREAD);
	}

	// direct message 나가기
	leaveDirectMessage(roomId: string, leaverId: string) {
		this.dmRoom?.send(DirectMessageType.LEAVE_DIRECT_MESSAGE, {
			roomId,
			leaverId,
		});
	}

	// direct message 완전히 나가기
	exitDirectMessage(roomId: string, exit: boolean) {
		this.dmRoom?.send(DirectMessageType.EXIT_DIRECT_MESSAGE, {
			roomId,
			exit,
		});
	}

	// direct message 단일 결제
	singlePaymentCompleted(roomId: string, payerId: string) {
		this.dmRoom?.send(DirectMessageType.SINGLE_PAYMENT_COMPLETED, {
			roomId,
			payerId,
		});
	}

	// direct message 쌍방 결제
	mutualPaymentCompleted(roomId: string, paid: boolean) {
		this.dmRoom?.send(DirectMessageType.MUTUAL_PAYMENT_COMPLETED, {
			roomId,
			paid,
		});
	}

	// direct message 방 타입 변경
	changeDirectRoomType(roomId: string, dmRoomType: string) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_ROOM_TYPE, {
			roomId,
			dmRoomType,
		});
	}

	// direct message 사용 상태 변경
	changeDMUseStatus(roomId: string, useStatus: string) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_USE_STATUS, {
			roomId,
			useStatus,
		});
	}

	// direct message 유저 차단
	blockUser(
		roomId: string,
		blockType: string,
		blockerUuid: string,
		targetUid: string,
	) {
		this.dmRoom?.send(DirectMessageType.BLOCK_USER, {
			roomId,
			blockType,
			blockerUuid,
			targetUid,
		});
	}

	// direct message 프로필 url 변경
	updateDMProfileUrl(userUid: string, profileUrl: string) {
		this.dmRoom?.send(DirectMessageType.UPDATE_DM_PROFILE_URL, {
			userUid,
			profileUrl,
		});
	}

	// direct message 휴면 상태 변경
	changeDMInactive(userUid: string, inActive: boolean) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_INACTIVE, {
			userUid,
			inActive,
		});
	}

	// direct message 탈퇴 상태 변경
	changeDMIsDeleted(userUid: string, isDeleted: boolean) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_IS_DELETED, {
			userUid,
			isDeleted,
		});
	}

	updateDirectMessageRoom(room: IDMRoom): void {
		this.dmRoom?.send(DirectMessageType.DM_ROOM_UPDATED, room);
	}

	removeDirectMessageRoom(roomId: string): void {
		this.dmRoom?.send(DirectMessageType.DM_ROOM_REMOVED, roomId);
	}
}
