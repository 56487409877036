import { AuthService, type GoogleLoginCIVO } from '@/site/api';

export const useAuthAdpater = () => {

	/*
	 * oauthLoginInfos
	 * SNS 로그인
	 */
	const oauthLoginInfos = (provider: string) => {
		return AuthService.oauthLoginInfos(provider);
	};

	/*
	 * autoLogin
	 * 자동로그인
	 */
	const autoLogin = (deviceId?: string, fcmToken?: string) => {
		return AuthService.autoLogin(deviceId, fcmToken);
	};

	/*
	 * googleLogin
	 * 구글 로그인 정보 저장
	 */
	const googleLogin = (map: GoogleLoginCIVO) => {
		return AuthService.googleLogin(map);
	};

	return {
		oauthLoginInfos,
		autoLogin,
		googleLogin,
	};
};