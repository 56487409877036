import FeedEditMenuView from '@dating/ui/components/feedEdit/views/FeedEditMenuView';
import {Link} from 'react-router-dom';
import {PostInfoCOVO} from '@/site/api';
import FeedMenuView from "@dating/ui/pages/lounge/feedList/views/FeedMenuView";
import { PATH } from '@dating/constants/RoutingEndPoints';

interface props {
    post: PostInfoCOVO;
    srcPrefix: string;
    menuDotClickHandler: () => void;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    deleteContent: (id: string) => void;
    setUserProfileOpen: (userProfileOpen: string) => void;
    setBlockUserUid: (blockUserUid: string) => void;
    currentUserUid: string;
    setModifyPostId: (postId: string) => void;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    fnCommonConfirmPopOn: (message: string, okFunc: any, okParam: any, noFunc: any, noParam: any) => any;
    setBlockPostId: (blockPostId: string) => void;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
    reportPopOpen: (location: string, targetUid: string, category: string) => void;
    hidePopOpen: (postId: string) => void;
}

const FeedProfBoxView = ({
                             post,
                             srcPrefix,
                             menuDotClickHandler,
                             setOpenFeedHidePop,
                             deleteContent,
                             setUserProfileOpen,
                             setBlockUserUid,
                             currentUserUid,
                             setModifyPostId,
                             setMyProfileOpen,
                             fnCommonConfirmPopOn,
                             setBlockPostId,
                             fnFeedEditHandler,
                             feedEditPops,
                             reportPopOpen,
                             hidePopOpen,
                         }: props) => {
    return (
        <>
            <div className="profileType">
                <div className="proLeft">
                    <Link
                        to={
                            post.role === 'MANAGER' ? PATH.PROFILE.MANAGER_PROFILE :
                                post.postUserUid !== currentUserUid ? PATH.PROFILE.USER_PROFILE : null
                        }
                        onClick={() => {
                            post.postUserUid !== currentUserUid ?
                                setUserProfileOpen(post.postUserUid) : null
                        }}
                        state={{
                            isOtherProfile: post.postUserUid !== currentUserUid,
                        }}
                    >
                        <div className="proImg Mid">
                            <img
                                src={srcPrefix + post.postUserProfileUrl}
                                alt="테스트이미지"
                            />
                        </div>
                    </Link>

                    <div className="proName">
                        <p className="proFz20">{post.postNickName}</p>
                    </div>
                </div>
                <div className="ProRight">
                    <div className="menuDotWap">
                        <button
                            type="button"
                            className="menuDot"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!(post.isDeletable && post.isEditable)) {
                                    setBlockUserUid(post.postUserUid);
                                }
                                menuDotClickHandler();
                                fnFeedEditHandler(post.postId);
                            }}
                        />

                        {post.isDeletable || post.isEditable ? (
                            <FeedEditMenuView
                                post={post}
                                deleteContent={deleteContent}
                                openMenuPop={feedEditPops[post.postId]}
                                setModifyPostId={setModifyPostId}
                                fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                            />
                        ) : (
                            <FeedMenuView
                                openMenuPop={feedEditPops[post.postId]}
                                setOpenFeedHidePop={setOpenFeedHidePop}
                                setBlockPostId={setBlockPostId}
                                postId={post.postId}
                                targetUid={post.postUserUid}
                                reportPopOpen={reportPopOpen}
                                hidePopOpen={hidePopOpen}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeedProfBoxView;
