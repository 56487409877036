import { useTranslation } from 'react-i18next';

interface IManagerFooterViewProps {
	fnOpenProducts: () => void;
}
const ManagerFooterView = ({ fnOpenProducts }: IManagerFooterViewProps) => {
	const { t } = useTranslation('store');
	return (
		<footer>
			<button
				type="button"
				className="btn btnBig btnBlack radiusNone"
				onClick={() => {
					fnOpenProducts();
				}}
			>
				{t('serviceReady')}
				{/*매니저 도움받기*/}
			</button>
		</footer>
	);
};

export default ManagerFooterView;
