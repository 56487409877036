import {useTranslation} from "react-i18next";

interface props {
	closeChargeAlertPop: () => void;
	confirmChargeAlertPop: () => void;
};

export const ChargeAlertPopView = ({
									   closeChargeAlertPop,
									   confirmChargeAlertPop,
								   }: props) => {
	const { t } = useTranslation('common');

	return (
		<div className="logoutWrap">
			<div className="popupInfo padding-t36">
				<p className="blackTxt text-c bold-500" dangerouslySetInnerHTML={{ __html: t('chargePop.chargeQuote') }}/>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							closeChargeAlertPop();
						}}
					>
						<span>{t('chargePop.cancel')}</span>
					</button>
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={() => {
							confirmChargeAlertPop();
						}}
					>
						<span>{t('chargePop.confirm')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};