import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import {useTranslation} from "react-i18next"; // 스타일 파일 불러오기

interface props {
    formatAgeValue: (age: number) => string;
    ageRange: { min: number, max: number };
    handleAgeChange: (ageRange) => void;
    formatDistanceValue: (distance: number) => string;
    nearRange: { min: number, max: number };
    handleNearChange: (nearRange) => void;
    formatHeightValue: (height: number) => string;
    heightRange: { min: number, max: number };
    handleHeightChange: (heightRange) => void;
    initAgeRange: { min: number, max: number };
    initNearRange: { min: number, max: number };
    initHeightRange: { min: number, max: number };
    nearOverRange: boolean;
    ageOverRange: boolean;
    heightOverRange: boolean;
}

const IdealRangeSettingView = ({
                                   formatAgeValue,
                                   ageRange,
                                   handleAgeChange,
                                   formatDistanceValue,
                                   nearRange,
                                   handleNearChange,
                                   formatHeightValue,
                                   heightRange,
                                   handleHeightChange,
                                   initAgeRange,
                                   initNearRange,
                                   initHeightRange,
                                   nearOverRange,
                                   ageOverRange,
                                   heightOverRange,
                               }: props) => {

    const { t } = useTranslation('register')
    return (
        <div className="contents">
            <div className="rangeBox">
                <div className="leftRightTxt">
                    <span>{t('idealDetail.age')}</span>
                    <span className="ageRangeValue">
            {formatAgeValue(ageRange.min)} ~ {formatAgeValue(ageRange.max)} {ageOverRange ? "++" : ""}
          </span>
                </div>
                <div id="ageRange">
                    <InputRange
                        minValue={initAgeRange.min}
                        maxValue={initAgeRange.max}
                        value={ageRange}
                        formatLabel={(value) => ``} // 라벨 포맷 지정
                        step={1}
                        onChange={handleAgeChange}
                    />
                </div>
            </div>

            <div className="rangeBox">
                <div className="leftRightTxt">
                    <span>{t('idealDetail.distance')}</span>
                    <span className="nearRangeValue">
                        {
                            nearOverRange
                                ?
                                `${formatDistanceValue(nearRange.min)} ~ ${formatDistanceValue(nearRange.max)} ++`
                                :
                                t('modifyProfile.withinKm',{min:formatDistanceValue(nearRange.min),max:formatDistanceValue(nearRange.max)})

                        }
                    </span>
                </div>
                <div id="nearRange">
                    <InputRange
                        minValue={initNearRange.min}
                        maxValue={initNearRange.max}
                        step={10}
                        value={nearRange}
                        onChange={handleNearChange}
                        formatLabel={(value) => ``} // 라벨 포맷 지정
                    />
                </div>
            </div>

            <div className="rangeBox">
                <div className="leftRightTxt">
                    <span>{t('idealDetail.height')}</span>
                    <span className="heightRangeValue">
            {formatHeightValue(heightRange.min)} ~
                        {formatHeightValue(heightRange.max)} {heightOverRange ? "++" : ""}
          </span>
                </div>
                <div id="heightRange">
                    <InputRange
                        minValue={initHeightRange.min}
                        maxValue={initHeightRange.max}
                        step={1}
                        value={heightRange}
                        onChange={handleHeightChange}
                        formatLabel={(value) => ``} // 라벨 포맷 지정
                    />
                </div>
            </div>

        </div>
    );
};

export default IdealRangeSettingView;
