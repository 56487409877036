import { CSSTransition } from 'react-transition-group';
import {ProfileAddBadgeType} from "@dating/constants/ProfileAddBadgeType";
import {useTranslation} from "react-i18next";
interface props {
    openTypePop : boolean;
    setOpenTypePop : (open:boolean) => void;
    setBadgeTypeClick : () => void;
}

export const AuthAddBadgeTypePopView = ({openTypePop, setOpenTypePop, setBadgeTypeClick}:props) => {
    const { t } = useTranslation('profile')
    return(
        <>
            <CSSTransition
                in={openTypePop}
                classNames="popWrap-transition"
                timeout={100}
                unmountOnExit
            >
                <div className="popWrap layerPopWrap" id="selectPop">
                    <div className="popupInfo padding-b32">
                        <p className="fz20 bold-500 text-c padding-t8">{t('badge.selectType')}</p>
                        <div className="content">
                            <div className="txtRadio d-flex gap-8 popcloseBtn">
                                {
                                    ProfileAddBadgeType().map((val, idx)=>{
                                        return (
                                            <div key={val+idx} className="width-100p" onClick={()=>{setBadgeTypeClick(val)}}>
                                                <input type="radio" id={`select0${idx+1}`} name="select" value={val.code}/>
                                                <label htmlFor={`select0${idx+1}`}>{val.title}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}
