import { Schema } from '@colyseus/schema';
import { IUser, IDirectMessage } from '../../../types/IDirectMessageState';

export class UserSchema extends Schema implements IUser {
	userUid: string = '';
	nickName: string = '';
	profileUrl: string = '';
	region1: string = '';
	region2: string = '';
	age: string = '';
	role: string = '';
	inActive: boolean = false;
	isDeleted: boolean = false;
	blockType: string = '';
}

export class DirectMessageSchema extends Schema implements IDirectMessage {
	roomId: string = '';
	sender: UserSchema;
	receiver: UserSchema;
	content: string = '';
	createdAt: string = '';
	read: boolean = false;
	messageId: number = 0;

	constructor() {
		super();
		this.sender = new UserSchema();
		this.receiver = new UserSchema();
	}
}
