import { useState } from 'react';

export const useHushHomeStateRepo = () => {
	// addPreferUser 호출 시 스크롤 맨 아래로 이동
	const [scrollBottom, setScrollBottom] = useState<boolean>(false);

	// 별점평가 별 색칠여부
	const [isChecked, setIsChecked] = useState<number | null>(null);

	return {
		scrollBottom,
		setScrollBottom,
		isChecked,
		setIsChecked,
	}
}