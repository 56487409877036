import {useTranslation} from "react-i18next";

interface props {
    focusItem: { label: string, value: string | string[] };
    popOnHandler: (id: string) => void;
    closeEditPopup: (key: string) => void;
    interestList: string[] | { id: string, styleValue: string }[];
    interestValue: { id: string, styleValue: string }[];
    customInterestList: { id: string, styleValue: string }[];
    customInterestValue: string[] | { id: string, state: boolean }[];
    interestValueHandler: (id: string) => void;
    customInterestHandler: (id: string) => void;
    fnModifyProfileInterest: () => void;
}

const EditInterestPopView = ({
                                 focusItem,
                                 popOnHandler,
                                 closeEditPopup,
                                 interestList,
                                 interestValue,
                                 customInterestList,
                                 customInterestValue,
                                 interestValueHandler,
                                 customInterestHandler,
                                 fnModifyProfileInterest,
                             }: props) => {
    const { t } = useTranslation('register')
    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="cateTitBox">
                        <span className="cateTit01 like">{t('modifyProfile.like')}</span>
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="subAddBtn popOnBtn"*/}
                        {/*    onClick={() => {*/}
                        {/*        popOnHandler("interest");*/}
                        {/*    }}>*/}
                        {/*    나만의 항목 추가*/}
                        {/*</button>*/}
                    </div>
                    <div className="myCateList">
                        {
                            interestList.length > 0 ?
                                interestList.map((item) => {
                                    return (
                                        <button
                                            key={item.id + item.styleValue}
                                            type="button"
                                            className={
                                                interestValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                interestValueHandler(item.id);
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }

                        {
                            customInterestList.length > 0 ?
                                customInterestList?.map((item) => {
                                    return (
                                        <button
                                            key={item.id}
                                            type="button"
                                            className={
                                                customInterestValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                customInterestHandler(item.id)
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }
                    </div>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileInterest();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditInterestPopView;