import { useLikeHeaderService } from '@dating/ui/pages/like/views/useLikeHeaderService';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import React, { useRef } from 'react';
import { useLikeSendService } from '@dating/ui/pages/like/likeSend/useLikeSendService';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import LikeTitleView from '@dating/ui/pages/like/views/LikeTitleView';
import LikedPostListView from '@dating/ui/pages/like/likeReceive/views/LikedPostListView';
import LikeMoreButtonView from '@dating/ui/pages/like/views/LikeMoreButtonView';
import PostWithCommentListView from '@dating/ui/pages/like/likeReceive/views/PostWithCommentListView';
import SentLikeListView from '@dating/ui/pages/like/likeSend/views/SentLikeListView';
import HighlySentRatedListView from '@dating/ui/pages/like/likeSend/views/HighlySentRatedListView';
import {useTranslation} from "react-i18next";

function LikeSendContainer() {
	const {
		myProfile,
		setMyProfileOpen,
	} = useHeaderService();

	const {
		isReceived,
		handleScroll,
		scrollYIndex,
	} = useLikeHeaderService();

	const {
		noDataUserLike,
		fnGetSendUserLike,
		likeUsers,
		noDataHighlyRated,
		highlyRatedUsers,
		fnGetSendUserHighScore,
		srcPrefix,
		setUserProfileOpen,
		fnRemoveSendUserLike,
		fnRemoveSendHighlyRatedUsers,
		fnGetLikedPosts,
		noDataPost,
		likedPosts,
		fnGetSendPostComment,
		noDataComments,
		commentPosts,
		fadeIn,
		setSingleFeedId,
		sendIndex,
	} = useLikeSendService();

	const {
		setOpenPaymentPop,
		fnSendLikePopOpen,
		setMessageTargetUser,
	} = usePaymentService();

	const likeContainer = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('like');

	return (
		<div className="toDayWrap" ref={likeContainer} onScroll={() => {
			handleScroll(likeContainer.current?.scrollTop);
		}}>
			<div className="inner height-100p">
				<div className="tabContent">
					<div className={`${isReceived ? '' : 'active'} tabCon tabCon02`}>
						<div className={`likeList spinnerContent ${fadeIn ? 'fade-in' : ''}`}>

							{
								likeUsers.length === 0 && highlyRatedUsers.length === 0 && likedPosts.length === 0 && commentPosts.length === 0 ?
									(
										<>
											<div className="dataNoneTxt">
												<p className="txt01">{t('emptySentQuote')}</p>
											</div>
										</>
									)
									: null
							}

							{/*보낸 좋아요*/}
							{
								likeUsers.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('sentLikeTitSets', { returnObjects: true })[0]}
												index={0}
												scrollYIndex={scrollYIndex}
											/>
											<SentLikeListView
												setOpenPaymentPop={setOpenPaymentPop}
												likeUsers={likeUsers}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												fnRemoveSendUserLike={fnRemoveSendUserLike}
												setMessageTargetUser={setMessageTargetUser}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetSendUserLike}
												btnHandle={noDataUserLike}
											/>
										</>
									) : null
							}

							{/*높게 평가한 유저*/}
							{
								highlyRatedUsers.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('sentLikeTitSets', { returnObjects: true })[1]}
												scrollYIndex={scrollYIndex}
												index={1}
											/>
											<HighlySentRatedListView
												highlyRatedUsers={highlyRatedUsers}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												fnRemoveSendHighlyRatedUsers={fnRemoveSendHighlyRatedUsers}
												fnSendLikePopOpen={fnSendLikePopOpen}
												sendIndex={sendIndex}
											/>

											<LikeMoreButtonView
												fnGetData={fnGetSendUserHighScore}
												btnHandle={noDataHighlyRated}
											/>
										</>
									) : null
							}

							{/*좋아요 보낸 게시글*/}
							{
								likedPosts.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('sentLikeTitSets', { returnObjects: true })[2]}
												scrollYIndex={scrollYIndex}
												index={2}
											/>
											<LikedPostListView
												likePosts={likedPosts}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												currentUserUid={myProfile.userUid}
												setMyProfileOpen={setMyProfileOpen}
												setSingleFeedId={setSingleFeedId}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetLikedPosts}
												btnHandle={noDataPost}
											/>
										</>
									) : null
							}

							{/*댓글 단 게시글*/}
							{
								commentPosts.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('sentLikeTitSets', { returnObjects: true })[3]}
												scrollYIndex={scrollYIndex}
												index={3}
											/>
											<PostWithCommentListView
												srcPrefix={srcPrefix}
												commentPost={commentPosts}
												setUserProfileOpen={setUserProfileOpen}
												currentUserUid={myProfile.userUid}
												setMyProfileOpen={setMyProfileOpen}
												setSingleFeedId={setSingleFeedId}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetSendPostComment}
												btnHandle={noDataComments}
											/>
										</>
									) : null
							}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LikeSendContainer;
