import {useState} from "react";
import {JobListCOVO} from "@/site/api";

export const useEditProfilePopStateRepo = () => {

    const [heightValue, setHeightValue] = useState<string[]>(Array(3).fill(''));
    const [activeHeightIndex, setActiveHeightIndex] = useState(0);
    const [jobList, setJobList] = useState<Array<JobListCOVO>>([]);
    const [selectedJob, setSelectedJob] = useState<string>("");
    const [nicknameValue, setNicknameValue] = useState<string>("");
    const [introduceValue, setIntroduceValue] = useState<string>("");
    const [drinkingValue, setDrinkingValue] = useState<string>("");
    const [smokingValue, setSmokingValue] = useState<string>("");
    const [religionValue, setReligionValue] = useState<string>("");

    // 공통 관심사 리스트 상태 관리
    const [interestList, setInterestList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 선택한 공통 관심사
    const [interestValue, setInterestValue] = useState<{id: string, styleValue: string}[]>([]);

    // 유저가 추가한 관심사 리스트
    const [customInterestList, setCustomInterestList] = useState<{ id: string, styleValue: string }[]>([]);

    // 유저가 추가한 관심사 리스트 상태 관리
    const [customInterestValue, setCustomInterestValue] = useState<{id: string, state: boolean}[]>([]);

    // 공통 라이프 스타일 리스트 상태 관리
    const [lifeStyleList, setLifeStyleList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 선택한 공통 라이프스타일
    const [lifeStyleValue, setLifeStyleValue] = useState<{id: string, styleValue: string}[]>([]);

    // 유저가 추가한 라이프 스타일 리스트
    const [customLifeStyleList, setCustomLifeStyleList] = useState<{ id: string, styleValue: string }[]>([]);

    // 유저가 추가한 라이프 스타일 리스트 상태 관리
    const [customLifeStyleValue, setCustomLifeStyleValue] = useState<{id: string, state: boolean}[]>([]);

    // 나만의 항목 추가 팝업 show, hide
    const [isPopOn, setIsPopOn] = useState({id: "", active: false});

    // 나만의 항목 추가 팝업 input value
    const [customVal, setCustomVal] = useState<string>("");

    const [lengthOver, setLengthOver] = useState<boolean>(false);
    const [isValueEntered, setIsValueEntered] = useState<boolean>(false);
    const [isAvailable, setIsAvailable] = useState<boolean>(true);
    const [nickError, setNickError] = useState<boolean>(true);

    return {
        nickError,
        setNickError,
        customLifeStyleList,
        setCustomLifeStyleList,
        customInterestValue,
        setCustomInterestValue,
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        customVal,
        setCustomVal,
        isPopOn,
        setIsPopOn,
        heightValue,
        setHeightValue,
        activeHeightIndex,
        setActiveHeightIndex,
        interestList,
        setInterestList,
        lifeStyleList,
        setLifeStyleList,
        customInterestList,
        setCustomInterestList,
        customLifeStyleValue,
        setCustomLifeStyleValue,
        jobList,
        setJobList,
        selectedJob,
        setSelectedJob,
        nicknameValue,
        setNicknameValue,
        introduceValue,
        setIntroduceValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        lengthOver,
        setLengthOver,
        isValueEntered,
        setIsValueEntered,
        isAvailable,
        setIsAvailable,
    };
}