import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

interface props {
    isPopOn: { id: string, active: boolean };
    popOnHandler: (id: string) => void;
    popOnSaveHandler: (value: string) => void;
    setCustomVal: (value: string) => void;
    customVal: string;
};

const LifeStylePopupView = ({
                                isPopOn,
                                popOnHandler,
                                popOnSaveHandler,
                                customVal,
                                setCustomVal,
                            }: props) => {

    // 나만의 항목 추가 팝업 input
    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation('register')

    useEffect(() => {
        inputRef.current?.focus();
    }, [isPopOn.active, customVal]);

    return (
        <div className="popWrap" style={{display: isPopOn.active ? "flex" : "none"}}>
            <div className="popupInfo">
                <p className="fz20 bold-500 text-c padding-t8">{t('modifyProfile.addLifeOption')}</p>
                <div className="popInput input margin-t16">
                    <input
                        ref={inputRef}
                        onChange={(e) => setCustomVal(e.target.value)}
                        type="text"
                        value={customVal}
                        placeholder={t('modifyProfile.addLifePlaceholder')}
                        maxLength={14}/>
                </div>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            popOnHandler(isPopOn.id);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
							if (inputRef.current) {
								popOnSaveHandler(inputRef.current.value);
							}
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default LifeStylePopupView;