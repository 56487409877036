import { useEffect } from 'react';
import { useBlockReportRepo } from '@dating/repository/dating/useBlockReportRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useBlockReportStateRepo } from '@dating/ui/components/blockReport/useBlockReportStateRepo';
import { BlockUserCIVO, ReportCIVO } from '@/site/api';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import {useTranslation} from "react-i18next";

export const useBlockReportService = () => {
	const { t } = useTranslation('setting')
	const {
		openReport,
		setOpenReport,
		reportValue,
		setReportValue,
		commonReportCodes,
		setCommonReportCodes,
	} = useBlockReportStateRepo();

	const {
		openMenuPop,
		setOpenMenuPop,
		selectedOption,
		setSelectedOption,
		inputDisabled,
		setInputDisabled,
		openBlock,
		setOpenBlock,
		blockUserUid,
		setBlockUserUid,
		reportInfo,
		setReportInfo,
	} = useBlockReportRepo();

	const {
		getCommonCode,
		saveBlockUser,
		statusSaveBlockUser,
		dataSaveBlockUser,
		saveReport,
	} = useSettingAdapter();

	const { isMessageRoom } = useMessageAdapter();
	const { myProfile } = useDatingRepo();
	const { setCommentState, setBlockPostId, setOpenFeedHidePop } =
		useLoungeRepo();

	/////////////////////////////////////////////

	useEffect(() => {
		if (openReport) {
			getCommonCode()
				.then((response) => {
					if (response.length > 0) {
						setCommonReportCodes(response);
					}
				})
				.catch((reason) => {});
		}
	}, [openReport]);

	const getCurrentRoomId = async (targetUid: string) => {
		try {
			// 기존 방이 있는지 먼저 확인
			const existingRoomData = await isMessageRoom(targetUid);

			if (existingRoomData && existingRoomData.roomId) {
				return existingRoomData.roomId;
			}
		} catch (error) {
			console.error('방 ID 가져오기 실패:', error);
			return;
		}
	};

	/*
	 * menuDotClickHandler
	 * menuDot 클릭 시 신고하기차단하기 모달 팝업 open/close
	 */
	function menuDotClickHandler() {
		if (!openMenuPop) {
			setOpenMenuPop(true);
		} else {
			setOpenMenuPop(false);
		}
	}

	useEffect(() => {
		if (openMenuPop) {
			document.addEventListener('click', menuDotHandler);
		}
	}, [openMenuPop]);

	/*
	 * menuDotHandler
	 * 모달팝업 오픈 상태일 때 팝업 바깥쪽 클릭하면 팝업 닫기
	 */
	function menuDotHandler() {
		setOpenMenuPop(false);
	}

	/*
	 * handleOptionSelect
	 * 신고 팝업에서 선택한 신고 내용 set
	 */
	const handleOptionSelect = (option: string) => {
		setSelectedOption(option === selectedOption ? '' : option);
	};

	useEffect(() => {}, [reportValue, inputDisabled]);

	useEffect(() => {
		if (selectedOption === '000') {
			setInputDisabled(false);
		} else {
			setReportValue('');
			setInputDisabled(true);
		}
	}, [selectedOption]);

	/*
	 * reportInputHandler
	 * 신고하기 기타 사유 입력
	 */
	const reportInputHandler = (value: string) => {
		if (selectedOption === '000') {
			setReportValue(value);
		} else {
			setReportValue('');
		}
	};

	useEffect(() => {
		if (!openMenuPop) {
			setSelectedOption('');
		}
	}, [openMenuPop]);

	/*
	 * fnSaveBlockUser
	 * 유저 차단하기
	 */
	const fnSaveBlockUser = async (blockUserUid: string) => {
		const map: BlockUserCIVO = {
			blockUserUid: blockUserUid,
		};
		saveBlockUser(map);
		const currentDMRoomId = await getCurrentRoomId(blockUserUid);
		if (currentDMRoomId) {
			dmEventEmitter.emit('react-block-user', {
				roomId: currentDMRoomId,
				blockType: 'block',
				blockerUuid: myProfile.userUid,
				targetUid: blockUserUid,
			});
		}
	};

	useEffect(() => {
		if (statusSaveBlockUser === 'success' && dataSaveBlockUser) {
			setBlockUserUid('');
			setOpenBlock(false);
			setOpenMenuPop(false);
			alert(t('blocked'));
		}
	}, [statusSaveBlockUser, dataSaveBlockUser]);

	/*
	 * fnSaveReport
	 * 신고하기
	 */
	const fnSaveReport = () => {
		if (reportInfo.category != '' && selectedOption != '') {
			const map: ReportCIVO = {
				reportCategory: reportInfo.category,
				reportCode: selectedOption,
				reportDescription: reportValue,
				reportSource: reportInfo.location,
				reportTargetId: reportInfo.targetId,
			};
			saveReport(map)
				.then((value) => {
					if (value) {
						setOpenReport(false);
					}
				})
				.catch((reason) => {});
			// 팝업 닫기
			setOpenReport(false);
			setCommentState(false);
		} else {
			alert('신고정보가 없습니다.');
		}
	};

	/*
	 * reportPopOpen
	 * 신고하기 팝업 오픈 및 관련 정보 셋
	 */
	const reportPopOpen = (
		location: string,
		targetUid: string,
		category: string,
	) => {
		setReportInfo({
			location: location,
			category: category,
			targetId: targetUid,
		});
		setOpenReport(true);
		// footer 비노출 처리
		setCommentState(true);
	};

	/*
	 * reportPopClose
	 * 신고하기 팝업 닫기 및 관련 정보 초기화
	 */
	const reportPopClose = () => {
		setReportInfo({
			location: '',
			category: '',
			targetId: '',
		});
		setOpenReport(false);
		// footer 노출 처리
		setCommentState(false);
	};

	const hidePopOpen = (postId) => {
		setOpenFeedHidePop(true);
		setBlockPostId(postId);
		// footer 비노출 처리
		setCommentState(true);
	};

	const hidePopClose = () => {
		setOpenFeedHidePop(false);
		// footer 노출 처리
		setCommentState(false);
	};

	return {
		setOpenMenuPop,
		openMenuPop,
		openReport,
		setOpenReport,
		openBlock,
		setOpenBlock,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		reportValue,
		reportInputHandler,
		inputDisabled,
		commonReportCodes,
		setBlockUserUid,
		fnSaveBlockUser,
		fnSaveReport,
		reportInfo,
		setReportInfo,
		reportPopOpen,
		reportPopClose,
		hidePopOpen,
		hidePopClose,
	};
};
