import { Client, Room } from 'colyseus.js';
import { IMetaverseState } from '../../../types/IMetaverseState';
import { MetaMessageType } from '../../../types/MetaMessageType';
import { LobbyMessageType } from '../../../types/LobbyMessageType';
import { IRoomData, RoomType } from '../../../types/Rooms';
import { phaserEventEmitter } from '../events/PhaserEventEmitter';
import PermissionManager from '../web/PermissionManager';
import WebRTC from '../web/WebRTC';
import { PlayerEventProcess } from './metaverse/PlayerEventProcess';
import { RoomEventSetup } from './metaverse/RoomEventSetup';
import { StoreService } from './StoreService';
import { CreateClient } from './network/CreateClient';
import GameScene from '@/scenes/GameScene';
import DirectMessageManager from './directMessage/DirectMessageManager';
import { DirectMessageEventController } from '@/scenes/setup-game/DirectMessageEventController';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { IDMRoom, IUser } from '../../../types/IDirectMessageState';
import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { DirectMessageType } from '../../../types/DirectMessageType';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export default class Network implements RoomService {
	public readonly scene: GameScene;
	private client: Client;
	private lobby!: Room;
	private room: Room<IMetaverseState> | null = null;
	private _webRTC?: WebRTC;
	private roomType: RoomType | null = null;
	public static instance: Network | null = null;
	public storeService: StoreService;
	public playerEventProcess?: PlayerEventProcess;
	public dmManager: DirectMessageManager;
	private dmEventController: DirectMessageEventController;
	public static getInstance(scene: GameScene): Network {
		if (!Network.instance) {
			Network.instance = new Network(scene);
		}
		return Network.instance;
	}

	private constructor(scene: GameScene) {
		this.scene = scene;
		this.client = CreateClient.create();
		this.storeService = new StoreService();
		this.dmManager = DirectMessageManager.getInstance();
		this.dmManager.setupEventListeners();
		this.dmEventController = DirectMessageEventController.getInstance(this);
		this.dmEventController.listen();
	}

	get webRTC() {
		return this._webRTC;
	}

	get sessionId() {
		return this.room!.sessionId;
	}

	get dmSessionId() {
		return this.dmManager.sessionId;
	}

	get currentRoomType() {
		return this.roomType;
	}

	public async initializeLobby() {
		try {
			const userUid = useDatingRepo.getState().myProfile.userUid;
			const accessToken = useAuthRepo.getState().authToken.accessJmt;
			const langCode = useAuthRepo.getState().langCode;

			if (!userUid || !accessToken || !langCode) {
				throw new Error('로비 연결에 필요한 데이터가 없습니다');
			}
			this.lobby = await this.client.joinOrCreate(RoomType.LOBBY, {
				userUid,
				accessToken,
				langCode,
			});
			this.setupLobbyMessageHandlers();
			return true;
		} catch (error) {
			console.error('로비 연결 실패:', error);
			throw error;
		}
	}

	/* ---------- Lobby Room ---------- */
	private setupLobbyMessageHandlers() {
		this.lobby.onMessage('rooms', (rooms) => {
			this.storeService.setAvailableRoomsData(rooms);
		});
		this.lobby.onMessage('+', ([roomId, room]) => {
			this.storeService.setAddAvailableRoomData({ roomId, room });
		});
		this.lobby.onMessage('-', (roomId) => {
			this.storeService.setRemoveAvailableRoomData(roomId);
		});
	}

	public updateAccessToken(newAccessToken: string) {
		if (this.lobby) {
			const userUid = useDatingRepo.getState().myProfile.userUid;

			if (!userUid) {
				console.error('Unable to update token: userUid is not available');
				return;
			}

			// TokenUpdateMessage 인터페이스에 맞게 데이터 전송
			this.lobby.send(LobbyMessageType.ACCESS_TOKEN_UPDATE, {
				userUid: userUid,
				accessToken: newAccessToken,
			});
		} else {
			console.warn('Lobby is not initialized, cannot update token');
		}
	}

	/* ---------- WebRTC ---------- */
	private initRoomAndWebRTC() {
		new RoomEventSetup(this.room!, this.sessionId, this._webRTC).setup();
		const permissionManager = new PermissionManager(this._webRTC!, this);
		permissionManager.checkPermissions();
	}

	/* ---------- Phaser Event ---------- */
	private initEvent() {
		if (!this.room) return;
		const networkEventProcess = new PlayerEventProcess(
			this.room,
			this._webRTC!,
		);
		networkEventProcess.listen();
	}

	/* ---------- Setup Room ---------- */
	private async setupRoom(uuid: string) {
		this.storeService.setPlayerUUID(uuid);
		this.storeService.setSessionId(this.sessionId);
		this.storeService.clearAllPlayerNameMap();
		this._webRTC = new WebRTC(this.sessionId, this);
		this.initEvent();
		this.initRoomAndWebRTC();
		this.storeService.resetState();
	}

	/* ---------- DirectMessage Room ---------- */
	async joinOrCreateDMRoom(
		uuid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		region1: string,
		region2: string,
		role: string,
		inActive: boolean,
		isDeleted: boolean,
		blockType: string,
	): Promise<void> {
		await this.dmManager.joinOrCreateDMRoom(
			uuid,
			nickName,
			profileUrl,
			age,
			region1,
			region2,
			role,
			inActive,
			isDeleted,
			blockType,
		);
		dmEventEmitter.emit('react-dm-room-joined');
	}

	/* ---------- Public Room ---------- */
	async joinOrCreatePublic(
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		this.room = await this.client.create(RoomType.PUBLIC, {
			uuid,
			nickName,
			gender,
			profileUrl,
			role,
			x,
			y,
			anim,
			readyToConnect,
			mediaConnected,
			statusMessage,
			audioStatus,
		});
		this.roomType = RoomType.PUBLIC;
		await this.setupRoom(uuid);
	}

	/* ---------- Custom Room ---------- */
	async createCustomRoom(
		roomData: IRoomData,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		const {
			name,
			password,
			autoDispose,
			headCount,
			roomTheme,
			remainingTime,
			createdBy,
		} = roomData;

		this.room = await this.client.create(RoomType.CUSTOM, {
			name,
			password,
			autoDispose,
			headCount,
			roomTheme,
			remainingTime,
			createdBy,
			uuid,
			nickName,
			gender,
			profileUrl,
			role,
			x,
			y,
			anim,
			readyToConnect,
			mediaConnected,
			statusMessage,
			audioStatus,
		});
		this.roomType = RoomType.CUSTOM;
		await this.setupRoom(uuid);
	}
	async joinCustomById(
		roomId: string,
		password: number | null,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		this.room = await this.client.joinById(roomId, {
			password,
			uuid,
			nickName,
			gender,
			profileUrl,
			role,
			x,
			y,
			anim,
			readyToConnect,
			mediaConnected,
			statusMessage,
			audioStatus,
		});
		this.roomType = RoomType.CUSTOM;
		await this.setupRoom(uuid);
	}

	/* ---------- Leave Room & Cleanup ---------- */
	private cleanupWebRTC() {
		if (this._webRTC) {
			this._webRTC.cleanup();
			this._webRTC = undefined;
		}
	}
	async leaveCurrentRoom() {
		if (this.room) {
			reactEventEmitter.emit('react-leave-room', this.room.id);
			reactEventEmitter.emit('react-change-room-data', false);
			try {
				await this.room.leave();
				this.room = null;
				this.cleanupWebRTC();
				this.cleanupEventListeners();
			} catch (error) {}
		} else {
			return;
		}
	}
	leaveAllRooms() {
		this.leaveCurrentRoom();
		if (this.lobby) {
			this.lobby.leave();
		}
		if (this.dmManager.dmRoom) {
			this.dmManager.leaveDMRoom();
			this.dmEventController.removeAllListeners();
		}
	}

	public static leaveAllRoomsStatic() {
		if (Network.instance) {
			Network.instance.leaveAllRooms();
			Network.instance = null; // 인스턴스도 정리
		}
	}
	async cleanup() {
		try {
			// 1. DM 룸 강제 종료
			if (this.dmManager?.dmRoom) {
				// 강제 연결 종료 메시지 전송 후 연결 종료
				this.dmManager.dmRoom.send(DirectMessageType.FORCE_DISCONNECT_DM);
				await this.dmManager.dmRoom.leave(true);
				this.dmManager.dmRoom = null;
			}

			// 2. 메인 룸 정리
			if (this.room) {
				await this.room.leave(true);
				this.room = null;
			}

			// 3. 로비 정리
			if (this.lobby) {
				await this.lobby.leave(true);
				this.lobby.removeAllListeners();
			}

			// 4. WebRTC 정리
			this.cleanupWebRTC();

			// 5. 이벤트 리스너 정리
			this.cleanupEventListeners();

			// 6. Store 초기화
			this.storeService.resetState();
			this.storeService.clearAllPlayerNameMap();
			this.storeService.setPlayerUUID('');
			this.storeService.setSessionId('');

			// 7. DM 컨트롤러 정리
			if (this.dmEventController) {
				this.dmEventController.removeAllListeners();
			}

			// 8. 타입 초기화
			this.roomType = null;
		} catch (error) {
			console.error('Network cleanup failed:', error);
			throw error;
		}
	}

	public static async cleanup() {
		if (Network.instance) {
			await Network.instance.cleanup();
			Network.instance = null;
		}
	}

	private cleanupEventListeners() {
		try {
			// 1. Phaser 이벤트 정리
			phaserEventEmitter.shutdown();

			// 2. Player 이벤트 정리
			if (this.playerEventProcess) {
				this.playerEventProcess.removeListeners();
			}

			// 3. Room 이벤트 리스너 정리
			if (this.room) {
				this.room.removeAllListeners();
			}

			// 4. DM 이벤트 정리
			if (this.dmEventController) {
				this.dmEventController.removeAllListeners();
			}
		} catch (error) {
			console.error('Event listener cleanup failed:', error);
		}
	}

	/* ---------- Player ---------- */
	setUpUUID() {
		this.room?.send(MetaMessageType.UPDATE_PLAYER_UUID);
		phaserEventEmitter.emit('set-up-player-uuid');
	}
	readyToConnect() {
		this.room?.send(MetaMessageType.READY_TO_CONNECT);
		phaserEventEmitter.emit('my-player-ready');
	}
	mediaConnected() {
		this.room?.send(MetaMessageType.VIDEO_CONNECTED);
		phaserEventEmitter.emit('my-player-video-connected');
	}

	/* ---------- computer ---------- */
	connectToComputer(id: string) {
		this.room?.send(MetaMessageType.CONNECT_TO_COMPUTER, { computerId: id });
	}
	disconnectFromComputer(id: string) {
		this.room?.send(MetaMessageType.DISCONNECT_FROM_COMPUTER, {
			computerId: id,
		});
	}

	/* ---------- Whiteboard ---------- */
	connectToWhiteboard(id: string) {
		this.room?.send(MetaMessageType.CONNECT_TO_WHITEBOARD, {
			whiteboardId: id,
		});
	}
	disconnectFromWhiteboard(id: string) {
		this.room?.send(MetaMessageType.DISCONNECT_FROM_WHITEBOARD, {
			whiteboardId: id,
		});
	}

	/* ---------- ShareScreen ---------- */
	stopScreenShare(id: string) {
		this.room?.send(MetaMessageType.STOP_SCREEN_SHARE, { computerId: id });
	}

	/* ---------- Chat ---------- */
	addChatMessage(content: string) {
		this.room?.send(MetaMessageType.ADD_CHAT_MESSAGE, {
			content: content,
		});
	}
	receiveChatMessage() {
		this.room?.onMessage(MetaMessageType.ADD_CHAT_MESSAGE, (message) => {
			this.storeService.setChatMessage(message);
		});
	}

	/* ---------- DirectMessage ---------- */
	getDmRooms() {
		this.dmManager.getDirectMessageRoom();
	}
	enterDirectMessageRoom(roomId: string) {
		this.dmManager.enterDirectMessageRoom(roomId);
	}
	leaveDirectMessageRoom() {
		this.dmManager.leaveDirectMessageRoom();
	}
	checkUserInRoom(targetUid: string, roomId: string) {
		this.dmManager.checkUserInRoom(targetUid, roomId);
	}
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
		matchedAt: string | null,
		productType?: string,
		productSubType?: string,
		useStatus?: string,
		seq?: number,
		validUses?: number,
		orderId?: number,
	) {
		this.dmManager.createDirectMessageRoom(
			billingStatus,
			dmRoomType,
			roomId,
			user1Id,
			user2Id,
			user1Info,
			user2Info,
			createdAt,
			matchedAt,
			productType,
			productSubType,
			useStatus,
			seq,
			validUses,
			orderId,
		);
	}
	getDirectMessageRooms() {
		this.dmManager.getDirectMessageRoom();
	}
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	): void {
		this.dmManager.sendDirectMessage(
			roomId,
			messageId,
			sender,
			receiver,
			content,
			createdAt,
			read,
		);
	}
	receiveDirectMessage(): void {
		this.dmManager.receiveDirectMessage();
	}
	requestUnreadMessageCount(roomId: string): void {
		this.dmManager.requestUnreadMessageCount(roomId);
	}
	readDirectMessage(roomId: string, messageId: number, userUid: string): void {
		this.dmManager.readDirectMessage(roomId, messageId, userUid);
	}
	resetUnreadMessageCount(): void {
		this.dmManager.resetUnreadMessageCount();
	}
	leaveDirectMessage(roomId: string, leaverId: string): void {
		this.dmManager.leaveDirectMessage(roomId, leaverId);
	}
	exitDirectMessage(roomId: string, exit: boolean): void {
		this.dmManager.exitDirectMessage(roomId, exit);
	}
	singlePaymentCompleted(roomId: string, payerId: string): void {
		this.dmManager.singlePaymentCompleted(roomId, payerId);
	}
	mutualPaymentCompleted(roomId: string, paid: boolean): void {
		this.dmManager.mutualPaymentCompleted(roomId, paid);
	}
	changeDirectRoomType(roomId: string, dmRoomType: string): void {
		this.dmManager.changeDirectRoomType(roomId, dmRoomType);
	}
	changeDMUseStatus(roomId: string, useStatus: string): void {
		this.dmManager.changeDMUseStatus(roomId, useStatus);
	}
	blockUser(
		roomId: string,
		blockType: string,
		blockerUuid: string,
		targetUid: string,
	): void {
		this.dmManager.blockUser(roomId, blockType, blockerUuid, targetUid);
	}
	updateDMProfileUrl(userUid: string, profileUrl: string): void {
		this.dmManager.updateDMProfileUrl(userUid, profileUrl);
	}
	updateDirectMessageRoom(room: IDMRoom): void {
		this.dmManager.updateDirectMessageRoom(room);
	}
	removeDirectMessageRoom(roomId: string): void {
		this.dmManager.removeDirectMessageRoom(roomId);
	}
	changeDMInactive(userUid: string, inActive: boolean): void {
		this.dmManager.changeDMInactive(userUid, inActive);
	}
	changeDMIsDeleted(userUid: string, isDeleted: boolean): void {
		this.dmManager.changeDMIsDeleted(userUid, isDeleted);
	}

	/* ---------- Emoji ---------- */
	sendEmoji(senderId: string, emoji: string) {
		this.room?.send(MetaMessageType.SEND_EMOJI_MESSAGE, { senderId, emoji });
	}

	/* ---------- Chair ---------- */
	getChairState() {
		return this.room?.state.chairs;
	}
	updateChairStatus(chairId?: string, status?: boolean) {
		this.room?.send(MetaMessageType.UPDATE_CHAIR_STATUS, { chairId, status });
	}
	getPlayers() {
		return this.room?.state.players;
	}
}

/* ---------- roomService ---------- */
export function createRoomService(scene: GameScene): RoomService {
	return Network.getInstance(scene);
}

/* ---------- roomService interface---------- */
export interface RoomService {
	readonly scene: GameScene;
	get webRTC(): WebRTC | undefined;
	get sessionId(): string;
	get dmSessionId(): string;

	initializeLobby(): Promise<boolean>;

	updateAccessToken(newAccessToken: string): void;

	joinOrCreateDMRoom(
		uuid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		region1: string,
		region2: string,
		role: string,
		inActive: boolean,
		isDeleted: boolean,
		blockType: string,
	): Promise<void>;

	leaveCurrentRoom(): Promise<void>;

	createCustomRoom(
		roomData: IRoomData,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	joinCustomById(
		roomId: string,
		password: number | null,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	joinOrCreatePublic(
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		role: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	/* ---------- Player ---------- */
	setUpUUID(): void;
	readyToConnect(): void;
	mediaConnected(): void;

	/* ---------- Computer ---------- */
	connectToComputer(id: string): void;
	disconnectFromComputer(id: string): void;

	/* ---------- Whiteboard ---------- */
	connectToWhiteboard(id: string): void;
	disconnectFromWhiteboard(id: string): void;

	/* ---------- ShareScreen ---------- */
	stopScreenShare(id: string): void;

	/* ---------- Chat ---------- */
	addChatMessage(content: string): void;
	receiveChatMessage(): void;

	/* ---------- DirectMessage ---------- */
	getDmRooms(): void;
	enterDirectMessageRoom(roomId: string): void;
	leaveDirectMessageRoom(): void;
	checkUserInRoom(targetUid: string, roomId: string): void;
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
		matchedAt: string | null,
		productType?: string,
		productSubType?: string,
		useStatus?: string,
		seq?: number,
		validUses?: number,
		orderId?: number,
	): void;
	getDirectMessageRooms(): void;
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	): void;
	receiveDirectMessage(): void;
	readDirectMessage(roomId: string, messageId: number, userUid: string): void;
	resetUnreadMessageCount(): void;
	requestUnreadMessageCount(roomId: string): void;
	leaveDirectMessage(roomId: string, leaverId: string): void;
	exitDirectMessage(roomId: string, exit: boolean): void;
	singlePaymentCompleted(roomId: string, payerId: string): void;
	mutualPaymentCompleted(roomId: string, paid: boolean): void;
	changeDirectRoomType(roomId: string, dmRoomType: string): void;
	changeDMUseStatus(roomId: string, useStatus: string): void;
	blockUser(
		roomId: string,
		blockType: string,
		blockerUuid: string,
		targetUid: string,
	): void;
	updateDMProfileUrl(userUid: string, profileUrl: string): void;
	updateDirectMessageRoom(room: IDMRoom): void;
	removeDirectMessageRoom(roomId: string): void;
	changeDMInactive(userUid: string, inActive: boolean): void;
	changeDMIsDeleted(userUid: string, isDeleted: boolean): void;

	/* ---------- Emoji ---------- */
	sendEmoji(senderId: string, emoji: string): void;

	/* ---------- Chair ---------- */
	getChairState(): any;
	getPlayers(): any;
	updateChairStatus(chairId?: string, status?: boolean): void;
}
