import LeftMenuContainer from '@dating/ui/pages/sideBar/LeftMenuContainer';
import EventContainer from '@dating/ui/pages/sideBar/event/EventContainer';
import FriendInviteEventContainer from '@dating/ui/pages/sideBar/event/friendInviteEvent/FriendInviteEventContainer';
import MissionContainer from '@dating/ui/pages/sideBar/mission/MissionContainer';
import NoticeContainer from '@dating/ui/pages/sideBar/notice/NoticeContainer';
import StoreContainer from '@dating/ui/pages/store/StoreContainer';
import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from './RouteTransition';
import CharacterContainer from '@/ui/components/character/CharacterContainer';
import MakeRoomContainer from '@/ui/components/room/roomMake/MakeRoomContainer';
	
const SideBarRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();
	return (
		<RouteTransition location={location} navigationType={navigationType}>
			<Routes location={location}>
				<Route path="/" element={<LeftMenuContainer />} />
				<Route path="/avatar" element={<CharacterContainer />} />
				<Route path="/event" element={<EventContainer />} />
				<Route path="/notice" element={<NoticeContainer />} />
				<Route path="/mission" element={<MissionContainer />} />
				<Route
					path="/friendInviteEvent"
					element={<FriendInviteEventContainer />}
				/>
				<Route path="/store" element={<StoreContainer />} />
				<Route path="/makeRoom" element={<MakeRoomContainer />} />
			</Routes>
		</RouteTransition>
	);
};

export default SideBarRouter;
