import { CSSTransition } from 'react-transition-group';
import { ManagerProductsPopView } from '../views/ManagerProductsPopView';
import ScreenManagerContentView from './views/ScreenManagerContentView';
import ManagerInfoView from '../views/ManagerInfoView';
import ManagerHeaderView from '../views/ManagerHeaderView';
import ManagerFooterView from '../views/ManagerFooterView';
import { useOnlineManagerService } from '@dating/ui/pages/store/manager/online/service/useOnlineManagerService';

const ScreenManagerContainer = () => {
	const {
		openScreenProducts,
		fnOpenScreenProducts,
		managerProducts,
		fnCloseScreenProducts,
		fnValidateOrder,
		fnOpenScreenMessageRoom,
	} = useOnlineManagerService();

	return (
		<>
			<div
				className={`${openScreenProducts ? 'settingPopOn' : 'settingPop'}`}
				onClick={() => {
					fnCloseScreenProducts();
				}}
			>
				<div className="footPopDim"></div>
				<CSSTransition
					in={openScreenProducts}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<ManagerProductsPopView
						managerProducts={managerProducts}
						productSubType={'ONLINE'}
						fnValidateOrder={fnValidateOrder}
						onOpenMessageRoom={fnOpenScreenMessageRoom}
					/>
				</CSSTransition>
			</div>

			<div className="wrap footerNone">
				<ManagerHeaderView productSubType={'ONLINE'} />
				<div id="conWrap">
					<img
						src="/assets/img/common/online-mg-bg.jpg"
						alt="ONLINE MANAGER"
						width="100%"
					/>
					<ManagerInfoView productSubType={'ONLINE'} />
					<ScreenManagerContentView />
				</div>
				<ManagerFooterView fnOpenProducts={fnOpenScreenProducts} />
			</div>
		</>
	);
};
export default ScreenManagerContainer;
