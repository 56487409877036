import { Joystick } from 'react-joystick-component';
import { useAndroidJoyStickService } from './service/useAndroidJoyStickService';

const AndroidJoystickContainer = () => {
	const { joystickRef, handleJoyStick } = useAndroidJoyStickService();

	return (
		<Joystick
			size={120}
			stickSize={50}
			baseColor={'rgba(0,0,0,0.4)'}
			stickImage={'/assets/joystick/ic-joy.svg'}
			throttle={50}
			move={handleJoyStick}
			stop={handleJoyStick}
			start={handleJoyStick}
			minDistance={10}
			ref={joystickRef}
		/>
	);
};

export default AndroidJoystickContainer;
