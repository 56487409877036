import { useRecommendCodeService } from './service/useRecommendCodeService';
import RecommendCodeFooterView from './views/RecommendCodeFooterView';
import RecommendCodeContentsView from './views/RecommendCodeContentsView';
import React, { useEffect } from 'react';
import {
	useRegisterHeaderService,
} from '@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService';

import { useNavigate } from 'react-router-dom';
import StepProgressView from '@dating/ui/pages/register/common/stepProgress/views/StepProgressView';
import {useTranslation} from "react-i18next";

const RecommendCodeContainer = () => {
	const {
		setRecommendCode,
		fnSaveRecommender,
		codeFailed,
		response,
		registerSteps,
	} = useRecommendCodeService();

	const {
		skipHandler,
		skipRecommenderStatus,
		skipRecommenderData,
		step,
	} = useRegisterHeaderService();

	const navigate = useNavigate();

	const { t } = useTranslation('register')

	useEffect(() => {
		if (skipRecommenderStatus === 'success') {
			if (skipRecommenderData?.result) {
				navigate(registerSteps[9].url);
			}
		}
	}, [skipRecommenderStatus]);

	useEffect(() => {
		if (response) {
			navigate(registerSteps[9].url);
		}
	}, [response]);

	return (
		<div className="wrap noFooter">
			<header className="h-fixed">
				<div className="headerWrap">
					<div className="h-left">
						<button
							className="btnBack"
							onClick={() => {
								navigate(-1);
							}}
						/>
					</div>
					<div className="h-center"></div>
					<div className="h-right">
						<button
							type="button"
							className="fz18 bold-500"
							onClick={skipHandler}
						>
							{t('codeStep.pass')}
						</button>
					</div>
				</div>
			</header>

			<div className="contentsWrap">
				<div className="titleWrap">
					<StepProgressView step={step} />
					<h2 className="title01">{t('codeStep.inputCode')}</h2>
					<p className="titleSub01" dangerouslySetInnerHTML={{ __html: t('codeStep.inputCodeQuote') }}>
					</p>
				</div>
				<RecommendCodeContentsView
					setRecommendCode={setRecommendCode}
					codeFailed={codeFailed}
				/>
			</div>

			<RecommendCodeFooterView
				fnSaveRecommender={fnSaveRecommender}
			/>
		</div>
	);
};

export default RecommendCodeContainer;
