import { useTranslation } from "react-i18next";

export const ProfileAddBadgeType = () => {
    const { t } = useTranslation('profile')

    return [
        {
            title: t('badge.typeJob'),
            code: 'JOB'
        },
        {
            title: t('badge.typeIncome'),
            code: 'INCOME'
        },
        {
            title: t('badge.typeAsset'),
            code: 'ASSET'
        },
        {
            title: t('badge.typeCar'),
            code: 'CAR'
        },
        {
            title: t('badge.typeETC'),
            code: 'ETC'
        },
    ];
}