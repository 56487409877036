/**
 * app으로부터 bridge 수신 받는다
 * 받아서 hook, data 전달을 위한 context provider
 */

import { createContext, useEffect, useMemo, useState } from 'react';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useNavigate } from 'react-router-dom';
import { useOrderUtils } from '@/utils/orderUtils';
import { useMessageNavigation } from './ui/components/dm/service/useMessageNavigation';
import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

const SetBridgeContextProvider = createContext(undefined);

export const SetBridgeProvider: React.FC = ({ children }) => {
	const { handlePushNavigation, handleUserMatchPush } = useMessageNavigation();

	const navigate = useNavigate();
	const { setSingleFeedId } = useLoungeRepo();
	const { isSuccess, setIsSuccess } = useOrderRepo();
	const { setAuthToken } = useAuthRepo();

	const [bridgeVal, setBridgeVal] = useState('');
	const value = useMemo(
		() => ({
			bridgeVal,
		}),
		[bridgeVal],
	);

	const { fnSaveInAppPurchase } = useOrderUtils();
	useEffect(() => {
		// 넘겨줄 bridge response 값 필요하면 해당 함수에서 setBridgeVal(data)
		(window as any).receiveNotification = (data: any) => {
			// 인앱 상태 - 인앱 때는 정책 없는데 일단 작성
			switch (data) {
				case 'postLike':
					break;
				case 'comment':
					break;
				case 'userLike':
					break;
				case 'userMatch':
					break;
				case 'message':
					break;
			}
		};
		// 앱 백그라운드 상태에서 노티 클릭 브릿지 받기 위함
		(window as any).clickNotification = (data: any) => {
			goPushPage(
				data,
				navigate,
				setSingleFeedId,
				handleUserMatchPush,
				handlePushNavigation,
			);
		};
		// 인앱결제 결과 수신
		(window as any).purchaseResult = (data: any) => {
			if (data?.status == 'success') {
				alert(`결제 완료 되었습니다.`);
				setIsSuccess(data.status);
				setTimeout(() => {
					setIsSuccess('');
				}, 1000); // 1초 지연
			} else {
				alert(`결제가 실패하였습니다. ${data?.message}`);
			}
			// fnSaveInAppPurchase(data);
			// const successResult: Object = {
			//  status: 'success',
			//  message: 'Purchase successful',
			//  productId: 'test2',
			//  transactionId: 'GPA.3358-7596-8396-4179',
			// };
			// const failResult: Object = {
			//  status: 'failure',
			//  message: 'BillingResponse.billingUnavailable',
			//  productId: '',
			//  transactionId: '',
			// };
		};

		(window as any).setToken = (data: {
			accessToken: string;
			refreshToken: string;
		}) => {
			if (data && data.accessToken && data.refreshToken) {
				// 1. zustand 세팅
				setAuthToken({
					accessJmt: data.accessToken,
					refreshJmt: data.refreshToken,
				});
				// 2. sessionStorage setting
				const authInfo = JSON.parse(sessionStorage.getItem('authInfo') || '{}');
				authInfo.state.authToken.accessJmt = data.accessToken;
				authInfo.state.authToken.refreshJmt = data.refreshToken;
				sessionStorage.setItem('authInfo', JSON.stringify(authInfo));
			}
		};
	}, []);

	return (
		<SetBridgeContextProvider.Provider value={value}>
			{children}
		</SetBridgeContextProvider.Provider>
	);
};

export const goPushPage = (
	data,
	navigate,
	setSingleFeedId,
	handleUserMatchPush,
	handlePushNavigation,
) => {
	// 앱 백그라운드 상태 - 정상
	// 앱 종료 상태 - 아직 => 로그인 처리때 브릿지 온거 있는지 체크해서 랜딩페이지 처리해주기.
	switch (data.methodName) {
		case 'home':
			navigate(PATH.DATING.HOME);
			break;
		case 'postLike':
			navigate(PATH.LIKE.RECEIVED);
			break;
		case 'comment':
			setSingleFeedId(data.parameter1);
			navigate(PATH.LOUNGE.SINGLE);
			// 댓글 레이어 열라고 추가되면
			// commentListHandler(data, {userUid: '',nickname: ''});
			// commentViewHandler(true);
			break;
		case 'userLike':
			navigate(PATH.LIKE.RECEIVED);
			break;
		case 'userMatch':
			handleUserMatchPush();
			break;
		case 'message':
			const parameterMessage = JSON.parse(data.parameter1);
			handlePushNavigation(
				parameterMessage.roomId,
				parameterMessage.productSubType,
			);
			break;
		case 'managerMessage':
			const parameterMyManager = JSON.parse(data.parameter1);
			handlePushNavigation(
				parameterMyManager.roomId,
				parameterMyManager.productSubType,
			);
			break;
		case 'managerConnect':
			navigate(PATH.MANAGER.MESSENGER, { replacement: true });
			break;
		case 'managerNewMatching':
			const parameterManager = JSON.parse(data.parameter1);
			handlePushNavigation(
				parameterManager.roomId,
				parameterManager.productSubType,
			);
			break;
	}
};
