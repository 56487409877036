import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import { useEffect } from "react";
import { useProfileAdapter } from "@dating/adapter/profile/useProfileAdapter";
import { useDatingRepo } from "@dating/repository/dating/useDatingRepo";
import { useProfileImgEditStateRepo } from "@dating/ui/pages/profile/editProfile/useProfileImgEditStateRepo";

export const useProfileImgEditService = () => {
    const {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    } = useProfileImgEditStateRepo();

    const {
        previewURLs,
        clearPreviewURLs,
        cropFiles,
        setCropFiles,
        setPreviewURLs,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    const {
        modifyProfileImage,
        statusModifyProfileImage,
        dataModifyProfileImage,
    } = useProfileAdapter();

    /*
     * fnProfileImgUpdate
     * 프로필사진 수정
     */
    const fnProfileImgUpdate = () => {
        const map = {
            files: cropFiles
        }
        modifyProfileImage(map);
    };

    useEffect(() => {
        if (statusModifyProfileImage === "success" && dataModifyProfileImage) {
            clearPreviewURLs();
            clearCropFiles();
            setResponse(true);
        }
    }, [statusModifyProfileImage, dataModifyProfileImage]);

    useEffect(() => {
        if (cropFiles.length > 0 || cropFiles[0]?.size > 0) {
            setBtnActivate(false);
        } else {
            setBtnActivate(true);
        }
    }, [cropFiles]);

    return {
        fnProfileImgUpdate,
        previewURLs,
        btnActivate,
        srcPrefix,
        response,
    };
};