import {useLoungeAdapter} from "@dating/adapter/lounge/useLoungeAdapter";
import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {FeedFormType} from "@dating/model/LoungeModel";
import {useFeedFormStateRepo} from "@dating/ui/components/feedFrom/useFeedFormStateRepo";
import {useEffect} from "react";
import {useLoungeModifyAdapter} from "@dating/adapter/lounge/useLoungeModifyAdapter";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import {bufferArrayToBlobArray} from "@/utils/fileUtils";
import {useTranslation} from "react-i18next";

export const useFeedFormService = () => {
    const {
        inputHashTag,
        setInputHashTag,
    } = useFeedFormStateRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    const {
        showImgCrop,
        previewURLs,
        setPreviewURLs,
        clearPreviewURLs,
        cropFiles,
        setCropFiles,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        saveBtnDisabled,
        setSaveBtnDisabled,
        feedForm,
        setFeedForm,
        setNewFeedForm,
        clearFeedForm,
        modifyPostId,
        setModifyPostId,
        clearModifyPostId,
        prev,
        setPrev,
    } = useLoungeRepo();

    const {
        savePost,
        statusSavePost,
        dataSavePost,
    } = useLoungeAdapter();

    const {
        modifyPost,
        statusModifyPost,
        dataModifyPost,
        statusGetModifyPost,
        dataGetModifypost,
    } = useLoungeModifyAdapter(modifyPostId);

    const { t } = useTranslation('lounge');

    //////////////////////////////////////////////////////

    /*
     * backStep
     * 피드 작성/수정화면 초기화 및 뒤로 이동
     */
    const backStep = () => {
        initFeedForm();
        setPrev(true);
    };

    /*
     * fnSavePost
     * 새 게시글 저장
     */
    const fnSavePost = () => {
        const result = checkFeed(feedForm);
        if (result != null) {
            alert(result);
            return;
        }

        const param = {
            files: cropFiles,
            content: feedForm.content,
            tag: feedForm.tags,
        }
        savePost(param);
        setSaveBtnDisabled(true);
    };

    /*
     * fnModifyPost
     * 피드 수정. file validation 후 서버에 update 요청
     */
    function fnModifyPost() {
        const result = checkFeed(feedForm);
        if (result != null) {
            alert(result);
            return;
        }

        const param: {
            files?: Array<Blob>;
            postId?: string;
            content?: string;
            tag?: any[];
        } = {
            files: cropFiles,
            postId: feedForm.postId,
            content: feedForm.content,
            tag: feedForm.tags,
        }
        modifyPost(param);
    }

    /*
     * checkFeed
     * 피드 등록/수정 시 파일첨부여부 validation
     */
    const checkFeed = (feed: FeedFormType) => {
        if (!feed.files[0]) {
            return t('imgValidation');
        }
        return null;
    };

    /*
     * handleContentChange
     * 피드 내용 입력 시 repo에 저장
     */
    const handleContentChange = (value: string) => {
        setNewFeedForm({content: value});
    };

    /*
     * isEmptyValue
     * 태그 추가 시 문자열이 빈 값("")인지 확인, 빈 값이면 false / 빈 값이 아니면 빈칸을 제거한 값을 리턴
     */
    //TODO: 유틸로따로빼기
    const isEmptyValue = (value: string) => {
        if (value.trim().length === 0) {
            return false;
        } else {
            return value.trim();
        }
    };

    /**
     * addHashTag
     * 태그를 repo에 추가
     */
    const addHashTag = (code: string, value: string) => {
        const allowedCommand = ["Comma", "Enter", "NumpadEnter"];
        if (!allowedCommand.includes(code)) return;

        let resultValue: boolean | string = isEmptyValue(value);
        if (resultValue) {
            // 빈 문자열이 아닌 경우에만 추가
            const regExp = /[\{\}\[\]\/?.;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
            if (regExp.test(resultValue)) {
                resultValue = resultValue.replace(regExp, "");
            }
            if (resultValue.includes(",")) {
                resultValue = resultValue.split(",").join("");
            }

            // resultValue = "#" + resultValue;
            setInputHashTag(""); // 입력창 초기화
            setNewFeedForm({tags: [...feedForm.tags!, resultValue]});
        }
    };

    /**
     * removeHashTag
     * 태그 삭제 시, repo에서 삭제
     */
    const removeHashTag = (indexToRemove: number) => {
        if (feedForm.tags) {
            setNewFeedForm({tags: feedForm.tags.filter((_, idx) => idx !== indexToRemove)});
        }
    };

    /**
     * changeHashTagInput
     * 태그 입력 시 repo에 저장
     */
    const changeHashTagInput = (value: string) => {
        setInputHashTag(value);
    };

    useEffect(() => {
        // 수정할 포스트 조회(api) 후 repo에 저장
        if (statusGetModifyPost === "success" && dataGetModifypost && modifyPostId != "") {
            const result = bufferArrayToBlobArray(dataGetModifypost.files);
          
            setPreviewURLs(result.urls);
            setFeedForm({
                postId: dataGetModifypost.postId,
                files: result.blobs,
                content: dataGetModifypost.content,
                tags: dataGetModifypost.tag,
            });
            setCropFiles(result.blobs);

            clearModifyPostId();
            setSaveBtnDisabled(false);
        }
    }, [statusGetModifyPost, dataGetModifypost]);

    /*
     * initFeedForm
     * 피드폼, 미리보기url, 버튼 초기화
     */
    function initFeedForm () {
        clearFeedForm();
        clearPreviewURLs();
        clearCropFiles();
        clearModifyPostId();
        setSaveBtnDisabled(true);
    };

    useEffect(() => {
        // 피드 신규 작성 후 화면 및 관련 repo 초기화
        if (statusSavePost === "success" && dataSavePost) {
            initFeedForm();
            setPrev(true);
        }
    }, [statusSavePost, dataSavePost]);

    useEffect(() => {
        // 피드 수정 후 화면 및 관련 repo 초기화
        if (statusModifyPost === "success" && dataModifyPost) {
            initFeedForm();
            setPrev(true);
        }
    }, [statusModifyPost, dataModifyPost]);

    return {
        backStep,
        handleContentChange,
        saveBtnDisabled,
        removeHashTag,
        inputHashTag,
        changeHashTagInput,
        addHashTag,
        previewURLs,
        feedForm,
        prev,
        setPrev,
        showImgCrop,
        fnSavePost,
        fnModifyPost,
        srcPrefix,
        initFeedForm,
        setModifyPostId,
    };
};