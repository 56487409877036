import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import React, { useRef } from 'react';
import { useLikeReceiveService } from '@dating/ui/pages/like/likeReceive/useLikeReceiveService';
import { useLikeHeaderService } from '@dating/ui/pages/like/views/useLikeHeaderService';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import LikeTitleView from '@dating/ui/pages/like/views/LikeTitleView';
import ReceivedLikeListView from '@dating/ui/pages/like/likeReceive/views/ReceivedLikeListView';
import LikeMoreButtonView from '@dating/ui/pages/like/views/LikeMoreButtonView';
import HighlyRatedListView from '@dating/ui/pages/like/likeReceive/views/HighlyRatedListView';
import LikedPostListView from '@dating/ui/pages/like/likeReceive/views/LikedPostListView';
import PostWithCommentListView from '@dating/ui/pages/like/likeReceive/views/PostWithCommentListView';
import {useTranslation} from "react-i18next";

function LikeReceiveContainer() {
	const {
		myProfile,
		setMyProfileOpen,
		srcPrefix,
	} = useHeaderService();

	const {
		isReceived,
		handleScroll,
		scrollYIndex,
	} = useLikeHeaderService();

	const { fnSendLikePopOpen } = usePaymentService();

	const {
		noDataUserLike,
		fnGetReceiveUserLike,
		likeUsers,
		noDataHighlyRated,
		highlyRatedUsers,
		fnGetReceiveUserHighScore,
		noDataPost,
		fnGetReceivePostLike,
		likePosts,
		setUserProfileOpen,
		noDataComment,
		commentPost,
		fnGetReceivePostComment,
		fnRemoveReceiveUserLike,
		fnRemoveReceiveHighlyRatedUsers,
		fadeIn,
		setSingleFeedId,
		setMessageTargetUser,
		receivedLikeIndex,
		highlyRatedIndex,
	} = useLikeReceiveService();

	const likeContainer = useRef<HTMLDivElement>(null);

	const { t } = useTranslation('like');

	return (
		<div
			className="toDayWrap"
			ref={likeContainer}
			onScroll={() => {
				handleScroll(likeContainer.current?.scrollTop);
			}}
		>
			<div className="inner height-100p">
				<div className="tabContent">
					<div className={`${isReceived ? 'active' : ''} tabCon tabCon01`}>
						{/*<div className="likeList">*/}
						<div
							className={`likeList spinnerContent ${
								fadeIn ? 'fade-in' : ''
							}`}
						>
							{
								likeUsers.length === 0 && highlyRatedUsers.length === 0 && likePosts.length === 0 && commentPost.length === 0 ?
									(
										<>
											<div className="dataNoneTxt">
												<p className="txt01">{t('emptyReceivedQuote')}</p>
											</div>
										</>
									)
									: null
							}

							{/*받은 좋아요*/}
							{
								likeUsers.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('receivedLikeTitSets', { returnObjects: true })[0]}
												scrollYIndex={scrollYIndex}
												index={0}
											/>
											<ReceivedLikeListView
												receivedLikeIndex={receivedLikeIndex}
												likeUsers={likeUsers}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												fnRemoveReceiveUserLike={fnRemoveReceiveUserLike}
												fnSendLikePopOpen={fnSendLikePopOpen}
												setMessageTargetUser={setMessageTargetUser}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetReceiveUserLike}
												btnHandle={noDataUserLike}
											/>
										</>
									) : null
							}


							{/*높게 받은 평가*/}
							{
								highlyRatedUsers.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('receivedLikeTitSets', { returnObjects: true })[1]}
												scrollYIndex={scrollYIndex}
												index={1}
											/>
											<HighlyRatedListView
												highlyRatedUsers={highlyRatedUsers}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												fnRemoveReceiveHighlyRatedUsers={fnRemoveReceiveHighlyRatedUsers}
												fnSendLikePopOpen={fnSendLikePopOpen}
												highlyRatedIndex={highlyRatedIndex}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetReceiveUserHighScore}
												btnHandle={noDataHighlyRated}
											/>
										</>
									) : null
							}


							{/*좋아요 받은 게시글*/}
							{
								likePosts.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('receivedLikeTitSets', { returnObjects: true })[2]}
												scrollYIndex={scrollYIndex}
												index={2}
											/>
											<LikedPostListView
												likePosts={likePosts}
												srcPrefix={srcPrefix}
												setUserProfileOpen={setUserProfileOpen}
												currentUserUid={myProfile.userUid}
												setMyProfileOpen={setMyProfileOpen}
												setSingleFeedId={setSingleFeedId}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetReceivePostLike}
												btnHandle={noDataPost}
											/>
										</>
									) : null
							}


							{/*댓글 달린 게시글*/}
							{
								commentPost.length != 0 ?
									(
										<>
											<LikeTitleView
												titSet={t('receivedLikeTitSets', { returnObjects: true })[3]}
												scrollYIndex={scrollYIndex}
												index={3}
											/>
											<PostWithCommentListView
												srcPrefix={srcPrefix}
												commentPost={commentPost}
												setUserProfileOpen={setUserProfileOpen}
												currentUserUid={myProfile.userUid}
												setMyProfileOpen={setMyProfileOpen}
												setSingleFeedId={setSingleFeedId}
											/>
											<LikeMoreButtonView
												fnGetData={fnGetReceivePostComment}
												btnHandle={noDataComment}
											/>
										</>
									) : null
							}

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LikeReceiveContainer;
