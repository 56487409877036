import {useTranslation} from "react-i18next";

interface props {
	setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
	openMenuPop: boolean;
	setBlockPostId: (blockPostId: string) => void;
	postId: string;
	targetUid: string;
	reportPopOpen: (location: string, targetUid: string, category: string) => void;
	hidePopOpen: (postId: string) => void;
};

const FeedMenuView = ({
						  setOpenFeedHidePop,
						  openMenuPop,
						  setBlockPostId,
						  postId,
						  targetUid,
						  reportPopOpen,
						  hidePopOpen,
					  }: props) => {
	const { t } = useTranslation('common');

	return (
		<div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
			<button
				type="button"
				className="popOnBtn"
				onClick={() => {
					reportPopOpen(location.pathname, postId, 'Post');
				}}
			>
				{t('postPop.report')}
			</button>
			<a
				onClick={() => {
					// setOpenFeedHidePop(true);
					// setBlockPostId(postId);
					hidePopOpen(postId);
				}}
			>
				{t('postPop.hide')}
			</a>
		</div>
	);
};

export default FeedMenuView;
