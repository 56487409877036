import { Link } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useEffect, useState } from 'react';
import {useTranslation} from "react-i18next";

interface props {
	resetRegisterInfo: () => void;
	fnOauthLoginInfos: (provider: string) => void;
	isAppleSignInVisible: boolean;
	authToken: { accessJmt: string; refreshJmt: string };
	fnAutoLogin: (deviceId?: string, fcmToken?: string) => void;
	deviceId: string;
	fcmToken: string;
}

const RegisterMainView = ({
	isAppleSignInVisible,
	resetRegisterInfo,
	fnOauthLoginInfos,
	authToken,
	fnAutoLogin,
	deviceId,
	fcmToken,
}: props) => {
	const [isGoogle, setIsGoogle] = useState<boolean>(false);

	const { t } = useTranslation('register')

	useEffect(() => {
		const handleAuth = async () => {
			if (!(isGoogle && (window as any).flutter_inappwebview)) return;
			if (authToken.accessJmt && authToken.refreshJmt) {
				try {
					console.log('Auth token after set:', authToken);
					await fnAutoLogin(deviceId, fcmToken);
					setIsGoogle(false);
				} catch (error) {
					console.error('Login failed:', error);
				}
			} else {
				console.error('Token not set properly');
			}
		};

		handleAuth();
	}, [authToken]);
	return (
		<div className="mainBtm">
			<button type="button" className="btn btnBig btnBlack fz16">
				<Link
					to={PATH.REGISTER.PHONE}
					onClick={(e) => {
						resetRegisterInfo();
					}}
				>
					{t('phoneStep.phoneStart')}
				</Link>
			</button>
			<div className="mainSnsList">

				<a
					onClick={(e) => {
						e.preventDefault();
						setIsGoogle(true);
						fnOauthLoginInfos('google');
					}}
				>
					<img
						src="/assets/img/common/ico-google.png"
						width="50"
						height="50"
						alt="구글 로그인"
					/>
				</a>
				<a
					onClick={(e) => {
						e.preventDefault();
						fnOauthLoginInfos('kakao');
					}}
				>
					<img
						src="/assets/img/common/ico-kakao.png"
						width="50"
						height="50"
						alt="카카오톡 로그인"
					/>
				</a>
				<a
					onClick={(e) => {
						e.preventDefault();
						fnOauthLoginInfos('naver');
					}}
				>
					<img
						src="/assets/img/common/ico-naver.png"
						width="50"
						height="50"
						alt="네이버 로그인"
					/>
				</a>
				{isAppleSignInVisible && (
					<a
						onClick={(e) => {
							e.preventDefault();
							fnOauthLoginInfos('apple');
						}}
					>
						<img
							src="/assets/img/common/ico-apple.png"
							width="50"
							height="50"
							alt="애플계정 로그인"
						/>
					</a>
				)}
			</div>
		</div>
	);
};

export default RegisterMainView;
