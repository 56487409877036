export const detectDevice = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	const isIOS = /iphone|ipad|ipod/.test(userAgent);
	const isAndroid = /android/.test(userAgent);

	return {
		isIOS,
		isAndroid,
		isMobile: isIOS || isAndroid,
	};
};
