import {Link} from 'react-router-dom';
import { ReceiveLikeUserCOVO, SendUserLikeCOVO } from '@/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

interface props {
    setOpenPaymentPop: (openPaymentPop: boolean) => void;
    likeUsers: SendUserLikeCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (userUid: string) => void;
    fnRemoveSendUserLike: (id: string, type: string) => void;
    setMessageTargetUser: (messageTargetUser: string) => void;
}

const SentLikeListView = ({
                              setOpenPaymentPop,
                              likeUsers,
                              srcPrefix,
                              setUserProfileOpen,
                              fnRemoveSendUserLike,
                              setMessageTargetUser,
                          }: props) => {
    const { t } = useTranslation('like');

    return (
        <div
            className={`likeProfileList ${
                likeUsers.length === 0 ? 'likeProfileList-empty' : ''
            }`}
        >
            {likeUsers.length > 0
                ? likeUsers.map((item, i) => {
                    return (
                        <div
                            className="profileType"
                            // style={{
                            //     display: 'flex',
                            // }}
                            key={item.likeUserUid + i}
                        >
                            <div className="proLeft">
                                <div className="proImg">
                                    <Link
                                        to={PATH.PROFILE.USER_PROFILE}
                                        onClick={() => {
                                            setUserProfileOpen(item.likeUserUid);
                                        }}
                                        state={{
                                            isOtherProfile: true,
                                        }}
                                    >
                                        <img
                                            src={srcPrefix + item.profileUrl}
                                            alt="profile-img"
                                        />
                                    </Link>
                                </div>
                                <div className="proName margin-l12">
                                    <p className="bold padding-b4">{item.nickName}</p>
                                    <p className="fz14 color-666">
                                        {item.age + t('common.age') +', ' + (item.region1 && item.region2 ? `${item.region1} ${item.region2}` : t('common.noRegion'))}
                                    </p>
                                </div>
                            </div>
                            <div className="ProRight">
                                <button
                                    type="button"
                                    className="shareBtn fixOn"
                                    onClick={() => {
                                        if (item.likeUserUid != "") {
                                            setOpenPaymentPop(true);
                                            setMessageTargetUser(item.likeUserUid);
                                        }
                                    }}
                                />
                                <button
                                    type="button"
                                    className="closeBtn2 margin-l5"
                                    onClick={() => {
                                        fnRemoveSendUserLike(item.likeViewId, "send");
                                    }}
                                />
                            </div>
                        </div>
                    );
                })
                : 
                <div className="dataNoneBox">
                    <p className="txt01" dangerouslySetInnerHTML={{ __html: t('sentLike.quote') }}/>
                    <Link to={PATH.DATING.HOME}>
                        {t('common.homeBtn')}
                    </Link>
                </div>
                }
        </div>
    );
};

export default SentLikeListView;
