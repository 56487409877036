import {useTranslation} from "react-i18next";

interface props {
    interestValue: { [key: string]: boolean }[];
    interestHandler: (id: string) => void;
    lifeStyleValue: { [key: string]: boolean }[];
    lifeStyleHandler: (id: string) => void;
    drinkingValue: string,
    smokingValue: string,
    initDrinkingState: { code: string, value: string }[] | string[];
    initSmokingState: { code: string, value: string }[] | string[];
    setSmokingValue: (smokingValue: string) => void;
    setDrinkingValue: (drinkingValue: string) => void;
    lifeStyleList: string[] | { id: string, styleValue: string }[];
    interestList: string[] | { id: string, styleValue: string }[];
};

const LifeStyleContentsView = ({
                                   interestHandler,
                                   interestValue,
                                   lifeStyleValue,
                                   lifeStyleHandler,
                                   drinkingValue,
                                   smokingValue,
                                   initDrinkingState,
                                   initSmokingState,
                                   setSmokingValue,
                                   setDrinkingValue,
                                   lifeStyleList,
                                   interestList,
                               }: props) => {
    const { t } = useTranslation('register')

    return (
        <div className="contentsWrap">
            <div className="content margin-t16">
                <div className="cateTitBox">
                    <span className="cateTit01 like">{t('interestLifeStyle.interest')}</span>
                </div>

                <div className="myCateList">
                    {
                        interestList.length > 0 ?
                            interestList.map((item, i) => {
                                return (
                                    <button
                                        type="button"
                                        key={item.id + item.styleValue}
                                        className={
                                            interestValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            interestHandler(item.id);
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }
                </div>

                <div className="cateTitBox">
                    <span className="cateTit01 life">{t('interestLifeStyle.lifeStyle')}</span>
                </div>
                <div className="myCateList">
                    {
                        lifeStyleList.length > 0 ?
                            lifeStyleList.map((item) => {
                                return (
                                    <button
                                        key={item.id + item.styleValue}
                                        type="button"
                                        className={
                                            lifeStyleValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            lifeStyleHandler(item.id);
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }
                </div>

                <div className="cateTitBox">
                    <span className="cateTit01 smoking">{t('interestLifeStyle.smoking')}</span>
                </div>

                <div className="myCateList">
                    {
                        initSmokingState.map((item) => {
                            return (
                                <button
                                    key={item.id + item.value}
                                    className={item.code === smokingValue.toString() ? "active" : ""}
                                    onClick={() => {
                                        setSmokingValue(item.code);
                                    }}>
                                    {item.value}
                                </button>
                            )
                        })

                    }
                </div>

                <div className="cateTitBox">
                    <span className="cateTit01 smoking">{t('interestLifeStyle.drinking')}</span>
                </div>
                <div className="myCateList">
                    {
                        initDrinkingState.map((item) => {
                            return (
                                <button
                                    key={item.id + item.value}
                                    className={item.code === drinkingValue.toString() ? "active" : ""}
                                    onClick={() => {
                                        setDrinkingValue(item.code);
                                    }}>
                                    {item.value}
                                </button>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
};

export default LifeStyleContentsView;