import { create } from 'zustand';
import {
	ActiveProductCOVO,
	type ManagerProfileCOVO,
	UserProfileCOVO,
} from '@/site/api';

interface ManagerState {
	// 매니저 내 프로필
	managerMyProfile: UserProfileCOVO;
	setManagerMyProfile: (managerMyProfile: UserProfileCOVO) => void;

	// 매니저 프로필
	managerProfile: ManagerProfileCOVO;
	setManagerProfile: (managerProfile: ManagerProfileCOVO) => void;

	showMyManagerMessageList: boolean;
	setShowMyManagerMessageList: (showMyManagerMessageList: boolean) => void;

	// 매니저 상품 정보 추가
	activeManagerProducts: ActiveProductCOVO | null;
	setActiveManagerProducts: (products: ActiveProductCOVO | null) => void;
}
export const useManagerRepo = create<ManagerState>((set) => ({
	showMyManagerMessageList: false,
	setShowMyManagerMessageList: (state: boolean) =>
		set({ showMyManagerMessageList: state }),

	managerMyProfile: {
		userUid: '',
		nickName: '',
		age: '0',
		birthday: '',
		region1: '',
		region2: '',
		profileUrl: [],
		gender: '',
		introduce: '',
		job: '',
		height: 0,
		interest: [],
		lifeStyle: [],
		extraInterest: [],
		extraLifeStyle: [],
		smoking: '',
		drink: '',
		religion: '',
		ceo: false,
		house: false,
		salary: false,
		profession: false,
		car: false,
		phoneNumber: '',
		latestScore: 0,
		role: '',
		likeYn: false,
	},
	setManagerMyProfile: (managerMyProfile: UserProfileCOVO) =>
		set({ managerMyProfile }),

	// 매니저 프로필
	managerProfile: {
		userUid: '',
		nickName: '',
		profileUrl: [],
		intro: '',
		successCnt: 0,
		likeYn: false,
		score: 0,
	},
	setManagerProfile: (managerProfile: ManagerProfileCOVO) =>
		set({ managerProfile }),

	// 매니저 상품 정보 추가
	activeManagerProducts: null,
	setActiveManagerProducts: (products: ActiveProductCOVO | null) =>
		set({ activeManagerProducts: products }),
}));
