import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import ManagerMessageDetailHeaderView from '../../views/ManagerMessageDetailHeaderView';
import { useOnlineManagerDetailService } from './service/useOnlineManagerDetailService';
import OnlineManagerInputView from './views/OnlineManagerInputView';
import OnlineManagerDetailView from './views/OnlineManagerDetailView';

const OnlineManagerDetailContainer = () => {
	const {
		isOtherUserLeft,
		groupedMessagesDetail,
		setUserProfileOpen,
		handleSubmit,
		handleChange,
		focused,
		messageInputValue,
		srcPrefix,
		messagesEndRef,
		myProfile,
		navigationRoom,
		handleRoomMessageClick,
		isUseStatusDone,
		matchedAt,
		handleMessageSendClick,
	} = useOnlineManagerDetailService();
	const { setShowOnlineMessageList } = useDirectMessageRepo();

	const blockStatus = navigationRoom.otherUserInfo.blockType;

	return (
		<div className="wrap footerNone">
			<ManagerMessageDetailHeaderView
				navigationRoom={navigationRoom}
				setShowDirectMessageList={setShowOnlineMessageList}
				handleMessageSendClick={handleMessageSendClick}
			/>
			<div id="conWrap" className="chatWrap">
				<div className="messengerWrap">
					<form onSubmit={handleSubmit}>
						<OnlineManagerDetailView
							setUserProfileOpen={setUserProfileOpen}
							groupedMessagesDetail={groupedMessagesDetail}
							srcPrefix={srcPrefix}
							myProfile={myProfile}
							navigationRoom={navigationRoom}
							isOtherUserLeft={isOtherUserLeft}
							messagesEndRef={messagesEndRef}
							handleRoomMessageClick={handleRoomMessageClick}
							blockStatus={blockStatus}
							matchedAt={matchedAt}
						/>
						<OnlineManagerInputView
							handleSubmit={handleSubmit}
							messageInputValue={messageInputValue}
							handleChange={handleChange}
							focused={focused}
							isOtherUserLeft={isOtherUserLeft}
							blockStatus={blockStatus}
							isUseStatusDone={isUseStatusDone}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default OnlineManagerDetailContainer;
