import { create } from 'zustand';

type UserProfileState = {
	savedLikeProfile: boolean;
	setSavedLikeProfile: (savedLikeProfile: boolean) => void;
};

export const useUserProfileRepo = create<UserProfileState>((set) => ({
	savedLikeProfile: false,
	setSavedLikeProfile: (savedLikeProfile: boolean) => set({savedLikeProfile}),
}));