import { create } from 'zustand';
import { AllSendUserLikeCOVO } from '@/site/api';

interface LikeState {
	scrollY: number;
	setScrollY: (scrollY: number) => void;

	scrollYIndex: number;
	setScrollYIndex: (scrollY: any) => void;

	// 받은관심/보낸관심 탭 선택
	isReceived: boolean;
	setIsReceived: (isReceived: boolean) => void;

	// 받은 관심 좋아요 success
	savedLikeReceive: boolean;
	setSavedLikeReceive: (savedLikeReceive: boolean) => void;

	// 높게 받은 평가 좋아요 success
	savedLikeHighRated: boolean;
	setSavedLikeHighRated: (savedLikeHighRated: boolean) => void;

	// 높게 보낸 평가 좋아요 success
	savedLikeSend: boolean;
	setSavedLikeSend: (savedLikeSend: boolean) => void;

	// 최근 좋아요 보낸 유저들 목록 -> 이건 api호출로 받아온 날것의 정보
	sendLikeUsers: AllSendUserLikeCOVO[],
	setSendLikeUsers: (sendLikeUsers: AllSendUserLikeCOVO[]) => void;
}

export const useLikeRepo = create<LikeState>((set) => ({
	isReceived: true,
	setIsReceived: (isReceived: boolean) => set({ isReceived }),

	scrollY: 0,
	setScrollY: (scrollY: number) => set({ scrollY }),

	scrollYIndex: -1,
	setScrollYIndex: (scrollYIndex: number) => set({ scrollYIndex }),

	// 받은 관심 좋아요 success
	savedLikeReceive: false,
	setSavedLikeReceive: (savedLikeReceive: boolean) => set({savedLikeReceive}),

	// 높게 받은 평가 좋아요 success
	savedLikeHighRated: false,
	setSavedLikeHighRated: (savedLikeHighRated: boolean) => set({savedLikeHighRated}),

	// 높게 보낸 평가 좋아요 success
	savedLikeSend: false,
	setSavedLikeSend: (savedLikeSend: boolean) => set({savedLikeSend}),

	// 최근 좋아요 보낸 유저들 목록
	sendLikeUsers: [],
	setSendLikeUsers: (sendLikeUsers: AllSendUserLikeCOVO[]) =>set({ sendLikeUsers }),

}));
