import PhotoImageGuideView from '@dating/ui/pages/register/photo/views/PhotoImageGuideView';
import React, { useEffect } from 'react';
import { useImgCropService } from '@dating/ui/pages/imgCrop/service/useImgCropService';
import { useNavigate } from 'react-router-dom';
import { useProfileImgEditService } from '@dating/ui/pages/profile/editProfile/service/useProfileImgEditService';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { ImgAddBoxView } from '@dating/ui/components/imgAddBox/ImgAddBoxView';
import { useImgAddBoxService } from '@dating/ui/components/imgAddBox/useImgAddBoxService';
import {useTranslation} from "react-i18next";

export const ProfileImgEditContainer = () => {
	const {
		fnProfileImgUpdate,
		previewURLs,
		btnActivate,
		response,
	} = useProfileImgEditService();

	const {
		updateFile,
		clearCropFiles,
		cropFiles,
	} = useImgCropService();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const {
		removeImg,
		osType,
		arrayToBlob,
	} = useImgAddBoxService();

	const navigate = useNavigate();

	const { t } = useTranslation('profile')

	useEffect(() => {
		if (response) {
			navigate(-1);
		}
	}, [response]);

	return (
		<div className="wrap footerNone">
			<header className="h-fixed">
				<div className="headerWrap bg-fff">
					<div className="h-left">
						<button
							type="button"
							className="btnBack"
							onClick={() => {
								navigate(-1);
								clearCropFiles();
							}}
						/>
					</div>
				</div>
			</header>

			<div className="contentsWrap">
				<div className="titleWrap">
					<h2 className="title01">{t('photoEdit.quote1')}</h2>
					<p className="titleSub01">{t('photoEdit.quote2')}</p>
				</div>

				<div className="content">
					<div className="imgAddBox">
						{
							[0, 1].map(boxIndex => {
								return (
									<ImgAddBoxView
										key={boxIndex}
										boxIndex={boxIndex}
										previewURLs={previewURLs}
										osType={osType}
										arrayToBlob={arrayToBlob}
										updateFile={updateFile}
										removeImg={removeImg}
									/>
								);
							})
						}
					</div>

					<div className="imgAddBox box4 margin-t8 margin-b32">
						{
							[2, 3, 4, 5].map(boxIndex => {
								return (
									<ImgAddBoxView
										key={boxIndex}
										boxIndex={boxIndex}
										previewURLs={previewURLs}
										osType={osType}
										arrayToBlob={arrayToBlob}
										updateFile={updateFile}
										removeImg={removeImg}
									/>
								);
							})
						}
					</div>

					<PhotoImageGuideView />
				</div>
			</div>

			<footer>
				<div className="f-btnWrap noFixed">
					<button
						type="button"
						className="btn btnBig btnBlack"
						onClick={() => {
							if (cropFiles.length === 0) {
								fnCommonAlertPopOn(t('photoEdit.quote2'));
								return;
							}
							fnProfileImgUpdate();
						}}
						disabled={btnActivate}
					>
						<span>{t('photoEdit.complete')}</span>
					</button>
				</div>
			</footer>

		</div>
	);
};