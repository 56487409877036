import { create } from 'zustand';

interface PaymentPopState {
	// 베리드충전필요 알림 팝업 on/off
	chargeAlertPopOn: boolean;
	setChargeAlertPopOn: (chargeAlertPopOn: boolean) => void;

	// 베리드 충전 팝업 on/off
	chargePopOn: boolean;
	setChargePopOn: (chargePopOn: boolean) => void;
};

export const usePaymentPopRepo = create<PaymentPopState>((set) => ({
	// 베리드충전필요 알림 팝업 on/off
	chargeAlertPopOn: false,
	setChargeAlertPopOn: (chargeAlertPopOn: boolean) => set({chargeAlertPopOn}),

	// 베리드 충전 팝업 on/off
	chargePopOn: false,
	setChargePopOn: (chargePopOn: boolean) => set({chargePopOn}),
}));