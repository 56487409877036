import { useEffect, useRef, useCallback } from 'react';
import {
	reactEventEmitter,
	REACT_CLICK_SCREEN,
} from '@/events/ReactEventEmitter';
import { useMetaverse } from '@/MetaverseProvider';
import { useChatRepo } from '@/stores/useChatRepo';
import { useJoystickRepo } from '@/stores/useJoystickRepo';

export interface JoystickDirection {
	type: 'move' | 'stop';
	direction: Direction;
	x: number;
	y: number;
}

export type Direction =
	| 'RIGHT'
	| 'RIGHT-FORWARD'
	| 'FORWARD'
	| 'LEFT-FORWARD'
	| 'LEFT'
	| 'LEFT-BACKWARD'
	| 'BACKWARD'
	| 'RIGHT-BACKWARD'
	| '';

export const calculateMoreDirection = (
	x: number,
	y: number,
	direction: Direction,
): Direction => {
	const atanX = Math.atan(x);
	const atanY = Math.atan(y);

	switch (direction) {
		case 'LEFT':
			if (atanY <= -0.5) return 'LEFT-BACKWARD';
			if (atanY >= 0.5) return 'LEFT-FORWARD';
			return 'LEFT';
		case 'FORWARD':
			if (atanX <= -0.5) return 'LEFT-FORWARD';
			if (atanX >= 0.5) return 'RIGHT-FORWARD';
			return 'FORWARD';
		case 'RIGHT':
			if (y <= -0.5) return 'RIGHT-BACKWARD';
			if (y >= 0.5) return 'RIGHT-FORWARD';
			return 'RIGHT';
		case 'BACKWARD':
			if (atanX <= -0.5) return 'LEFT-BACKWARD';
			if (atanX >= 0.5) return 'RIGHT-BACKWARD';
			return 'BACKWARD';
		default:
			return '';
	}
};

export const useiOSJoystickService = (
	size: number,
	stickSize: number,
	minDistance: number = 10,
) => {
	const { isInMetaverseRoot } = useMetaverse();
	const { showChat } = useChatRepo();
	const {
		position,
		setJoystickPosition,
		setJoystickDirection,
		setJoystickMoreDirection,
	} = useJoystickRepo();

	const joystickRef = useRef<HTMLDivElement>(null);
	const stickRef = useRef<HTMLDivElement>(null);
	const isDraggingRef = useRef(false);
	const startPositionRef = useRef({ x: 0, y: 0 });
	const lastDirectionRef = useRef<Direction>('');

	const resetJoystickPosition = useCallback(() => {
		if (showChat) {
			setJoystickPosition({ x: 20, y: 244 });
		} else {
			setJoystickPosition({ x: 20, y: 136 });
		}

		if (stickRef.current) {
			// 중앙 위치로 스틱 재설정
			const centerX = (size - stickSize) / 2;
			const centerY = (size - stickSize) / 2;
			stickRef.current.style.transition = 'all 0.2s ease-out';
			stickRef.current.style.left = '50%';
			stickRef.current.style.top = '50%';
			stickRef.current.style.transform = 'translate(-50%, -50%)';
		}
	}, [showChat, size, stickSize, setJoystickPosition]);

	const processJoystickAction = useCallback(
		(direction: JoystickDirection) => {
			setJoystickDirection(direction.type);
			setJoystickDirection(direction.direction);
			setJoystickMoreDirection(direction.direction); // moreEventDirection 대신 현재 방향 사용

			lastDirectionRef.current = direction.direction;
		},
		[setJoystickDirection, setJoystickMoreDirection],
	);

	const calculateDirection = useCallback(
		(deltaX: number, deltaY: number): JoystickDirection | '' => {
			const normalizedX = deltaX / (size / 2);
			const normalizedY = deltaY / (size / 2);
			const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

			if (distance < 10) return '';

			// 각도 계산 (0도는 오른쪽, 시계 반대 방향)
			const angle = Math.atan2(-deltaY, deltaX) * (180 / Math.PI);

			// 8방향 구간 정의 (각 방향은 45도 구간)
			let direction: Direction;

			// 각도를 0~360 범위로 변환
			const normalizedAngle = angle < 0 ? angle + 360 : angle;

			// 더 부드러운 8방향 판정
			if (normalizedAngle >= 337.5 || normalizedAngle < 22.5) {
				direction = 'RIGHT';
			} else if (normalizedAngle >= 22.5 && normalizedAngle < 67.5) {
				direction = 'RIGHT-FORWARD';
			} else if (normalizedAngle >= 67.5 && normalizedAngle < 112.5) {
				direction = 'FORWARD';
			} else if (normalizedAngle >= 112.5 && normalizedAngle < 157.5) {
				direction = 'LEFT-FORWARD';
			} else if (normalizedAngle >= 157.5 && normalizedAngle < 202.5) {
				direction = 'LEFT';
			} else if (normalizedAngle >= 202.5 && normalizedAngle < 247.5) {
				direction = 'LEFT-BACKWARD';
			} else if (normalizedAngle >= 247.5 && normalizedAngle < 292.5) {
				direction = 'BACKWARD';
			} else {
				direction = 'RIGHT-BACKWARD';
			}

			return {
				type: 'move',
				direction,
				x: normalizedX,
				y: normalizedY,
			};
		},
		[size],
	);
	const handleJoystickStart = useCallback(
		(x: number, y: number) => {
			if (!isInMetaverseRoot || !joystickRef.current) return;

			isDraggingRef.current = true;
			startPositionRef.current = { x, y };

			const joystickRect = joystickRef.current.getBoundingClientRect();
			const centerX = joystickRect.left + joystickRect.width / 2;
			const centerY = joystickRect.top + joystickRect.height / 2;

			if (stickRef.current) {
				const deltaX = x - centerX;
				const deltaY = y - centerY;
				const direction = calculateDirection(deltaX, deltaY);

				if (direction) {
					processJoystickAction(direction);
				}
			}
		},
		[isInMetaverseRoot, calculateDirection, processJoystickAction],
	);

	const handleJoystickMove = useCallback(
		(x: number, y: number) => {
			if (!isDraggingRef.current || !joystickRef.current || !stickRef.current)
				return;

			const joystickRect = joystickRef.current.getBoundingClientRect();
			const centerX = joystickRect.left + joystickRect.width / 2;
			const centerY = joystickRect.top + joystickRect.height / 2;

			const deltaX = x - centerX;
			const deltaY = y - centerY;
			const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

			if (distance < minDistance) {
				if (stickRef.current) {
					stickRef.current.style.transform = 'translate(-50%, -50%)';
				}
				return;
			}

			// 스틱의 최대 이동 거리 제한
			const maxRadius = size / 2;
			const angle = Math.atan2(deltaY, deltaX);

			let moveX = deltaX;
			let moveY = deltaY;

			if (distance > maxRadius) {
				moveX = Math.cos(angle) * maxRadius;
				moveY = Math.sin(angle) * maxRadius;
			}

			// transition 제거하고 transform만 사용
			if (stickRef.current) {
				stickRef.current.style.transform = `translate(calc(-50% + ${moveX}px), calc(-50% + ${moveY}px))`;
			}

			const direction = calculateDirection(deltaX, deltaY);
			if (direction && direction.direction !== lastDirectionRef.current) {
				processJoystickAction(direction);
			}
		},
		[size, stickSize, minDistance, calculateDirection, processJoystickAction],
	);

	const handleJoystickStop = useCallback(() => {
		if (stickRef.current) {
			// 스틱을 중앙으로 부드럽게 복귀
			stickRef.current.style.transition = 'all 0.2s ease-out';
			stickRef.current.style.left = '50%';
			stickRef.current.style.top = '50%';
			stickRef.current.style.transform = 'translate(-50%, -50%)';
			stickRef.current.style.transition = '';
		}

		isDraggingRef.current = false;
		setJoystickDirection('stop');
		setJoystickMoreDirection('');
		lastDirectionRef.current = '';
		setJoystickPosition(showChat ? { x: 20, y: 244 } : { x: 20, y: 136 });
	}, [
		setJoystickDirection,
		setJoystickMoreDirection,
		showChat,
		setJoystickPosition,
	]);

	useEffect(() => {
		if (!isInMetaverseRoot) return;

		const handleTouchStart = (event: TouchEvent) => {
			const touch = event.touches[0];
			handleJoystickStart(touch.clientX, touch.clientY);
		};

		const handleTouchMove = (event: TouchEvent) => {
			event.preventDefault();
			const touch = event.touches[0];
			handleJoystickMove(touch.clientX, touch.clientY);
		};

		const handleClickScreen = (args: REACT_CLICK_SCREEN) => {
			handleJoystickStart(args.x, args.y);
		};

		joystickRef.current?.addEventListener('touchstart', handleTouchStart);
		document.addEventListener('touchmove', handleTouchMove, { passive: false });
		document.addEventListener('touchend', handleJoystickStop);
		reactEventEmitter.on('react-click-screen', handleClickScreen);

		return () => {
			joystickRef.current?.removeEventListener('touchstart', handleTouchStart);
			document.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('touchend', handleJoystickStop);
			reactEventEmitter.off('react-click-screen', handleClickScreen);
		};
	}, [
		isInMetaverseRoot,
		handleJoystickStart,
		handleJoystickMove,
		handleJoystickStop,
	]);

	return {
		position,
		joystickRef,
		stickRef,
	};
};
