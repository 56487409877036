import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';

export const useCommonConfirmPopService = () => {
	const {
		confirmPopMessage,
		setConfirmPopMessage,
		confirmPopOpen,
		setConfirmPopOpen,
		confirmOkFunc,
		setConfirmOkFunc,
		initConfirmOkFunc,
		confirmNoFunc,
		setConfirmNoFunc,
		initConfirmNoFunc,
	} = useDatingRepo();

	/*
	 * fnCommonConfirmPopOn
	 * 팝업 열기 - 메세지, 실행함수 세팅
	 */
	const fnCommonConfirmPopOn = (message: string, okFunc: any, okParam: any, noFunc: any, noParam: any) => {
		if (message === '') {
			setConfirmPopOpen(false);
			return;
		}
		setConfirmPopMessage(message);
		setConfirmOkFunc({ param: okParam, func: okFunc });
		setConfirmNoFunc({ param: noParam, func: noFunc });
		setConfirmPopOpen(true);
	};

	/*
	 * fnCommonConfirmPopOff
	 * 팝업 닫기(취소) - 메세지, 실행함수 초기화
	 */
	const fnCommonConfirmPopOff = () => {
		if (confirmNoFunc.param != null) {
			confirmNoFunc.func(confirmNoFunc.param);
		} else if (confirmNoFunc.func != null) {
			confirmNoFunc.func();
		}

		setConfirmPopMessage('');
		initConfirmOkFunc();
		initConfirmNoFunc();

		// 팝업닫기
		setConfirmPopOpen(false);
	};

	/*
	 * fnCommonConfirmPopExecute
	 * 확인 버튼 클릭 (함수 실행 및 팝업 닫기)
	 */
	const fnCommonConfirmPopExecute = () => {
		if (confirmOkFunc.param != null) {
			confirmOkFunc.func(confirmOkFunc.param);
		} else {
			confirmOkFunc.func();
		}

		// 팝업닫기
		setConfirmPopOpen(false);
	};

	return {
		confirmPopMessage,
		confirmPopOpen,
		setConfirmPopOpen,
		fnCommonConfirmPopOn,
		fnCommonConfirmPopOff,
		confirmOkFunc,
		fnCommonConfirmPopExecute,
	};
};
