/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMessageRoomCIVO } from '../models/CreateMessageRoomCIVO';
import type { ForbiddenWordCOVO } from '../models/ForbiddenWordCOVO';
import type { MessageCIVO } from '../models/MessageCIVO';
import type { MessageInviteCIVO } from '../models/MessageInviteCIVO';
import type { MessageListCOVO } from '../models/MessageListCOVO';
import type { MessageReadStatusCIVO } from '../models/MessageReadStatusCIVO';
import type { MessageRoom } from '../models/MessageRoom';
import type { MessageRoomCIVO } from '../models/MessageRoomCIVO';
import type { MessageRoomCOVO } from '../models/MessageRoomCOVO';
import type { MessageRoomIdCOVO } from '../models/MessageRoomIdCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MessageService {
    /**
     * 메시지 방 아이디 생성
     * @returns MessageRoomIdCOVO 방 아이디
     * @throws ApiError
     */
    public static createMessageRoomId(): CancelablePromise<MessageRoomIdCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/createMessageRoomId',
        });
    }
    /**
     * 메시지 방 생성
     * @param requestBody 방 생성 정보
     * @returns MessageRoomIdCOVO 생성된 Message 방 ID
     * @throws ApiError
     */
    public static createMessageRoom(
        requestBody: CreateMessageRoomCIVO,
    ): CancelablePromise<MessageRoomIdCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/message/createMessageRoom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메시지 방 여부 확인
     * @param toUserUid 상대방 아이디
     * @returns MessageRoomIdCOVO roomId: 존재하는 방 아이디 (없으면 방생성Api 호출 / 있으면 메시지 저장Api 호출
     * @throws ApiError
     */
    public static isMessageRoom(
        toUserUid: string,
    ): CancelablePromise<MessageRoomIdCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/isMessageRoom',
            query: {
                'toUserUid': toUserUid,
            },
        });
    }
    /**
     * 메시지 방 정보 수정
     * @param requestBody 업데트할 방 정보
     * @returns MessageRoom 업데이트 된 Message 방 정보
     * @throws ApiError
     */
    public static updateMessgaeRoom(
        requestBody: MessageRoomCIVO,
    ): CancelablePromise<MessageRoom> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/message/updateMessgaeRoom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메시지 방 리스트 조회
     * @param userUid 자신의 회원 아이디
     * @returns MessageRoomCOVO 메시지 방 정보 리스트
     * @throws ApiError
     */
    public static getMessageRoomList(
        userUid?: string,
    ): CancelablePromise<MessageRoomCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/getMessageRoomList',
            query: {
                'userUid': userUid,
            },
        });
    }
    /**
     * 메시지 리스트 조회
     * @param roomId 방 아이디
     * @param userUid 자신의 회원 아이디
     * @returns MessageListCOVO 메시지 리스트
     * @throws ApiError
     */
    public static getMessageList(
        roomId: string,
        userUid?: string,
    ): CancelablePromise<MessageListCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/getMessageList',
            query: {
                'roomId': roomId,
                'userUid': userUid,
            },
        });
    }
    /**
     * 메시지 내용 저장 (단건)
     * @param requestBody 메시지 정보
     * @returns boolean true: 성공, false: 실패
     * @throws ApiError
     */
    public static saveMessage(
        requestBody: MessageCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/message/saveMessage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 초대 이력 저장
     * @param requestBody 초대자, 초대 받은 사람 정보
     * @returns any
     * @throws ApiError
     */
    public static saveInvite(
        requestBody: MessageInviteCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/message/invite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메시지 방 나가기
     * @param roomId 방 아이디
     * @returns boolean true: 성공, false: 실패
     * @throws ApiError
     */
    public static leaveMessageRoom(
        roomId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/message/leaveMessageRoom',
            query: {
                'roomId': roomId,
            },
        });
    }
    /**
     * 메시지 읽음 상태 수정
     * @param requestBody 메시지 읽음 상태 정보
     * @returns boolean true: 성공, false: 실패
     * @throws ApiError
     */
    public static updateMessageReadStatus(
        requestBody: MessageReadStatusCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/message/updateMessageReadStatus',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메시지 방 정보 조회
     * @param roomId 방 아이디
     * @returns MessageRoom 메시지 방 정보
     * @throws ApiError
     */
    public static getMessageRoomInfo(
        roomId: string,
    ): CancelablePromise<MessageRoom> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/getMessageRoomInfo',
            query: {
                'roomId': roomId,
            },
        });
    }
    /**
     * 메시지 보내기 (포인트 상품)
     * @param requestBody 방 생성 정보
     * @returns MessageRoomIdCOVO 생성된 Message 방 ID
     * @throws ApiError
     */
    public static sendMessage(
        requestBody: CreateMessageRoomCIVO,
    ): CancelablePromise<MessageRoomIdCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/message/sendMessage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 대화 열기 (포인트 상품)
     * @param requestBody 업데트 할 방 정보
     * @returns MessageRoom 업데이트 된 Message 방 정보
     * @throws ApiError
     */
    public static openMessageRoom(
        requestBody: MessageRoomCIVO,
    ): CancelablePromise<MessageRoom> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/message/openMessageRoom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 금칙어 리스트
     * @returns ForbiddenWordCOVO 금칙어 리스트
     * @throws ApiError
     */
    public static getForbiddenWords(): CancelablePromise<Array<ForbiddenWordCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/message/forbidden-word',
        });
    }
}
