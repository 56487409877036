import { Link } from 'react-router-dom';
import { UserProfileCOVO } from '@/site/api';
import {PATH} from "@dating/constants/RoutingEndPoints";

interface props {
	myProfile: UserProfileCOVO;
	srcPrefix: string;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
}

const ProfileSmImgView = ({
	myProfile,
	srcPrefix,
	setMyProfileOpen,
}: props) => {
	return (
		<div>
			<Link
				to={PATH.PROFILE.MY_PROFILE}
				onClick={() => {
					//내 프로필 open
					setMyProfileOpen(true);
				}}
			>
				<button type="button" className="profileSmImg">
					<img
						src={
							myProfile && myProfile?.profileUrl[0]
								? srcPrefix + myProfile?.profileUrl[0]
								: '/assets/img/test/boy.png'
						}
						alt="테스트이미지"
					/>
				</button>
			</Link>
		</div>
	);
};

export default ProfileSmImgView;
