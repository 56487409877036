import { ManagerService } from '@/site/api';
import { useManagerRepo } from '@dating/repository/manager/useManagerRepo';

export const useManagerAdapter = () => {
	const { setActiveManagerProducts } = useManagerRepo();

	/**
	 * 매니저 상품 구매 (매니저 매칭)
	 * @param orderId 주문 ID
	 * @returns MatchedResultCOVO 매니저와 매칭된 정보
	 * @throws ApiError
	 */
	const purchaseManagerProduct = (orderId: number) => {
		return ManagerService.purchaseManagerProduct(orderId);
	};

	/**
	 * 매니저 사용 내역 조회
	 * @returns ManagerProductListCOVO 매니저 사용 내역 조회
	 * @throws ApiError
	 */
	const getManagerUsageHistory = () => {
		return ManagerService.getManagerUsageHistory();
	};

	/**
	 * 사용중인 매니저 상품이 있는지 조회
	 * @returns MatchedResultCOVO 사용 중인 매니저 상품 조회
	 * @throws ApiError
	 */
	const getActiveManagerProduct = async () => {
		const results = await ManagerService.getActiveManagerProduct();
		const productData = Array.isArray(results) ? results[0] : results;
		setActiveManagerProducts(productData);
		return results;
	};

	/**
	 * 매니저 상품 사용
	 * @param orderId 주문 ID
	 * @returns ManagerMatchingRoomCOVO 매니저 상품 사용 정보
	 * @throws ApiError
	 */
	const useManagerProduct = async (orderId: number) => {
		const result = await ManagerService.useManagerProduct(orderId);
		// 상품 사용 후 자동으로 활성 상품 정보 갱신
		await getActiveManagerProduct();
		return result;
	};

	/**
	 * 나의 매니저 조회
	 * @returns ManagerInfoCOVO 나의 매니저 조회
	 * @throws ApiError
	 */
	const getMyManager = () => {
		return ManagerService.getMyManager();
	};
	return {
		getMyManager,
		purchaseManagerProduct,
		getManagerUsageHistory,
		getActiveManagerProduct,
		useManagerProduct,
	};
};
