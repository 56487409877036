import { I18nMessages, MessageParams, SupportedLanguages } from "./types";

const messages: Record<SupportedLanguages, I18nMessages> = {
  ko: {
    square: () => {
      return "광장";
    },
    welcome: ({ playerName, roomName }) =>
      `${playerName} 님, ${roomName}에 오신 것을 환영합니다.`,
    leave: ({ playerName }) => `${playerName} 님이 나가셨습니다.`,
  },
  en: {
    square: () => {
      return "Square";
    },
    welcome: ({ playerName, roomName }) =>
      `Welcome to ${roomName}, ${playerName}.`,
    leave: ({ playerName }) => `${playerName} has left.`,
  },
  vi: {
    square: () => {
      return "Quảng trường";
    },
    welcome: ({ playerName, roomName }) =>
      `Chào mừng ${playerName} đến với ${roomName}.`,
    leave: ({ playerName }) => `${playerName} đã rời đi.`,
  },
};

export const getI18nMessage = <T extends keyof I18nMessages>(
  messageType: T,
  langCode: string | SupportedLanguages,
  params?: T extends "square" ? never : MessageParams[T]
): string => {
  // 지원하는 언어가 아닌 경우 한국어를 기본값으로 사용
  const supportedLangCode = Object.keys(messages).includes(langCode)
    ? langCode
    : "en";
  if (messageType === "square") {
    return messages[supportedLangCode as SupportedLanguages].square();
  } else if (messageType === "welcome") {
    return messages[supportedLangCode as SupportedLanguages].welcome(
      params as MessageParams["welcome"]
    );
  } else {
    return messages[supportedLangCode as SupportedLanguages].leave(
      params as MessageParams["leave"]
    );
  }
};

export default messages;
