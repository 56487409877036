import React from "react";
import {useTranslation} from "react-i18next";

interface props {
    inputValue: string;
    setInputValue: (inputValue: string) => void;
    handleInputChange: (newValue: string) => void;
    lengthOver: boolean;
    isAvailable: boolean;
    isValueEntered: boolean;
}

const NicknameView = ({
                          inputValue,
                          setInputValue,
                          handleInputChange,
                          lengthOver,
                          isAvailable,
                          isValueEntered,
                      }: props) => {
    const { t } = useTranslation('register')

    return (
        <div className="content">
            <div className="inputWrap">
                <div className="input">
                    <input
                        id="firstFocus"
                        type="text"
                        name="name"
                        placeholder={t('nicknameStep.nicknamePlaceholder')}
                        value={inputValue}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            handleInputChange(newValue);
                        }}
                        autoComplete="off"
                    />
                </div>
                <p className="sub-color fz12 padding-t8">
                    {
                        isValueEntered ?
                            lengthOver ?
                                t('nicknameStep.nicknameValidation')
                                : isAvailable ?
                                    ""
                                    : t('nicknameStep.nicknameValidation2')
                            : ""
                    }
                </p>
            </div>
        </div>
    );
};

export default NicknameView;