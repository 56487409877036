import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import {
	MetaRoomCIVO,
	MetaService,
	MetaRoomUserInCIVO,
	MetaRoomChatCIVO,
	MetaRoomUserMatchCIVO,
} from '@/site/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useMetaRoomAdapter = () => {
	const queryClient = useQueryClient();

	/*
	 * saveCreateRoom
	 * 메타버스 방만들기 룸생성 저장
	 */
	const saveCreateRoom = useMutation({
		mutationFn: (map: MetaRoomCIVO) => MetaService.saveCreateRoom(map),
		onSuccess: (data	) => {
			queryClient.invalidateQueries({ queryKey: ['getUsableRoom'] });
			return data;
		},
		onError: (error) => {
			console.error('Room creation error:', error);
		},
	});

	/*
	 * saveRoomUserIn
	 * 메타버스 유저 룸 입장 저장
	 */
	const saveRoomUserIn = useMutation({
		mutationFn: (map: MetaRoomUserInCIVO) => MetaService.saveRoomUserIn(map),
		onSuccess: (data) => {
			if (!data.result && data.resultCode?.includes('패스워드가 틀렸습니다')) {
				throw {
					response: {
						data: {
							message: data.resultCode
						}
					}
				};
			}
			queryClient.invalidateQueries({ queryKey: ['getMetaRoom'] });
			return data;
		},
		onError: (error: any) => {
			throw error;
		}
	});
	/*
	 * saveRoomChat
	 * 메타버스 룸 채팅 저장
	 */
	const saveRoomChat = useMutation({
		mutationFn: (map: MetaRoomChatCIVO) => MetaService.saveRoomChat(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getRoomChat'] });
			return data;
		},
	});

	/**
	 * matchUser
	 * 메타버스 온라인 매칭권 매칭되었는지 확인
	 */
	const matchUser = useMutation({
		mutationFn: (map: MetaRoomUserMatchCIVO) => MetaService.matchUser(map),
		onSuccess: (data) => {
			// 기존 쿼리 무효화
			queryClient.invalidateQueries({ queryKey: ['getRoomChat'] });

			// 매칭된 방들에 대해 이벤트 발생
			if (data.messageRoomIds && data.messageRoomIds?.length! > 0) {
				data.messageRoomIds!.forEach((roomId) => {
					dmEventEmitter.emit('react-change-dm-use-status', {
						roomId: roomId,
						useStatus: 'DONE',
						matchedAt: data.matchedAt,
					});
				});
			}

			return data;
		},
	});

	/*
	 * getDisposedRoomCheck
	 * 메타버스 삭제된 방인지 체크
	 */
	const getDisposedRoomCheck = async (roomId: string) => {
		if (roomId != '') {
			return MetaService.getDisposedRoomCheck(roomId);
		}
	};

	/*
	 * getMetaRoom
	 * 메타버스 방 정보 조회
	 */

	const getMetaRoom = async (roomId: string) => {
		if (roomId != '') {
			return MetaService.getMetaRoom(roomId);
		}
	};

	/*
	 * updateMetaRoomUser
	 * 메타버스 방에 있는 유저 정보 업데이트
	 */
	const updateMetaRoomUser = useMutation({
		mutationFn: ({
			roomId,
			userUid,
			...requestBody
		}: {
			roomId: string;
			userUid: string;
			isExit: boolean;
			password?: number; // MetaRoomUserUpdateCIVO의 필드
		}) => MetaService.updateMetaRoomUser(roomId, userUid, requestBody),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMetaRoom'] });
			return data;
		},
		onError: (error: any) => {
			if (error?.response?.status === 400) {
				throw error.response;
			}
			throw error;
		}
	});

	return {
		updateMetaRoomUser: updateMetaRoomUser.mutate,
		statusUpdateMetaRoomUser: updateMetaRoomUser.status,
		dataUpdateMetaRoomUser: updateMetaRoomUser.data,

		saveRoomChat: saveRoomChat.mutateAsync, // mutate 대신 mutateAsync 반환
		statusSaveRoomChat: saveRoomChat.status,
		dataSaveRoomChat: saveRoomChat.data,

		matchUser: matchUser.mutate,
		statusMatchUser: matchUser.status,
		dataMatchUser: matchUser.data,

		saveCreateRoom: saveCreateRoom.mutate,
		statusSaveCreateRoom: saveCreateRoom.status,
		dataSaveCreateRoom: saveCreateRoom.data,

		saveRoomUserIn: saveRoomUserIn.mutate,
		statusSaveRoomUserIn: saveRoomUserIn.status,
		dataSaveRoomUserIn: saveRoomUserIn.data,

		getDisposedRoomCheck,
		getMetaRoom,
	};
};
