import { UserSettingCOVO } from "@/site/api";
import { useTranslation } from 'react-i18next';

interface props {
	userSettings: UserSettingCOVO | null;
	settingsSaveHandler: (type: string, state: boolean) => void;
};

export const SettingsProfileSuggestionView = ({
												  userSettings,
												  settingsSaveHandler,
											  }: props) => {
	const { t } = useTranslation('setting');
	return (
		<>
			<p className="title02 padding-b16">
				{t('recommend')}
				{/* 추천 */}
			</p>

			<div className="leftRightTxt margin-b5">
                <span className="fz16Fw500">
                    {t('profileVisibility')}
					{/* 프로필 공개 */}
                </span>
				<input
					type="checkbox"
					className="switch"
					checked={userSettings?.openProfileYn ? userSettings?.openProfileYn : false}
					onChange={() => settingsSaveHandler("openProfile", !userSettings?.openProfileYn)}
				/>
			</div>

			<p className="fz12 color-999 margin-b24">
				{t('disableProfile')}
				{/* 비활성화시 사람들에게 프로필 추천이 되지 않습니다. */}
				<br />
				{t('managerOnlyVisible')}
				{/* 매니저에게만 공개됩니다. */}
			</p>
		</>
	);
};
