import {
    FeedFormType,
    newFeedFormType,
} from "@dating/model/LoungeModel";
import {create} from "zustand";
import {CommentCOVO, PostInfoCOVO} from "@/site/api";
import {createRef} from "react";


interface LoungeState {
    // 피드 작성 시 이미지 없는 경우 등록하기 버튼 disabled
    saveBtnDisabled: boolean;
    setSaveBtnDisabled: (saveBtnDisabled) => void;

    // 댓글 더보기 (댓글창 open)
    feedCommentOpen: boolean;
    setFeedCommentOpen: (feedCommentOpen) => void;

    //feedList에서 입력한 검색어
    keyword: string;
    setKeyword: (keyword) => void;

    //피드 검색결과 리스트
    searchResult: PostInfoCOVO[];
    setSearchResult: (searchResult) => void;

    focusingId: string;
    setFocusingId:(focusingId) => void;

    // 피드 작성, 수정 타입 및 내용
    feedForm: FeedFormType;
    setFeedForm: (feedForm: FeedFormType) => void;
    setNewFeedForm: (newFeedForm: newFeedFormType) => void;
    clearFeedForm: () => void;

    // 피드 검색 활성화
    openSearchBox: boolean;
    setOpenSearchBox: (openSearchBox: boolean) => void;

    // 수정할 postId
    modifyPostId: string;
    setModifyPostId: (modifyPostId: string) => void;
    clearModifyPostId: () => void;

    // 게시글 숨기기 팝업
    openFeedHidePop: boolean;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;

    // 숨길 게시글 ID (postId)
    blockPostId: string;
    setBlockPostId: (blockPostId: string) => void;

    // 성별필터
    feedFilterOpen: boolean;
    setFeedFilterOpen: (feedFilterOpen: boolean) => void;

    // 필터리스트
    feedFilterList: {label: string, value: string}[];

    // 싱글피드에 넘길 postId
    singleFeedId: string;
    setSingleFeedId: (singleFeedId: string) => void;

    // feedEditMenu handle
    feedEditPops: {};
    setFeedEditPops: (feedEditPops: {}) => void;

    // 피드리스트 스크롤 위치
    scrollY: number;
    setScrollY: (scrollY: number) => void;

    // 피드리스트 탑 버튼 show/hide
    isTopBtnVisible: boolean;
    setIsTopBtnVisible: (isTopBtnVisible: boolean) => void;

    // 피드리스트 댓글창 on/off
    commentState: boolean;
    setCommentState: (commentState: boolean) => void;

    prev: boolean;
    setPrev: (prev: boolean) => void;

    hasMore: boolean;
    setHasMore: (hasMore:boolean) => void;

    myPosts: PostInfoCOVO[];
    setMyPosts: (list:PostInfoCOVO[]) => void;

    postList: PostInfoCOVO[];
    setPostList: (list:PostInfoCOVO[]) => void;
    // 인피니티 스크롤용 데이터 누적 합치기
    setPostListAdd: (list: PostInfoCOVO[]) => void;
    // 수정, 삭제 발생 시, 리스트 가공
    setPostListEvent: ({flag:string, id:number}) => void;

    // 피드정보 (이미지, 내용 등)
    feedDetail: PostInfoCOVO | null;
    setFeedDetail: (postInfo: PostInfoCOVO) => void;
    setFeedDetailEvent: ({flag: string}) => void;

    observeCard: string;
    setObserveCard: (id:string) => void;

    fadeIn: boolean;
    setFadeIn: (fadeIn:boolean) => void;

    // scrollWrapRef 영역
    scrollWrapRef: React.RefObject<HTMLDivElement>;
    // infinite scroll load target 영역
    target: React.RefObject<HTMLDivElement> | null;
    setTarget: (ref: HTMLDivElement | null) => void;

    isClickedFeedMenu: boolean;
    setIsClickedFeedMenu: (click:boolean) => void;

    // 무한스크롤 시, 리스트 중복제거를 위한 페이지 리스트 담는 array
    pageArray: number[];
    setPageArray: (page:number) => void;
    setPageArrayReset: () => void;
    getMaxPage: () => number | null;

    commentValue: string;
    setCommentValue: (commentValue: string) => void;
    openComment: boolean;
    setOpenComment: (state:boolean) => void;

    focusedPostId: string;
    setFocusedPostId: (val:string) => void;

    // 댓글 조회 api 응답결과
    commentResponse: CommentCOVO[] | undefined;
    setCommentResponse: (arr: CommentCOVO[]) => void;

    isSearchPost: boolean;
    setIsSearchPost: (isSearch:boolean) => void;

    // savepost 등 이벤트 발생 시, 피드 캐싱x 새로 렌더링 해야한다는 플래그
    haveToRefresh: boolean;
    setHaveToRefresh: (val:boolean) => void;

    // 싱글피드에서 숨김/삭제처리하면 navigate(-1) 하려고 만든 변수
    // 이동되고 나서 isHiddenDelete(false)
    isHiddenDelete: boolean;
    setIsHiddenDelete: (hidden:boolean) => void;
}

const useLoungeRepo = create<LoungeState>(
    (set, get) => ({
        saveBtnDisabled: true,
        setSaveBtnDisabled: (saveBtnDisabled: boolean) => set({saveBtnDisabled}),

        feedCommentOpen: false,
        setFeedCommentOpen: (feedCommentOpen: boolean) => set({feedCommentOpen}),

        keyword: "",
        setKeyword: (keyword: string) => set({keyword}),

        searchResult: [],
        setSearchResult: (searchResult: PostInfoCOVO[]) => set({searchResult}),

        focusingId:"",
        setFocusingId:(focusingId:string) => set({focusingId:focusingId}),

        feedForm: {
            // name: "",
            // title: "",
            postId: "",
            files: [],
            content: "",
            tags: [],
        },
        setFeedForm: (feedForm: FeedFormType) => set({feedForm}),
        setNewFeedForm: (newFeedForm: newFeedFormType) => set((state) => ({feedForm: {...state.feedForm, ...newFeedForm}})),
        clearFeedForm: () => set({
            feedForm: {
                postId: "",
                files: [],
                content: "",
                tags: [],
            }
        }),

        openSearchBox: false,
        setOpenSearchBox: (openSearchBox: boolean) => set({openSearchBox}),

        modifyPostId: "",
        setModifyPostId: (modifyPostId: string) => set({modifyPostId}),
        clearModifyPostId: () => set({modifyPostId: ""}),

        openFeedHidePop: false,
        setOpenFeedHidePop: (openFeedHidePop: boolean) => set({openFeedHidePop}),

        blockPostId: "",
        setBlockPostId: (blockPostId: string) => set({blockPostId}),

        feedFilterOpen: false,
        setFeedFilterOpen: (feedFilterOpen: boolean) => set({feedFilterOpen}),

        feedFilterList: [
            {
                label: "모두",
                value: "A",
            },
            {
                label: "여성",
                value: "F",
            },
            {
                label: "남성",
                value: "M",
            },
        ],

        singleFeedId: "",
        setSingleFeedId: (singleFeedId: string) => set({singleFeedId}),

        // feedEditMenu handle
        feedEditPops: {},
        setFeedEditPops: (feedEditPops: {}) => set({feedEditPops}),

        // 피드리스트 스크롤 위치
        scrollY: 0,
        setScrollY: (scrollY: number) => set({scrollY}),

        // 피드리스트 탑 버튼 show/hide
        isTopBtnVisible: false,
        setIsTopBtnVisible: (isTopBtnVisible: boolean) => set({isTopBtnVisible}),

        // 피드리스트 댓글창 on/off
        commentState : false,
        setCommentState: (commentState:boolean) => set({commentState}),

        prev: false,
        setPrev: (prev:boolean) => set({prev: prev}),


        hasMore: true,
        setHasMore: (hasMore:boolean) => set({hasMore: hasMore}),

        myPosts: [],
        setMyPosts: (list:PostInfoCOVO[]) => set({myPosts:list}),

        postList: [],
        // 인피니티 X setList
        setPostList: (list:PostInfoCOVO[]) => set({postList:list}),

        // 인피니티 스크롤용 setList
        setPostListAdd: (list) => set((state) => {
            const uniqueItems = list.filter(item => !state.postList.some(stateItem => stateItem.postId === item.postId));
            return { postList: [...state.postList, ...uniqueItems] };
        }),

        setPostListEvent: ({flag, id}) => set((state)=>{
            const item = state.postList.find(itm => itm.postId == id)
            const newList = state.postList.filter(itm => itm.postId != id)
            let sortedList = []
            switch (flag) {
                case 'like':
                    if(!item) return { postList: state.postList }

                    item.postLikeYn = !item.postLikeYn
                    return { postList: [...newList, ...item ] }
                case 'comment':
                    const {setOpenComment,setCommentState,setFeedCommentOpen,setCommentValue,} = get();
                    setCommentValue(''); // 댓글입력 input 초기화
                    setOpenComment(true); // 댓글창 열기
                    setCommentState(true); // wrap에 no-scroll class 추가
                    setFeedCommentOpen(true); // 댓글창 활성화 시 footer menu z-index 조정

                    if(!item) return { postList: state.postList }

                    item.commentCount += 1
                    sortedList = [...newList, item ]
                    // item 순서가 맨 뒤 가서 순서 sorting
                    if(!state.isSearchPost){
                        sortedList.sort((x,y)=>Number(y.postId)-Number(x.postId));
                    }else{
                        sortedList.sort((x,y)=>Number(x.postId)-Number(y.postId));
                    }
                    return { postList: sortedList }
                case 'commentDelete':
                    if(!item) return { postList: state.postList }

                    item.commentCount -= 1
                    sortedList = [...newList, item ]
                    // item 순서가 맨 뒤 가서 순서 sorting
                    if(!state.isSearchPost){
                        sortedList.sort((x,y)=>Number(y.postId)-Number(x.postId));
                    }else{
                        sortedList.sort((x,y)=>Number(x.postId)-Number(y.postId));
                    }
                    return { postList: sortedList }
                case 'hide':
                    return { postList: newList }
                case 'remove':
                    return { postList: newList }
            }
        }),

        // 피드정보 (이미지, 내용 등)
        feedDetail: null,
        setFeedDetail: (postInfo:PostInfoCOVO) => set({feedDetail:postInfo}),
        setFeedDetailEvent: ({flag}) => set((state)=>{
           const item = state.feedDetail
            if(!item) return {feedDetail: null}
           switch (flag) {
               case 'comment':
                   item.commentCount += 1;
                   return {feedDetail:item}

               case 'commentDelete':
                   item.commentCount -= 1;
                   return {feedDetail:item}
           }
        }),

        observeCard: '',
        setObserveCard: (id:string) => set({observeCard:id}),

        fadeIn: false,
        setFadeIn: (fadeIn:boolean) => set({fadeIn:fadeIn}),

        scrollWrapRef: createRef<HTMLDivElement>(), // Ref 객체 생성
        target: createRef<HTMLDivElement>(), // Ref 객체 생성
        setTarget: (ref) => set(() => ({ target: ref })),

        isClickedFeedMenu: false,
        setIsClickedFeedMenu: (click:boolean) => set({isClickedFeedMenu:click}),

        // 무한스크롤 시, 리스트 중복제거를 위한 페이지 리스트 담는 array
        pageArray: [],
        setPageArray: (page) => set((state) =>
            state.pageArray.includes(page)
                ? state // 중복이면 상태를 변경하지 않음
                : { pageArray: [...state.pageArray, page] } // 중복이 아니면 추가
        ),
        setPageArrayReset: () => set({pageArray:[], hasMore:true}),
        getMaxPage: () => {
            const { pageArray } = get(); // 현재 상태 가져오기
            return pageArray.length > 0 ? Math.max(...pageArray) : null; // 배열의 최대값 반환
        },

        commentValue: '',
        setCommentValue: (commentValue: string) => set({commentValue:commentValue}),
        openComment: false,
        setOpenComment: (state:boolean) => set({openComment:state}),
        focusedPostId: '',
        setFocusedPostId: (val:string) => set({focusedPostId: val}),

        // 댓글 조회 api 응답결과
        commentResponse: [],
        setCommentResponse: (arr: CommentCOVO[]) => set({commentResponse:arr}),

        // getPosts2 or getSearchPost 담았는지
        isSearchPost: false,
        setIsSearchPost: (isSearch:boolean) => set({isSearchPost:isSearch}),

        // savepost 등 이벤트 발생 시, 피드 캐싱x 새로 렌더링 해야한다는 플래그
        haveToRefresh: false,
        setHaveToRefresh: (val:boolean) => set((state)=>({ haveToRefresh:val, observeCard: val ? '' : state.observeCard })),

        // 싱글피드에서 숨김처리하면 navigate(-1) 하려고 만든 변수
        // 이동되고 나서 isHiddenDelete(false)
        isHiddenDelete: false,
        setIsHiddenDelete: (hidden:boolean) => set({isHiddenDelete:hidden}),
    }),
);

export default useLoungeRepo;