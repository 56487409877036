// 개발서버 웹에서 가입할때 데이터 다양하게,,

export const coords:{latitude:string,longitude:string}[] = [
    { latitude: "37.5662952", longitude: "126.9779451" },  // 서울특별시청
    { latitude: "35.1795543", longitude: "129.0756416" },  // 부산광역시청
    { latitude: "35.8714354", longitude: "128.601445" },   // 대구광역시청
    { latitude: "37.456256", longitude: "126.7052062" },   // 인천광역시청
    { latitude: "35.160032", longitude: "126.851338" },    // 광주광역시청
    { latitude: "36.35111", longitude: "127.385953" },     // 대전광역시청
    { latitude: "35.5383773", longitude: "129.3113596" },  // 울산광역시청
    { latitude: "33.4996213", longitude: "126.5311884" },  // 제주특별자치도청
    { latitude: "37.5241392", longitude: "126.989463" },   // 경기도청
    { latitude: "35.8219759", longitude: "127.1481146" },  // 전라북도청
    { latitude: "34.8161198", longitude: "126.4629244" },  // 전라남도청
    { latitude: "36.4802052", longitude: "127.2890268" },  // 충청북도청
    { latitude: "36.6357691", longitude: "127.4913125" },  // 충청남도청
    { latitude: "37.8813154", longitude: "127.7299706" },  // 강원도청
    { latitude: "35.8992946", longitude: "128.6007475" },  // 경상북도청
    { latitude: "35.2335135", longitude: "128.6926009" },  // 경상남도청
    { latitude: "35.5566804", longitude: "129.2569573" },  // 포항시
    { latitude: "36.992107", longitude: "127.9264885" },   // 충주시
    { latitude: "34.9835611", longitude: "126.711195" },   // 목포시
    { latitude: "35.9605912", longitude: "126.6852883" },  // 군산시
    { latitude: "37.5456297", longitude: "126.9643746" },  // 부천시
    { latitude: "37.7490813", longitude: "128.8726083" },  // 속초시
    { latitude: "36.3945513", longitude: "127.2529603" },  // 세종시
    { latitude: "35.1147911", longitude: "129.0387445" },  // 해운대구
    { latitude: "37.3002524", longitude: "126.866435" },   // 안산시
    { latitude: "37.604458", longitude: "126.929141" },    // 파주시
    { latitude: "36.8064583", longitude: "127.152167" },   // 천안시
    { latitude: "35.8092501", longitude: "128.5583918" },  // 구미시
    { latitude: "35.1932296", longitude: "128.084603" },   // 창원시
    { latitude: "37.275635", longitude: "127.009216" },    // 수원시
    { latitude: "37.4569", longitude: "126.7052" },         // 경기
    { latitude: "37.4852", longitude: "126.8495" },         // 경기
    { latitude: "37.6747", longitude: "127.1786" },         // 경기
    { latitude: "37.3443", longitude: "126.9205" },         // 경기
    { latitude: "37.6026", longitude: "127.0529" },         // 경기
    { latitude: "37.7367", longitude: "126.8349" },         // 경기
    { latitude: "37.2790", longitude: "127.0435" },         // 경기
    { latitude: "37.4145", longitude: "127.2574" },         // 경기
    { latitude: "37.5816", longitude: "126.9739" },         // 경기
    { latitude: "37.6767", longitude: "127.1153" }          // 경기
]