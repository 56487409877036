import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

export const HomeLayout = () => {
	const {
		hushHome,
		changeHomeType,
		srcPrefix,
		myProfile,
	} = useHomeHeaderService();

	const { t } = useTranslation('common');

	return (
		<>
			<div className="wrap">
				<header className="cateHeader">
					<div className="headerWrap">
						<div className="h-left">
							<div className="headerTab">
								<button type="button" className={`tabButton tab01 ${hushHome ? 'active' : ''}`}>
									<Link to={PATH.DATING.HOME}
										  onClick={() => {
											  changeHomeType(true);
										  }}>
										<span><img src="/assets/img/common/logo-hush.svg" alt="hushHome" /></span>
									</Link>
								</button>
								<button type="button" className={`tabButton tab02 ${!hushHome ? 'active' : ''}`}>
									<Link to={PATH.DATING.PICK}
										  onClick={() => {
											  changeHomeType(false);
										  }}>
										<span>{t('upperMenu.suggestion')}</span>
									</Link>
								</button>
							</div>
						</div>

						<div className="h-center" />

						<div className="h-right">
							<Link to={PATH.PROFILE.MY_PROFILE}>
								<button
									className="profileSmImg"
									type="button"
								>
									<img
										src={
											myProfile && myProfile?.profileUrl[0]
												? srcPrefix + myProfile?.profileUrl[0]
												: '/assets/img/test/boy.png'
										}
										alt="테스트이미지"
									/>
								</button>
							</Link>
						</div>
					</div>
				</header>

				<Outlet />
			</div>
		</>
	);
};