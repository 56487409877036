import {useNavigate} from "react-router-dom";

interface props {
    right: boolean;
    title?: string;
    berith?: number;
    clearPreviewURLs: () => void;
};

export const EditProfileHeaderView = ({
                                          right,
                                          title,
                                          berith,
                                          clearPreviewURLs,
                                      }: props) => {
    const navigate = useNavigate();
    const onClickBack = () => {
        navigate(-1);
    };

    return (
        <header>
            <div className="headerWrap">
                <div className="h-left">
                    {
                        !right && <button type="button" className="btnBack" onClick={onClickBack}/>
                    }
                </div>
                <div className="h-center">
                    {
                        title && <p className="title02">{title}</p>
                    }
                </div>
                <div className="h-right">
                    {
                        berith && <p className="ic-berith sm">{berith}</p>
                    }
                    {
                        right && <button type="button" className="btnClose" onClick={() => {
                            clearPreviewURLs();
                            onClickBack();
                        }}/>
                    }
                </div>
            </div>
        </header>
    );
};