import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const OfflineManagerInputView = ({
	handleSubmit,
	messageInputValue,
	handleChange,
	focused,
	isOtherUserLeft,
	blockStatus,
	isUseStatusDone,
}) => {
	const { t } = useTranslation('messenger');
	const inputRef = useRef<HTMLInputElement>(null);
	const { setFocused } = useDirectMessageRepo();

	const getPlaceholder = () => {
		if (isUseStatusDone) return t('noChatAvailable');
		if (isOtherUserLeft) return t('endedChat');
		if (blockStatus && blockStatus !== 'none') return t('noChatAvailable');
		return t('enterChat');
	};
	const isDisabled =
		isUseStatusDone ||
		isOtherUserLeft ||
		(blockStatus && blockStatus !== 'none');

	return (
		<div className="chatInput input">
			<input
				type="text"
				ref={inputRef}
				value={messageInputValue}
				maxLength={3000}
				onChange={handleChange}
				placeholder={getPlaceholder()}
				disabled={isDisabled}
				enterKeyHint="send"
				onFocus={() => {
					if (!focused) {
						setFocused(true);
					}
				}}
				onBlur={() => {
					setFocused(false);
				}}
			/>
			<button className="submitBtn" type="button" onClick={handleSubmit} />
		</div>
	);
};

export default OfflineManagerInputView;
