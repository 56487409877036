import { dmEventEmitter } from './../../../../../events/ReactDMEventEmitter';
import { useAuthAdpater } from '@dating/adapter/auth/useAuthAdpater';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useNavigate } from 'react-router-dom';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import {
	bridgeGetNotiData,
	bridgeGoogleLogin,
} from '@/utils/bridgeCallHandler';
import type { GoogleLoginCIVO } from '@/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useOrderUtils } from '@/utils/orderUtils';
import { detectDevice } from '@/utils/deviceDetection';
import { goPushPage } from '@/SetBridgeContextProvider';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useMessageNavigation } from '@/ui/components/dm/service/useMessageNavigation';
import { useMetaverse } from '@/hooks/useMetaverse';
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';
import Network from '@/services/Network';

export const useAuthService = () => {
	const {
		deviceId,
		setDeviceId,
		fcmToken,
		setFcmToken,
		authToken,
		initAuthToken,
		setAccessToken,
		setRefreshToken,
		setAuthToken,
		osType,
		setOsType,
		langCode,
		setLangCode,
	} = useAuthRepo();

	const { isIOS } = detectDevice();

	const { userSettings } = useSettingRepo();

	const { SNSInfo, setSNSInfo } = useRegisterRepo();

	const { setMyProfile, myProfile } = useDatingRepo();

	const { setSingleFeedId } = useLoungeRepo();

	const { oauthLoginInfos, autoLogin, googleLogin } = useAuthAdpater();

	const { getMyProfile } = useProfileAdapter();

	const { setMyMetaUser } = useMetaUserRepo();

	const { getMyMetaUser } = useMetaUserAdapter();

	const { setProductItems } = useOrderUtils();

	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();

	const { handlePushNavigation, handleUserMatchPush } = useMessageNavigation();

	const { initializeGame } = useMetaverse();
	//////////////////////////////////////////////////

	const navigate = useNavigate();

	/*
	 * toMain
	 * 메인으로 이동
	 */
	function toMain() {
		navigate(PATH.REGISTER.REGISTER);
	}

	/*
	 * toPhone
	 * 핸드폰번호 입력 화면으로 이동
	 */
	function toPhone() {
		navigate(PATH.REGISTER.PHONE);
	}

	/*
	 * fnOauthLoginInfos
	 * SNS 로그인
	 */
	const fnOauthLoginInfos = (provider: string) => {
		if (provider != '') {
			if (provider === 'google' && (window as any).flutter_inappwebview) {
				// 앱에서 구글 로그인
				bridgeGoogleLogin().then((response) => {
					if (response) {
						const map: GoogleLoginCIVO = {
							email: response.email,
							socialType: 'google',
							sub: response.id,
						};

						googleLogin(map)
							.then(async (value1) => {
								if (value1.resultCode === 'success') {
									if (value1.jwt?.accessToken && value1.jwt?.refreshToken) {
										const token = {
											accessJmt: value1.jwt?.accessToken,
											refreshJmt: value1.jwt?.refreshToken,
										};
										setAuthToken(token);
									}
								} else if (value1.resultCode === 'empty') {
									if (value1.id && value1.email && value1.socialType) {
										const tmp: {
											resultCode: string;
											id: string;
											email: string;
											socialType: string;
										} = {
											resultCode: value1.resultCode,
											id: value1.id,
											email: value1.email,
											socialType: value1.socialType,
										};
										setSNSInfo(tmp);
										// 미가입 사용자 or 연동정보가 없는 사용자
										fnCommonConfirmPopOn(
											'SNS연동 로그인을 하려면\r\n' +
												'회원임이 확인되어야 합니다.\r\n' +
												'로그인을 진행해 주세요.',
											toPhone,
											null,
											toMain,
											null,
										);
									}
								}
							})
							.catch((reason) => {
								// alert(reason.body.message);
							});
					}
				});
				return;
			}

			oauthLoginInfos(provider)
				.then((value) => {
					// return url로 화면 이동
					window.location.href = value.url;
				})
				.catch((reason) => {
					console.log(reason.body);
				});
		}
	};

	/*
	 * fnLogin
	 * 클라이언트서버 로그인 처리(myProfile, message/meta init)
	 */
	const fnLogin = () => {
		getMyProfile('').then(async (value) => {
			if (value.profileUrl.length > 0) {
				setMyProfile(value);
				if (Network.instance) {
					await Network.instance.initializeLobby();
					// 로비 연결 성공 후 DM 이벤트 발생
					dmEventEmitter.emit('react-join-or-create-dm-room', {
						userUid: value.userUid,
						nickName: value.nickName,
						profileUrl: value.profileUrl[0],
						age: value.age,
						region1: value.region1,
						region2: value.region2,
						role: value.role,
						inActive: false,
						isDeleted: false,
						blockType: '',
					});
					dmEventEmitter.emit('react-get-dm-rooms');
				}

				try {
					const metaUserData = await getMyMetaUser();

					// 메타유저 데이터가 있고 객체인지 확인
					if (metaUserData && typeof metaUserData === 'object') {
						// 메타유저 데이터 설정 (profileUrl 체크하지 않음)
						setMyMetaUser(metaUserData);
					} else {
						console.warn('Invalid meta user data:', metaUserData);
					}
				} catch (metaError) {
					console.error('Failed to fetch meta user data:', metaError);
				}

				const getNotiData = await bridgeGetNotiData();
				if (
					getNotiData != null &&
					getNotiData != '' &&
					getNotiData.methodName != ''
				) {
					goPushPage(
						getNotiData,
						navigate,
						setSingleFeedId,
						handleUserMatchPush,
						handlePushNavigation,
					);
				} else if (value.role === 'NORMAL') {
					navigate(PATH.DATING.HOME);
				} else if (value.role === 'MANAGER') {
					navigate(PATH.MANAGER.HOME);
				}
			} else {
				navigate(PATH.REGISTER.REGISTER);
				return;
			}
		});

		initializeGame();
		setProductItems();
	};

	/*
	 * fnAutoLogin
	 * 서버 로그인
	 */
	const fnAutoLogin = (deviceId?: string, fcmToken?: string) => {
		autoLogin(deviceId, fcmToken)
			.then((value) => {
				if (value.result) {
					if (value.accessToken && value.refreshToken) {
						const token = {
							accessJmt: value.accessToken,
							refreshJmt: value.refreshToken,
						};
						setAuthToken(token);
					}
					return fnLogin(); // Promise를 return
				}
			})
			.catch((reason) => {
				console.log(reason.body);
				throw reason; // 에러를 전파
			});
	};

	/**
	 * 디바이스가 iOS인 경우에만 애플 로그인 버튼을 표시하기 위한 상태값
	 */
	const isAppleSignInVisible =
		((window as any).flutter_inappwebview && osType === 'ios') ||
		(!!(window as any).flutter_inappwebview === false && isIOS);

	return {
		authToken,
		SNSInfo,
		isIOS,
		osType,
		isAppleSignInVisible,
		fnOauthLoginInfos,
		fnLogin,
		fnAutoLogin,
		setAuthToken,
		setSNSInfo,
		setFcmToken,
		setDeviceId,
		setOsType,
		langCode,
		setLangCode,
		setAccessToken,
		setRefreshToken,
		toMain,
		toPhone,
		userSettings,
	};
};
