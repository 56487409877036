import {
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { errorHandlerUtils } from '@/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';
import { ManagerProfileContainer } from '@/ui/components/dm/manager/managerProfile/ManagerProfileConatiner';


export default function ManagerProfileRouter() {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();

	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Routes location={location}>
				<Route path="*" element={<ManagerProfileContainer />} />
			</Routes>
		</ErrorBoundary>
	);
}
