import { Key } from 'react';

const ManagerListView = ({ players, srcPrefix }) => {
	const managerPlayers = players.filter(
		(player: { role: string }) => player.role === 'MANAGER',
	);
	return (
		<div className="mgList">
			{managerPlayers.map(
				(profile: {
					uuid: Key | null | undefined;
					profileUrl: any;
					name: string;
				}) => (
					<div key={profile.uuid}>
						<img src={srcPrefix + profile.profileUrl} alt={profile.name} />
					</div>
				),
			)}
		</div>
	);
};

export default ManagerListView;
