import { FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';

export const ErrorHandlerView = ({ error, resetErrorBoundary }: FallbackProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	return (
		<div className="wrap h-fixed footerNone">
			{/*{error.message}*/}
			{/*{error.stack}*/}
			{/*<p*/}
			{/*	onClick={() => {*/}
			{/*		resetErrorBoundary();*/}
			{/*		navigate(-1);*/}
			{/*	}}*/}
			{/*>뒤로가기</p>*/}
			{/*{location.pathname}*/}

			<div className="dataNoneTxt">
				<p className="txt01"
				   onClick={() => {
					   resetErrorBoundary();
					   navigate(-1);
				   }}
				>
					오류가 발생했습니다.<br/>
					뒤로가기
				</p>
			</div>
		</div>
	);
};