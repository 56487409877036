export enum ItemType {
	CHAIR,
	COMPUTER,
	WHITEBOARD,
	POPUP,
	YOUTUBE,
	WORDLE,
}

export const CHAIR_MESSAGES = {
	ko: '눌러 앉기',
	en: {
		prefix: 'Press',
		suffix: 'to sit',
	},
	vi: 'để ngồi',
} as const;

export const CHAIR_MESSAGES_DESKTOP = {
	ko: 'F를 눌러 앉기',
	en: 'Press F to sit',
	vi: 'Nhấn F để ngồi',
} as const;

export const STAND_MESSAGES = {
	ko: '서기',
	en: {
		prefix: 'Press',
		suffix: 'to stand',
	},
	vi: 'để đứng lên',
} as const;

export const STAND_MESSAGES_DESKTOP = {
	ko: 'F를 눌러 서기',
	en: 'Press F to stand',
	vi: 'Nhấn F để đứng lên',
} as const;

export const OCCUPIED_MESSAGES = {
	ko: '이미 누가 앉아있어요',
	en: 'Someone is already sitting here',
	vi: 'Đã có người ngồi ở đây',
} as const;
