import { useTranslation } from 'react-i18next';

const LeaveChatPopView = ({
	shouldExitChat,
	handleExitChat,
	setOpenLeaveChat,
	handleLeaveChat,
}) => {
	const { t } = useTranslation('messenger');

	return (
		<div className="leaveChatPopWrap">
			<div className="popupInfo">
				<p className="fz20 bold-500 text-c">{t('leaveChatConfirm')}</p>
				<p className="fz16Fw500 color-999 text-c padding-t8">
					{t('noUndoLeave')}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							setOpenLeaveChat(false);
						}}
					>
						<span>{t('no')}</span>
					</button>
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={shouldExitChat ? handleExitChat : handleLeaveChat}
					>
						<span>{t('yes')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LeaveChatPopView;
