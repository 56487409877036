import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { LocationDeviceInfoCIVO } from '@/site/api';
import axios from "axios";

export default function useLocationInfoService() {
	const {
		setStep,
		registerSteps,
	} = useRegisterRepo();

	const {
		saveUserLocation,
		statusSaveUserLocation,
		dataSaveUserLocation,
	} = useRegisterAdapter();

	/*
	 * fnSaveUserLocation
	 * 위치정보공유 동의 시 좌표값 저장
	 */
	const fnSaveUserLocation = async (map: {latitude:string, longitude:string}) => {
		const regions = await fnGetGeocode(map)
		const locParam:LocationDeviceInfoCIVO = {...regions, ...map}
		saveUserLocation(locParam);
	};

	const fnGetGeocode = async (map: {latitude:string, longitude:string}) => {
		const header = {
			url: `https://dapi.kakao.com/v2/local/geo/coord2address?x=${map.longitude}&y=${map.latitude}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `KakaoAK ${import.meta.env.KAKAO_REST_API_KEY}`
			}
		};
		try {
			const response = await axios.request(header)
			const document = response?.data?.documents[0]
			let regions:{region1:string, region2:string} = {}
			if(document?.road_address){
				regions = {
					region1:document?.road_address?.region_1depth_name,
					region2:document?.road_address?.region_2depth_name
				}

			}else if(document?.address){
				regions = {
					region1:document?.address?.region_1depth_name,
					region2:document?.address?.region_2depth_name?.split(" ")[0]
				}

			}
			return regions
		} catch (e) {
			console.log("KAKAOAK GEOCODE ERROR ---> ", e)
		}
	}

	return {
		fnSaveUserLocation,
		statusSaveUserLocation,
		dataSaveUserLocation,
		setStep,
		registerSteps,
	};
}