/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JmtToken } from './JmtToken';
export type SocialLoginStatusCOVO = {
    /**
     * 응답 코드
     */
    resultCode: SocialLoginStatusCOVO.resultCode;
    /**
     * jwt 토큰
     */
    jwt: JmtToken | null;
    /**
     * OAuth provider에서 제공해준 회원 id
     */
    id: string | null;
    /**
     * OAuth provider에서 제공해준 회원 email
     */
    email: string | null;
    /**
     * OAuth provider 값
     */
    socialType: string | null;
};
export namespace SocialLoginStatusCOVO {
    /**
     * 응답 코드
     */
    export enum resultCode {
        SUCCESS = 'success',
        EMPTY = 'empty',
    }
}

