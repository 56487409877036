import { useRef } from 'react';
import {bridgePickImage} from "@/utils/bridgeCallHandler";
import {useTranslation} from "react-i18next";

interface props {
	id: number;
	required: boolean;
	previewURLs: string[];
	updateFile: (index: number, file: File | Blob) => void;
	srcPrefix: string;
	removeImg: (id: number) => void;
	osType: string;
	arrayToBlob: (value: []) => Blob;
}

const EditProfileImgAddBoxView = ({
									  id,
									  required,
									  previewURLs,
									  updateFile,
									  srcPrefix,
									  removeImg,
									  osType,
									  arrayToBlob,
								  }: props) => {
	const imgRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation('profile')

	return (
		<>
			{previewURLs[id] ? (
				<div
					onClick={() => {
						imgRef.current?.click();
					}}
				>
					<button
						type="button"
						className="deleteBtn"
						onClick={(e) => {
							removeImg(id);
							e.stopPropagation();
						}} />
					<label htmlFor={`img${id}`}
						onClick={() => {
							if (osType != '') {
								bridgePickImage().then(value => {
									if (value != null) {
										const file = arrayToBlob(value);
										updateFile(id, file);
									}
								});
							}
						}}
					/>
					<img
						src={previewURLs[id]}
						alt="preview-img"
						className={`img${id}`}
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
						onClick={() => {
							if (osType != '') {
								bridgePickImage().then(value => {
									if (value != null) {
										const file = arrayToBlob(value);
										updateFile(id, file);
									}
								});
							}
						}}
					/>
					<input
						type={`${osType === '' ? 'file' : ''}`}
						id={`img${id}`}
						ref={imgRef}
						onChange={(e) => {
							e.preventDefault();
							const currentRef = imgRef?.current;
							if (currentRef && currentRef.files) {
								const file = currentRef.files[0];
								updateFile(id, file);
							}

							e.target.value = '';
						}}
						style={{ display: 'none' }}
					/>
					{required ? <span className="fz12 color-white">{t('badge.essential')}</span> : null}
				</div>
			) : (
				<div>
					<label htmlFor={`img${id}`}
						onClick={() => {
							if (osType != '') {
								bridgePickImage().then(value => {
									if (value != null) {
										const file = arrayToBlob(value);
										updateFile(id, file);
									}
								});
							}
						}}
					/>
					<input
						type={`${osType === '' ? 'file' : ''}`}
					    id={`img${id}`}
					    ref={imgRef}
					    onChange={(e) => {
					        e.preventDefault();
					        const currentRef = imgRef?.current;
					        if (currentRef && currentRef.files) {
					            const file = currentRef.files[0];
					            updateFile(id, file);
					        }

					        e.target.value = '';
					    }}
					    style={{display: 'none'}}
					/>
					<div
						onClick={() => {

						}}
					>

					</div>
					{required ? <span className="fz12 color-white">{t('badge.essential')}</span> : null}
				</div>
			)}
		</>
	);
};

export default EditProfileImgAddBoxView;
