import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css"; // slick 기본 스타일
import "slick-carousel/slick/slick-theme.css";
import { UserAuthBadgeCOVO } from "@/site/api";
import {AuthAddBadgeInnerView} from "@dating/ui/components/authAddBadge/views/AuthAddBadgeInnerView";
import {useTranslation} from "react-i18next";

interface props {
  clearPreviewURLs: () => void;
  myProfileOpen: boolean;
  badgeData: UserAuthBadgeCOVO[];
}

const AuthAddBadgeView = ({ clearPreviewURLs, myProfileOpen, badgeData }: props) => {
  const [currentSlide, setCurrentSlide] = useState(0); // 현재 슬라이드
  const [slideCount, setSlideCount] = useState(5); // 전체 슬라이드 개수, 기본값 설정
  const sliderRef = useRef(null);
  const { t } = useTranslation('profile')

  // 슬라이드 상태 변경 및 슬라이드 카운트 설정
  useEffect(() => {
    if (sliderRef.current) {
      const totalSlides = sliderRef.current.innerSlider.props.children.length;
      setSlideCount(totalSlides);
    }
  }, [myProfileOpen]);

  const settings = {
    arrows: false,
    autoplay: false,
    infinite: false,
    speed: 300,
    slidesToShow: Math.min(4, badgeData.length), // 기본적으로 4개 이하일 경우, badgeData.length만큼 표시
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 340, // 340px 이하일 때
        settings: {
          slidesToShow: Math.min(3, badgeData.length), // 최대 3개 이하로 표시
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    afterChange: (index) => {
      setCurrentSlide(index);
    },
  };

  const imgSrc = (imgPath) => {
    return `/assets/img/common/icon/${imgPath}.svg`;
  };

  // 이전 버튼 클릭 핸들러
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // 다음 버튼 클릭 핸들러
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="authAddWrap">
      <h3 className="title03">
        {myProfileOpen ? t('badge.myTitle') : t('badge.otherTitle')}
      </h3>
      <p className="authSubTit">
        {myProfileOpen ? t('badge.myQuote') : t('badge.otherQuote') }
      </p>
      <div className="authList">
        {badgeData.length > 4 ? (
          <Slider ref={sliderRef} className="authSlide" {...settings}>
            {badgeData.map((data, i) => (
                <AuthAddBadgeInnerView key={data.badgeLevel + data.badgeId + i} data={data} i={i} clearPreviewURLs={clearPreviewURLs}/>
            ))}
          </Slider>
        ) : (
          <div className="authStatic">
            {badgeData.map((data, i) => (
                <AuthAddBadgeInnerView key={data.badgeLevel + data.badgeId + i} data={data} i={i} clearPreviewURLs={clearPreviewURLs}/>
            ))}
          </div>
        )}

        {/* 커스텀 화살표 */}
        {badgeData.length > 4 && (
          <>
            <button
              className="authArrow authPrev"
              onClick={handlePrev}
              style={{ display: currentSlide === 0 ? "none" : "block" }}
            >
              <span className="hide">이전</span>
            </button>
            <button
              className="authArrow authNext"
              onClick={handleNext}
              style={{
                display:
                  slideCount > 0 &&
                  currentSlide + settings.slidesToShow < slideCount &&
                  (settings.slidesToShow === 3
                    ? currentSlide + 3 < slideCount
                    : currentSlide + 4 < slideCount)
                    ? "block"
                    : "none",
              }}
            >
              <span className="hide">다음</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthAddBadgeView;