import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useEffect } from 'react';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { useNavigate } from 'react-router-dom';
import { useInactiveAccountService } from '@dating/ui/pages/settings/inactiveAccount/useInactiveAccountService';
import { PATH } from "@dating/constants/RoutingEndPoints";
import { useTranslation } from 'react-i18next';

export const InactiveAccountContainer = () => {
	const {
		fnSaveInactiveUser,
		inactive,
	} = useInactiveAccountService();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const navigate = useNavigate();
	const { t } = useTranslation('setting');

	useEffect(() => {
		if (inactive) {
			fnCommonAlertPopOn(t('inactiveAccountMsg'));
			// 휴면 계정이 되었습니다.
			navigate(PATH.REGISTER.REGISTER)
		}
	}, [inactive]);

	return (
		<>
			<div className="wrap rightShow active">
				<ButtonHeaderView right={false} />
				<div className="popConWrap hiddenCon padding-b0 margin-b0">
					<div className="inner padding-t8">
						<div className="titleWrap">
							<h2 className="title01">
								{t('setInactiveAccount')}
								{/* 휴면 계정으로 설정하시겠어요? */}
							</h2>
							<p className="titleSub01">
								{t('inactiveAccountWarning1')}
								{/* 휴면계정이 되면 안전하게 잠금이 되어 접근이 불가 하며 */}
								<br />
								{t('inactiveAccountWarning2')}
								{/* 계정이 해제되기 전까지 HUSH를 사용할 수 없습니다. */}
							</p>
						</div>
						<div className="icoCenter text-c">
							<img src="/assets/img/common/ico-id-delete.svg" width="100" alt="" />
						</div>
					</div>
				</div>
				<div className="inner padding-t16 padding-b16">
					<button
						className="btn btnBig btnBlack popOnBtn"
						onClick={() => {
							fnSaveInactiveUser();
						}}
					>
                        <span>
                            {t('inactiveAccount')}
							{/* 휴면 계정 */}
                        </span>
					</button>
					<button
						className="btn btnBig btnCCC margin-t8"
						type="button"
						onClick={() => {
							navigate(-1);
						}}
					>
                        <span>
                            {t('cancel')}
							{/* 취 소 */}
                        </span>
					</button>
				</div>
			</div>
		</>
	);
};
