/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BothLikeUserCIVO } from '../models/BothLikeUserCIVO';
import type { BothLikeUserCOVO } from '../models/BothLikeUserCOVO';
import type { PostLikeCIVO } from '../models/PostLikeCIVO';
import type { ReceiveCommentPostCOVO } from '../models/ReceiveCommentPostCOVO';
import type { ReceiveLikeUserCOVO } from '../models/ReceiveLikeUserCOVO';
import type { ReceivePostLikeCOVO } from '../models/ReceivePostLikeCOVO';
import type { ReceiveUserHighScoreCOVO } from '../models/ReceiveUserHighScoreCOVO';
import type { RemoveLikeViewCIVO } from '../models/RemoveLikeViewCIVO';
import type { SendCommentPostCOVO } from '../models/SendCommentPostCOVO';
import type { SendPostLikeCOVO } from '../models/SendPostLikeCOVO';
import type { SendUserHighScoreCOVO } from '../models/SendUserHighScoreCOVO';
import type { SendUserLikeCOVO } from '../models/SendUserLikeCOVO';
import type { UserLikeCIVO } from '../models/UserLikeCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LikeService {
    /**
     * 유저 좋아요
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveUserLike(
        requestBody: UserLikeCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/like/saveUserLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 두 유저가 서로 좋아요를 눌러서 매칭이되었는지 확인한다. 매칭이 되었는지 여부와 매칭이 된 경우, 메세지 방 생성을 위한 메타정보를 같이 내려준다.
     * @param requestBody
     * @returns BothLikeUserCOVO 좋아요 매칭 결과
     * @throws ApiError
     */
    public static checkUserBothLike(
        requestBody: BothLikeUserCIVO,
    ): CancelablePromise<BothLikeUserCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/like/match',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 게시글 좋아요
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static savePostLike(
        requestBody: PostLikeCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/like/savePostLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 받은 유저 좋아요
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns ReceiveLikeUserCOVO
     * @throws ApiError
     */
    public static getReceiveUserLike(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<ReceiveLikeUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getReceiveUserLike',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 받은 유저 높은 평가
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns ReceiveUserHighScoreCOVO
     * @throws ApiError
     */
    public static getReceiveUserHighScore(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<ReceiveUserHighScoreCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getReceiveUserHighScore',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 받은 게시글 좋아요
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns ReceivePostLikeCOVO
     * @throws ApiError
     */
    public static getReceivePostLike(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<ReceivePostLikeCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getReceivePostLike',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 받은 게시글 댓글
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns ReceiveCommentPostCOVO
     * @throws ApiError
     */
    public static getReceivePostComment(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<ReceiveCommentPostCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getReceivePostComment',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 보낸 유저 좋아요 3명 -> 조회하는 순간으로부터 7일 이내에 생성된 데이터만
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns SendUserLikeCOVO
     * @throws ApiError
     */
    public static getSendUserLike(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<SendUserLikeCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getSendUserLike',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 보낸 유저 높은 평가 3명
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns SendUserHighScoreCOVO
     * @throws ApiError
     */
    public static getSendUserHighScore(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<SendUserHighScoreCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getSendUserHighScore',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 좋아요 View 삭제
     * @param requestBody 좋아요View 삭제
     * @returns boolean
     * @throws ApiError
     */
    public static saveRemoveLikeView(
        requestBody: RemoveLikeViewCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/like/saveRemoveLikeView',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 좋아요 보낸 게시물 조회
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns SendPostLikeCOVO
     * @throws ApiError
     */
    public static getLikedPosts(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<SendPostLikeCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getLikedPosts',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
    /**
     * 댓글 단 게시물 조회
     * @param page 현재 페이지
     * @param limit 페이지 최대 갯수
     * @param dateTime 페이지 입장 시각 Date.getTime()
     * @returns SendCommentPostCOVO
     * @throws ApiError
     */
    public static getSendPostComment(
        page: number,
        limit: number,
        dateTime: number,
    ): CancelablePromise<Array<SendCommentPostCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/like/getSendPostComment',
            query: {
                'page': page,
                'limit': limit,
                'dateTime': dateTime,
            },
        });
    }
}
