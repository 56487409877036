import { useState } from "react";

export const useProfileAddStateRepo = () => {
    const [response, setResponse] = useState<boolean>(false);
    const [openTypePop, setOpenTypePop] = useState<boolean>(false);

    return {
        response,
        setResponse,
        openTypePop,
        setOpenTypePop,
    };
};