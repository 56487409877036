import EditProfileImgAddBoxView from '@dating/ui/pages/profile/editProfile/views/EditProfileImgAddBoxView';

interface props {
    previewURLs: string[];
    updateFile: (index: number, file: File) => void;
    srcPrefix: string;
    removeImg: (id: number) => void;
    osType: string;
    arrayToBlob: (value: []) => Blob;
}

const AddProfileImgView = ({
                               previewURLs,
                               updateFile,
                               srcPrefix,
                               removeImg,
                               osType,
                               arrayToBlob,
                           }: props) => {
    return (
        <div className="imgAddBox">
            {[0, 1].map((id) => {
                if (id === 0) {
                    return (
                        <EditProfileImgAddBoxView
                            key={id}
                            id={id}
                            required={true}
                            previewURLs={previewURLs}
                            updateFile={updateFile}
                            srcPrefix={srcPrefix}
                            removeImg={removeImg}
                            osType={osType}
                            arrayToBlob={arrayToBlob}
                        />
                    );
                } else {
                    return (
                        <EditProfileImgAddBoxView
                            key={id}
                            id={id}
                            required={false}
                            previewURLs={previewURLs}
                            updateFile={updateFile}
                            srcPrefix={srcPrefix}
                            removeImg={removeImg}
                            osType={osType}
                            arrayToBlob={arrayToBlob}
                        />
                    );
                }
            })}
        </div>
    );
};

export default AddProfileImgView;
