import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export const useImgAddBoxService = () => {
	const {
		previewURLs,
		setPreviewURLs,
		cropFiles,
		setCropFiles,
	} = useImgCropRepo();
	
	const {
		osType,
	} = useAuthRepo();
	///////////////////////////////

	/*
	 * removeImg
	 * 프로필 사진 1개 삭제
	 */
	const removeImg = (id: number) => {
		// 미리보기 리스트에서 해당하는 인덱스 찾아서 지우기
		const newUrls = previewURLs;
		previewURLs.splice(id, 1);
		setPreviewURLs(newUrls);

		// 서버로 삭제할 파일 정보 전송
		const newFiles: Blob[] = [];
		cropFiles.splice(id, 1);
		newFiles.push(...cropFiles);
		setCropFiles(newFiles);
	};

	/*
	 * arrayToBlob
	 * 앱에서 받은 unit8Array 파일 Blob으로 변환
	 */
	const arrayToBlob = (value: []) => {
		const blobFile = new Blob([new Uint8Array(value)], {type: "image/jpg"});
		return blobFile;
	};

	return {
		removeImg,
		osType,
		arrayToBlob,
	};
};