import ChargePopView from '@dating/ui/components/payment/views/ChargePopView';
import { CSSTransition } from 'react-transition-group';
import { usePaymentPopService } from '@dating/ui/components/payment/usePaymentPopService';
import { ChargeAlertPopView } from '@dating/ui/components/payment/views/ChargeAlertPopView';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';

export const PaymentPopContainer = () => {
	const {
		chargePopOn,
		chargeAlertPopOn,
		closeChargeAlertPop,
		closeChargePop,
		pointProducts,
		confirmChargeAlertPop,
		fnSaveInAppPurchase,
	} = usePaymentPopService();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	return (
		<div
			className={`${
				chargeAlertPopOn || chargePopOn 
					? 'settingPopOn'
					: 'settingPop'
			}`}
			id="PaymentPopContainer"
		>
			<div className="settingDim" />

			{/*베리드 충전 필요 알림 팝업*/}
			<CSSTransition
				in={chargeAlertPopOn}
				classNames="logoutWrap-transition"
				timeout={100}
			>
				<ChargeAlertPopView
					closeChargeAlertPop={closeChargeAlertPop}
					confirmChargeAlertPop={confirmChargeAlertPop}
				/>
			</CSSTransition>

			{/*베리드 충전 팝업*/}
			<CSSTransition
				in={chargePopOn}
				classNames="logoutWrap-transition"
				timeout={100}
			>
				<ChargePopView
					closeChargePop={closeChargePop}
					productPacks={pointProducts}
					fnSaveInAppPurchase={fnSaveInAppPurchase}
					fnCommonAlertPopOn={fnCommonAlertPopOn}
				/>
			</CSSTransition>
		</div>
	);
};