import { dmEventEmitter } from '@/events/ReactDMEventEmitter';
import { PATH } from '@dating/constants/RoutingEndPoints';
import LeaveChatContainer from '@dating/ui/components/leaveChat/LeaveChatContainer';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MessageDetailHeaderView = ({
	setOpenLeaveChat,
	modifyMenuPop,
	navigationRoom,
}) => {
	const navigate = useNavigate();
	const { openMessageRoom } = useLikeMatchingService();

	const handleBackClick = () => {
		const isFromMetaverse = sessionStorage.getItem('fromMetaverse') === 'true';
		const isFromLikeMatching =
			sessionStorage.getItem('fromLikeMatching') === 'true';

		if (isFromMetaverse) {
			sessionStorage.removeItem('fromMetaverse'); // 사용 후 삭제
			openMessageRoom();
			navigate(PATH.METAVERSE.ROOT);
		}
		if (isFromLikeMatching) {
			sessionStorage.removeItem('fromLikeMatching'); // 사용 후 삭제
			openMessageRoom();
			navigate(PATH.MESSENGER.ROOT);
		}

		openMessageRoom();
		navigate(PATH.MESSENGER.ROOT);
	};

	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left d-flex">
					<button className="btnBack" onClick={handleBackClick} />
					<p className="bold margin-l8">
						{navigationRoom.otherUserInfo.nickName}
					</p>
				</div>
				<div className="h-right">
					<div className="menuDotWap top">
						<button className="menuDot" onClick={(e) => modifyMenuPop(e)} />
						<LeaveChatContainer setOpenLeaveChat={setOpenLeaveChat} />
					</div>
				</div>
			</div>
		</header>
	);
};

export default MessageDetailHeaderView;
