import { useEffect } from "react";
import {useLikeRepo} from "@dating/repository/like/useLikeRepo";
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';

export const useLikeHeaderService = () => {

    const {
        isReceived,
        setIsReceived,
        setScrollY,
        scrollYIndex,
        setScrollYIndex,
    } = useLikeRepo();

    const { myProfile, srcPrefix, setMyProfileOpen, setUserProfileOpen } =
        useDatingRepo();

    const changeLikeType = (type: boolean) => {
        setIsReceived(type);
    };

    const handleScroll = (yOffset: number | undefined) => {
        if (yOffset) {
            setScrollY(yOffset);
        }
    };

    useEffect(() => {
        if (scrollY == 0) {
            setScrollYIndex(-1);
        } else if (scrollY > 0 && scrollY < 430) {
            setScrollYIndex(0);
        } else if (scrollY >= 430 && scrollY < 857) {
            setScrollYIndex(1);
        } else {
            setScrollYIndex(2);
        }
    }, [scrollY]);

    return {
        isReceived,
        changeLikeType,
        handleScroll,
        scrollYIndex,
        myProfile,
        srcPrefix,
        setMyProfileOpen,
        setUserProfileOpen,
    };
};