import MyManagerMessageDetailHeaderView from '../../views/MyManagerMessageDetailHeaderView';
import { useMyManagerOnlineDetailService } from './service/useMyManagerOnlineDetailService';
import MyManagerOnlineDetailView from './views/MyManagerOnlineDetailView';
import MyManagerOnlineInputView from './views/MyManagerOnlineInputView';
import { CSSTransition } from 'react-transition-group';
import LeaveChatPopView from '@dating/ui/components/leaveChat/views/LeaveChatPopView';

const MyManagerOnlineDetailContainer = () => {
	const {
		isOtherUserLeft,
		groupedMessagesDetail,
		setUserProfileOpen,
		setOpenLeaveChat,
		modifyMenuPop,
		handleSubmit,
		handleExitChat,
		handleChange,
		focused,
		messageInputValue,
		srcPrefix,
		messagesEndRef,
		myProfile,
		handleRoomMessageClick,
		navigationRoom,
		isUseStatusDone,
		openLeaveChat,
		shouldExitChat,
		handleLeaveChat,
		matchedAt,
		handleMessageSendClick,
	} = useMyManagerOnlineDetailService();

	const blockStatus = navigationRoom.otherUserInfo.blockType;

	return (
		<div className="wrap footerNone">
			<MyManagerMessageDetailHeaderView
				setOpenLeaveChat={setOpenLeaveChat}
				modifyMenuPop={modifyMenuPop}
				navigationRoom={navigationRoom}
				handleMessageSendClick={handleMessageSendClick}
			/>
			<div id="conWrap" className="chatWrap">
				<div className="messengerWrap">
					<form onSubmit={handleSubmit}>
						<MyManagerOnlineDetailView
							setUserProfileOpen={setUserProfileOpen}
							groupedMessagesDetail={groupedMessagesDetail}
							srcPrefix={srcPrefix}
							myProfile={myProfile}
							navigationRoom={navigationRoom}
							isOtherUserLeft={isOtherUserLeft}
							messagesEndRef={messagesEndRef}
							handleRoomMessageClick={handleRoomMessageClick}
							blockStatus={blockStatus}
							matchedAt={matchedAt}
						/>
						<MyManagerOnlineInputView
							handleSubmit={handleSubmit}
							messageInputValue={messageInputValue}
							handleChange={handleChange}
							focused={focused}
							isOtherUserLeft={isOtherUserLeft}
							blockStatus={blockStatus}
							isUseStatusDone={isUseStatusDone}
						/>
					</form>
				</div>
			</div>
			<CSSTransition
				in={openLeaveChat}
				classNames="leaveChatPopWrap-transition"
				timeout={100}
			>
				<LeaveChatPopView
					shouldExitChat={shouldExitChat}
					handleExitChat={handleExitChat}
					setOpenLeaveChat={setOpenLeaveChat}
					handleLeaveChat={handleLeaveChat}
				/>
			</CSSTransition>
		</div>
	);
};

export default MyManagerOnlineDetailContainer;
