/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveProductCOVO } from '../models/ActiveProductCOVO';
import type { ManagerInfoCOVO } from '../models/ManagerInfoCOVO';
import type { ManagerMatchingRoomCOVO } from '../models/ManagerMatchingRoomCOVO';
import type { ManagerProductListCOVO } from '../models/ManagerProductListCOVO';
import type { MatchedResultCOVO } from '../models/MatchedResultCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManagerService {
    /**
     * 매니저 상품 구매 (매니저 매칭)
     * @param orderId 주문 ID
     * @returns MatchedResultCOVO 매니저와 매칭된 정보
     * @throws ApiError
     */
    public static purchaseManagerProduct(
        orderId: number,
    ): CancelablePromise<MatchedResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/manager/manager-match',
            query: {
                'orderId': orderId,
            },
        });
    }
    /**
     * 매니저 사용 내역 조회
     * @returns ManagerProductListCOVO 매니저 사용 내역 조회
     * @throws ApiError
     */
    public static getManagerUsageHistory(): CancelablePromise<Array<ManagerProductListCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/manager/manager-usage-history',
        });
    }
    /**
     * 사용중인 매니저 상품이 있는지 조회 (온라인/오프라인)
     * @returns ActiveProductCOVO 사용 중인 매니저 상품 조회
     * @throws ApiError
     */
    public static getActiveManagerProduct(): CancelablePromise<Array<ActiveProductCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/manager/active-manager-product',
        });
    }
    /**
     * 매니저 상품 사용
     * @param orderId 주문 ID
     * @returns ManagerMatchingRoomCOVO 매니저 상품 사용 정보
     * @throws ApiError
     */
    public static useManagerProduct(
        orderId: number,
    ): CancelablePromise<ManagerMatchingRoomCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/manager/use-manager-product',
            query: {
                'orderId': orderId,
            },
        });
    }
    /**
     * 나의 매니저 조회
     * @returns ManagerInfoCOVO 나의 매니저 조회
     * @throws ApiError
     */
    public static getMyManager(): CancelablePromise<ManagerInfoCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/manager/my-manager',
        });
    }
}
