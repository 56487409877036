import {useBlockReportRepo} from "@dating/repository/dating/useBlockReportRepo";
import {useTranslation} from "react-i18next";


const BlockAcquaintanceWrapView = () => {
	const { blockedNumberList } = useBlockReportRepo();
	const { t } = useTranslation('setting')

	return (
		<div className="popConWrap hiddenCon padding-b0 margin-b0">
			<div className="inner padding-t8">
				<div className="titleWrap">
					<h2 className="title01">{t('blockContactList')}</h2>
					<p className="titleSub01" dangerouslySetInnerHTML={{ __html: t('registeredContacts') }}>
					</p>
				</div>
				{!(blockedNumberList && blockedNumberList.length > 0) &&
					<div className="icoCenter text-c">
						<img src="/assets/img/common/ico-black-list.svg" width={100} alt=""/>
					</div>
				}
				{ blockedNumberList && blockedNumberList.length > 0 &&
					(
						<div className="myBlackList">
							{
								blockedNumberList.map((val:{ blockName: string; blockPhoneNumber: string },i)=>{return(<div key={val+i}>{val.blockName}</div>)})
							}
						</div>
					)
				}
			</div>
		</div>
	);
};

export default BlockAcquaintanceWrapView;
