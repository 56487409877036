import { type ManagerProductListCOVO, OrderResultCOVO } from '@/site/api';
import { create } from 'zustand';

export interface ProductInfo {
	seq: number;
	useDate: number;
	useStatus: string;
}
export interface ManagerHistoryItem {
	result: boolean;
	availableCount: number;
	purchaseDate: number;
	productName: string;
	productInfo: ProductInfo[];  // undefined 가능성 제거
	productSubType: string;
	price: number;
}

type OrderState = {
	// 주문 결과
	orderResult: OrderResultCOVO;
	setOrderResult: (orderResult: OrderResultCOVO) => void;

	// 매니저 사용내역 전체 ( status가 done인 값만 )
	myManagerProductHistory : ManagerProductListCOVO[];
	setMyManagerProductHistory : (myManagerProduct : ManagerProductListCOVO[]) => void;

	// 매니저 구매내역에 전달할 VO

	managerHistory: ManagerHistoryItem[];
	setManagerHistory: (managerHistory: ManagerHistoryItem[]) => void;

	isSuccess : string;
	setIsSuccess : (succ:string) => void;
};

export const useOrderRepo = create<OrderState>((set) => ({
	// 주문 결과
	orderResult: {
		orderId: 0,
		productId: 0,
	},
	setOrderResult: (orderResult: OrderResultCOVO) => set({ orderResult }),

	myManagerProductHistory: [{
		orderId: 0,
		productId: 0,
		price: 0,
		purchaseDate: 0,
		productType: "",
		productSubType: "",
		availableCount: 0,
		productInfo: [""],
	}],

	setMyManagerProductHistory: (myManagerProductHistory: ManagerProductListCOVO[]) => set({ myManagerProductHistory }),

	managerHistory: [{
		purchaseDate: 0,
		availableCount: 0,
		price: 0,
		productSubType: "",
		productName: "",
		productInfo: [],
		result: false,
	}],
	setManagerHistory: (managerHistory: ManagerHistoryItem[]) => set({ managerHistory }),


	isSuccess : '',
	setIsSuccess : (succ:string) => set({isSuccess: succ})
}));
