import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from './RouteTransition';
import MyBerithContainer from '@dating/ui/pages/store/berith/MyBerithContainer';
import OfflineManagerContainer from '@dating/ui/pages/store/manager/offline/OfflineManagerContainer';
import ScreenManagerContainer from '@dating/ui/pages/store/manager/online/ScreenManagerContainer';
import StoreContainer from '@dating/ui/pages/store/StoreContainer';
import PurchaseHistoryContainer from '@/ui/components/dm/myManager/purchase/history/PurchaseHistoryContainer';
import { errorHandlerUtils } from '@/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';

const StoreRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<RouteTransition location={location} navigationType={navigationType}>
				<Routes location={location}>
					<Route path="*" element={<StoreContainer />} />
					<Route path="myBerith" element={<MyBerithContainer />} />
					<Route path="offlineManager" element={<OfflineManagerContainer />} />
					<Route path="screenManager" element={<ScreenManagerContainer />} />
					<Route path="managerPurchaseHistory" element={<PurchaseHistoryContainer />} />
				</Routes>
			</RouteTransition>
		</ErrorBoundary>
	);
};

export default StoreRouter;
