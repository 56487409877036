import { create } from 'zustand';

// 매니저 구매 관련한 상품정보를 담고, 사용하기 위한 state

type managerProduct = {
	pId: number;
	pName: string;
	price: number;
	hot:boolean;
	url: string;
	category: number; //0-> 오프라인, 1-> 스크린, 2-> voice
}

type  ManagerProductState = {
	managerProductDetailOpen:boolean,
	setManagerProductDetailOpen: (managerProductDetailOpen:boolean) => void,

	managerProducts: managerProduct[];
	setManagerProducts : (managerProducts: managerProduct[]) => void;

	searchCategory: number;
	setSearchCategory: (searchCategory:number)=>void;

	purchasePopOpen: boolean;
	setPurchasePopOpen: (purchasePopOpen: boolean) => void;
}

export const useManagerProductRepo = create<ManagerProductState>(
	(set) => ({
		managerProductDetailOpen: false,
		setManagerProductDetailOpen: (managerProductDetailOpen : boolean) => set({managerProductDetailOpen}),

		managerProducts:[{
			pId: 0,
			pName: "오프라인 매니저 3회",
			price: 2850,
			hot: false,
			url: "null",
			category:0
		},{
			pId: 1,
			pName: "오프라인 매니저 5회",
			price: 4250,
			hot: true,
			url: "null",
			category:0
		},{
			pId:2 ,
			pName: "오프라인 매니저 10회",
			price: 8500,
			hot: false,
			url: "null",
			category:0
		},{
			pId:3 ,
			pName: "스크린 매니저 3회",
			price: 1425,
			hot: false,
			url: "null",
			category:1
		},{
			pId:4 ,
			pName: "스크린 매니저 5회",
			price: 2125,
			hot: true,
			url: "null",
			category:1
		},{
			pId:5 ,
			pName: "스크린 매니저 10회",
			price: 4250,
			hot: false,
			url: "null",
			category:1
		}],
		setManagerProducts : (managerProducts: managerProduct[]) => set({ managerProducts }),

		searchCategory: 0,
		setSearchCategory: (searchCategory:number)=> set({searchCategory}),

		purchasePopOpen: false,
		setPurchasePopOpen: (purchasePopOpen: boolean) => set({purchasePopOpen}),
	}),
);


