import {useTranslation} from "react-i18next";

interface props {
    saveBtnDisabled: boolean;
    postId: string | undefined;
    fnModifyPost: () => void;
    fnSavePost: () => void;
};

const FeedFormFooterView = ({
                                saveBtnDisabled,
                                postId,
                                fnModifyPost,
                                fnSavePost,
                            }: props) => {
    const { t } = useTranslation('lounge')
    return (
        <div className="f-btnWrap">
            <button
                type="button"
                className="btn btnBig btnBlack"
                onClick={() => {
                    if (postId != undefined && postId != "") {
                        fnModifyPost();
                    } else {
                        fnSavePost();
                    }
                }}
                disabled={saveBtnDisabled}
            >
                <span>
                     {
                         postId === "" || postId === undefined ?
                             t('insert')
                             : t('modify')
                     }
                </span>
            </button>
        </div>
    );
};

export default FeedFormFooterView;
