import {EditType} from "@dating/repository/profile/useEditProfileRepo";
import {useTranslation} from "react-i18next";

interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    introduceValue: string;
    setIntroduceValue: (introduceValue: string) => void;
    fnModifyProfileIntroduce: () => void;
    closeEditPopup: (key: string) => void;
}

const EditIntroducePopView = ({
                                  focusItem,
                                  introduceValue,
                                  setIntroduceValue,
                                  fnModifyProfileIntroduce,
                                  closeEditPopup,
                              }: props) => {

    const { t } = useTranslation('register')
    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">{t('modifyProfile.intro')}</h2>
                        <p className="titleSub01">{t('modifyProfile.introQuote')}</p>
                    </div>
                    <textarea cols={30} rows={10}
                              placeholder={t('modifyProfile.introPlaceholder')}
                              value={introduceValue ? introduceValue : ""}
                              onChange={(e) => {
                                  setIntroduceValue(e.target.value);
                              }}
                    />
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>{t('common.cancel')}</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileIntroduce();
                        }}
                    >
                        <span>{t('common.confirm')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditIntroducePopView;