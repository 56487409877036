import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import {useNavigate} from "react-router-dom";
import {PATH} from "@dating/constants/RoutingEndPoints";

export const useFeedSearchService = () => {

    const navigate = useNavigate();

    const {
        searchResult,
        setFocusingId,
    } = useLoungeRepo();

    /*
     * thumbnailClick
     * 포스트 썸네일 클릭 시 포스트로 이동
     */
    const thumbnailClick = (postId: string) => {
        navigate(`${PATH.LOUNGE.SEARCH_LIST}?id=${postId}`);
    };

    return {
        searchResult,
        thumbnailClick,
        setFocusingId,
    };
};