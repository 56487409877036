import { useNavigate } from 'react-router-dom';
import { useMyBerithService } from '@dating/ui/pages/store/berith/service/useMyBerithService';
import { useTranslation } from 'react-i18next';

const MyBerithContainer = () => {
	const { myBerith, myBerithHistory } = useMyBerithService();

	const navigate = useNavigate();
	const { t } = useTranslation('store');

	return (
		<div className="rightShow">
			<header className="cateHeader">
				<div className="headerWrap">
					<div className="h-left"></div>
					<div className="h-center">
						<p className="title02">{t('berithHistory')}
							{/* 베리드 내역 */}
						</p>
					</div>
					<div className="h-right">
						<button
							type="button"
							className="btnClose"
							onClick={() => {
								navigate(-1);
							}}
						>
                            <span className="hide">{t('close')}
								{/* 닫기 */}
                            </span>
						</button>
					</div>
				</div>
			</header>

			<div className="popConWrap berithWrap">
				<div className="berithBox">
					<div className="leftRightTxt">
                        <span>{t('remainingBerith')}
							{/* 보유 베리드 */}
                        </span>
						<span className="ic-berith md bold">{myBerith ? myBerith : 0}</span>
					</div>
				</div>
				{myBerithHistory.length > 0 ? (
					<div className="berithHistoryList inner">
						<div className="berithHistory">
							{
								// pointType: 10(이벤트적립) / 20(사용) / 30(사용취소) / 40(결제취소)
								myBerithHistory.map((item, i) => {
									const pointType = item.pointType === '20' ? 'minus' : 'plus';
									return (
										<div key={i} className="leftRightTxt">
											<div>
												<span className="date">{item.pointDt}</span>
												<p className="txt01">{item.pointRsn}</p>
											</div>
											{/*<span className="plmi plus">{item.point}</span>*/}
											<span className={`plmi ${pointType}`}>{item.point}</span>
										</div>
									);
								})
							}
						</div>
					</div>
				) : (
					<div className="dataNoneTxt flex-1">
						<p className="txt01">{t('noBerithHistory')}
							{/* 베리드 내역이 없습니다. */}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default MyBerithContainer;
