import { CSSTransition } from 'react-transition-group';
import { ManagerProductsPopView } from '@dating/ui/pages/store/manager/views/ManagerProductsPopView';
import OfflineManagerContentView from './views/OfflineManagerContentView';
import ManagerHeaderView from '../views/ManagerHeaderView';
import ManagerInfoView from '../views/ManagerInfoView';
import ManagerFooterView from '../views/ManagerFooterView';
import { useOfflineManagerService } from './service/useOfflineManagerService';

const OfflineManagerContainer = () => {
	const {
		managerProducts,
		fnOpenOfflineProducts,
		openOfflineProducts,
		fnCloseOfflineProducts,
		fnOpenOfflineMessageRoom,
		fnValidateOrder,
	} = useOfflineManagerService();

	return (
		<>
			{/*하단 팝업*/}
			<div
				className={`${openOfflineProducts ? 'settingPopOn' : 'settingPop'}`}
				onClick={() => {
					fnCloseOfflineProducts();
				}}
			>
				<div className="footPopDim"></div>
				<CSSTransition
					in={openOfflineProducts}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<ManagerProductsPopView
						managerProducts={managerProducts}
						productSubType={'OFFLINE'}
						fnValidateOrder={fnValidateOrder}
						onOpenMessageRoom={fnOpenOfflineMessageRoom}
					/>
				</CSSTransition>
			</div>

			<div className="wrap footerNone">
				<ManagerHeaderView productSubType={'OFFLINE'} />
				<div id="conWrap">
					<img
						src="/assets/img/common/offline-mg-bg.jpg"
						alt="OFFLINE MANAGER"
						width="100%"
					/>
					<ManagerInfoView productSubType={'OFFLINE'} />
					<OfflineManagerContentView />
				</div>
				<ManagerFooterView fnOpenProducts={fnOpenOfflineProducts} />
			</div>
		</>
	);
};
export default OfflineManagerContainer;
