import MessageDetailContainer from '@/ui/components/dm/user/detail/MessageDetailContainer';
import { useLocation, Routes, Route } from 'react-router-dom';
import ManagerADContainer from '@/ui/components/dm/manager/advertisement/ManagerADContainer';
import MyManagerLayout from '@dating/ui/components/layout/MyManagerLayout';
import MyManagerOnlineListContainer from '@/ui/components/dm/myManager/online/list/MyManagerOnlineListContainer';
import MyManagerOnlineDetailContainer from '@/ui/components/dm/myManager/online/detail/MyManagerOnlineDetailContainer';
import MyManagerOfflineListContainer from '@/ui/components/dm/myManager/offline/list/MyManagerOfflineListContainer';
import MyManagerOfflineDetailContainer from '@/ui/components/dm/myManager/offline/detail/MyManagerOfflineDetailContainer';
import { lazy, Suspense } from 'react';
import Spinner from '@dating/ui/components/spinner/Spinner';
import { useMessageRouteHandler } from '@/hooks/useMessageRouteHandler';

const MessengerLayout = lazy(() => import('@dating/ui/components/layout/MessengerLayout'));
const MessageListContainer = lazy(() => import('@/ui/components/dm/user/list/MessageListContainer'));
const MessengerRouter = () => {
	useMessageRouteHandler();
	const location = useLocation();

	return (
		<Suspense fallback={<Spinner/>}>
			<Routes location={location}>
				{/*사용자-사용자 메신저*/}
				<Route path="/" element={<MessengerLayout />}>
					<Route
						index
						element={
							<MessageListContainer/>
						}
					/>
					<Route path=":roomId" element={<MessageDetailContainer />} />

					{/*구매한 매니저 있을 때*/}
					<Route element={<MyManagerLayout />}>
						{' '}
						{/*프로필카드, 온/오프라인 탭*/}
						<Route
							path="managerOnline"
							element={<MyManagerOnlineListContainer />}
						/>
						<Route
							path="managerOffline"
							element={<MyManagerOfflineListContainer />}
						/>
					</Route>

					{/*구매한 매니저 없을 때*/}
					<Route path="managerNone" element={<ManagerADContainer />} />

					{/*매니저 메신저룸*/}
					<Route
						path="managerOnline/:roomId"
						element={<MyManagerOnlineDetailContainer />}
					/>
					<Route
						path="managerOffline/:roomId"
						element={<MyManagerOfflineDetailContainer />}
					/>
				</Route>
			</Routes>
		</Suspense>
	);
};
export default MessengerRouter;
