import MessageDetailHeaderView from './views/MessageDetailHeaderView';
import { CSSTransition } from 'react-transition-group';
import LeaveChatPopView from '@dating/ui/components/leaveChat/views/LeaveChatPopView';
import MessageInputView from './views/MessageInputView';
import MessageDetailView from './views/MessageDetailView';
import PartialPaymentMessageView from './views/PartialPaymentMessageView';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useMessageDetailService } from './service/useMessageDetailService';

const MessageDetailContainer = () => {
	const {
		handlePaymentClick,
		showPaymentView,
		isOtherUserLeft,
		groupedMessagesDetail,
		setUserProfileOpen,
		setOpenLeaveChat,
		modifyMenuPop,
		handleSubmit,
		handleLeaveChat,
		handleExitChat,
		handleChange,
		shouldExitChat,
		focused,
		messageInputValue,
		openLeaveChat,
		srcPrefix,
		messagesEndRef,
		myProfile,
		matchingTime,
		currentMessageLength,
		navigationRoom,
	} = useMessageDetailService();

	const {
		openReport,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		reportPopClose,
		fnSaveReport,
	} = useBlockReportService();

	const blockStatus = navigationRoom.otherUserInfo.blockType;

	return (
		<div className="wrap footerNone">
			<MessageDetailHeaderView
				setOpenLeaveChat={setOpenLeaveChat}
				modifyMenuPop={modifyMenuPop}
				navigationRoom={navigationRoom}
			/>
			<div id="conWrap" className="chatWrap">
				<div className="messengerWrap">
					{showPaymentView ? (
						<PartialPaymentMessageView
							currentMessageLength={currentMessageLength}
							matchingTime={matchingTime}
							myProfile={myProfile}
							groupedMessagesDetail={groupedMessagesDetail}
							srcPrefix={srcPrefix}
							navigationRoom={navigationRoom}
							setUserProfileOpen={setUserProfileOpen}
							handlePaymentClick={handlePaymentClick}
							isOtherUserLeft={isOtherUserLeft}
						/>
					) : (
						<form onSubmit={handleSubmit}>
							<MessageDetailView
								setUserProfileOpen={setUserProfileOpen}
								groupedMessagesDetail={groupedMessagesDetail}
								srcPrefix={srcPrefix}
								myProfile={myProfile}
								navigationRoom={navigationRoom}
								isOtherUserLeft={isOtherUserLeft}
								messagesEndRef={messagesEndRef}
								handleExitChat={handleExitChat}
								blockStatus={blockStatus}
							/>
							<MessageInputView
								handleSubmit={handleSubmit}
								messageInputValue={messageInputValue}
								handleChange={handleChange}
								focused={focused}
								isOtherUserLeft={isOtherUserLeft}
								blockStatus={blockStatus}
							/>
						</form>
					)}
				</div>
			</div>
			<CSSTransition
				in={openLeaveChat}
				classNames="leaveChatPopWrap-transition"
				timeout={100}
			>
				<LeaveChatPopView
					shouldExitChat={shouldExitChat}
					handleExitChat={handleExitChat}
					setOpenLeaveChat={setOpenLeaveChat}
					handleLeaveChat={handleLeaveChat}
				/>
			</CSSTransition>
			<CSSTransition
				in={openReport}
				classNames="popWrap-transition"
				timeout={100}
			>
				<ReportPopView
					reportPopClose={reportPopClose}
					selectedOption={selectedOption}
					handleOptionSelect={handleOptionSelect}
					commonReportCodes={commonReportCodes}
					reportValue={reportValue}
					reportInputHandler={reportInputHandler}
					inputDisabled={inputDisabled}
					fnSaveReport={fnSaveReport}
				/>
			</CSSTransition>
		</div>
	);
};

export default MessageDetailContainer;
