import {Link} from "react-router-dom";
import React from "react";
import { HomePastMatchingUserCOVO, HomePastUserSOVO } from '@/site/api';
import {PATH} from "@dating/constants/RoutingEndPoints";
import {useTranslation} from "react-i18next";

interface props {
    type: string;
    profile: HomePastUserSOVO;
    srcPrefix: string;
    setUserProfileOpen: (userProfileOpen: string) => void;
    currentUserUid: string;
}

export const DdayProfileBoxView = ({
                                       type,
                                       profile,
                                       srcPrefix,
                                       setUserProfileOpen,
                                       currentUserUid,
                                   }: props) => {
    const { t } = useTranslation('profile')
    return (
        <>
            <div className="profileBox">
                <div className="profileSlide">
                    <Link
                        to={PATH.PROFILE.USER_PROFILE}
                        onClick={() => {
                            setUserProfileOpen(profile.yours);
                        }}
                        state={{
                            isOtherProfile: profile.profileUrl !== currentUserUid
                        }}
                    >
                        <img
                            src={srcPrefix + profile.profileUrl}
                            alt={profile.nickName}
                            onClick={() => {
                            }}
                        />
                    </Link>
                </div>
                <div className="txtBox">
                    {
                        profile.hasBadged &&
                        <span className="addAuthBadge">{t('profileBox.addBadge')}</span>
                    }
                    <p className="txt01">{profile.nickName}</p>
                    <p className="txt02">
                        {profile.age + t('profileBox.age')+ ", " + (profile.region1 && profile.region2 ? `${profile.region1} ${profile.region2}` : t('profileBox.noRegion'))}
                    </p>
                    <div className="d-dayNum">
                        D-{profile.remainDays}
                    </div>
                </div>
            </div>
        </>
    );
};