import { useManagerPopService } from '../../myManager/purchase/popup/service/useManagerPopService';
import {useCommonAlertPopService} from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import {useTranslation} from "react-i18next";

const ManagerADContainer = () => {
	const { fnPurchasePopOn } = useManagerPopService();
	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();
	const { t } = useTranslation('messenger')

	return (
		<div className="msgWrap">
			<div className="chatRoomList">
				<div className="chatStartBox">
					<img
						src="/assets/img/common/ico-mg-none.png"
						alt="연결된 매니저가 없습니다."
						className="mgNoneImg"
					/>
					<p className="partnerStart2">{t('noManager')}</p>
					<p className="subTxt01" dangerouslySetInnerHTML={{ __html: t('tryCoupleService') }}>
					</p>
					<button
						type="button"
						className="mgPayBtn"
						onClick={() => {
							if (import.meta.env.MODE == 'prd') {
								// 운영
								fnCommonAlertPopOn(t('serviceNotYet'));

							} else {
								// 개발
								fnPurchasePopOn();
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ManagerADContainer;
