import FriendWrapView from './views/FriendWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import {useTranslation} from "react-i18next";

const FriendInviteEventContainer = () => {
	const { t } = useTranslation('setting')
	const title = t('inviteEvent');
	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />
				<FriendWrapView />
			</div>
		</>
	);
};

export default FriendInviteEventContainer;
