import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useLikeHeaderService } from '@dating/ui/pages/like/views/useLikeHeaderService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

export const LikeLayout = () => {
	const {
		isReceived,
		changeLikeType,
	} = useLikeHeaderService();

	const {
		myProfile,
		setMyProfileOpen,
		srcPrefix,
	} = useHeaderService();

	const { t } = useTranslation('like');

	return (
		<>
			<div className="wrap">
				<header className="cateHeader">
					<div className="headerWrap">
						<div className="h-left">
							<div className="headerTab">
								<button
									type="button"
									className={`tabButton tab01 ${isReceived ? 'active' : ''}`}
								>
								<span>
									<Link to={PATH.LIKE.RECEIVED}
										  onClick={() => {
											  changeLikeType(true);
										  }}>
									{t('header.likeReceived')}
									</Link>
								</span>
								</button>
								<button
									type="button"
									className={`tabButton tab02 ${!isReceived ? 'active' : ''}`}
								>
								<span>
									<Link to={PATH.LIKE.SEND}
										  onClick={() => {
											  changeLikeType(false);
										  }}>
									{t('header.likeSent')}
								</Link>
								</span>
								</button>
							</div>
						</div>

						<div className="h-center" />

						<div className="h-right">
							{/*<span class="headerMgBadge">매니저</span> */}
							<Link
								to={PATH.PROFILE.MY_PROFILE}
								onClick={() => {
									//내 프로필 open
									setMyProfileOpen(true);
								}}
							>
								<button className="profileSmImg">
									<img
										src={
											myProfile && myProfile?.profileUrl[0]
												? srcPrefix + myProfile?.profileUrl[0]
												: '/assets/img/test/boy.png'
										}
										alt="테스트이미지"
									/>
								</button>
							</Link>
						</div>
					</div>
				</header>
				<Outlet />
			</div>
		</>
	);
};