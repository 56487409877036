import { useMutation } from '@tanstack/react-query';
import {
	type LocationDeviceInfoCIVO,
	MobilePhoneOccupancyCIVO, MobilePhoneOccupancyVerificationCIVO,
	type UserBirthdayCIVO,
	type UserGenderCIVO, type UserHeightCIVO, type UserJobCIVO,
	type UserNicknameCIVO, type UserPreferCIVO, type UserRecommenderCIVO,
	UserService, type UserStyleCIVO,
	type UserTermsCIVO,
} from '@/site/api';

export const useRegisterAdapter = () => {
	/*
	 * sendVerificationCode
	 * 핸드폰 인증 번호 발송
	 */
	const sendVerificationCode = (map: MobilePhoneOccupancyCIVO) => {
		return UserService.sendVerificationCode(map);
	};

	/*
	 * verifyVerificationCode
	 * 핸드폰 인증 번호 검증
	 */
	const verifyVerificationCode = useMutation({
		mutationFn: (map: MobilePhoneOccupancyVerificationCIVO) => {
			return UserService.verifyVerificationCode(map);
		},
	});

	/*
	 * verifyAuthCode
	 * 핸드폰 인증 번호 검증
	 */
	const verifyAuthCode = (map: MobilePhoneOccupancyVerificationCIVO) => {
		return UserService.verifyVerificationCode(map);
	};

	/*
	 * saveUserTerms
	 * 회원 약관 동의 정보 저장
	 */
	const saveUserTerms = (map: UserTermsCIVO) => {
		return UserService.saveUserTerms(map);
	};

	/*
	 * saveUserGender
	 * 성별 저장
	 */
	const saveUserGender = useMutation({
		mutationFn: (map: UserGenderCIVO) => {
			return UserService.saveUserGender(map);
		},
	});

	/*
	 * saveNickname
	 * 닉네임 중복체크 및 저장
	 */
	const saveNickname = useMutation({
		mutationFn: (map: UserNicknameCIVO) => {
			return UserService.saveNickname(map);
		},
	});

	/*
	 * saveBirthday
	 * 나이 체크 및 생년월일 저장
	 */
	const saveBirthday = useMutation({
		mutationFn: (map: UserBirthdayCIVO) => {
			return UserService.saveBirthday(map);
		},
	});

	/*
	 * saveUserLocation
	 * 위치정보제공 동의 여부 저장
	 */
	// const saveUserLocation = useMutation({
	//     mutationFn: (map: UserLocationShareCIVO) => {
	//         return UserService.saveUserLocation(map);
	//     }
	// },);

	/*
	 * saveHeight
	 * 키(cm) 저장
	 */
	const saveHeight = useMutation({
		mutationFn: (map: UserHeightCIVO) => {
			return UserService.saveHeight(map);
		},
	});

	/*
	 * saveJob
	 * 직업 저장
	 */
	const saveJob = useMutation({
		mutationFn: (map: UserJobCIVO) => {
			return UserService.saveJob(map);
		},
	});

	/*
	 * saveUserPrefer
	 * 이상형 저장
	 */
	const saveUserPrefer = useMutation({
		mutationFn: (map: UserPreferCIVO) => {
			return UserService.saveUserPrefer(map);
		},
	});

	/*
	 * saveRecommender
	 * 추천인코드 저장
	 */
	const saveRecommender = useMutation({
		mutationFn: (map: UserRecommenderCIVO) => {
			return UserService.saveRecommender(map);
		},
	});

	/*
	 * skipRecommender
	 * 추천인코드 입력 건너뛰기
	 */
	const skipRecommender = useMutation({
		mutationFn: () => {
			return UserService.skipRecommender();
		},
	});

	/*
	 * getUserStyle
	 * 나의 관심, 라이프스타일 가져오기
	 */
	const getUserStyle = () => {
		return UserService.getUserStyle();
	};

	/*
	 * saveUserStyle
	 * 나의 관심, 라이프스타일 저장
	 */
	const saveUserStyle = useMutation({
		mutationFn: (map: UserStyleCIVO) => {
			return UserService.saveUserStyle(map);
		},
	});

	/*
	 * saveUserProfile
	 * 프로필사진 업로드
	 */
	const saveUserProfile = useMutation({
		mutationFn: (map: {
			files?: Array<Blob>;
		}) => {
			return UserService.saveUserProfile(map);
		},
	});

	/*
	 * getUserWaiting
	 * 회원가입 시 입력한 정보 가져오기 - 승인대기(완료)화면
	 */
	const getUserWaiting = () => {
		return UserService.getUserWaiting();
	};

	/*
	 * startSignUp
	 * 회원가입 승인완료 화면 시작버튼 클릭 여부 저장
	 */
	const startSignUp = () => {
		return UserService.startSignUp();
	};

	/*
	 * saveUserLocation
	 * 회원가입 시 위치정보 저장
	 */
	const saveUserLocation = useMutation({
		mutationFn: (map: LocationDeviceInfoCIVO) => {
			return UserService.saveUserLocation(map);
		},
	});

	/*
	 * getMyRecommender
	 * 내 추천인 코드 조회
	 */
	const getMyRecommender = () => {
		return UserService.getMyRecommender();
	};

	return {
		startSignUp,
		sendVerificationCode: sendVerificationCode,
		verifyVerificationCode: verifyVerificationCode.mutate,
		verifyVerificationCodeStatus: verifyVerificationCode.status,
		verifyVerificationCodeData: verifyVerificationCode.data,
		saveUserTerms,
		saveUserGender: saveUserGender.mutate,
		statusSaveUserGender: saveUserGender.status,
		dataSaveUserGender: saveUserGender.data,
		saveNickname: saveNickname.mutate,
		statusSaveNickname: saveNickname.status,
		dataSaveNickname: saveNickname.data,
		saveBirthday: saveBirthday.mutate,
		saveBirthdayStatus: saveBirthday.status,
		saveBirthdayData: saveBirthday.data,
		saveUserLocation: saveUserLocation.mutate,
		statusSaveUserLocation: saveUserLocation.status,
		dataSaveUserLocation: saveUserLocation.data,
		saveHeight: saveHeight.mutate,
		statusSaveHeight: saveHeight.status,
		dataSaveHeight: saveHeight.data,
		saveJob: saveJob.mutate,
		statusSaveJob: saveJob.status,
		dataSaveJob: saveJob.data,
		saveUserPrefer: saveUserPrefer.mutate,
		statusSaveUserPrefer: saveUserPrefer.status,
		dataSaveUserPrefer: saveUserPrefer.data,
		saveRecommender: saveRecommender.mutate,
		statusSaveRecommender: saveRecommender.status,
		dataSaveRecommender: saveRecommender.data,
		getUserStyle,
		saveUserStyle: saveUserStyle.mutate,
		statusSaveUserStyle: saveUserStyle.status,
		dataSaveUserStyle: saveUserStyle.data,
		saveUserProfile: saveUserProfile.mutate,
		saveUserProfileStatus: saveUserProfile.status,
		saveUserProfileData: saveUserProfile.data,
		getUserWaiting,
		skipRecommender: skipRecommender.mutate,
		skipRecommenderStatus: skipRecommender.status,
		skipRecommenderData: skipRecommender.data,
		verifyAuthCode,
		getMyRecommender,
	};
};