import { useTranslation } from 'react-i18next';

interface Props {
	fnActivatedUserLogin: () => void;
}

export const InactiveAlertPopView = ({
										 fnActivatedUserLogin,
									 }: Props) => {
	const { t } = useTranslation('setting');
	return (
		<div className="withdrawalWrap">
			<div className="popupInfo padding-t36"
				 style={{
					 "whiteSpace": "pre-wrap",
				 }}>
				<p className="blackTxt text-c bold-500">
					{t('accountReleased')}
					{/* 계정이 해제되었습니다. */}
					<br/>
					{t('accountUsable')}
					{/* 정상적으로 이용이 가능합니다. */}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={() => {
							fnActivatedUserLogin();
						}}
					>
                        <span>{t('confirm')}
							{/* 확 인 */}
                        </span>
					</button>
				</div>
			</div>
		</div>
	);
};
