import React from 'react';
import { useiOSJoystickService } from './service/useiOSJoystickService';

interface IOSJoystickProps {
	size?: number;
	stickSize?: number;
	baseColor?: string;
	stickImage?: string;
	minDistance?: number;
}

const IOSJoystickContainer: React.FC<IOSJoystickProps> = ({
	size = 120,
	stickSize = 50,
	baseColor = 'rgba(0,0,0,0.4)',
	stickImage = '/assets/joystick/ic-joy.svg',
	minDistance = 10,
}) => {
	const { joystickRef, stickRef } = useiOSJoystickService(
		size,
		stickSize,
		minDistance,
	);

	return (
		<div
			ref={joystickRef}
			style={{
				width: size,
				height: size,
				borderRadius: '50%',
				backgroundColor: baseColor,
				position: 'relative',
				touchAction: 'none',
				zIndex: 1,
			}}
		>
			<div
				ref={stickRef}
				style={{
					width: stickSize,
					height: stickSize,
					borderRadius: '50%',
					position: 'absolute',
					left: '50%', // 중앙 정렬을 위해 50%로 변경
					top: '50%', // 중앙 정렬을 위해 50%로 변경
					backgroundImage: `url(${stickImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center', // 배경 이미지 중앙 정렬
					touchAction: 'none',
					zIndex: 2,
					transform: 'translate(-50%, -50%)', // 중앙 정렬을 위한 transform
				}}
			/>
		</div>
	);
};

export default IOSJoystickContainer;
