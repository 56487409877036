import { useEffect } from 'react';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useFooterRepo } from '@dating/repository/dating/useFooterRepo';

export const useHeaderService = () => {
	const { myProfile, srcPrefix, setMyProfileOpen, setUserProfileOpen } =
		useDatingRepo();

	const { loungeOpen, likeOpen, homeOpen, metaOpen } = useFooterRepo();

	useEffect(() => {}, [homeOpen, loungeOpen, likeOpen, metaOpen]);

	return {
		myProfile,
		srcPrefix,
		setMyProfileOpen,
		setUserProfileOpen,
		loungeOpen,
	};
};
