import { useNavigate } from 'react-router-dom';

const RegisterHeaderView = () => {
	const navigate = useNavigate();

	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left">
					<button
						className="btnBack"
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
				<div className="h-center"></div>
				<div className="h-right"></div>
			</div>
		</header>
	);
};

export default RegisterHeaderView;
