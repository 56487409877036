import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import ProfileBoxView from '@dating/ui/pages/home/idealType/views/ProfileBoxView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { HushPickConfirmPopView } from '@dating/ui/pages/hushPick/HushPickConfirmPopView';
import { useAppFooterService } from '@/ui/components/footer/service/useAppFooterService';
import React, { useEffect, useRef } from 'react';
import { useHushHomeService } from '@dating/ui/pages/hushHome/useHushHomeService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useOrderUtils } from '@/utils/orderUtils';
import {useTranslation} from "react-i18next";

const HushHomeContainer = () => {
	const { t } = useTranslation('home');

	const {
		randomUsers,
		preferUsers,
		setUserProfileOpen,
		sliderSettings,
		pointConfirmPopOpen,
		fnAddPreferUsers,
		fnPointConfirmPopOn,
		fnPointConfirmPopOff,
		hushPickPacks,
		hushPickPacksHandle,
		hushPickOn,
		hushPickUsers,
		isChecked,
		evalHandler,
		setIsChecked,
		fnAddEvalUser,
		pointConfirmMessage,
		likeIndex,
		fnGetRecommendUsers,
		pointConfirmFunc,
		scrollBottom,
		setScrollBottom,
	} = useHushHomeService();

	const {
		hushHome,
		srcPrefix,
		myProfile,
	} = useHomeHeaderService();

	const {
		initFooterWrap,
	} = useAppFooterService();

	const {
		setOpenPaymentPop,
		fnSendLikePopOpen,
		setMessageTargetUser,
		recommendProducts,
	} = usePaymentService();

	const {
		fnValidateOrder,
	} = useOrderUtils();

	const wrapRef = useRef<HTMLDivElement>(null);
	const bottomRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		//메인 진입 시 하단 메뉴 홈 선택 활성화
		initFooterWrap();
	}, []);

	useEffect(() => {
		// 허쉬추천 과금한 경우 페이지 맨 위로 스크롤 이동
		wrapRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [hushPickUsers]);

	useEffect(() => {
		// Today 이상형 더보기 과금한 경우 페이지 맨 아래로 스크롤 이동
		if (scrollBottom) {
			bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
		return setScrollBottom(false);
	}, [scrollBottom]);

	return (
		<>
			<div id="conWrap" className="metaPopWrap" ref={wrapRef}>
				<div className="tabConWrap">
					<div className="tabContent">
						<div className={`tabCon tabCon01 ${hushHome ? 'active' : ''}`}>
							{/*과금한 추천 리스트*/}
							{
								hushPickUsers.length > 0 ?
									(
										<>
											<div className="todayListWrap bg-f5f5f5">
												<div className="inner">
													<p className="title03">{t('hushPickUser.todayRecommend')}</p>
													<div className="profileList">
														{
															hushPickUsers.map((profile, i) => {
																return <ProfileBoxView
																	key={profile.nickName + i}
																	index={i}
																	location={'hushPick'}
																	likeIndex={likeIndex}
																	profile={profile}
																	srcPrefix={srcPrefix}
																	fnSendLikePopOpen={fnSendLikePopOpen}
																	setUserProfileOpen={setUserProfileOpen}
																	currentUserUid={myProfile.userUid}
																	setOpenPaymentPop={setOpenPaymentPop}
																	sliderSettings={sliderSettings}
																	setMessageTargetUser={setMessageTargetUser}
																/>;
															})
														}
													</div>
													<a className="addViewBtn openBtn"
													   onClick={(e) => {
														   e.preventDefault();
														   // 허쉬 추천을 더 해 드려요! 팝업 호출
														   const message = {
															   tit1: t('hushPickUser.popTit'),
															   tit2: '',
															   price: recommendProducts[2].price,
														   };
														   fnPointConfirmPopOn(message, fnGetRecommendUsers, {
															   productId: recommendProducts[2].id,
															   matchType: 'random',
														   });
													   }}
													>
														{t('common.moreRecommend')}
													</a>
												</div>
											</div>
										</>
									)
									: null
							}
							{/*과금한 추천 리스트*/}

							{/*별점평가*/}
							<div className="homeStarWrap">
								<div className="inner">
									{
										randomUsers.length > 0 ?
											(
												<>
													<p className="title03">{t('randomUser.todayCharmEval')}</p>
													<div className="evList profileList">
														<div className="profileBox active">
															<div className="profileSlide">
																<div>
																	<img
																		src={
																			srcPrefix + randomUsers[0].profileUrls[0]
																		}
																	/>
																	<div className="txtBox">
																		{
																			randomUsers[0].hasBadged && <span className="addAuthBadge">{t('randomUser.addBadge')}</span>
																		}
																		<p className="txt01 fz20 color-fff">
																			{randomUsers[0].nickName}
																		</p>
																		<p className="txt02 fz16 color-fff">
																			{randomUsers[0].age + t('common.age')}, {randomUsers[0].region1 && randomUsers[0].region2 ? `${randomUsers[0].region1} ${randomUsers[0].region2}` : t('common.noRegion')}
																		</p>
																		<div className="profileLikeBox">
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="starBox margin-t16">
														{/*별점평가*/}
														{[5, 4, 3, 2, 1].map((value, i) => {
															const id = 'star' + value.toString() + i;
															return (
																<React.Fragment key={id}>
																	<input
																		type="radio"
																		name="rating"
																		id={id}
																		value={value}
																		checked={isChecked === value ? true : false}
																		onChange={(e) => {
																			if (isChecked) {
																				e.preventDefault();
																			} else {
																				setIsChecked(value);
																				evalHandler(
																					randomUsers[0].matchingUserUid,
																					Number(e.target.value),
																				);
																			}
																		}}
																	/>
																	<label htmlFor={id} />
																</React.Fragment>
															);
														})}
													</div>
													<p className="fz12 color-666 text-c margin-t8" dangerouslySetInnerHTML={{ __html: t('randomUser.appealQuote') }}/>
												</>
											)
											: <div className="homeStarWrap">
												<div className="inner">
													<div className="starNone">
														<p className="title03">{t('randomUser.moreRate')}</p>
														<p className="color-666 fz14" dangerouslySetInnerHTML={{__html:t('randomUser.moreRate2')}}/>
														<button
															type="button"
															className="addViewBtn openBtn"
															onClick={() => {
																// 더 많은 이성을 평가하시겠어요? 팝업 호출
																const message = {
																	tit1: t('randomUser.popTit1'),
																	tit2: t('randomUser.popTit2'),
																	price: recommendProducts[1].price,
																};
																fnPointConfirmPopOn(message, fnAddEvalUser, { productId: recommendProducts[1].id });
															}}
														>
															{t('randomUser.moreRatePlus')}
														</button>
													</div>
												</div>
											</div>
									}
								</div>
							</div>
							{/*별점평가*/}

							{/*	이상형 추천받기 (과금)*/}
							<div className="someWrap">
								<div className="inner">
									<p className="title03 bold">{t('ideal.wantToKnow')}</p>

									<Link to={PATH.SETTINGS.IDEAL_EDIT}>
										<div className="someGrayBox">
											<p className="title03 bold" dangerouslySetInnerHTML={{ __html: t('ideal.setMyIdeal') }}></p>
											<p className="color-666 fz14 margin-t10">{t('ideal.findIdeal')}</p>
										</div>
									</Link>
								</div>
							</div>
							<div className="pickWrap">
								<div className="inner">
									<div className="pickList">
										{
											hushPickPacks.map(item => {
												return (
													<div
														key={item.title}
														className={`pickItem ico-pick-ceo ${hushPickOn[item.title] ? 'on' : ''}`}
														onClick={() => {
															hushPickPacksHandle(item.title);
														}}
													>
														<div className="pickType">
															<div>
																<img src={item.iconUrl} alt={item.matchType} />
															</div>
															<p className="txt01">
																{
																	item.matchType === 'tall' ?
																		(myProfile.gender === '여성' ? t('ideal.longTall1') : t('ideal.longTall2'))
																		: item.titleLang
																}
															</p>
														</div>
														<a onClick={(e) => {
															e.preventDefault();
															fnValidateOrder(fnGetRecommendUsers, {
																productId: recommendProducts[item.productIndex].id,
																matchType: item.matchType,
																jobCode: item.jobCode,
															});
														}}>
															<span className="fz14 bold">{t('ideal.getRecommend')}</span>
															<span
																className="ic-berith">{recommendProducts[item.productIndex]?.price}</span>
														</a>
													</div>
												);
											})
										}
									</div>
								</div>
							</div>
							{/*이상형 추천받기(과금)*/}

							<div className="todayListWrap">
								<div className="inner">
									<p className="title03">Today</p>
									<div className="profileList">
										{preferUsers.length > 0
											? preferUsers.map((profile, i) => {
												return (
													<ProfileBoxView
														key={profile.nickName + i}
														index={i}
														location={'today'}
														likeIndex={likeIndex}
														profile={profile}
														srcPrefix={srcPrefix}
														fnSendLikePopOpen={fnSendLikePopOpen}
														setUserProfileOpen={setUserProfileOpen}
														currentUserUid={myProfile.userUid}
														setOpenPaymentPop={setOpenPaymentPop}
														sliderSettings={sliderSettings}
														setMessageTargetUser={setMessageTargetUser}
													/>
												);
											})
											: (
												<div className="starNone">
													<p className="title03">{t('preferUsers.moreLike')}</p>
													<p className="color-666 fz14" dangerouslySetInnerHTML={{ __html: t('preferUsers.moreLikeQuote') }}/>
												</div>
											)}

										<a className="addView openBtn margin-t20"
										   ref={bottomRef}
										   onClick={(e) => {
											   e.defaultPrevented;
											   // 과금 팝업 호출
											   const message = {
												   tit1: t('preferUsers.popTit1'),
												   tit2: t('preferUsers.popTit2'),
												   price: recommendProducts[0].price,
											   };
											   fnPointConfirmPopOn(message, fnAddPreferUsers, { productId: recommendProducts[0].id });
										   }}
										>
											{t('preferUsers.moreMember')}
										</a>
									</div>
								</div>
							</div>
							{/*오늘의 이상형 추천*/}
						</div>
					</div>
				</div>
			</div>

			{/*과금 팝업*/}
			<div
				className={`${
					pointConfirmPopOpen ? 'settingPopOn' : 'settingPop'
				}`}
			>
				<div className="settingDim" />
				<CSSTransition
					in={pointConfirmPopOpen}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<HushPickConfirmPopView
						pointConfirmMessage={pointConfirmMessage}
						pointConfirmFunc={pointConfirmFunc}
						fnPointConfirmPopOff={fnPointConfirmPopOff}
						fnValidateOrder={fnValidateOrder}
					/>
				</CSSTransition>
			</div>
		</>
	);
};

export default HushHomeContainer;
