import { Outlet } from 'react-router-dom';
import ManagerTabView from '../../views/ManagerTabView';
import OfflineManagerListView from './views/OfflineManagerListView';
import {
	useOfflineManagerMessageService
} from '@/ui/components/dm/manager/offline/service/useOfflineManagerMessageService';

const OfflineManagerListContainer = () => {
	const {
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		isOnline,
		setIsOnline,
		setIsFirst,
		isFirst
	} = useOfflineManagerMessageService()
	return (
		<>
			<div className="wrap">
				<ManagerTabView
					setIsFirst ={setIsFirst}
					isFirst = {isFirst}
					setIsOnline={setIsOnline}
					setShowOnlineMessageList={setShowOnlineMessageList}
					setShowOfflineMessageList={setShowOfflineMessageList}
					isOnline={isOnline}
				/>
				<div
					id="conWrap"
					className="metaPopWrap bdNone"
					style={{ marginBottom: 60 }}
				>
					<div className="messengerWrap">
						<div className="inner">
							<div className="tabCon tabCon01">
								<OfflineManagerListView />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Outlet />
		</>
	);
};

export default OfflineManagerListContainer;
