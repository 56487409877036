import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ManagerHeaderViewProps {
	productSubType: 'ONLINE' | 'OFFLINE';
}

const ManagerHeaderView = ({ productSubType }: ManagerHeaderViewProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation('store');

	const getTitle = () => {
		switch (productSubType) {
			case 'ONLINE':
				return t('screenMatchingManager');
			// 스크린 매칭 매니저
			case 'OFFLINE':
				return t('offlineMatchingManager');
			// 오프라인 매칭 매니저
			default:
				return '';
		}
	};

	return (
		<header>
			<div className="headerWrap">
				<div className="h-left">
					<button
						type="button"
						className="btnBack"
						onClick={() => {
							navigate(-1);
						}}
					/>
				</div>
				<div className="h-center">
					<p className="title02">{getTitle()}</p>
				</div>
				<div className="h-right"></div>
			</div>
		</header>
	);
};

export default ManagerHeaderView;
