/**
 * 결제 관련 API
 * */

import type { OrderInfoCIVO, ProductSOVO, PurchaseResultCIVO } from '@/site/api';
import { useEffect, useState } from 'react';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useProductAdapter } from '@dating/adapter/product/useProductAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useOrderAdapter } from '@dating/adapter/order/useOrderAdapter';
import { usePaymentPopService } from '@dating/ui/components/payment/usePaymentPopService';
import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';

export const useOrderUtils = () => {
	const [orderResponse, setOrderResponse] = useState<boolean>(false);

	const {
		setCommonProducts,
		setRecommendProducts,
		setManagerProducts,
		setPointProducts,
	} = useProductsRepo();

	const {
		orderCallback,
		setOrderCallback,
		initOrderCallback,
		addOrderCallbackParam,
	} = useDatingRepo();

	const { getProducts } = useProductAdapter();

	const {
		validateOrder,
		createOrder,
		saveInAppPurchase,
	} = useOrderAdapter();

	const { openChargeAlertPop } = usePaymentPopService();

	const { setOrderResult } = useOrderRepo();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const setProductItems = () => {
		getProducts().then((packs) => {
			if (packs.data.length > 0) {
				let commons: ProductSOVO[] = [];
				let recommends: ProductSOVO[] = [];
				let managers: ProductSOVO[] = [];
				let points: ProductSOVO[] = [];

				packs.data.map((item) => {
					switch (item.productType) {
						case 'LIKE':
						case 'MESSENGER':
							commons.push(item);
							break;

						case 'RECOMMEND':
						case 'REVIEW':
							recommends.push(item);
							break;

						case 'MANAGER':
							managers.push(item);
							break;

						default:
							points.push(item);
							break;
					}
				});

				setCommonProducts(commons);
				setRecommendProducts(recommends);
				setManagerProducts(managers);
				setPointProducts(points);
			}
		});
	};

	/*
	 * fnCreateOrder
	 * 상품 주문
	 */
	const fnCreateOrder = (productId: number) => {
		const map: OrderInfoCIVO = {
			productId: productId,
		};
		createOrder(map).then((value) => {
			if (value) {
				console.log('createOrder 응답:', value); // 주문 생성 응답 확인

				setOrderResponse(true);
				setOrderResult(value);

				// callback 파라미터에 주문 결과 추가
				addOrderCallbackParam({ orderId: value.orderId });
			}
		});
	};

	/*
	 * fnValidateOrder
	 * 주문 가능 여부 확인
	 */
	const fnValidateOrder = (callback: any, param: any) => {
		setOrderCallback({
			func: callback,
			param: param,
		});

		validateOrder(param.productId).then((response) => {
			if (response) {
				fnCreateOrder(param.productId);
			} else if (!response) {
				openChargeAlertPop();
			}
		}).catch(reason => {
			console.log(reason);
		});
	};

	useEffect(() => {
		if (orderResponse && orderCallback.param.productId != -1 && orderCallback.param.orderId) {
			orderCallback.func(orderCallback.param); // 이제 orderId가 포함된 param이 전달됨
			setOrderResponse(false);
			initOrderCallback();
		}
	}, [orderResponse, orderCallback]);

	/*
	 * fnSaveInAppPurchase
	 * 앱에서 수신한 인앱결제 결과 서버에 저장
	 */
	const fnSaveInAppPurchase = (data: PurchaseResultCIVO) => {
		const map: PurchaseResultCIVO = {
			productKey: data.productKey,
			message: data.message,
			transactionId: data.transactionId,
			status: data.status,
		};
		saveInAppPurchase(map).then(value => {
			if (value) {

			}
		}).catch(reason => {
			alert(reason.body.message);
		});
	};

	return {
		setProductItems,
		fnValidateOrder,
		fnSaveInAppPurchase,
	};
};
