import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import { useHushHomeService } from '@dating/ui/pages/hushHome/useHushHomeService';
import { useAppFooterService } from '@/ui/components/footer/service/useAppFooterService';
import Slider from 'react-slick';
import { PATH } from '@dating/constants/RoutingEndPoints';
import {useTranslation} from "react-i18next";

export const ManagerHushHomeContainer = () => {
	const {
		randomUsers,
		preferUsers,
		setUserProfileOpen,
		sliderSettings,
		isChecked,
		evalHandler,
		setIsChecked,
	} = useHushHomeService();

	const { hushHome, srcPrefix, myProfile } = useHomeHeaderService();

	const { initFooterWrap } = useAppFooterService();

	const { t } = useTranslation('home')

	useEffect(() => {
		//메인 진입 시 하단 메뉴 홈 선택 활성화
		initFooterWrap();
	}, []);

	return (
		<div className="wrap">
			<header className="cateHeader">
				<div className="headerWrap">
					<div className="h-left">
						<div className="headerTab">
							<button type="button" className="tabButton tab01 active">
								<span>
									<img src="/assets/img/common/logo-hush.svg" alt="hushHome" />
								</span>
							</button>
						</div>
					</div>

					<div className="h-center" />

					<div className="h-right">
						<span className="headerMgBadge">{t('common.manager')}</span>
						<Link to={PATH.MANAGER.MY_PROFILE}>
							<button className="profileSmImg">
								<img
									src={
										myProfile && myProfile?.profileUrl[0]
											? srcPrefix + myProfile?.profileUrl[0]
											: '/assets/img/test/boy.png'
									}
									alt={myProfile.nickName}
								/>
							</button>
						</Link>
					</div>
				</div>
			</header>

			<div id="conWrap" className="metaPopWrap">
				<div className="tabConWrap">
					<div className="tabContent">
						<div className={`tabCon tabCon01 ${hushHome ? 'active' : ''}`}>
							{/*별점평가*/}
							{randomUsers.length > 0 ? (
								<div className="homeStarWrap">
									<div className="inner">
										<p className="title03">{t('randomUser.todayCharmEval')}</p>
										<div className="evList profileList">
											<div className="profileBox active">
												<div className="profileSlide">
													<Link
														to={PATH.PROFILE.USER_PROFILE}
														onClick={() => {
															setUserProfileOpen(
																randomUsers[0].matchingUserUid,
															);
														}}
														state={{
															isOtherProfile:
																randomUsers[0].matchingUserUid !==
																myProfile.userUid,
														}}
													>
														<img
															src={srcPrefix + randomUsers[0].profileUrls[0]}
														/>
													</Link>
												</div>
												<div className="txtBox">
													{
														randomUsers[0].hasBadged &&
														<span className="addAuthBadge">{t('randomUser.addBadge')}</span>
													}
													<p className="txt01 fz20 color-fff">
														{randomUsers[0].nickName}
													</p>
													<p className="txt02 fz16 color-fff">
														{' '}
														{randomUsers[0].age + t('common.age')},{' '}
														{randomUsers[0].region1 && randomUsers[0].region2
															? `${randomUsers[0].region1} ${randomUsers[0].region2}`
															: t('common.noRegion')}
													</p>
													<div className="profileLikeBox"></div>
												</div>
											</div>
										</div>
										<div className="starBox margin-t16">
											{/*별점평가*/}
											{[5, 4, 3, 2, 1].map((value, i) => {
												const id = 'star' + value.toString() + i;
												return (
													<React.Fragment key={id}>
														<input
															type="radio"
															name="rating"
															id={id}
															value={value}
															checked={isChecked === value ? true : false}
															onChange={(e) => {
																if (isChecked) {
																	e.preventDefault();
																} else {
																	setIsChecked(value);
																	evalHandler(
																		randomUsers[0].matchingUserUid,
																		Number(e.target.value),
																	);
																}
															}}
														/>
														<label htmlFor={id} />
													</React.Fragment>
												);
											})}
										</div>
										<p className="fz12 color-666 text-c margin-t8"
										   dangerouslySetInnerHTML={{ __html: t('randomUser.appealQuote') }}
										>

										</p>
									</div>
								</div>
							) : null}
							{/*별점평가*/}

							<div className="someWrap">
								<div className="inner">
									<p className="title03 bold">{t('manager.quote1')}</p>
									<Link to={PATH.SETTINGS.IDEAL_EDIT}>
										<div className="someGrayBox">
											<p className="title03 bold"
											   dangerouslySetInnerHTML={{ __html: t('manager.quote2') }}
											>
											</p>
											<p className="color-666 fz14 margin-t10">
												{t('manager.quote3')}
											</p>
										</div>
									</Link>
								</div>
							</div>

							<div className="todayListWrap">
								<div className="inner">
									<p className="title03">Today</p>
									<div className="profileList">
										{preferUsers?.length > 0
											? preferUsers.map((profile, i) => {
												return (
													<div key={i} className="profileBox">
														<div className="profileSlide">
															<Link
																to={PATH.PROFILE.USER_PROFILE}
																onClick={() => {
																	setUserProfileOpen(profile.matchingUserUid);
																}}
																state={{
																	isOtherProfile:
																		profile.matchingUserUid !==
																		myProfile.userUid,
																}}
															>
																<Slider {...sliderSettings}>
																	{profile.profileUrls.map((item, i) => {
																		return (
																			<img
																				key={item + i}
																				src={srcPrefix + item}
																				alt={profile.nickName}
																			/>
																		);
																	})}
																</Slider>
															</Link>
														</div>
														<div className="txtBox">
															{profile.hasBadged ? (
																<span className="addAuthBadge">{t('randomUser.addBadge')}</span>
															) : null}
															<p className="txt01 fz20 color-fff">
																{profile.nickName}
															</p>
															<p className="txt02 fz16 color-fff">
																{profile.age + t('common.age')},{' '}
																{profile.region1 && profile.region2
																	? `${profile.region1} ${profile.region2}`
																	: t('common.noRegion')}
															</p>
														</div>
													</div>
												);
											})
											: null}
									</div>
								</div>
							</div>
							{/*오늘의 이상형 추천*/}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
