import { useManagerRepo } from '@dating/repository/manager/useManagerRepo';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useManagerPopService } from '../../../purchase/popup/service/useManagerPopService';
import { MatchedResultCOVO } from '@/site/api';

export const useMyManagerListService = () => {
	const {
		showMyManagerMessageList,
		setShowMyManagerMessageList,
		managerProfile,
	} = useManagerRepo();

	const {
		isOnline,
		setIsOnline,
		showOnlineMessageList,
		setShowOnlineMessageList,
		showOfflineMessageList,
		setShowOfflineMessageList,
		managerEmpty,
		setManagerEmpty,
		isMessenger,
		setIsMessenger,
	} = useDirectMessageRepo();

	const { handleManagerPurchase } = useManagerPopService();
	/*
	 * successFn
	 * 구매권 사용하기
	 */
	const successFn = (
		product: MatchedResultCOVO | undefined | null,
		isOnline: boolean,
		result: any,
	) => {
		if (product) {
			handleManagerPurchase(product, isOnline, result);
		}
	};

	return {
		managerEmpty,
		successFn,
		isOnline,
		setIsOnline,
		showOnlineMessageList,
		setShowOnlineMessageList,
		showOfflineMessageList,
		setShowOfflineMessageList,
		setManagerEmpty,
		isMessenger,
		managerProfile,
		setIsMessenger,
		showMyManagerMessageList,
		setShowMyManagerMessageList,
	};
};
