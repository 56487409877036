import {
	type InAppResultInfoCIVO,
	type OrderInfoCIVO,
	OrderService,
	type PurchaseResultCIVO,
	type TemporayPointChargeCIVO,
} from '@/site/api';

export const useOrderAdapter = () => {

	/*
	 * validateOrder
	 * 주문 가능 여부 확인
	 */
	const validateOrder = (productId: number) => {
		return OrderService.validateOrder(productId);
	};

	/*
	 * createOrder
	 * 주문 하기 (포인트 상품)
	 */
	const createOrder = (map: OrderInfoCIVO) => {
		return OrderService.createOrder(map);
	};

	/*
	 * saveInAppPurchase
	 * 인앱 결제 정보 저장
	 */
	const saveInAppPurchase = (map: PurchaseResultCIVO) => {
		return OrderService.saveInAppPurchase(map);
	};

	/*
	 * temporaryPointCharge
	 * 웹 테스트용 임시 포인트 충전
	 */
	const temporaryPointCharge = (map: TemporayPointChargeCIVO) => {
		return OrderService.temporaryPointCharge(map);
	};

	return {
		createOrder,
		validateOrder,
		saveInAppPurchase,
		temporaryPointCharge,
	};
};
