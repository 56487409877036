import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import { useEffect, useState } from "react";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import { useProfileAddStateRepo } from "@dating/ui/pages/profile/addProfile/useProfileAddStateRepo";
import {useProfileAdapter} from "@dating/adapter/profile/useProfileAdapter";
import {BadgeFormType} from "@dating/model/ProfileModel";
import useProfileRepo from "@dating/repository/profile/useProfileRepo";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthRepo} from "@dating/repository/auth/useAuthRepo";
import {useTranslation} from "react-i18next";

export const useProfileAddService = () => {
    const { t } = useTranslation('profile')
    const title = t('profileBox.addBadge')
    const location = useLocation()
    const navigate = useNavigate()

    const {
        openTypePop,
        setOpenTypePop,
        response,
        setResponse,
    } = useProfileAddStateRepo();

    const {
        previewURLs,
        setPreviewURLs,
        clearPreviewURLs,
        cropFiles,
        setCropFiles,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    const {
        authBadgeForm,
        setAuthBadgeForm,
        clearAuthBadgeForm,
        btnActivate,
        setBtnActivate,
        badgeTypeKor,
        setBadgeTypeKor,
    } = useProfileRepo();

    const {
        osType,
    } = useAuthRepo();

    /*
    * form insert
    * */
    const {
        saveProfileAuth,
        statusProfileAuth,
        dataProfileAuth,
    } = useProfileAdapter()

    /*
    * checkFeed
    * 인증사진 등록/수정 시 파일첨부여부 validation
    */
    const checkFeed = (param: BadgeFormType) => {
        if (!param.files[0]) {
            return "이미지는 필수입니다.";
        }
        return null;
    }

    /*
     * initForm
     * 폼, 미리보기url, 버튼 초기화
     */
    const initForm = () => {
        clearAuthBadgeForm();
        clearPreviewURLs();
        clearCropFiles();
        setBadgeTypeKor('');
        setBtnActivate(false);
    };

    /*
     * backStep
     * 작성/수정화면 초기화 및 뒤로 이동
     */
    const backStep = () => {
        initForm();
    };

    /*
     * removeImg
     * 프로필 사진 1개 삭제
     */
    const removeImg = (id: number) => {
        // 미리보기 리스트에서 해당하는 인덱스 찾아서 지우기
        const newUrls = previewURLs;
        previewURLs.splice(id, 1);
        setPreviewURLs(newUrls);

        // 서버로 삭제할 파일 정보 전송
        const newFiles: Blob[] = [];
        cropFiles.splice(id, 1);
        newFiles.push(...cropFiles);
        setCropFiles(newFiles);
    };

    /*
      * fnSaveAuthAddBadge
      * auth add 신청 저장
      */
    const fnSaveAddProfile = () => {
        const result = checkFeed(authBadgeForm);
        if (result != null) {
            alert(result);
            return;
        }

        const param = {
            files: authBadgeForm.files,
            badgeType: authBadgeForm.badgeType,
            contents: authBadgeForm.contents,
        }
        saveProfileAuth(param)
        setBtnActivate(false);
        initForm()
        setResponse(true)
    };

    const setBadgeTypeClick = (val:{title:string; code:string;}) => {
        setAuthBadgeForm({badgeType: val.code});
        setBadgeTypeKor(val.title);
        setOpenTypePop(false)
    }

    /*
    * 프로필 사진 초기화 안되서 임시 처리
    * */
    useEffect(() =>{
        if(location.state?.from){
            setCropFiles([])
            navigate(location.pathname, { replace: true, state: null });
        }
    },[location])

    /*
    * 버튼 활성화/비활성화 처리
    * */
    useEffect(() => {
        if ((cropFiles.length > 0 || cropFiles[0]?.size > 0) && authBadgeForm.badgeType) {
            setBtnActivate(true);
        } else {
            setBtnActivate(false);
        }
    }, [cropFiles, authBadgeForm.badgeType]);

    useEffect(() => {
        // insert 후 화면 및 관련 repo 초기화
        if (statusProfileAuth === "success" && dataProfileAuth) {
            initForm();
        }
    }, [statusProfileAuth, dataProfileAuth]);

    // 저장 완료 시, 이전페이지로 이동
    useEffect(()=>{
        if (response) navigate(-1);
    },[response])


    return {
        title,
        previewURLs,
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
        fnSaveAddProfile,
        srcPrefix,
        removeImg,
        clearCropFiles,
        openTypePop,
        setOpenTypePop,
        setBadgeTypeClick,
        authBadgeForm,
        setAuthBadgeForm,
        badgeTypeKor,
        initForm,
        backStep,
        osType,
    };
};