/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttendanceCOVO } from '../models/AttendanceCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EventService {
    /**
     * 출석체크 하기
     * @returns boolean
     * @throws ApiError
     */
    public static checkDailyAttendance(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/event/checkIn',
        });
    }
    /**
     * 출석 조회
     * @returns AttendanceCOVO
     * @throws ApiError
     */
    public static getDailyAttendance(): CancelablePromise<AttendanceCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/event/getCheckIn',
        });
    }
}
