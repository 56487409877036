import { useTranslation } from 'react-i18next';

interface ManagerInfoViewProps {
	productSubType: 'ONLINE' | 'OFFLINE';
}

const ManagerInfoView = ({ productSubType }: ManagerInfoViewProps) => {
	const { t } = useTranslation('store');

	const getTitle = () => {
		switch (productSubType) {
			case 'ONLINE':
				return t('screenManager')
			// 스크린 매니저
			case 'OFFLINE':
				return t('offlineManager')
			// 오프라인 매니저
			default:
				return '';
		}
	};

	return (
		<div className="inner margin-t16">
			<div className="titleWrap">
				<h2 className="title02">{getTitle()}</h2>
				<p className="fz14">
					{t('pricePerMeeting')} <span className="bold">85,000</span> {t('priceUnit')}
					{/* 1회 만남 약 <span className="bold">85,000</span> 원 */}
				</p>
				<p className="fz12 color-666 margin-t4">{t('priceFor10Meetings')}
					{/* 10회 결제시 가격입니다 */}
				</p>
			</div>
		</div>
	);
};

export default ManagerInfoView;
