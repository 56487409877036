import { useHomeRepo } from '@dating/repository/home/useHomeRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import {
	AddPreferUserCIVO,
	MatchTypeAndCodeCIVO, type RandomUserOrderInfoCIVO,
	type RandomUserScoreCIVO,
} from '@/site/api';
import { useHomeAdapter } from '@dating/adapter/home/useHomeAdapter';
import { useEffect, useRef, useState } from 'react';
import { useHushHomeRepo } from '@dating/repository/hushHome/useHushHomeRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useHushHomeStateRepo } from '@dating/ui/pages/hushHome/useHushHomeStateRepo';
import {useTranslation} from "react-i18next";

export const useHushHomeService = () => {
	const likeIndex = useRef<number>(-1);

	const {
		scrollBottom,
		setScrollBottom,
		isChecked,
		setIsChecked,
	} = useHushHomeStateRepo();

	const {
		pointConfirmPopOpen,
		setPointConfirmPopOpen,
		pointConfirmFunc,
		setPointConfirmFunc,
		initPointConfirmFunc,
		pointConfirmMessage,
		setPointConfirmMessage,
		initPointConfirmMessage,
	} = useHomeRepo();

	const {
		hushPickOn,
		setHushPickOn,
		savedLikeToday,
		setSavedLikeToday,
		savedLikePick,
		setSavedLikePick,
		randomUsers,
		setRandomUsers,
		preferUsers,
		setPreferUsers,
		hushPickUsers,
		setHushPickUsers,
		hushPickPacks,
		setHushPickPacks,
		isCalled,
		setIsCalled,
	} = useHushHomeRepo();

	const {
		setUserProfileOpen,
		sliderSettings,
	} = useDatingRepo();

	const { t } = useTranslation('home');

	const {
		getPreferUser,
		getRandomUser,
		addRandomUser,
		getRecommendUser,
		saveEvalUser,
		addPreferUser,
	} = useHomeAdapter();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();
	////////////////////////////////////////////////

	useEffect(() => {
		initHushPickOn();

		// 무료 별점평가 리스트 조회
		getRandomUser().then(value => {
			setRandomUsers(value);
		});

		// 무료 Today(이상형설정보로 추천) 리스트 조회
		getPreferUser().then(value => {
			setPreferUsers(value);
		});

	}, []);

	/*
	 * fnGetRecommendUsers
	 * matchType에 따른 이성 추천 (과금)
	 */
	const fnGetRecommendUsers = (param: {
		productId: number,
		matchType: string,
		jobCode?: string,
		orderId: number
	}) => {
		const map: MatchTypeAndCodeCIVO = {
			matchType: param.matchType,
			orderId: param.orderId,
			jobCode: param.jobCode,
		};
		if (param.matchType === 'random') {
			map.matchType = 'prefer';
		}
		getRecommendUser(map).then(response2 => {
			if (response2) {
				let newArray = [response2, ...hushPickUsers];
				setHushPickUsers(newArray);
			}

		}).catch(reason => {
			if (reason.body.statusCode === 400) {
				fnCommonAlertPopOn('조건에 맞는 회원이 없습니다.');
			}
		}).finally(() => {
			initHushPickOn();
		});
	};

	/*
	 * initHushPickOn
	 * 허쉬픽 UI 초기화값 set
	 */
	const initHushPickOn = () => {
		const tmp = hushPickOn;
		hushPickPacks.map(value => {
			tmp[value.title] = false;
		});
		setHushPickOn(tmp);
	};

	/*
     * fnIdealPopOff
     * 이상형 더보기 과금 팝업 닫기
     */
	const fnIdealPopOff = () => {
		setPointConfirmPopOpen(false);
	};

	/*
	 * hushPickPacksHandle
	 * 과금 추천 항목 ui 핸들러
	 */
	const hushPickPacksHandle = (key: string) => {
		initHushPickOn();
		const item = hushPickOn;
		item[key] = true;
		setHushPickOn(item);
	};

	/*
	 * evalHandler
	 * 별점평가 및 애니메이션 처리
	 */
	const evalHandler = async (userUid: string, score: number) => {
		const map: RandomUserScoreCIVO = {
			randomUserId: userUid,
			score: score,
		};
		const response = await saveEvalUser(map);
		if (response) {
			const tmp = randomUsers;
			tmp.shift();
			setRandomUsers(tmp);
			setIsChecked(null);
		}
	};

	/*
	 * fnPointConfirmPopOn
	 * 과금 안내 팝업 열기 - 메세지, 실행함수 세팅
	 */
	const fnPointConfirmPopOn = (message: { tit1: string; tit2: string; price: number }, func: any, param: any) => {
		setPointConfirmMessage(message);
		setPointConfirmFunc({ param: param, func: func });
		setPointConfirmPopOpen(true);
	};

	/*
	 * fnPointConfirmPopOff
	 * 과금 안내 팝업 닫기(취소) - 메세지, 실행함수 초기화
	 */
	const fnPointConfirmPopOff = () => {
		initPointConfirmMessage();
		initPointConfirmFunc();

		// 팝업닫기
		setPointConfirmPopOpen(false);
	};

	/*
	 * fnAddEvalUser
	 * 별점평가 이상형 추가 (과금)
	 */
	const fnAddEvalUser = async (param: { orderId: number }) => {
		const map: RandomUserOrderInfoCIVO = {
			orderId: param.orderId,
		};
		try {
			const result = await addRandomUser(map);
			if (result) {
				getRandomUser().then(repsonse => {
					setRandomUsers(repsonse);
				});
			}
		} catch (e: any) {
			if (e.status === 400) {
				fnCommonAlertPopOn(e.response);
			}
		}
	};

	/*
	 * fnAddPreferUsers
	 * 이상형 추천 리스트 조회 (과금)
	 */
	const fnAddPreferUsers = async (param: { orderId: number }) => {
		try {
			const map: AddPreferUserCIVO = {
				orderId: param.orderId,
			};

			const result = await addPreferUser(map);
			if (result) {
				getPreferUser().then(value => {
					setPreferUsers(value);
					setScrollBottom(true);
				});
			}
		} catch (e: any) {
			if (e.body.statusCode) {
				fnCommonAlertPopOn(e.body.message);
			}
		}
	};

	const setHushPickPacksTranslated = () => {
		const translatedTitles = hushPickPacks.reduce((acc, pack) => {
			acc[pack.titleEng] = t(`pickPacks.${pack.titleEng}`); // i18n 번역 처리
			return acc;
		}, {} as { [key: number]: string });

		// 상태 업데이트
		setHushPickPacks(translatedTitles);
	}

	useEffect(() => {
		setHushPickPacksTranslated()
	}, [t]);

	useEffect(() => {
		// 허쉬픽 좋아요 처리 완료 시 프로필카드 삭제
		if (savedLikePick) {
			const tmp = hushPickUsers;
			tmp.splice(likeIndex.current, 1);
			setHushPickUsers(tmp);

			setSavedLikePick(false);
			likeIndex.current = -1;
		}
	}, [savedLikePick]);

	useEffect(() => {
		// today 좋아요 처리 완료 시 프로필카드 삭제
		if (savedLikeToday) {
			const tmp = preferUsers;
			tmp.splice(likeIndex.current, 1);
			setPreferUsers(tmp);

			setSavedLikeToday(false);
			likeIndex.current = -1;
		}
	}, [savedLikeToday]);

	return {
		randomUsers,
		preferUsers,
		setUserProfileOpen,
		sliderSettings,
		pointConfirmPopOpen,
		fnAddPreferUsers,
		fnPointConfirmPopOn,
		fnPointConfirmPopOff,
		hushPickPacks,
		hushPickPacksHandle,
		hushPickOn,
		hushPickUsers,
		isChecked,
		evalHandler,
		setIsChecked,
		fnAddEvalUser,
		pointConfirmMessage,
		likeIndex,
		fnGetRecommendUsers,
		pointConfirmFunc,
		scrollBottom,
		setScrollBottom,
	};
};