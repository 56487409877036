import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { generateCharacterImages } from '@/utils/avatarUtils';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';

const SmallAvatarProfileView = () => {
	const { costumeColor, faceColor, hairColor, myMetaUser, setMyMetaUser } =
		useMetaUserRepo();
	const characterImages = useMemo(() => {
		if (!myMetaUser?.avatarName) return [];

		return generateCharacterImages(
			myMetaUser.avatarName,
			costumeColor,
			faceColor,
			hairColor,
		);
	}, [myMetaUser?.avatarName, costumeColor, faceColor, hairColor]);

	return (
		<div className="metaProImg">
			<Link to={PATH.SIDE_BAR.AVATAR}>
				<div className="avatar_character avatar_front">
					{characterImages.map((img, index) => (
						<img
							key={index}
							className="avatar_profile_object"
							style={{
								background: `url('${img}')`,
							}}
						/>
					))}
				</div>
			</Link>
		</div>
	);
};

export default SmallAvatarProfileView;
