import {useState} from "react";
import {PostInfoCOVO} from "@/site/api";

export const useFeedListStateRepo = () => {

    // 피드 작성자 닉네임, userUid
    const [postUser, setPostUser] = useState<{userUid: string, nickname: string}>({userUid: "", nickname: ""});

    // 체크된 성별 필터 값
    const [checkedFilter, setCheckedFilter] = useState<string>("A");

    // fnInitFeedEditHandler이 실행되었는지 여부
    const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{postId: string, state: boolean}>({postId: "", state: false});

    return {
        isClickedFeedMenu,
        setIsClickedFeedMenu,
        postUser,
        setPostUser,
        checkedFilter,
        setCheckedFilter,
    };
};