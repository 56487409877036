import { create } from "zustand";
import { HomeMatchingCOVO } from '@/site/api';

interface HushHomeState {
	// 추천받기 ui handler
	hushPickOn: {};
	setHushPickOn: (hushPickOn: {}) => void;

	// 무료 선호이상형 (getPreferUser)
	preferUsers: HomeMatchingCOVO[];
	setPreferUsers: (preferUsers: HomeMatchingCOVO[]) => void;

	// today 좋아요 처리 결과
	savedLikeToday: boolean;
	setSavedLikeToday: (savedLikeToday: boolean) => void;

	// hushPick 좋아요 처리 결과
	savedLikePick: boolean;
	setSavedLikePick: (savedLikeToday: boolean) => void;

	// 무료 별점평가 (getRandomUser)
	randomUsers: HomeMatchingCOVO[];
	setRandomUsers: (randomUsers: HomeMatchingCOVO[]) => void;

	// HUSH추천
	hushPickUsers: HomeMatchingCOVO[];
	setHushPickUsers: (hushPickUsers: HomeMatchingCOVO[]) => void;

	// 허쉬픽 추천 리스트-금액
	hushPickPacks: {title: string; titleEng:string; iconUrl: string; matchType: string; jobCode?: string; productIndex: number; titleLang?:string;}[];
	setHushPickPacks: (translatedTitle: { [key: number]: string }) => void;
};

export const useHushHomeRepo = create<HushHomeState>(
	(set) => ({
		// 추천받기 ui handler
		hushPickOn: {},
		setHushPickOn: (hushPickOn: {}) => set({hushPickOn}),

		// 무료 선호이상형 (getPreferUser)
		preferUsers: [],
		setPreferUsers: (preferUsers: HomeMatchingCOVO[]) => set({ preferUsers }),

		// today 좋아요 처리 결과
		savedLikeToday: false,
		setSavedLikeToday: (savedLikeToday: boolean) => set({savedLikeToday}),

		// hushPick 좋아요 처리 결과
		savedLikePick: false,
		setSavedLikePick: (savedLikePick: boolean) => set({savedLikePick}),

		// 무료 별점평가 (getRandomUser)
		randomUsers: [],
		setRandomUsers: (randomUsers: HomeMatchingCOVO[]) => set({ randomUsers }),

		// HUSH추천
		hushPickUsers: [],
		setHushPickUsers: (hushPickUsers: HomeMatchingCOVO[]) => set({hushPickUsers}),


		// 허쉬픽 추천 리스트-금액
		hushPickPacks: [
			{
				title: 'HUSH 추천',
				titleEng:'recommend',
				matchType: 'random',
				iconUrl: '/assets/img/common/ico-pick01.svg',
				productIndex: 2,
			},
			{
				title: '상위 10% 이성',
				titleEng:'top',
				matchType: 'top',
				iconUrl: '/assets/img/common/icon/ico-pick-top10p.svg',
				productIndex: 3,
			},
			{
				title: '최근 접속 이성',
				titleEng:'recentLogin',
				matchType: 'login',
				iconUrl: '/assets/img/common/icon/ico-pick-recent.svg',
				productIndex: 4,
			},
			{
				title: '',
				titleEng:'',
				matchType: 'tall',
				iconUrl: '/assets/img/common/icon/ico-pick-topheight.svg',
				productIndex: 5,
			},
			{
				title: '전문직',
				titleEng:'professional',
				matchType: 'job',
				jobCode: '007',
				iconUrl: '/assets/img/common/icon/ico-pick-profession.svg',
				productIndex: 6,
			},
			{
				title: '공무원',
				titleEng:'official',
				matchType: 'job',
				jobCode: '008',
				iconUrl: '/assets/img/common/icon/ico-pick-public.svg',
				productIndex: 7,
			},
			{
				title: '의료직',
				titleEng:'medical',
				matchType: 'job',
				jobCode: '014',
				iconUrl: '/assets/img/common/icon/ico-pick-medical.svg',
				productIndex: 8,
			},
			{
				title: '사업가',
				titleEng:'business',
				matchType: 'job',
				jobCode: '006',
				iconUrl: '/assets/img/common/icon/ico-pick-ceo.svg',
				productIndex: 9,
			},
			{
				title: '자영업',
				titleEng:'selfEmployed',
				matchType: 'job',
				jobCode: '004',
				iconUrl: '/assets/img/common/icon/ico-pick-selfbusiness.svg',
				productIndex: 11,
			},
		],
		setHushPickPacks: (translatedTitle) =>
			set((state) => ({
				hushPickPacks: state.hushPickPacks.map((pack) => ({
					...pack,
					titleLang: translatedTitle[pack.titleEng] || pack.title, // 번역된 값 세팅
				})),
			})),
	}),
);