import { Outlet } from 'react-router-dom';
import React from 'react';
import {useFeedListService} from "@dating/ui/pages/lounge/feedList/service/useFeedListService";
import {FeedListHeaderView} from "@dating/ui/pages/lounge/feedList/views/FeedListHeaderView";

export const LoungeLayout = () => {
	const {
		commentState,
	} = useFeedListService();

	return (
		<div className={`${commentState ? 'wrap no-scroll footerNone' : 'wrap'}`}>
			<FeedListHeaderView />
			<Outlet />
		</div>
	);
};